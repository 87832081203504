import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 8px;

  & > p {
    padding-bottom: 8px;
    padding-top: 16px;
  }
`
