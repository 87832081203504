import React, { useContext, useEffect, useMemo } from 'react'

import { useLiveQuery } from 'dexie-react-hooks'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'

import { EVALUATION_COMPLETE_TITLE } from 'constants/evaluation-complete'
import { EntityTypes, INITIAL_FILL_EVALUATION_STATE } from 'constants/evaluations'
import { Colors } from 'constants/global'
import { routes } from 'constants/routes'
import { NetworkStatusContext } from 'contexts/NetworkContext'
import { fetchOfflineFullList } from 'db/fetch-db'
import { proceedEvaluationFeedback } from 'db/helpers'
import useDownloadEvaluationJson from 'hooks/useDownloadEvaluationJson'
import { asyncGetMyFillEvaluations, finishFillEvaluation, setFillEvaluation } from 'modules/fill-evaluation/action'
import { selectAnswersData, selectEvaluationForComplete, selectQuestions } from 'modules/fill-evaluation/selectors'
import { useAppDispatch, useAppSelector } from 'modules/store'
import { EvaluationCompleteContent, EvaluationCompleteContentSkeleton, HomeContentLayout } from 'page-components'
import { EvaluationCompleteActionsWrapper } from 'page-components/home/evaluation-complete/style'
import { BottomActions, ContentCard, LoadingLine, TextEditor } from 'shared-components'
import { getFillEvaluationLink, getFlatAndFilteredEvaluationCompletion } from 'utils'

import { EvaluationCompleteWrapper } from './style'

const EvaluationComplete = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const isOnline = useContext(NetworkStatusContext)

  const { handleDownloadJson } = useDownloadEvaluationJson({ id: Number(id) })

  const {
    title,
    id: evaluationId,
    completed_at,
    feedback: evaluationFeedback,
    isLoading,
  } = useAppSelector(selectEvaluationForComplete)

  const {
    title: offlineTitle,
    feedback: offlineFeedback,
    questions: offlineQuestions,
  } = useLiveQuery(() => fetchOfflineFullList(Number(id)), [id]) || {}

  const evaluationData = useAppSelector(selectAnswersData)
  const questions = useAppSelector(selectQuestions)
  const { questionsLength, answersCount } = getFlatAndFilteredEvaluationCompletion(
    isOnline ? questions : offlineQuestions || [],
  )

  const evaluationTitle = isOnline ? title : offlineTitle || ''

  const evaluationCurrentFeedback = isOnline ? evaluationFeedback : offlineFeedback

  const submitButtonText = isOnline ? 'Complete evaluation' : 'Save'

  const exitButtonText = isOnline ? 'Save and Exit' : 'Exit'

  const formik = useFormik({
    initialValues: {
      feedback: evaluationFeedback || '',
      isExit: false,
    },
    onSubmit: async ({ feedback, isExit }) => {
      const numberId = Number(id)

      if (!isOnline) {
        await proceedEvaluationFeedback({ id: numberId, feedback })

        await handleDownloadJson(feedback)

        navigate(isExit ? getFillEvaluationLink(numberId) : routes.myEvaluations)

        return
      }

      dispatch(
        finishFillEvaluation({
          id: numberId,
          feedback,
          action: !isExit ? 'complete' : undefined,
        }),
      )
        .unwrap()
        .then(() => {
          if (isExit) {
            dispatch(setFillEvaluation(INITIAL_FILL_EVALUATION_STATE))

            navigate(getFillEvaluationLink(numberId))
            return
          }
          dispatch(setFillEvaluation(INITIAL_FILL_EVALUATION_STATE))
          navigate(routes.myEvaluations)
        })
    },
    enableReinitialize: true,
  })

  const notCompletedEvaluations = useMemo(() => {
    return evaluationData.questions.filter((i) =>
      i.entityType === EntityTypes.QUESTION ? !i.parent_id && !i.answer : true,
    )
  }, [evaluationData.questions])

  const handleFinishEvaluation = () => {
    formik.setFieldValue('isExit', false)
    formik.handleSubmit()
  }

  const handleSaveAndExist = () => {
    formik.setFieldValue('isExit', true)
    formik.handleSubmit()
  }

  useEffect(() => {
    if (completed_at && id) navigate(routes.myEvaluations)
  }, [completed_at, id, navigate])

  useEffect(() => {
    if (evaluationId !== Number(id) && isOnline) dispatch(asyncGetMyFillEvaluations(Number(id)))
  }, [isOnline, dispatch, evaluationId, id])

  if ((isOnline && ((!evaluationId && !notCompletedEvaluations?.length) || evaluationId !== Number(id))) || isLoading)
    return <EvaluationCompleteContentSkeleton />

  return (
    <HomeContentLayout title={EVALUATION_COMPLETE_TITLE + `"${evaluationTitle}"`}>
      <LoadingLine isLoading={isLoading} />
      <EvaluationCompleteWrapper>
        <ContentCard spaces='0' title={evaluationTitle} topBackground={Colors.GRAY}>
          <EvaluationCompleteContent
            id={evaluationId}
            totalQuestionsCount={questionsLength}
            positiveAnswersCount={answersCount}
          >
            <TextEditor
              label='Evaluation Feedback'
              name='feedback'
              value={formik.values.feedback}
              error={formik.errors.feedback}
              setFieldValue={formik.setFieldValue}
              defaultValue={evaluationCurrentFeedback}
            />
          </EvaluationCompleteContent>
          <EvaluationCompleteActionsWrapper>
            <BottomActions
              onSubmit={handleFinishEvaluation}
              handleAdditionalButton={handleSaveAndExist}
              submitButtonText={submitButtonText}
              navigateButtonText={exitButtonText}
              submitBackground={Colors.GREEN}
            />
          </EvaluationCompleteActionsWrapper>
        </ContentCard>
      </EvaluationCompleteWrapper>
    </HomeContentLayout>
  )
}

export default EvaluationComplete
