import { isBefore, isSameDay } from 'date-fns'
import * as Yup from 'yup'

import { DeviceInfo, FillEvaluation, FormField, QuestionType } from 'interfaces'
import { Button, DatePicker, Select, TextEditor } from 'shared-components'

export enum EntityTypes {
  SECTION = 'section',
  QUESTION = 'question',
}

export enum MyTasksFieldNames {
  PRECANNED_RECOMMENDATIONS = 'precannedRecomendations',
  PREFILL_RECOMMENDATIONS = 'prefillRecommendations',
  PRIORITY = 'priority',
  COMPLETION_DUE = 'completionDue',
  ASSIGNED_TO = 'assignedTo',
  RECOMMENDATION = 'recommendation',
}

export const OWN_EVALUATIONS_TITLE = 'My Evaluations'

export const OWN_EVALUATIONS_DOWNLOAD_MODAL_TITLE = 'Download evaluation'

export const OWN_EVALUATIONS_DOWNLOAD_MODAL_SUBMIT_TEXT = 'Unload'

export const OWN_EVALUATIONS_OFFLINE_TEXT = `Select download to access evaluations in offline mode`

export const EVALUATION_ANSWERED_TEXT = 'answered'

export const EVALUATION_NOT_ANSWERED_TEXT = 'not answered'

export const DISABLED_EVALUATION_TEXT = 'Question marked as Not Applicable.'

export const MY_EVALUATIONS_HEADERS = ['Evaluation Title', 'Question set', 'Category', 'Area', 'Due', 'Status']

export const MY_EVALUATIONS_ITEM_STATUS_COMPLETE = 'Completed'

export const MY_EVALUATIONS_ITEM_STATUS_OVERDUE = 'Overdue'

export const MY_EVALUATIONS_ITEM_STATUS_UPCOMING = 'Upcoming'

export const MY_EVALUATIONS_ITEM_STATUS_IN_PROGRESS = 'In progress'

export const MY_EVALUATIONS_NAVIGATE_TEXT = 'Back to evaluation'

export const MY_EVALUATIONS_COMPLETE_TEXT = 'Complete Evaluation'

export const NO_EVALUATIONS_MESSAGE_TEXT = 'No evaluations have been assigned'

export const EVALUATION_SUBMIT_EVALUATION_TEXT = 'Proceed to Feedback'

export const INITIAL_FILL_EVALUATION_STATE: FillEvaluation = {
  isLoading: false,
  isModalLoading: false,
  isQuestionLoading: false,
  offlineUploadProgress: null,
  id: null,
  title: '',
  start_date: null,
  questions: [],
  downloaded_at: null,
  deviceToken: null,
  deviceDescription: null,
  completed_at: null,
  assignedTo: [],
  category: '',
  sectionCompletions: null,
  evaluationCompletions: null,
  feedback: '',
}

export const MyTasksFields: FormField[] = [
  {
    name: MyTasksFieldNames.PRECANNED_RECOMMENDATIONS,
    type: 'select',
    label: 'Pre-canned Recommendations',
    component: Select,
    options: [],
  },
  {
    name: MyTasksFieldNames.PREFILL_RECOMMENDATIONS,
    label: 'Prefill Recommendation',
    type: 'button',
    onClick: () => '',
    component: Button, // Replace 'Button' with the desired button component
  },
  {
    name: MyTasksFieldNames.PRIORITY,
    type: 'select',
    label: 'Priority',
    placeholder: ' ',
    component: Select,
    options: [],
    validation: Yup.string().required('Required field'),
  },
  {
    name: MyTasksFieldNames.COMPLETION_DUE,
    placeholder: 'Completion Due',
    label: 'Completion Due',
    type: 'date',
    component: DatePicker,
    validation: Yup.string()
      .required('Required field')
      .test('dob', 'Date should be today or later', (value) => {
        const date = new Date(value as string)
        return !isBefore(date, new Date()) || isSameDay(date, new Date())
      }),
  },
  {
    name: MyTasksFieldNames.ASSIGNED_TO,
    type: 'select',
    label: 'Assigned To',
    placeholder: ' ',
    component: Select,
    options: [],
    validation: Yup.string().required('Required field'),
  },
  {
    name: MyTasksFieldNames.RECOMMENDATION,
    label: 'Recommendation',
    type: 'editor',
    placeholder: '',
    component: TextEditor,
    validation: Yup.string().required('Required field'),
  },
]

export const TASKS_FORM_HEADERS = ['Recommendation', 'Assigned To', 'Priority', 'Due Date', 'Completed']

export const EVALUATION_MANUAL_SAVE_QUESTION_TYPES = [
  QuestionType.MULTIPLE,
  QuestionType.TEXT,
  QuestionType.DATE,
  QuestionType.MARKS,
  QuestionType.PERCENTAGE,
  QuestionType.NUMBER,
]

export const defaultDeviceInfo: DeviceInfo = {
  deviceToken: null,
  deviceDescription: null,
  downloaded_at: null,
}
