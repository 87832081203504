/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { FC } from 'react'

import { FormikProps } from 'formik'

import { Colors } from 'constants/global'
import { TableDataFormItem } from 'interfaces'
import { BottomActions } from 'shared-components'

import { SettingsActionsWrapper, SettingsWrapper } from './style'

interface Props {
  fields: TableDataFormItem[]
  hideNavigate?: boolean
  submitText?: string
  navigateText?: string
  actionFunctions?: any
  formik: FormikProps<any>
  isDisabled?: boolean
  disabledFields?: string[]
  isHideBottomActions?: boolean
  navigateLink?: string
}

const Settings: FC<Props> = ({
  fields,
  formik,
  disabledFields,
  isHideBottomActions,
  submitText,
  navigateText,
  isDisabled = false,
  ...actionsProps
}) => {
  return (
    <form onSubmit={formik.handleSubmit}>
      <SettingsWrapper>
        {fields.map(({ component, renderRelation, initial, ...props }) => {
          if (renderRelation && !formik.values[renderRelation]) return null

          const Component = component
          return (
            <Component
              key={props.name}
              {...props}
              {...formik.getFieldProps(props.name)}
              error={formik.errors[props.name]}
              {...(props.type === 'custom' && { setFieldValue: formik.setFieldValue })}
              {...(props.type === 'checkbox' && { checked: formik.values[props.name] || false })}
              disabled={isDisabled || disabledFields?.includes(props.name)}
            />
          )
        })}
      </SettingsWrapper>
      {!isHideBottomActions && (
        <SettingsActionsWrapper>
          <BottomActions
            customBackground={Colors.GRAY1}
            submitButtonText={submitText}
            navigateButtonText={navigateText}
            {...actionsProps}
          />
        </SettingsActionsWrapper>
      )}
    </form>
  )
}

export default Settings
