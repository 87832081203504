import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'modules/store'
import { convertCategoriesListToOptions } from 'utils/categories'

const selectState = (x: RootState) => x.categoriesReducer

export const selectCategoriesWithPagination = createSelector(
  selectState,
  ({ categories, total, current_page, per_page }) => ({
    total,
    categories,
    pageSize: per_page,
    current: current_page,
  }),
)

export const selectCategoryItemById = (id?: string) =>
  createSelector(selectState, ({ categories }) => {
    return categories.find((i) => i?.id === Number(id))
  })

export const selectCategoryItem = createSelector(selectState, ({ currentCategory }) => currentCategory)

export const selectIsLoading = createSelector(selectState, (state) => state.isLoading)

export const selectCategoriesListAsOptions = createSelector(selectState, (state) =>
  convertCategoriesListToOptions(state.categoriesList),
)
