import * as Yup from 'yup'

import { TableDataFormItem } from 'interfaces'
import { RadioV2, Input, Select } from 'shared-components'

export enum AreaFieldsTypes {
  SINGLE_LINE = 'singleLine',
  MULTI_LINE = 'multiLine',
  EMAIL = 'email',
  DATE = 'date',
}

export const AREA_FIELDS_PAGE_TITLE = 'Area fields'
export const AREA_FIELDS_CONTENT_TITLE = 'Area Fields list'

export const AREA_FIELDS_TABLE_HEADERS = ['Name', 'Type', 'Created', 'Updated', 'Mandatory']

export const AREA_FIELDS_ADD_PAGE_TITLE = 'Add Area Field'
export const AREA_FIELDS_EDIT_PAGE_TITLE = 'Edit Area Field'

export const AREA_FIELDS_ADD_TEXT = 'Add area field'
export const AREA_FIELDS_EDIT_TEXT = 'Update area field'

export const DEFAULT_TYPE_REQUIRED_OPTION = {
  name: 'Choose type',
  value: '',
}

export const TEMPORARY_TYPE_OPTIONS = [
  { name: 'Single Line', value: AreaFieldsTypes.SINGLE_LINE },
  { name: 'Multi Line', value: AreaFieldsTypes.MULTI_LINE },
  { name: 'Date', value: AreaFieldsTypes.DATE },
  { name: 'E-mail', value: AreaFieldsTypes.EMAIL },
]

export const AREA_FIELDS_FORM_FIELDS: TableDataFormItem[] = [
  {
    name: 'name',
    placeholder: 'Enter area field name',
    label: 'Name',
    type: 'text',
    component: Input,
    validation: Yup.string().required('Required field'),
  },
  {
    name: 'type',
    label: 'Type',
    component: Select,
    options: [DEFAULT_TYPE_REQUIRED_OPTION, ...TEMPORARY_TYPE_OPTIONS],
    validation: Yup.string().required('Required field'),
  },
  {
    name: 'isMandatory',
    label: 'Mandatory?',
    component: RadioV2,
    type: 'radio',
    isNumber: true,
    initial: 0,
  },
]
