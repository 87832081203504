import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'modules/store'

const selectState = (x: RootState) => x.userReducer

export const selectUserData = createSelector(selectState, (state) => state?.user)

export const selectUserId = createSelector(selectState, (state) => state?.user?.id)

export const selectAuthError = createSelector(selectState, (state) => state?.error)

export const selectAuthErrors = createSelector(selectState, (state) => state?.errors)

export const selectIsAuthOptions = createSelector(selectState, (state) => ({
  isAuth: state?.isAuth,
  isLoaded: state?.isLoaded,
  isTwoFactor: state?.required2fa,
  twoFactorChannel: state?.channel2fa,
  isUserLoading: state?.isUserLoading,
}))

export const select2Fa = createSelector(selectState, (state) => ({
  isResended: state?.isResended,
  channel2fa: state?.channel2fa,
  access_token: state?.access_token,
}))

export const selectUserName = createSelector(selectState, (state) => state?.user?.name)

export const selectIsAuthLoading = createSelector(selectState, (state) => state.isLoading)

export const selectUserPinCode = createSelector(selectState, (state) => state?.userPinCode)
