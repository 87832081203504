import styled from 'styled-components'

import { RadioV1Wrapper } from 'shared-components/radio/radio-v1/style'

export const CheckboxWrapper = styled(RadioV1Wrapper)<{
  checked?: boolean
}>`
  background-color: ${({ checked, theme }) => (checked ? theme.colors.green5 : theme.colors.green1)};

  &:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  &:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  & svg {
    position: absolute;
    top: calc(50% - 6px);
    left: calc(50% - 5px);
    border-radius: 50%;
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    color: ${({ theme }) => theme.colors.green5};
  }

  & span {
    background-color: #fff;
    border-radius: 2px;

    border-left: 1px solid transparent;
    border-right: 1px solid transparent;

    &:hover {
      border-color: ${({ theme }) => theme.colors.gray3};
    }
  }
  & span:after {
    border-radius: 0;
  }
`
