import React, { FC, ReactNode, useContext } from 'react'

import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { REMEMBER_ME_FIELD } from 'constants/auth'
import { routes } from 'constants/routes'
import { NetworkStatusContext } from 'contexts/NetworkContext'
import { FormikProps, AuthField } from 'interfaces'
import { useAppSelector } from 'modules/store'
import { selectAuthError, selectIsAuthLoading } from 'modules/user/selectors'
import { AuthHeader, AuthLayout } from 'page-components'
import { Checkbox, Input, LoadingLine } from 'shared-components'

import AuthOfflineContent from './AuthOfflineContent'
import { AuthContentForm, AuthFooter, AuthFormBottom, Separator, Button, ErrorMessageStyle } from './styles'

interface Props {
  fields: AuthField[]
  title: string
  isShownCheckbox?: boolean
  submitButtonProps: {
    label: string
    isShownIcon?: boolean
    customButtonIcon?: IconProp
    buttonClassName?: string
  }
  inputClassName?: string
  isShownFooter?: boolean
  formikProps: FormikProps
  additionalInfoSection?: ReactNode
}

const AuthContent: FC<Props> = ({
  fields,
  title,
  isShownCheckbox = false,
  submitButtonProps,
  isShownFooter = true,
  formikProps,
  inputClassName,
  additionalInfoSection,
}) => {
  const isOnline = useContext(NetworkStatusContext)

  const { label, isShownIcon = true, customButtonIcon, buttonClassName } = submitButtonProps

  const { values, errors, handleSubmit, onChange } = formikProps

  const isLoading = useAppSelector(selectIsAuthLoading)

  const authFieldErrorMessage = useAppSelector(selectAuthError)

  return (
    <AuthLayout isEnlarged={!isOnline}>
      <LoadingLine isLoading={isLoading} />
      <AuthHeader title={title} />
      <Separator />
      {isOnline ? (
        <AuthContentForm onSubmit={handleSubmit}>
          {additionalInfoSection}
          {fields?.length &&
            fields.map((field) => (
              <Input
                key={field.name}
                {...field}
                onChange={onChange}
                value={values[field.name]}
                error={errors[field.name]}
                className={inputClassName}
              />
            ))}
          <ErrorMessageStyle>{authFieldErrorMessage}</ErrorMessageStyle>
          <AuthFormBottom>
            {isShownCheckbox && REMEMBER_ME_FIELD.label && (
              <Checkbox
                label={REMEMBER_ME_FIELD.label}
                name={REMEMBER_ME_FIELD.name}
                value={values[REMEMBER_ME_FIELD.name]}
                onChange={onChange}
              />
            )}
            <Button className={buttonClassName} isShownCheckbox={isShownCheckbox} type='submit'>
              {isShownIcon && <FontAwesomeIcon icon={customButtonIcon || (faSignInAlt as IconDefinition)} />}
              {label}
            </Button>
          </AuthFormBottom>
        </AuthContentForm>
      ) : (
        <AuthOfflineContent />
      )}

      {isShownFooter && isOnline && (
        <AuthFooter>
          <Link to={routes.reset}>I forgot my password</Link>
        </AuthFooter>
      )}
    </AuthLayout>
  )
}

export default AuthContent
