import styled from 'styled-components'

export const TableWrapper = styled.div`
  width: 100%;
`

export const TableContent = styled.div`
  flex-wrap: wrap;
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.gray11};
`

export const TableItem = styled.div`
  padding: ${({ theme }) => theme.spaces.min2};
  border: 1px solid ${({ theme }) => theme.colors.gray11};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 25%;

  @media (max-width: 567px) {
    flex: 0 1 33.333%;
  }
  @media (max-width: 400px) {
    flex: 0 1 50%;
  }

  & > span {
    display: inline-block;
    border: 1px solid ${({ theme }) => theme.colors.blue1};
    border-radius: 4px;
    padding: ${({ theme }) => `${theme.spaces.min1} ${theme.spaces.min2}`};
    color: ${({ theme }) => theme.colors.blue1};
    line-height: 24px;
    transition: 0.3s all ease;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.colors.blue1};
      color: #fff;
    }
  }
`
