import { createSlice } from '@reduxjs/toolkit'

import { BASE_PAGINATION_DATA } from 'constants/shared'
import { CategoriesInitialState } from 'interfaces'

import { CATEGORIES_SLICE_NAME } from './action'
import {
  createCategoryReducer,
  deleteCategoryReducer,
  editCategoryReducer,
  getAllCategoriesReducer,
  getCategoriesListReducer,
  getCategoryReducer,
} from './reducer'

const initialState: CategoriesInitialState = {
  categories: [],
  categoriesList: [],
  currentCategory: null,
  isLoading: false,
  ...BASE_PAGINATION_DATA,
}

export const categoriesSlice = createSlice({
  name: CATEGORIES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getAllCategoriesReducer(builder)
    getCategoryReducer(builder)
    createCategoryReducer(builder)
    editCategoryReducer(builder)
    deleteCategoryReducer(builder)
    getCategoriesListReducer(builder)
  },
})

export default categoriesSlice.reducer
