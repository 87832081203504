import React, { FC, useEffect } from 'react'

import { FormikHelpers } from 'formik'
import ReactSummernote from 'react-summernote'

import { FormikPropsItem } from 'interfaces'
import { setWarningNotify } from 'modules/notifications/action'
import { useAppDispatch } from 'modules/store'
import { Label, Error } from 'shared-components/input/styles'
import 'configs/summernote-en-GB'

import { TextEditorWrapper } from './style'

interface Props extends Omit<FormikPropsItem, 'onChange'> {
  label?: string
  name: string
  value: string
  setFieldValue: FormikHelpers<string>['setFieldValue']
  error?: string
  defaultValue?: string
}

const MAX_FILE_SIZE = 1024 * 1024 * 5

const TextEditor: FC<Props> = ({ label, setFieldValue, value, name, error, defaultValue }) => {
  const editorRef = React.useRef<any>(null)
  const dispatch = useAppDispatch()

  function uploadImage(list: FileList) {
    Array.from(list).forEach((file) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        editorRef?.current?.insertImage(reader.result)
      }
      if (file.size >= MAX_FILE_SIZE) {
        dispatch(setWarningNotify('Image size is too big'))
        return
      }
      reader.readAsDataURL(file)
    })
  }

  useEffect(() => {
    if (defaultValue) {
      editorRef?.current?.editor?.summernote('code', defaultValue)
    }
  }, [defaultValue])

  return (
    <TextEditorWrapper>
      {label && <Label>{label}</Label>}
      <ReactSummernote
        ref={editorRef}
        value={value}
        options={{
          lang: 'en-GB',
          dialogsInBody: true,
          toolbar: [
            ['font', ['bold', 'underline', 'clear']],
            ['fontname'],
            ['fontsize'],
            ['color'],
            ['para', ['ul', 'ol', 'paragraph']],
            ['table'],
            ['link'],
            ['view', []],
          ],
        }}
        onChange={(value: string) => setFieldValue(name, value)}
        onImageUpload={uploadImage}
      />
      <Error className='error'>{error}</Error>
    </TextEditorWrapper>
  )
}

export default TextEditor
