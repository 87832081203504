import { AxiosRequestConfig } from 'axios'

import { httpApiClient } from 'configs/http-client'
import { DashboardValues } from 'interfaces'

export class DashboardApi {
  constructor(private url: string) {}

  getDashboardInfo(config?: AxiosRequestConfig) {
    return httpApiClient.get<DashboardValues>(this.url, config)
  }
}

export const dashboardApi = new DashboardApi('/dashboard')
