import styled from 'styled-components'

import { Colors } from 'constants/global'

export const ContentContainer = styled.div<{
  customBackground?: Colors
  isStraight?: boolean
}>`
  display: flex;
  margin-top: ${({ theme }) => theme.spaces.min3};
  padding: ${({ theme }) => theme.spaces.min5};
  background: ${({ customBackground, theme }) => (customBackground ? theme.colors[customBackground] : '#fff')};
  box-shadow: ${({ theme }) => theme.shadows.shadow2};
  border-radius: ${({ isStraight }) => (isStraight ? 0 : '4px')};

  & button:nth-child(2) {
    margin-left: 4px;
  }
`
