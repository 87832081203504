import { createSlice } from '@reduxjs/toolkit'

import { DashboardValues } from 'interfaces'

import { DASHBOARD_SLICE_NAME } from './action'
import { dashboardInfoReducer } from './reducers'

const initialState: DashboardValues = {
  users: null,
  roles: null,
  questionSets: null,
  evaluations: null,
}

export const dashboardSlice = createSlice({
  name: DASHBOARD_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    dashboardInfoReducer(builder)
  },
})

export default dashboardSlice.reducer
