import styled from 'styled-components'

export const HomeContainer = styled.div`
  min-height: 100vh;
  display: flex;
  background: ${({ theme }) => theme.colors.gray8};
`

export const HomeContent = styled.div<{ isOpen: boolean }>`
  width: 100%;
  transition: 0.3s all ease-in-out;
  & > div,
  & header > div {
    transition: 0.3s all ease-in-out;
    padding-left: ${({ isOpen }) => (isOpen ? '250px' : '74px')};

    @media (max-width: 996px) {
      padding-left: 0;
    }
  }
`

export const ContentWrapper = styled.div<{
  isOpen: boolean
}>`
  transition: 0.3s all ease-in-out;

  @media (max-width: 996px) {
    padding-left: 0;
  }
`
