import React, { FC } from 'react'

import { Logo } from 'shared-components'

import { AuthContentHeader, Title } from './styles'

interface Props {
  title?: string
}

const AuthHeader: FC<Props> = ({ title }) => {
  return (
    <AuthContentHeader>
      <Logo />
      {title && <Title>{title}</Title>}
    </AuthContentHeader>
  )
}

export default AuthHeader
