import { EntityTypes } from 'constants/evaluations'
import { DragItem, QuestionSetsListItem, QuestionSetV4 } from 'interfaces'

import { getQuestionSetsEditQuestionLink, getQuestionSetsEditSectionLink } from './url'

export const questionSetsToDrag = (questionSetsId: number, sections: QuestionSetsListItem[]): DragItem[] =>
  sections.map(({ id, name, questions, entityType, parent_id }) => ({
    id,
    text: name,
    parent_id,
    entityType,
    link:
      entityType === EntityTypes.SECTION
        ? getQuestionSetsEditSectionLink(questionSetsId, id)
        : getQuestionSetsEditQuestionLink(questionSetsId, id),
    children: questions?.map(({ id: questionId, name, entityType, parent_id }) => ({
      id: questionId,
      text: name,
      entityType,
      parent_id,
      link: getQuestionSetsEditQuestionLink(questionSetsId, questionId),
    })),
  }))

export const getFlattedQuestionSetsQuestionList = (sections: QuestionSetsListItem[]) => {
  return sections
    .map(({ questions, ...i }) => {
      const { entityType } = i
      if (entityType === EntityTypes.SECTION && questions?.length) return [i, ...questions]
      return i
    })
    .flat(3)
}

export const convertQuestionSetsV4ListToOptions = (questionSets: QuestionSetV4[]) => {
  if (!questionSets?.length) return []

  return questionSets?.map(({ questionSetId, questionSetName }) => ({ name: questionSetName, value: questionSetId }))
}
