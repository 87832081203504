import * as Yup from 'yup'

import { ProfileSetting } from 'interfaces'

export const PROFILE_PAGE_TITLE = 'User profile'

export const PROFILE_SETTINGS_FIELDS: ProfileSetting[] = [
  {
    title: 'Basic information',
    fields: [
      {
        name: 'name',
        placeholder: 'Name',
        type: 'text',
        validation: Yup.string().required('Required field').min(5, 'Min 5 characters').max(20, 'Max 20 characters'),
      },
      {
        name: 'email',
        placeholder: 'Email',
        type: 'text',
        disabled: true,
        validation: Yup.string().email('Invalid email address'),
      },
    ],
  },
  {
    title: 'Change password',
    path: '/password',
    fields: [
      {
        name: 'current_password',
        placeholder: 'Current password',
        type: 'password',
        validation: Yup.string().required('Write your current password'),
      },
      {
        name: 'password',
        placeholder: 'New password',
        type: 'password',
        validation: Yup.string()
          .required('Write your new password')
          .min(8, 'New password must be at least 8 characters')
          .max(20, 'New password must be a maximum of 20 characters')
          .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,20}$/,
            'New password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 symbol',
          ),
      },
      {
        name: 'password_confirmation',
        placeholder: 'Confirm password',
        type: 'password',
        validation: Yup.string().when('password', {
          is: (val: string) => val && val.length > 0,
          then: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm your new password'),
          otherwise: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
        }),
      },
    ],
  },
  {
    title: 'Two factor Authentication',
    text: [
      'Add additional security to your account using two factor authentication.',
      'When two factor authentication is enabled, you will be prompted for a secure, random token during authentication.' +
        " You may retrieve this token from your phone's Google Authenticator application or from your mailbox.",
    ],
    fields: [
      {
        name: 'twoFactor',
        type: 'select',
        options: [
          {
            name: 'Disabled',
            value: 'disabled',
          },
          {
            name: 'E-mail',
            value: 'email',
          },
          {
            name: 'Google Authenticator',
            value: 'google',
          },
        ],
      },
    ],
  },
]
