import React, { FC, InputHTMLAttributes, useMemo, useRef } from 'react'

import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { DEFAULT_UPLOAD_ALLOWED_EXTENSIONS } from 'constants/shared'
import { HandleOpenAndSubmitConfirmType } from 'hooks/useConfirm'
import { UploadFile } from 'interfaces'
import { Label } from 'shared-components/input/styles'

import InputFileListItem from './InputFileListItem'
import { ChooseFiles, ChooseFilesToUpload, InputFileWrapper, FileWrapper, SelectedFilesList } from './style'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  onUploadFiles: (files: File[]) => void
  onDeleteFile: HandleOpenAndSubmitConfirmType
  files?: UploadFile[]
}

const InputFile: FC<Props> = ({ label, onUploadFiles, onDeleteFile, files = [], ...props }) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const chosenFilesText = useMemo(() => {
    if (!files?.length) return 'Choose files to upload'

    if (files?.length === 1) return `${files.length} file was chosen`

    return `${files.length} files were chosen`
  }, [files])

  const handleChooseFilesClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const onChange = async (e: React.FormEvent<HTMLInputElement>) => {
    if (e?.currentTarget?.files?.length) {
      const newFiles = Array.from(e.currentTarget.files) as File[]
      const unsupportedFiles = newFiles.filter(
        (file) => !DEFAULT_UPLOAD_ALLOWED_EXTENSIONS.includes(`.${file.name.split('.').pop()?.toLowerCase()}`),
      )

      if (unsupportedFiles.length > 0) {
        const allowedExtensions = DEFAULT_UPLOAD_ALLOWED_EXTENSIONS.map((ext) => ext.slice(1)).join(', ')
        window.alert(`Only ${allowedExtensions} files are allowed to be uploaded.`)
      } else {
        await onUploadFiles(newFiles)
      }
    }

    if (e.currentTarget) {
      e.currentTarget.value = ''
    }
  }

  return (
    <>
      <InputFileWrapper>
        {label && <Label>{label}</Label>}
        <FileWrapper htmlFor={props.name}>
          <ChooseFilesToUpload onClick={handleChooseFilesClick}>
            <div>{chosenFilesText}</div>
          </ChooseFilesToUpload>
          <ChooseFiles>
            <input
              multiple
              ref={fileInputRef}
              type='file'
              onChange={onChange}
              style={{ display: 'none' }}
              accept={DEFAULT_UPLOAD_ALLOWED_EXTENSIONS.join(',')}
            />
            <button type='button' onClick={handleChooseFilesClick} />
            <span>Choose Files</span>
          </ChooseFiles>
        </FileWrapper>
        <SelectedFilesList>
          <ul>
            <TransitionGroup>
              {files?.map((file, index) => (
                <CSSTransition key={`${file?.file}-${index}`} classNames='file-item' timeout={300}>
                  <InputFileListItem
                    file={file}
                    handleDeleteFile={() => onDeleteFile(file?.id || Math.random() * 100000000, file.file)}
                  />
                </CSSTransition>
              ))}
            </TransitionGroup>
          </ul>
        </SelectedFilesList>
      </InputFileWrapper>
    </>
  )
}

export default InputFile
