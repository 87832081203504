import React, { FC, KeyboardEvent, useCallback, useContext, useMemo } from 'react'

import { parse } from 'date-fns'
import { isObject } from 'lodash'

import { ISO_DATE_REGEX_PATTERN } from 'constants/dates'
import { EntityTypes } from 'constants/evaluations'
import { DEFAULT_DATE_FORMAT } from 'constants/global'
import { NetworkStatusContext } from 'contexts/NetworkContext'
import { QuestionsContext } from 'contexts/QuestionsContext'
import {
  CustomFileRetrieve,
  Question,
  QuestionOptions,
  QuestionOptionsNumberAnswer,
  QuestionOptionsType,
  QuestionType,
  StringOrNullType,
} from 'interfaces'
import { DatePicker, Input, RadioV2, Slider, Textarea } from 'shared-components'
import { getFillEvaluationMapComponents } from 'utils'

import { DynamicContent, FlexGroup } from './style'

interface Props extends Pick<Question, 'type' | 'id' | 'entityType' | 'hasDefaultAnswer'> {
  conditional: boolean
  options: QuestionOptions | undefined
  answer: StringOrNullType
  onSaveAnswer: (event: string, value?: string | number) => void
  evaluationId?: number
  handleUpdateLocalValue?: (value: string) => void
  localValue?: string
}

const FillCardContent: FC<Props> = ({
  id,
  conditional,
  hasDefaultAnswer,
  options,
  type,
  answer,
  entityType,
  onSaveAnswer,
  handleUpdateLocalValue,
  localValue,
}) => {
  const { formik } = useContext(QuestionsContext)
  const isOnline = useContext(NetworkStatusContext)
  const isDisabled = Number(answer) === -1000

  const answerId = String(id)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const defaultValue = hasDefaultAnswer ? options[type]?.default : ''
  const currentValue = localValue || (formik?.values[answerId] ? formik?.values[answerId] : defaultValue)

  const name = answerId

  const formikData = {
    currentValue,
    name,
    onChange: formik?.handleChange,
  }

  const componentToMapRender = getFillEvaluationMapComponents(type)

  const isShownSlider = type === QuestionType.PERCENTAGE || type === QuestionType.MARKS

  const isCheckboxQuestionType = type === QuestionType.CONDITIONAL || type === QuestionType.CONDITIONAL_REVERSE

  const isShownCheckbox = isCheckboxQuestionType && (entityType === EntityTypes.SECTION ? conditional : true)

  const dateCurrentValue = useMemo(() => {
    if (type !== QuestionType.DATE || !currentValue) return ''

    if (currentValue.match(ISO_DATE_REGEX_PATTERN)) return currentValue

    if (currentValue) return parse(currentValue, DEFAULT_DATE_FORMAT, new Date()).toISOString()

    return ''
  }, [currentValue, type])

  const formattedOptions: QuestionOptionsType[] = useMemo(() => {
    if (!options) return undefined

    if (Array.isArray(options)) return options[0]?.options

    if (isObject(options[type])) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return options[type].options
    }

    return options[type]
  }, [options, type])

  const files = useMemo(() => {
    if (!options?.picture) return []

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return options?.picture?.files?.map((file) => file?.file) as CustomFileRetrieve[]
  }, [options?.picture])

  const onChangeSave = useCallback(
    //eslint-disable-next-line
    (e: any) => {
      if (!formik) return
      formik.handleChange(e)
      onSaveAnswer(e, e.target.value)
    },
    [formik, onSaveAnswer],
  )

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  if (!formik) return null

  return (
    <DynamicContent>
      <FlexGroup isRow={type === QuestionType.PICTURE}>
        {formattedOptions?.map((item, index) => {
          const { description, color } = item as QuestionOptionsType
          const Component = componentToMapRender

          const value = type === QuestionType.MULTIPLE ? description : description || currentValue

          return (
            <Component
              {...item}
              key={index}
              value={type === QuestionType.PICTURE ? description : color || value}
              label={description}
              background={color}
              disabled={isDisabled}
              file={files ? files[index] : 0}
              {...formikData}
              {...(type === QuestionType.PICTURE && { isOnline })}
              {...(type === QuestionType.COLOR && { isAdaptiveColor: true })}
              onChange={
                color || type === QuestionType.SINGLE || type === QuestionType.PICTURE
                  ? onChangeSave
                  : formikData?.onChange
              }
            />
          )
        })}

        {isShownCheckbox && (
          <RadioV2
            isDefault={currentValue === ''}
            name={name}
            onChange={formik.handleChange}
            setField={formik.setFieldValue}
            onSaveAnswer={onSaveAnswer}
            checked={!!Number(currentValue)}
            value={currentValue || ''}
            disabled={isDisabled}
            isNumber
            type={type}
          />
        )}

        {type === QuestionType.NUMBER && (
          <Input
            name={name}
            onChange={formik.handleChange}
            value={currentValue}
            additionalValue={(options?.number as QuestionOptionsNumberAnswer)?.measure}
            type='number'
          />
        )}
      </FlexGroup>

      {type === QuestionType.DATE && (
        <DatePicker
          name={name}
          value={dateCurrentValue}
          placeholder='Choose date'
          setFieldValue={formik.setFieldValue}
          dateFormatToSet={DEFAULT_DATE_FORMAT}
        />
      )}
      {type === QuestionType.TEXT && JSON.stringify(options).includes('"inputType":"multiple"') && (
        <Textarea name={name} value={currentValue} onChange={formik.handleChange} setValue={handleUpdateLocalValue} />
      )}
      {type === QuestionType.TEXT && JSON.stringify(options).includes('"inputType":"single"') && (
        <Textarea
          name={name}
          className={'singleLine'}
          onKeyDown={handleKeyDown}
          height={38}
          value={currentValue}
          onChange={formik.handleChange}
          setValue={handleUpdateLocalValue}
        />
      )}

      {isShownSlider && (
        <Slider
          disabled={isDisabled}
          name={name}
          setField={formik.setFieldValue}
          onChange={formik?.handleChange}
          initial={currentValue}
          {...((!Array.isArray(options) && options?.[type]) || {})}
        />
      )}
    </DynamicContent>
  )
}

export default FillCardContent
