import { createSlice } from '@reduxjs/toolkit'

import { BASE_PAGINATION_DATA } from 'constants/shared'
import { AllEvaluationsState } from 'interfaces'

import { ALL_EVALUATIONS_SLICE_NAME } from './action'
import {
  createEvaluationReducer,
  deleteFromAllEvaluationsListReducer,
  editEvaluationReducer,
  getAllEvaluationsDropdownsListReducer,
  getAllEvaluationsEditInfoReducer,
  getAllEvaluationsReducer,
  resetReducer,
} from './reducer'

const initialState: AllEvaluationsState = {
  allEvaluations: [],
  evaluation: null,
  areas: {},
  categories: {},
  questionSets: {},
  users: {},
  isLoading: false,
  ...BASE_PAGINATION_DATA,
}

export const allEvaluationsSlice = createSlice({
  name: ALL_EVALUATIONS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getAllEvaluationsReducer(builder)
    deleteFromAllEvaluationsListReducer(builder)
    createEvaluationReducer(builder)
    editEvaluationReducer(builder)
    getAllEvaluationsDropdownsListReducer(builder)
    getAllEvaluationsEditInfoReducer(builder)
    resetReducer(builder)
  },
})

export default allEvaluationsSlice.reducer
