import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { debounce } from 'lodash'
import { toast } from 'react-toastify'

import { fillEvaluationApi } from 'apis'
import {
  clearAnswersByEvaluationId,
  clearDeletedByEvaluationId,
  clearEditedByEvaluationId,
  clearNewTasksByEvaluationId,
  clearNotesByEvaluationId,
  removeEvaluationByIdFromDB,
} from 'db/helpers'
import { db } from 'db/mainDb'
import { errorNotify } from 'helpers/notifications'
import {
  KeyWithString,
  OfflineUploadTasksPayload,
  OfflineDeleteTasksPayload,
  OfflineUploadNotesPayload,
  SaveEvaluationAnswerPayload,
  CompleteEvaluationPayload,
  IsOnline,
  FillEvaluation,
  OfflineCompletionType,
  EvaluationIds,
  NotesFile,
  UploadFile,
} from 'interfaces'
import history from 'services/history'
import { getEvaluationCompleteLink, changeEvaluationQuestionsAnswer } from 'utils'
import { getServerErrorMessage } from 'utils/errors'

import { clearNewFiles, clearNotesFilesToDelete } from '../../db/files'
import { asyncSyncEvaluationNotesFiles, asyncUploadEvaluationNotesFiles } from '../evaluation-actions-group/action'
import { asyncGetOfflineEvaluation } from '../evaluations/action'
import { setErrorNotify, setSuccessNotify } from '../notifications/action'
import { AppDispatch } from '../store'

export const EVALUATIONS_FILL_SLICE_NAME = 'fill-all-evaluations'

export const asyncGetMyFillEvaluations = createAsyncThunk(
  `${EVALUATIONS_FILL_SLICE_NAME}/evaluationsFill`,
  async (evaluationId: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await fillEvaluationApi.getFillEvaluation(evaluationId)

      const answers = await dispatch(getQuestionsAnswer(evaluationId)).unwrap()

      return {
        ...response.data,
        questions: changeEvaluationQuestionsAnswer(response.data.questions, answers),
      }
    } catch (e) {
      errorNotify(getServerErrorMessage(e))
      return rejectWithValue(e)
    }
  },
)

export const asyncSaveQuestionsAnswer = createAsyncThunk(
  `${EVALUATIONS_FILL_SLICE_NAME}/asyncSaveQuestionsAnswer`,
  async (data: SaveEvaluationAnswerPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await fillEvaluationApi.saveQuestionsAnswer(data)

      dispatch(setSuccessNotify('Successful'))
      return { ...response.data, questionId: data.questionId }
    } catch (err: unknown) {
      dispatch(setErrorNotify(err as string))
      return rejectWithValue(err)
    }
  },
)

export const debouncedSaveQuestionsAnswerDispatch = debounce(
  (arg, dispatch) => dispatch(asyncSaveQuestionsAnswer(arg)),
  1000,
)

export const debouncedAsyncSaveQuestionsAnswer = (arg: SaveEvaluationAnswerPayload) => (dispatch: AppDispatch) =>
  debouncedSaveQuestionsAnswerDispatch(arg, dispatch)

export const asyncSaveQuestionsAnswers = createAsyncThunk(
  `${EVALUATIONS_FILL_SLICE_NAME}/answersSave`,
  async (
    data: {
      id: number
      answer: KeyWithString
      completePage: boolean
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { id, completePage, ...rest } = data

      const response = await fillEvaluationApi.saveQuestionsAnswers(id, rest)

      if (response.status !== 200) throw new Error("Evaluation wasn't saved")

      //TODO - uncomment line below - when response will be correct(equal 1 evaluation req)
      // await saveAllEvaluationsToDB([response.data]);
      dispatch(setSuccessNotify('Successful'))
      if (completePage) {
        history.push(getEvaluationCompleteLink(id))
      }

      return {
        ...response.data,
        questions: changeEvaluationQuestionsAnswer(response.data?.questions || [], data.answer),
      }
    } catch (err: unknown) {
      dispatch(setErrorNotify(err as string))
      return rejectWithValue(err)
    }
  },
)

export const asyncSetNotApplicable = createAsyncThunk(
  `${EVALUATIONS_FILL_SLICE_NAME}/notApplicable`,
  async (
    {
      evaluationId,
      questionId,
      applicable,
      isOnline,
    }: {
      evaluationId: number
      questionId: number
      applicable: boolean
    } & IsOnline,
    { dispatch, rejectWithValue },
  ) => {
    try {
      if (isOnline) {
        const response = await fillEvaluationApi.setNotApplicable({
          id: evaluationId,
          questionId,
          applicable,
        })
        dispatch(setSuccessNotify(`Question ${questionId} is marked ${!applicable ? 'not ' : ''}applicable`))
      }
      return
    } catch (e) {
      dispatch(setErrorNotify('Please contact the administrator.'))
      rejectWithValue('Something went wrong.')
    }
  },
)

export const offlineUploadEvaluationData = createAsyncThunk(
  `${EVALUATIONS_FILL_SLICE_NAME}/offlineUploadEvaluationData`,
  async (
    {
      tasks,
      notes = {},
      deletedTasks,
      answers,
      deletedNotes,
      newFiles,
      filesToDelete,
      evaluationId,
      shouldBeCompleted,
      feedback,
    }: {
      tasks?: OfflineUploadTasksPayload
      notes?: OfflineUploadNotesPayload
      deletedTasks?: OfflineDeleteTasksPayload
      deletedNotes?: EvaluationIds[]
      newFiles: NotesFile[]
      filesToDelete: UploadFile[]
      answers?: string
      evaluationId: number
    } & OfflineCompletionType,
    { dispatch },
  ) => {
    try {
      if (Object.keys(tasks || {}).length) {
        await fillEvaluationApi.uploadTasksFromOffline(evaluationId, tasks)
        await clearEditedByEvaluationId(evaluationId)
        await clearNewTasksByEvaluationId(evaluationId)
        dispatch(setOfflineUploadProgress(33))
      }

      if (Object.keys(deletedTasks || {}).length) {
        await fillEvaluationApi.deleteTasksFromOffline(evaluationId, deletedTasks)
        await clearDeletedByEvaluationId(evaluationId)
        await dispatch(setOfflineUploadProgress(55))
      }
      if (deletedNotes?.length) {
        await Promise.all(deletedNotes?.map((payload) => fillEvaluationApi.deleteMyNote(payload)))
        await db.deletedNotes.where({ evaluationId }).delete()
      }

      if (Object.keys(notes)?.length) {
        await fillEvaluationApi.uploadNotesFromOffline(evaluationId, notes)
      }
      await clearNotesByEvaluationId(evaluationId)
      await dispatch(setOfflineUploadProgress(66))

      // Create new files
      if (newFiles?.length) {
        const filesToCreate: Record<string, File[]> = newFiles.reduce((result, { questionId, file }) => {
          if (!result[questionId]) {
            result[questionId] = []
          }

          result[questionId].push(file)
          return result
        }, {} as Record<string, File[]>)

        await Promise.all(
          Object.keys(filesToCreate)?.map(
            async (key: string) =>
              await dispatch(
                asyncSyncEvaluationNotesFiles({
                  evaluationId,
                  questionId: Number(key),
                  files: filesToCreate[key],
                }),
              ),
          ),
        )
        await clearNewFiles(evaluationId)
      }

      // Delete downloaded files
      if (filesToDelete?.length) {
        await Promise.all(
          filesToDelete?.map(async ({ evaluation_id, questionId, file }) => {
            if (!questionId) return
            await fillEvaluationApi.deleteEvaluationFile({ evaluationId: evaluation_id, questionId, file })
            await clearNotesFilesToDelete(evaluationId)
          }),
        )
      }

      await fillEvaluationApi.saveQuestionsAnswers(evaluationId, {
        answer: JSON.parse(answers || ''),
      })

      if (feedback) {
        await dispatch(
          finishFillEvaluation({
            id: evaluationId,
            feedback,
            ignoreNotify: true,
          }),
        )
      }

      await clearAnswersByEvaluationId(evaluationId)

      if (!shouldBeCompleted) {
        await dispatch(asyncGetOfflineEvaluation(evaluationId))
        await dispatch(setOfflineUploadProgress(99))
        await dispatch(getQuestionsAnswer(evaluationId))
      }

      await dispatch(setSuccessNotify('Evaluation was successfully uploaded'))
      return null
    } catch (e) {
      return null
    }
  },
)

export const finishFillEvaluation = createAsyncThunk(
  `${EVALUATIONS_FILL_SLICE_NAME}/finishFillEvaluation`,
  async (
    { ignoreNotify, ...data }: CompleteEvaluationPayload & { ignoreNotify?: boolean },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { id } = data

      const response = await fillEvaluationApi.finishEvaluation(data)

      if (response?.data?.completed_at) await removeEvaluationByIdFromDB(id)

      if (!ignoreNotify) dispatch(setSuccessNotify('Evaluation was successfully completed'))

      return { completed_at: response?.data?.completed_at, feedback: response?.data?.feedback }
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getQuestionsAnswer = createAsyncThunk(
  `${EVALUATIONS_FILL_SLICE_NAME}/getQuestionsAnswer`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await fillEvaluationApi.getQuestionAnswers(id)

      return Object.keys(response?.data).reduce((obj, key) => ({ ...obj, [key]: response.data[key]?.answer }), {})
    } catch (e) {
      return rejectWithValue('something went wrong.')
    }
  },
)

export const setOfflineUploadProgress = createAction<number | null>('UPDATE_OFFLINE_UPLOAD_PROGRESS')
export const setFillEvaluation = createAction<FillEvaluation>('SET_FILL_EVALUATION')
