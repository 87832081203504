import styled from 'styled-components'

export const CheckboxV2Wrapper = styled.div<{
  isDefault: boolean
}>`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spaces.min3} 0;
  & > span {
    margin-right: 4px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.black1};
  }

  & input {
    display: none;
    width: 123px;

    &:checked + label:after {
      left: 50%;
    }

    & + label {
      overflow: hidden;
      backface-visibility: hidden;
      transition: all 0.2s ease;

      &:after,
      &:before {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease;
        width: 40px;
        text-align: center;
        vertical-align: middle;
        position: absolute;
        font-weight: 500;
        color: #fff;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
        // opacity here
      }

      &:after {
        background: ${({ theme }) => theme.colors.error};
        left: ${({ isDefault }) => (isDefault ? '75%' : '50%')};
        content: attr(data-tg-off);
      }

      &:before {
        background: ${({ theme }) => theme.colors.green1};
        left: ${({ isDefault }) => (isDefault ? '-25%' : '-100%')};
        content: attr(data-tg-on);
      }
    }

    &:checked + label {
      &:before {
        left: 0;
      }

      &:after {
        left: 100%;
      }
    }
  }

  & label {
    width: 80px;
    outline: 0;
    display: block;
    height: 28px;
    position: relative;
    cursor: pointer;
    user-select: none;
    border: 1px solid #ced4da;
    border-radius: 4px;
    overflow: hidden;

    &:after,
    &:before {
      position: relative;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }
  }
`

// opacity: ${({ isDefault }) =>
// isDefault ? '1' : '0.5'};
