import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import allEvaluationsReducer from './all-evaluations/slice'
import areaFieldsReducer from './area-fields/slice'
import areasReducer from './areas/slice'
import categoriesReducer from './categories/slice'
import dashboardReducer from './dashboard/slice'
import evaluationActionsGroupReducer from './evaluation-actions-group/slice'
import evaluationsReducer from './evaluations/slice'
import fillEvaluationReducer from './fill-evaluation/slice'
import notificationsReducer from './notifications/slice'
import questionSetsAdditionalReducer from './question-sets-additional/slice'
import questionSetsReducer from './question-sets/slice'
import riskGradingsReducer from './risk-gradings/slice'
import userReducer from './user/slice'

const rootReducer = combineReducers({
  userReducer,
  dashboardReducer,
  evaluationsReducer,
  fillEvaluationReducer,
  notificationsReducer,
  categoriesReducer,
  riskGradingsReducer,
  allEvaluationsReducer,
  questionSetsReducer,
  areaFieldsReducer,
  areasReducer,
  questionSetsAdditionalReducer,
  evaluationActionsGroupReducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
