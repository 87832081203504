import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import {
  CategoriesInitialState,
  Category,
  CategoryItemResponse,
  ListResponse,
  TableDefaultItemInterface,
} from 'interfaces'

import {
  createCategoryAction,
  deleteCategoryAction,
  editCategoryAction,
  getAllCategoriesItems,
  getCategoriesList,
  getCategoryAction,
} from './action'

export function getAllCategoriesReducer(builder: ActionReducerMapBuilder<CategoriesInitialState>) {
  builder.addCase(getAllCategoriesItems.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(getAllCategoriesItems.fulfilled, (state, action: PayloadAction<ListResponse>) => {
    const { data, ...pagination } = action.payload
    return {
      ...state,
      ...pagination,
      categories: data,
      isLoading: false,
    }
  })

  builder.addCase(getAllCategoriesItems.rejected, (state) => {
    state.isLoading = false
  })
}

export function getCategoryReducer(builder: ActionReducerMapBuilder<CategoriesInitialState>) {
  builder.addCase(getCategoryAction.fulfilled, (state, action: PayloadAction<CategoryItemResponse>) => {
    state.currentCategory = action.payload.category
  })
}

export function createCategoryReducer(builder: ActionReducerMapBuilder<CategoriesInitialState>) {
  builder.addCase(createCategoryAction.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(createCategoryAction.fulfilled, (state, action: PayloadAction<TableDefaultItemInterface>) => {
    state.categories.push(action.payload)
    state.isLoading = false
  })

  builder.addCase(createCategoryAction.rejected, (state) => {
    state.isLoading = false
  })
}

export function editCategoryReducer(builder: ActionReducerMapBuilder<CategoriesInitialState>) {
  builder.addCase(editCategoryAction.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(editCategoryAction.fulfilled, (state, action: PayloadAction<TableDefaultItemInterface>) => {
    state.categories = state.categories.map((i) => (i.id === action.payload.id ? action.payload : i))
    state.isLoading = false
  })

  builder.addCase(editCategoryAction.rejected, (state) => {
    state.isLoading = false
  })
}

export function deleteCategoryReducer(builder: ActionReducerMapBuilder<CategoriesInitialState>) {
  builder.addCase(deleteCategoryAction.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(deleteCategoryAction.fulfilled, (state, action: PayloadAction<number>) => {
    state.categories = state.categories.filter((i) => i.id !== action.payload)
    state.isLoading = false
  })

  builder.addCase(deleteCategoryAction.rejected, (state) => {
    state.isLoading = false
  })
}

export function getCategoriesListReducer(builder: ActionReducerMapBuilder<CategoriesInitialState>) {
  builder.addCase(getCategoriesList.fulfilled, (state, action: PayloadAction<Category[]>) => {
    state.categoriesList = action.payload
  })
}
