import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import { ToastifyType } from 'constants/global'
import { NOTIFICATIONS_INITIAL_STATE } from 'constants/notifications'
import { Notify } from 'interfaces'

import { resetNotify, setErrorNotify, setNotify, setSuccessNotify, setWarningNotify } from './action'

export function resetNotifyReducer(builder: ActionReducerMapBuilder<Notify>) {
  builder.addCase(resetNotify, () => NOTIFICATIONS_INITIAL_STATE)
}

export function setNotifyReducer(builder: ActionReducerMapBuilder<Notify>) {
  builder.addCase(setNotify, (_, action: PayloadAction<Notify>) => action.payload)

  builder.addCase(setSuccessNotify, (state, action: PayloadAction<string>) => {
    state.type = ToastifyType.SUCCESS
    state.message = action.payload
  })

  builder.addCase(setErrorNotify, (state, action: PayloadAction<string>) => {
    state.type = ToastifyType.ERROR
    state.message = action.payload
  })

  builder.addCase(setWarningNotify, (state, action: PayloadAction<string>) => {
    state.type = ToastifyType.WARNING
    state.message = action.payload
  })
}
