import React, { ChangeEvent, FC, TextareaHTMLAttributes } from 'react'

import { Label, Error } from 'shared-components/input/styles'

import { TextareaWrapper } from './style'

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  error?: string
  height?: number
  setValue?: (value: string) => void
}

const Textarea: FC<Props> = ({ label, error, height, setValue, ...props }) => {
  return (
    <TextareaWrapper height={height}>
      {label && <Label>{label}</Label>}
      <textarea
        {...props}
        onChange={setValue ? (e: ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value) : props.onChange}
      />
      {error && <Error>{error}</Error>}
    </TextareaWrapper>
  )
}

export default Textarea
