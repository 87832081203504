import styled from 'styled-components'

export const AllEvaluationsSettingsContentWrapper = styled.div`
  & .ReactCollapse--collapse {
    transition: 1s all ease;
  }

  & > div {
    overflow: visible;
  }
`

export const Setting = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.min3};
`

export const Column = styled.div`
  flex: 0 1 49%;
  @media (max-width: 768px) {
    flex: 0 1 100%;
  }
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Center = styled.div`
  display: flex;
  justify-content: center;
`

export const Space = styled.div`
  margin-top: 16px;
`
