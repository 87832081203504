import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import { UserInfoInterface } from 'interfaces'
import { checkIsAccessTokenExist } from 'utils'

import {
  asyncLogin,
  asyncResendCode,
  asyncResetPassword,
  asyncUpdateUser,
  getUserInfo,
  resetError,
  send2FaCode,
  setIsAuth,
  setUserPinCode,
  userLogout,
} from './action'
import { initialState, UserState } from './slice'

export function loginReducer(builder: ActionReducerMapBuilder<UserState>) {
  builder.addCase(asyncLogin.pending, (state) => {
    state.error = ''
    state.isLoading = true
  })

  builder.addCase(asyncLogin.fulfilled, (state, action) => {
    if (action.payload) {
      const { required2fa, channel2fa, access_token } = action.payload

      if (!required2fa) {
        state.isAuth = true
      }
      state.required2fa = required2fa
      state.channel2fa = channel2fa
      state.access_token = access_token
    }

    state.isLoaded = true
    state.isLoading = false
  })

  builder.addCase(asyncLogin.rejected, (state, action) => {
    state.error = action?.payload as string
    state.isLoading = false
  })
}

export function userInfoReducer(builder: ActionReducerMapBuilder<UserState>) {
  builder.addCase(getUserInfo.pending, (state) => {
    state.isUserLoading = true
  })

  builder.addCase(getUserInfo.fulfilled, (state, action) => {
    if (action.payload) {
      state.user = action.payload
      state.isAuth = true
    } else state.isAuth = false
    state.isLoaded = true
    state.isUserLoading = false
  })
  // eslint-disable-next-line
  builder.addCase(getUserInfo.rejected, (state, action: PayloadAction<any>) => {
    const { isOnline } = action?.payload

    if (isOnline !== undefined) state.isAuth = Boolean(!isOnline && checkIsAccessTokenExist())
    state.isUserLoading = false
  })
}

// export function userInfoTwoFactorReducer(builder: ActionReducerMapBuilder<UserState>) {
//   builder.addCase(putUserInfoTwoFactor.pending, (state) => {
//     state.isLoading = true;
//     state.errors = null;
//   });
//   builder.addCase(putUserInfoTwoFactor.fulfilled, (state, action: PayloadAction<UserInfoTwoFactor>) => {
//     state.user = action.payload;
//     state.isLoading = false;
//   });
//   builder.addCase(putUserInfoTwoFactor.rejected, (state, action) => {
//     state.isLoading = false;
//     state.errors = action.payload as {
//       [key:string]:string[]
//     };
//   });
// }

export function logoutReducer(builder: ActionReducerMapBuilder<UserState>) {
  builder.addCase(userLogout, () => ({
    ...initialState,
    isLoaded: false,
    isAuth: false,
  }))
}
export function isAuthReducer(builder: ActionReducerMapBuilder<UserState>) {
  builder.addCase(setIsAuth, (state, action: PayloadAction<boolean>) => {
    state.isAuth = action.payload
  })
}

export function updateUserReducer(builder: ActionReducerMapBuilder<UserState>) {
  builder.addCase(asyncUpdateUser.pending, (state) => {
    state.isLoading = true
    state.errors = null
  })
  builder.addCase(asyncUpdateUser.fulfilled, (state, action: PayloadAction<UserInfoInterface>) => {
    state.user = action.payload
    state.isLoading = false
  })
  builder.addCase(asyncUpdateUser.rejected, (state, action) => {
    state.isLoading = false
    state.errors = action.payload as {
      [key: string]: string[]
    }
  })
}

export function postVerifyCodeReducer(builder: ActionReducerMapBuilder<UserState>) {
  builder.addCase(send2FaCode.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(send2FaCode.fulfilled, (state) => {
    state.isAuth = true
    state.isLoading = false
  })
}

export function resendVerifyCodeReducer(builder: ActionReducerMapBuilder<UserState>) {
  builder.addCase(asyncResendCode.fulfilled, (state) => {
    state.isResended = true
  })
}

export function resetPasswordReducer(builder: ActionReducerMapBuilder<UserState>) {
  builder.addCase(asyncResetPassword.rejected, (state) => {
    state.isLoading = false
  })
}

export function setUserPinCodeReducer(builder: ActionReducerMapBuilder<UserState>) {
  builder.addCase(setUserPinCode, (state, action: PayloadAction<string>) => {
    state.userPinCode = action.payload
  })
}

export function resetErrorReducer(builder: ActionReducerMapBuilder<UserState>) {
  builder.addCase(resetError, (state) => {
    state.error = ''
  })
}
