import React, { FC } from 'react'

import { SectionHeaderWrapper } from './style'

interface Props {
  children: React.ReactNode
}

const SectionHeader: FC<Props> = ({ children }) => {
  return <SectionHeaderWrapper>{children}</SectionHeaderWrapper>
}

export default SectionHeader
