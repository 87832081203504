import React, { useContext, useEffect } from 'react'

import { useLiveQuery } from 'dexie-react-hooks'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { GlobalStyles } from 'constants/global'
import { routes } from 'constants/routes'
import useAppVersion from 'hooks/useAppVersion'
import { resetNotify } from 'modules/notifications/action'
import { selectNotify } from 'modules/notifications/selectors'
import { useAppDispatch, useAppSelector } from 'modules/store'
import { getUserInfo, setIsAuth } from 'modules/user/action'
import { selectIsAuthOptions } from 'modules/user/selectors'
import { ProtectedRoute } from 'page-components'
import { checkIsAccessTokenExist } from 'utils/auth'
import { notify } from 'utils/global'
import ForgotPassword from 'views/auth/ForgotPassword'
import Login from 'views/auth/Login'
import ResetPassword from 'views/auth/reset-password/ResetPassword'
import Verify from 'views/auth/verify/Verify'
import Home from 'views/home/Home'

import { NetworkStatusContext } from './contexts/NetworkContext'
import { db } from './db/mainDb'
import { OfflineUser } from './interfaces'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

function App() {
  const dispatch = useAppDispatch()
  const isOnline = useContext(NetworkStatusContext)

  const currentUserList = useLiveQuery(() => db.user.toArray()) as OfflineUser[]

  useAppVersion()

  const { isAuth, isLoaded, isUserLoading } = useAppSelector(selectIsAuthOptions)
  const notifyData = useAppSelector(selectNotify)

  useEffect(() => {
    if (notifyData?.message) {
      notify(notifyData)
      dispatch(resetNotify())
    }
  }, [dispatch, notifyData, notifyData?.message])

  useEffect(() => {
    if (checkIsAccessTokenExist() && !isUserLoading) {
      dispatch(getUserInfo({ isOnline }))
      return
    }
    if (!isUserLoading || isLoaded) {
      dispatch(setIsAuth(false))
    }
  }, [dispatch, isAuth])

  useEffect(() => {
    if (!isOnline && currentUserList?.length) {
      dispatch(setIsAuth(true))
    }
  }, [currentUserList?.length, dispatch, isOnline, isLoaded, isAuth])

  if ((isAuth === null && !isLoaded) || isOnline === undefined) return null

  return (
    <div>
      <GlobalStyles />
      <ToastContainer />
      <Routes>
        <Route element={<ProtectedRoute isAuth={isAuth} to={routes.login} />}>
          <Route path={routes.home} element={<Home />} />
        </Route>
        <Route element={<ProtectedRoute isAuth={!isAuth} to={routes.myEvaluations} />}>
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.reset} element={<ForgotPassword />} />
          <Route path={routes.verify} element={<Verify />} />
          <Route path={routes.resetPassword} element={<ResetPassword />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App

serviceWorkerRegistration.register()
