import React, { FC, useEffect } from 'react'

import { isEqual, reduce } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'

import { Colors } from 'constants/global'
import { routes } from 'constants/routes'
import { db } from 'db/mainDb'
import { asyncGetMyFillEvaluations } from 'modules/fill-evaluation/action'
import {
  selectEvaluationCompletionsInfo,
  selectIsQuestionLoading,
  selectOfflineSyncData,
} from 'modules/fill-evaluation/selectors'
import { useAppDispatch, useAppSelector } from 'modules/store'
import {
  HomeContentLayout,
  QuestionsContainer,
  SkeletonEvaluation,
  useFillEvaluation,
  Tasks,
  Notes,
} from 'page-components'
import { LoadingLine, ContentCard } from 'shared-components'
import { Title } from 'shared-components/content-card/style'
import { getFlatAndFilteredEvaluationCompletion, getSectionCompletionContent } from 'utils'

import { Wrapper } from './style'

const Evaluation: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { id, title, isCompleted, isOnline, questions } = useFillEvaluation(Number(params?.id))

  const { onlineAnswers } = useAppSelector(selectOfflineSyncData)
  const isQuestionLoading = useAppSelector(selectIsQuestionLoading)
  const { completed, answeredQuestions, totalQuestions } = useAppSelector(selectEvaluationCompletionsInfo) || {}

  const { completionContent } = getFlatAndFilteredEvaluationCompletion(questions)

  const currentCompletionContent = getSectionCompletionContent(completed, answeredQuestions, totalQuestions)

  const checkAccessToPage = async () => {
    const offlineAnswers = await db.answers.get({
      id,
    })

    const parsedOfflineAnswers = offlineAnswers?.answerList ? JSON.parse(offlineAnswers?.answerList) : null
    const differentAnswersList = parsedOfflineAnswers
      ? reduce(
          onlineAnswers,
          function (result, value, key) {
            return isEqual(value, parsedOfflineAnswers[key]) ? result : result.concat(key as never)
          },
          [],
        )
      : null

    if (differentAnswersList?.length && isOnline) navigate(routes.myEvaluations)
  }

  useEffect(() => {
    if (id && onlineAnswers) checkAccessToPage()
    // eslint-disable-next-line
  }, [id, onlineAnswers])

  useEffect(() => {
    if (id !== Number(params?.id) && window.navigator.onLine) dispatch(asyncGetMyFillEvaluations(Number(params.id)))
  }, [dispatch, id, params.id])

  useEffect(() => {
    if (isCompleted && id) navigate(routes.myEvaluations)
  }, [id, isCompleted, isOnline, navigate])

  return (
    <HomeContentLayout>
      <LoadingLine isLoading={isQuestionLoading} />
      {id && (
        <>
          <Tasks evaluationId={id} />
          <Notes evaluationId={id} />
        </>
      )}

      <Wrapper>
        <ContentCard
          title={title || ''}
          topBackground={Colors.GRAY}
          topCustomComponent={
            completionContent || currentCompletionContent ? (
              <Title>{currentCompletionContent || completionContent}</Title>
            ) : null
          }
          isSticky
          spaces='0'
        >
          {questions?.length && id === Number(params?.id) ? (
            <QuestionsContainer questions={questions} id={id || 0} />
          ) : (
            <SkeletonEvaluation />
          )}
        </ContentCard>
      </Wrapper>
    </HomeContentLayout>
  )
}

export default Evaluation
