import React, { FC, useCallback, useContext, useState } from 'react'

import { OWN_EVALUATIONS_TITLE } from 'constants/evaluations'
import { NetworkStatusContext } from 'contexts/NetworkContext'
import { removeEvaluationByIdFromDBLists } from 'db/helpers'
import useConfirm, { HandleOpenAndSubmitConfirmType } from 'hooks/useConfirm'
import { PageProps } from 'interfaces'
import { asyncUnloadEvaluation } from 'modules/evaluations/action'
import { selectLoadingStatus } from 'modules/fill-evaluation/selectors'
import { useAppDispatch, useAppSelector } from 'modules/store'
import {
  HomeContentLayout,
  MyEvaluationsList,
  OfflineSyncModal,
  useMyEvaluations,
  useOfflineSync,
  UnloadOfflineEvaluationsModalContent,
} from 'page-components'
import { Confirm, ScreenLoader } from 'shared-components'
import { notify } from 'utils'

import { Wrapper } from './style'

const MyEvaluations: FC<PageProps> = ({ isSidebarOpen }) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const isOnline = useContext(NetworkStatusContext)

  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(selectLoadingStatus)

  const onUnloadOfflineItem = (id: number) => {
    dispatch(asyncUnloadEvaluation({ id, isOnline }))
  }

  const onUnloadOfflineEvaluations: HandleOpenAndSubmitConfirmType = async (_, offlineEvaluationsIds) => {
    if (!Array.isArray(offlineEvaluationsIds)) return
    await Promise.all([
      offlineEvaluationsIds.map(async (evaluationId) => {
        await removeEvaluationByIdFromDBLists(evaluationId)
        return
      }),
    ]).then(() => notify({ message: 'Evaluations synchronization was successful', type: 'success' }))
  }

  const { isConfirmOpen, handleOpenConfirm, handleCloseConfirm, onConfirm } = useConfirm(onUnloadOfflineItem)
  const {
    isConfirmOpen: isRemoveEvaluationsConfirmOpen,
    handleOpenConfirm: handleOpenRemoveEvaluationsConfirm,
    handleCloseConfirm: handleCloseRemoveEvaluationsConfirm,
    onConfirm: onRemoveEvaluations,
  } = useConfirm(onUnloadOfflineEvaluations)

  const { evaluations, extraOfflineEvaluations } = useMyEvaluations({
    handleOpenRemoveEvaluationsConfirm,
  })

  const { onContinue, handleCloseSyncModal, isSyncModalOpen, additionalButtons, answeredCountProps, answers } =
    useOfflineSync()

  const setIsLoading = useCallback((value: boolean) => {
    setIsDownloading(value)
  }, [])

  return (
    <HomeContentLayout title={OWN_EVALUATIONS_TITLE}>
      <ScreenLoader isSidebarOpen={isSidebarOpen} isOpen={isLoading} />
      <Wrapper>
        <MyEvaluationsList
          evaluationTitle='Evaluations list'
          evaluations={evaluations}
          setIsLoadingOffline={setIsLoading}
          onContinue={onContinue}
          handleUnloadConfirm={handleOpenConfirm}
        />
      </Wrapper>
      <OfflineSyncModal
        isOpen={isSyncModalOpen}
        additionalButtons={additionalButtons}
        onClose={handleCloseSyncModal}
        {...answers}
        {...answeredCountProps}
      />
      <Confirm isOpen={isConfirmOpen} onClose={handleCloseConfirm} onConfirm={onConfirm} />
      <Confirm
        style={{ transform: 'translate(-50%, -20%)' }}
        isOpen={isRemoveEvaluationsConfirmOpen}
        onClose={handleCloseRemoveEvaluationsConfirm}
        onConfirm={onRemoveEvaluations}
        title='Evaluation will be removed'
        isClose={false}
        customConfirm='OK'
        content={<UnloadOfflineEvaluationsModalContent evaluations={extraOfflineEvaluations} />}
      />
    </HomeContentLayout>
  )
}

export default MyEvaluations
