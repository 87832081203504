import { createAsyncThunk } from '@reduxjs/toolkit'

import { EntityTypes } from 'constants/evaluations'
import {
  DeleteSectionsQuestionsItemPayload,
  QuestionSetsListItem,
  QuestionSetsQuestion,
  QuestionSetsSectionValues,
  QuestionType,
} from 'interfaces'

import { setErrorNotify } from '../notifications/action'
import { QUESTION_SETS_SLICE_NAME } from '../question-sets/action'

export const QUESTION_SETS_ADDITIONAL_SLICE_NAME = 'question-sets-additional'

export const getSectionsQuestionsList = createAsyncThunk(
  `${QUESTION_SETS_SLICE_NAME}/getSectionsQuestionsList`,
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      return [
        {
          id: 9999,
          entityType: EntityTypes.SECTION,
          type: QuestionType.TEXT,
          name: 'Section 1',
          conditional: false,
          question: 'Question section 1?',
          guidance: 'Lorem ipsum dolor 1',
          questions: [
            {
              id: 656,
              entityType: EntityTypes.QUESTION,
              type: QuestionType.TEXT,
              name: 'Question 1',
              conditional: false,
              question: 'Question section 1?',
              guidance: 'Lorem ipsum dolor 1',
              questions: [],
              parent_id: 9999,
              notApplicable: false,
              noScore: false,
              weighting: 1,
              conditional_policy: '',
              risk_grading: '',
            },
            {
              id: 777,
              entityType: EntityTypes.QUESTION,
              type: QuestionType.TEXT,
              name: 'Question 2',
              conditional: false,
              question: 'Question section 1?',
              guidance: 'Lorem ipsum dolor 1',
              questions: [],
              parent_id: 9999,
              notApplicable: false,
              noScore: false,
              weighting: 1,
              conditional_policy: '',
              risk_grading: '',
            },
          ],
          parent_id: null,
          notApplicable: false,
          noScore: false,
          weighting: 1,
          conditional_policy: '',
          risk_grading: '',
        },
        {
          id: 4,
          entityType: EntityTypes.SECTION,
          type: QuestionType.TEXT,
          name: 'Section 2',
          conditional: false,
          question: 'Question section 1?',
          guidance: 'Lorem ipsum dolor 1',
          questions: [],
          parent_id: null,
          notApplicable: false,
          noScore: false,
          weighting: 1,
          conditional_policy: '',
          risk_grading: '',
        },
        {
          id: 7,
          entityType: EntityTypes.QUESTION,
          type: QuestionType.TEXT,
          name: 'QUESTION 111111',
          conditional: false,
          question: 'Question section 1?',
          guidance: 'Lorem ipsum dolor 1',
          questions: [],
          parent_id: null,
          notApplicable: false,
          noScore: false,
          weighting: 1,
          conditional_policy: '',
          risk_grading: '',
        },
      ] as QuestionSetsListItem[]
    } catch (e) {
      dispatch(setErrorNotify("Question set wasn't edited"))
      return rejectWithValue(e)
    }
  },
)

export const deleteSectionsQuestionsListItem = createAsyncThunk(
  `${QUESTION_SETS_SLICE_NAME}/deleteSectionsQuestionsListItem`,
  async (data: DeleteSectionsQuestionsItemPayload, { rejectWithValue, dispatch }) => {
    try {
      return data
    } catch (e) {
      dispatch(setErrorNotify("Question set wasn't edited"))
      return rejectWithValue(e)
    }
  },
)

export const createSection = createAsyncThunk(
  `${QUESTION_SETS_SLICE_NAME}/createSection`,
  async (data: QuestionSetsSectionValues, { rejectWithValue, dispatch }) => {
    try {
      return {
        ...data,
        entityType: EntityTypes.SECTION,
        type: QuestionType.TEXT,
        parent_id: null,
        id: Math.random() * 1000000,
      } as QuestionSetsListItem
    } catch (e) {
      dispatch(setErrorNotify("Section wasn't created"))
      return rejectWithValue(e)
    }
  },
)

export const editSection = createAsyncThunk(
  `${QUESTION_SETS_SLICE_NAME}/editSection`,
  async (data: QuestionSetsSectionValues, { rejectWithValue, dispatch }) => {
    try {
      return data
    } catch (e) {
      dispatch(setErrorNotify("Section wasn't edited"))
      return rejectWithValue(e)
    }
  },
)

export const createQuestion = createAsyncThunk(
  `${QUESTION_SETS_SLICE_NAME}/createQuestion`,
  async (data: QuestionSetsQuestion, { rejectWithValue, dispatch }) => {
    try {
      return {
        ...data,
        id: Math.random() * 100000000,
        entityType: EntityTypes.QUESTION,
        parent_id: null,
      } as QuestionSetsListItem
    } catch (e) {
      dispatch(setErrorNotify("Section wasn't edited"))
      return rejectWithValue(e)
    }
  },
)

export const editQuestion = createAsyncThunk(
  `${QUESTION_SETS_SLICE_NAME}/editQuestion`,
  async (data: QuestionSetsQuestion, { rejectWithValue, dispatch }) => {
    try {
      return {
        ...data,
        entityType: EntityTypes.QUESTION,
      } as QuestionSetsListItem
    } catch (e) {
      dispatch(setErrorNotify("Section wasn't edited"))
      return rejectWithValue(e)
    }
  },
)
