import styled, { keyframes } from 'styled-components'

const progressAnim = keyframes`
    0% {
        background-position: 60px 0;
    }
    100% {
        background-position: 0 0;
    }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
`

export const BarContainer = styled.div`
  flex: 1;
  height: 16px;
  background: ${({ theme }) => theme.colors.error};
  overflow: hidden;
`

export const FillerBackground = styled.div`
  height: inherit;
  transition: width 2s ease-in-out;
  background-color: ${({ theme }) => theme.colors.green1};
  background-size: 30px 30px;
  background-image: linear-gradient(
    -135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  animation: ${progressAnim} 1s linear infinite;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Filler = styled.div`
  transition: width 2s ease-in-out;
  height: inherit;
  border-radius: inherit;
  overflow: hidden;
`

export const Text = styled.p`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
`
