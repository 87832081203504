import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import {
  DeleteEvaluationNotesFilePayload,
  EvaluationActionsGroupState,
  FillEvaluationMyTasks,
  MyTask,
  NotesFilesWithKey,
  SetTasksCountsPayload,
  UploadFile,
} from 'interfaces'

import {
  asyncCreateMyTask,
  asyncCreateNote,
  asyncDeleteTask,
  asyncGetMyNote,
  asyncGetMyTasks,
  asyncUploadEvaluationNotesFiles,
  deleteEvaluationNotesFile,
  getEvaluationNotesFiles,
  setIsNotesOpen,
  setIsTasksOpen,
  setNoteAction,
  setNotesQuestionIdAction,
  setTaskAction,
  setTasksCounts,
  setTasksQuestionIdAction,
  updateTaskAction,
} from './action'

export function setIsTasksOpenReducer(builder: ActionReducerMapBuilder<EvaluationActionsGroupState>) {
  builder.addCase(setIsTasksOpen, (state, action: PayloadAction<boolean>) => {
    state.tasks.isOpen = action.payload
  })
}

export function setIsNotesOpenReducer(builder: ActionReducerMapBuilder<EvaluationActionsGroupState>) {
  builder.addCase(setIsNotesOpen, (state, action: PayloadAction<boolean>) => {
    state.notes.isOpen = action.payload
  })
}

export function getMyTasksReducer(builder: ActionReducerMapBuilder<EvaluationActionsGroupState>) {
  builder.addCase(asyncGetMyTasks.pending, (state) => {
    state.isLoading = true
    state.tasks.isOpen = true
  })

  builder.addCase(asyncGetMyTasks.fulfilled, (state, action: PayloadAction<FillEvaluationMyTasks>) => {
    state.isLoading = false
    state.tasks = { ...state.tasks, ...action.payload }
  })

  builder.addCase(asyncGetMyTasks.rejected, (state) => {
    state.isLoading = false
  })
}

export function createMyTaskReducer(builder: ActionReducerMapBuilder<EvaluationActionsGroupState>) {
  builder.addCase(asyncCreateMyTask.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(setTaskAction, (state, action: PayloadAction<MyTask>) => {
    state.tasks.tasks.push(action.payload)
    state.isLoading = false
  })
  builder.addCase(asyncCreateMyTask.rejected, (state) => {
    state.isLoading = false
  })
}

export function updateMyTaskReducer(builder: ActionReducerMapBuilder<EvaluationActionsGroupState>) {
  builder.addCase(updateTaskAction, (state, action: PayloadAction<MyTask>) => {
    const index = state.tasks.tasks.findIndex((i) => i.id === action.payload.id)
    state.tasks.tasks[index] = action.payload
    state.isLoading = false
  })
}

export function removeTaskReducer(builder: ActionReducerMapBuilder<EvaluationActionsGroupState>) {
  builder.addCase(asyncDeleteTask.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(asyncDeleteTask.fulfilled, (state, action: PayloadAction<number>) => {
    state.tasks.tasks = state?.tasks?.tasks?.filter((i) => i.id !== action.payload)
    state.isLoading = false
  })

  builder.addCase(asyncDeleteTask.rejected, (state) => {
    state.isLoading = false
  })
}

export function addMyNoteReducer(builder: ActionReducerMapBuilder<EvaluationActionsGroupState>) {
  builder.addCase(asyncCreateNote.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(asyncCreateNote.fulfilled, (state) => {
    state.isLoading = false
  })

  builder.addCase(asyncGetMyNote.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(
    setNoteAction,
    (
      state,
      action: PayloadAction<{
        content: string
        id: number
      }>,
    ) => {
      state.notes.list[action.payload.id] = action.payload.content
      state.isLoading = false
    },
  )

  builder.addCase(asyncGetMyNote.rejected, (state) => {
    state.isLoading = false
  })

  builder.addCase(asyncCreateNote.rejected, (state) => {
    state.isLoading = false
  })
}

export function getEvaluationNotesFilesReducer(builder: ActionReducerMapBuilder<EvaluationActionsGroupState>) {
  builder.addCase(getEvaluationNotesFiles.fulfilled, (state, action: PayloadAction<NotesFilesWithKey>) => {
    state.notes.notesFiles = { ...state.notes.notesFiles, ...action.payload }
  })
}

export function uploadEvaluationNotesFilesReducer(builder: ActionReducerMapBuilder<EvaluationActionsGroupState>) {
  builder.addCase(
    asyncUploadEvaluationNotesFiles.fulfilled,
    (state, action: PayloadAction<{ questionId: number; files: UploadFile[] } | null>) => {
      const { files = [], questionId } = action.payload || {}

      if (questionId) {
        state.notes.notesFiles[questionId] = [...(state.notes.notesFiles?.[questionId] || []), ...files]
      }
    },
  )
}

export function deleteEvaluationNotesFilesReducer(builder: ActionReducerMapBuilder<EvaluationActionsGroupState>) {
  builder.addCase(
    deleteEvaluationNotesFile.fulfilled,
    (state, action: PayloadAction<Omit<DeleteEvaluationNotesFilePayload, 'evaluationId'>>) => {
      const { questionId, file } = action.payload

      state.notes.notesFiles[questionId] = state.notes.notesFiles[questionId]?.filter(
        ({ file: fileUrl }) => fileUrl !== file,
      )
    },
  )
}

export function setNotesQuestionIdActionReducer(builder: ActionReducerMapBuilder<EvaluationActionsGroupState>) {
  builder.addCase(setNotesQuestionIdAction, (state, action: PayloadAction<number>) => {
    state.notes.questionId = action.payload
  })
}

export function setTasksQuestionIdActionReducer(builder: ActionReducerMapBuilder<EvaluationActionsGroupState>) {
  builder.addCase(setTasksQuestionIdAction, (state, action: PayloadAction<number>) => {
    state.tasks.questionId = action.payload
  })
}

export function setTasksCountsReducer(builder: ActionReducerMapBuilder<EvaluationActionsGroupState>) {
  builder.addCase(setTasksCounts, (state, action: PayloadAction<SetTasksCountsPayload>) => {
    const { count, questionId } = action?.payload || {}

    state.tasksCounts = { ...state.tasksCounts, [questionId]: count }
  })
}
