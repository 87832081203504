import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Colors } from 'constants/global'

import { ButtonWrapper, Loading } from './style'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: IconProp
  children?: ReactNode | string
  backgroundColor?: Colors
  color?: Colors
  isLoading?: boolean
  disabledEffects?: boolean
}

const Button: FC<Props> = ({
  children,
  icon,
  backgroundColor = Colors.BLUE,
  color = Colors.WHITE,
  type = 'button',
  isLoading,
  disabled,
  ...props
}) => {
  return (
    <ButtonWrapper
      backgroundColor={backgroundColor}
      color={color}
      isChildren={!!children}
      type={type}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {icon && <FontAwesomeIcon icon={icon} />}
          {children}
        </>
      )}
    </ButtonWrapper>
  )
}

export default Button
