import React, { FC, useMemo } from 'react'

import { PasswordComplexityWrapper } from './style'

interface Props {
  numberedPasswordComplexity?: number
}

const COMPLEXITY_LEVELS_DATA: { [key: string]: { label: string; color: string } } = {
  1: { label: 'very weak', color: '#FF0000' },
  2: { label: 'weak', color: '#D35400' },
  3: { label: 'medium', color: '#F39C12' },
  4: { label: 'average', color: '#F1C40F' },
  5: { label: 'strong', color: '#229954' },
}

const PasswordComplexityBlock: FC<Props> = ({ numberedPasswordComplexity }) => {
  const passwordComplexity = useMemo(() => {
    if (!numberedPasswordComplexity) return null

    return COMPLEXITY_LEVELS_DATA[numberedPasswordComplexity]
  }, [numberedPasswordComplexity])

  if (!passwordComplexity) return null

  return (
    <PasswordComplexityWrapper backgroundColor={passwordComplexity.color}>
      {passwordComplexity.label}
    </PasswordComplexityWrapper>
  )
}

export default PasswordComplexityBlock
