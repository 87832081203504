import React, { FC, useMemo } from 'react'

import { format } from 'date-fns'

import { DEFAULT_DATE_FORMAT } from 'constants/global'
import { ALIGN } from 'constants/shared'
import { AdditionalButton, KeyWithString, AnsweredCountPropsInterface } from 'interfaces'
import {
  selectOfflineUploadProgress,
  selectQuestionsLength,
  selectQuestionsText,
  selectUpdatedAt,
} from 'modules/fill-evaluation/selectors'
import { useAppSelector } from 'modules/store'
import { ModalWindow, ProgressBar } from 'shared-components'
import { getCorrectAnswerValue } from 'utils'

import { Block, Center, Paragraph, Span, Strong, Title } from './style'

const OFFLINE_SYNC_MODAL_TITLE = 'Offline sync'

interface Props extends AnsweredCountPropsInterface {
  isOpen: boolean
  additionalButtons: AdditionalButton[]
  onClose: VoidFunction
  onlineAnswers: KeyWithString
  offlineAnswers: KeyWithString
  differentAnswers: string[]
}

const OfflineSyncModal: FC<Props> = ({
  onlineAnswers,
  offlineAnswers,
  differentAnswers,
  onlineAnsweredCount,
  offlineAnsweredCount,
  ...props
}) => {
  const progressValue = useAppSelector(selectOfflineUploadProgress)
  const questionsLength = useAppSelector(selectQuestionsLength)
  const questions = useAppSelector(selectQuestionsText)

  const updated_at = useAppSelector(selectUpdatedAt)

  const formattedUpdatedAt = updated_at && format(new Date(updated_at), DEFAULT_DATE_FORMAT)

  const loadingText = useMemo(() => {
    let str = ''
    switch (progressValue) {
      case 0:
        str = 'Loading started'
        break
      case 33:
        str = 'Sync 1 completed'
        break
      case 66:
        str = 'Sync 2 completed'
        break
      case 99:
        str = 'Sync 3 completed'
        break
      case 100:
        str = 'Synchronization completed successfully'
        break
      default:
        break
    }
    return str
  }, [progressValue])

  return (
    <ModalWindow
      isCloseHidden
      isCrossHidden={!!progressValue}
      isDisableOutsideClick={!!progressValue}
      title={OFFLINE_SYNC_MODAL_TITLE}
      bottomAlign={ALIGN.CENTER}
      {...props}
    >
      <>
        <Title>Please synchronize offline and online versions of answers</Title>
        <Paragraph>
          Online Answered: {onlineAnsweredCount}/{questionsLength}
        </Paragraph>
        <Paragraph>
          Offline Answered: {offlineAnsweredCount || 0}/{questionsLength}
        </Paragraph>

        {differentAnswers?.length
          ? differentAnswers.map((answer) => {
              const onlineAnswer = answer ? getCorrectAnswerValue(onlineAnswers?.[answer]) : ''
              const offlineAnswer = answer ? getCorrectAnswerValue(offlineAnswers?.[answer]) : ''

              const questionData =
                questions?.length && answer
                  ? questions.find((i) => i.id === Number(answer))
                  : ({} as { id: string; question: string })

              return (
                <Paragraph key={answer}>
                  The answer to <Strong>{questionData?.question || answer}</Strong> does not match the offline version
                  <Span>
                    Online answer - {onlineAnswer || 'null'} | Last sync - {formattedUpdatedAt}
                  </Span>
                  <Span>
                    Offline answer - {offlineAnswer || 'null'} | Last sync - {formattedUpdatedAt}
                  </Span>
                </Paragraph>
              )
            })
          : null}

        {!offlineAnsweredCount && <Paragraph>Your tasks or notes should be synchronize</Paragraph>}

        <Block>
          {progressValue !== null ? <ProgressBar hideCompletedText valuePercentage={progressValue} /> : null}
        </Block>
        <Block>{loadingText}</Block>
        <Center>Please choose the preferred version of evaluation</Center>
      </>
    </ModalWindow>
  )
}

export default OfflineSyncModal
