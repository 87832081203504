import styled from 'styled-components'

export const UnloadContentWrapper = styled.div`
  padding: 5px 12px;

  & > h5 {
    font-weight: 600;
  }

  & > p {
    margin: 5px 0;
  }
`
