import React from 'react'

import { useFormik } from 'formik'

import { EMAIL_2_FA, GOOGLE_2_FA, VERIFY_BUTTON_LABEL, VERIFY_FIELDS, VERIFY_PAGE_TITLE } from 'constants/auth'
import { ISendVerifyCode } from 'interfaces'
import { useAppDispatch, useAppSelector } from 'modules/store'
import { asyncResendCode, send2FaCode } from 'modules/user/action'
import { select2Fa } from 'modules/user/selectors'
import { AuthContent, Resend } from 'page-components'
import { getFormikProps, getFormikInitialValues, getFormikValidationSchema } from 'utils'

import { VerifyWrapper } from './styled'

const DEVICE_NAME = 'Postman'

const Verify = () => {
  const dispatch = useAppDispatch()
  const { isResended, channel2fa, access_token } = useAppSelector(select2Fa)

  const formik = useFormik({
    initialValues: getFormikInitialValues(VERIFY_FIELDS),
    onSubmit: (values) => {
      dispatch(
        send2FaCode({
          ...values,
          device_name: DEVICE_NAME,
          url: !!channel2fa ? GOOGLE_2_FA : EMAIL_2_FA,
          access_token,
        } as ISendVerifyCode),
      )
        .unwrap()
        .catch(() => {
          formik.resetForm()
          formik.setFieldError('two_factor_code', 'The two factor code you have entered does not match')
        })
    },
    validationSchema: getFormikValidationSchema(VERIFY_FIELDS),
    validateOnBlur: false,
    validateOnChange: false,
  })
  const formikProps = getFormikProps(formik)

  const resendVerifyEmail = () => {
    dispatch(asyncResendCode(access_token))
  }

  return (
    <VerifyWrapper>
      <AuthContent
        formikProps={formikProps}
        fields={VERIFY_FIELDS}
        title={VERIFY_PAGE_TITLE}
        isShownCheckbox={false}
        inputClassName='lock'
        additionalInfoSection={
          <Resend isShownMessage={isResended} isShown={!channel2fa} onResend={resendVerifyEmail} />
        }
        submitButtonProps={{
          label: VERIFY_BUTTON_LABEL,
          isShownIcon: false,
          buttonClassName: 'button-alignLeft',
        }}
      />
    </VerifyWrapper>
  )
}

export default Verify
