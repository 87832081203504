import { createSlice } from '@reduxjs/toolkit'

import { QuestionSetsState } from 'interfaces'

import { QUESTION_SETS_SLICE_NAME } from './action'
import {
  addQuestionSetsItemReducer,
  deleteQuestionSetsItemReducer,
  editQuestionSetsItemReducer,
  getActiveQuestionSetsReducer,
  getArchiveQuestionSetsReducer,
  getQuestionSetsListReducer,
  moveQuestionSetToArchiveReducer,
  renameQuestionSetItemReducer,
  unArchiveQuestionSetsItemReducer,
} from './reducers'

const initialState: QuestionSetsState = {
  active: [],
  archive: [],
  isLoading: false,
  questionSetsList: [],
}

export const questionSetsSlice = createSlice({
  name: QUESTION_SETS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getActiveQuestionSetsReducer(builder)
    getArchiveQuestionSetsReducer(builder)
    addQuestionSetsItemReducer(builder)
    moveQuestionSetToArchiveReducer(builder)
    unArchiveQuestionSetsItemReducer(builder)
    editQuestionSetsItemReducer(builder)
    deleteQuestionSetsItemReducer(builder)
    renameQuestionSetItemReducer(builder)
    getQuestionSetsListReducer(builder)
  },
})

export default questionSetsSlice.reducer
