import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import { defaultDeviceInfo } from 'constants/evaluations'
import { Evaluation, EvaluationsState, EvaluationsWithMeta } from 'interfaces'
import { getPaginationDataFromMeta } from 'utils/shared'

import { asyncGetMyEvaluations, asyncSaveDeviceRelatedInfo, asyncUnloadEvaluation } from './action'

export function getMyEvaluationsReducer(builder: ActionReducerMapBuilder<EvaluationsState>) {
  builder.addCase(asyncGetMyEvaluations.pending, (state) => {
    state.isLoading = true
  })
  builder.addCase(asyncGetMyEvaluations.fulfilled, (state, action: PayloadAction<EvaluationsWithMeta | null>) => {
    if (!action?.payload) {
      state.isLoading = false
      return
    }

    state.myEvaluations = action.payload?.data
    state.myEvaluationsPagination = getPaginationDataFromMeta(action.payload?.meta)
    state.isLoading = false
  })
  builder.addCase(asyncGetMyEvaluations.rejected, (state) => {
    state.isLoading = false
  })
}

export function unloadEvaluationReducer(builder: ActionReducerMapBuilder<EvaluationsState>) {
  builder.addCase(asyncUnloadEvaluation.fulfilled, (state, action: PayloadAction<number>) => {
    state.myEvaluations = state.myEvaluations.map((item) =>
      item.id === action.payload ? { ...item, ...defaultDeviceInfo } : item,
    )
  })
}

export function saveDeviceRelatedInfoReducer(builder: ActionReducerMapBuilder<EvaluationsState>) {
  builder.addCase(asyncSaveDeviceRelatedInfo.fulfilled, (state, action: PayloadAction<Evaluation>) => {
    state.myEvaluations = state.myEvaluations.map((i) => (i.id === action.payload.id ? action.payload : i))
  })
}
