import { createSlice } from '@reduxjs/toolkit'

import { AreasState } from 'interfaces/areas.interfaces'

import { AREAS_SLICE_NAME } from './action'
import {
  createAreaReducer,
  deleteAreaReducer,
  editAreaReducer,
  getAreasListReducer,
  getAreasReducer,
  getCurrentAreaReducer,
} from './reducers'

const initialState: AreasState = {
  areas: [],
  areasList: [],
  currentArea: null,
  isLoading: false,
}

export const areasSlice = createSlice({
  name: AREAS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getAreasReducer(builder)
    createAreaReducer(builder)
    deleteAreaReducer(builder)
    getCurrentAreaReducer(builder)
    editAreaReducer(builder)
    getAreasListReducer(builder)
  },
})
export default areasSlice.reducer
