import { createSlice } from '@reduxjs/toolkit'

import { BASE_PAGINATION_DATA } from 'constants/shared'
import { RiskGradingsInitialState } from 'interfaces'

import { RISK_SLICE_NAME } from './action'
import {
  getRiskReducer,
  createRiskReducer,
  editRiskReducer,
  deleteRiskReducer,
  getRiskGradingsReducer,
} from './reducer'

const initialState: RiskGradingsInitialState = {
  list: [],
  currentRisk: null,
  isLoading: false,
  ...BASE_PAGINATION_DATA,
}

export const riskGradingsSlice = createSlice({
  name: RISK_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getRiskGradingsReducer(builder)
    getRiskReducer(builder)
    createRiskReducer(builder)
    editRiskReducer(builder)
    deleteRiskReducer(builder)
  },
})

export default riskGradingsSlice.reducer
