import React, { FC, SelectHTMLAttributes } from 'react'

import { Option } from 'interfaces'
import { Label, Error } from 'shared-components/input/styles'

import { SelectWrapper, SelectContainer } from './style'

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  options: Option[]
  label?: string
  error?: string
  placeholder?: string
}

const Style: FC<Props> = ({ options = [], label, placeholder, ...props }) => {
  return (
    <SelectContainer>
      {label && <Label>{label}</Label>}
      <SelectWrapper {...props}>
        {placeholder && (
          <option value='' disabled hidden>
            {placeholder}
          </option>
        )}
        {options.map(({ name, value }, index) => (
          <option key={name + index} value={value}>
            {name}
          </option>
        ))}
      </SelectWrapper>
      <Error>{props.error}</Error>
    </SelectContainer>
  )
}

export default Style
