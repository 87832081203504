import styled from 'styled-components'

export const Container = styled.div`
  overflow-x: auto;
`

export const TableWrapper = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  overflow-x: auto;
  font-size: 16px;
  & th:first-child,
  & td:first-child {
    padding-left: ${({ theme }) => theme.spaces.min6};
  }

  & th:last-child,
  & td:last-child {
    padding-right: ${({ theme }) => theme.spaces.min6};
  }

  & th {
    padding: ${({ theme }) => theme.spaces.min2};
    text-align: left;
  }

  & td {
    padding: ${({ theme }) => theme.spaces.min2};
  }
`

export const Thead = styled.thead`
  padding: 0 ${({ theme }) => theme.spaces.min6};
  color: ${({ theme }) => theme.colors.black1};
  font-weight: 700;
  line-height: 24px;
  box-shadow: inset 0 2px 0 #dee2e6, inset 0 -2px 0 #dee2e6;
`

export const Tbody = styled.tbody`
  & tr {
    &:nth-of-type(odd) {
      background: rgba(0, 0, 0, 0.05);
    }

    &:hover {
      background: rgba(0, 0, 0, 0.075);
    }
  }
`
