import React from 'react'

import { FlexCol } from 'page-components/home/evaluations/my-evaluations-item/style'

import { TasksSkeletonFlex, TasksSkeletonItem } from './style'

const TasksFormSkeleton = () => {
  const items = [1, 2, 3, 4, 5]

  return (
    <div>
      <TasksSkeletonFlex>
        {items.map((item) => (
          <FlexCol key={item}>
            <TasksSkeletonItem />
            <TasksSkeletonItem />
            <TasksSkeletonItem />
            <TasksSkeletonItem />
          </FlexCol>
        ))}
      </TasksSkeletonFlex>
    </div>
  )
}

export default TasksFormSkeleton
