import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { allEvaluationsApi } from 'apis'
import { EvaluationCreate } from 'interfaces'
import { getCorrectToSendEvaluationData } from 'utils'

import { setErrorNotify, setSuccessNotify } from '../notifications/action'

export const ALL_EVALUATIONS_SLICE_NAME = 'all-evaluations'

export const getAllEvaluationsList = createAsyncThunk(
  `${ALL_EVALUATIONS_SLICE_NAME}/getAllEvaluationsList`,
  async ({ page }: { page?: number }, { rejectWithValue }) => {
    try {
      const response = await allEvaluationsApi.getAllEvaluations(page)

      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const deleteFromAllEvaluationsList = createAsyncThunk(
  `${ALL_EVALUATIONS_SLICE_NAME}/deleteFromAllEvaluationsList`,
  async ({ id }: { id: number }, { rejectWithValue, dispatch }) => {
    try {
      await allEvaluationsApi.deleteFromAllEvaluationsList(id)

      dispatch(setSuccessNotify('Evaluation was successfully deleted'))

      return id
    } catch (e) {
      dispatch(setErrorNotify("Evaluation wasn't deleted"))
      return rejectWithValue(e)
    }
  },
)

export const createEvaluation = createAsyncThunk(
  `${ALL_EVALUATIONS_SLICE_NAME}/createEvaluation`,
  async ({ data }: { data: EvaluationCreate }, { rejectWithValue, dispatch }) => {
    try {
      const { scheduled, schedule_every, schedule_recurrence, schedule_every_period, appendDate, users } = data

      const response = await allEvaluationsApi.createAllEvaluationsItem({
        appendDate,
        ...getCorrectToSendEvaluationData(data),
        ...(scheduled && {
          scheduled,
          schedule_every: Number(schedule_every),
          schedule_every_period,
          schedule_recurrence,
          users,
        }),
      })
      if (response.status === 422) rejectWithValue('Invalid data')

      dispatch(setSuccessNotify('Evaluation was successfully created.'))

      return response.data
    } catch (e) {
      dispatch(setErrorNotify("Evaluation wasn't successfully created."))
      return rejectWithValue(e)
    }
  },
)

export const editEvaluation = createAsyncThunk(
  `${ALL_EVALUATIONS_SLICE_NAME}/editEvaluation`,
  async ({ data, id }: { data: EvaluationCreate; id: string }, { rejectWithValue, dispatch }) => {
    try {
      const { users } = data

      const response = await allEvaluationsApi.editAllEvaluationsItem(id, {
        ...getCorrectToSendEvaluationData(data),
        ...(users?.length && {
          users,
        }),
      })

      dispatch(setSuccessNotify('Evaluation was successfully edited.'))
      return response.data
    } catch (e) {
      dispatch(setErrorNotify("Evaluation wasn't edited."))
      return rejectWithValue(e)
    }
  },
)

export const getAllEvaluationsDropdownsList = createAsyncThunk(
  `${ALL_EVALUATIONS_SLICE_NAME}/getAllEvaluationsDropdownsList`,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(resetEvaluation())

      const response = await allEvaluationsApi.getAllEvaluationsDropdownsList()
      return response.data
    } catch (e) {
      dispatch(setErrorNotify('Something went wrong. Please check your internet connection'))
      return rejectWithValue(e)
    }
  },
)

export const getAllEvaluationsEditInfo = createAsyncThunk(
  `${ALL_EVALUATIONS_SLICE_NAME}/getAllEvaluationsEditInfo`,
  async ({ id }: { id: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await allEvaluationsApi.getAllEvaluationsEditInfo(id)

      return response.data
    } catch (e) {
      dispatch(setErrorNotify('Something went wrong. Please check your internet connection'))
      return rejectWithValue(e)
    }
  },
)

export const resetEvaluation = createAction('RESET_EVALUATION')
