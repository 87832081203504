import React, { FC, useContext } from 'react'

import { EntityTypes } from 'constants/evaluations'
import { QuestionsContext } from 'contexts/QuestionsContext'
import { Question } from 'interfaces'
import { EvaluationFillCard } from 'page-components'
import { Section } from 'shared-components'

interface Props {
  questions: Question[]
  isComplete?: boolean
  sectionPosition?: number | string
}

const QuestionsList: FC<Props> = ({ questions, isComplete, sectionPosition }) => {
  const { formik } = useContext(QuestionsContext)

  if (!formik) return null

  return (
    <>
      {questions?.length
        ? questions.map((question, index) => {
            const props = {
              key: question.id,
              position: sectionPosition ? `${sectionPosition}.${index + 1}` : index + 1,
            }

            return question.entityType === EntityTypes.SECTION ? (
              <Section {...props} {...question}>
                <QuestionsList
                  questions={question.questions || []}
                  isComplete={isComplete}
                  sectionPosition={sectionPosition ? props.position : index + 1}
                />
              </Section>
            ) : (
              <EvaluationFillCard {...props} {...question} isComplete={isComplete} sectionPosition={sectionPosition} />
            )
          })
        : null}
    </>
  )
}

export default QuestionsList
