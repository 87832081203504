import styled from 'styled-components'

export const EvaluationCompleteWrapper = styled.div`
  padding: 20px;
  margin: ${({ theme }) => `${theme.spaces.min5} ${theme.spaces.min3} `};
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
`

export const TextWrapper = styled.div`
  padding: 10px 7.5px;
  margin: 30px ${({ theme }) => `${theme.spaces.min2} 8px`};
  text-align: center;
`
export const EvaluationText = styled.p`
  font-size: 2rem;
  font-weight: 700;
  padding: 0.5rem;
  line-height: 1.2;
`

export const OptionBelow = styled.p`
  font-size: 1.75rem;
  padding: 0.5rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
`

export const EvaluationCompleteActionsWrapper = styled.div`
  margin-top: 36px;

  & > div {
    margin: 0;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.03);
    padding: ${({ theme }) => `${theme.spaces.min2} ${theme.spaces.min5} `};
    border-radius: 0;
  }
`
