import { AxiosRequestConfig } from 'axios'

import { httpApiClient, httpApiClientV3 } from 'configs/http-client'
import {
  ISendVerifyCode,
  LoginPayload,
  LoginResponse,
  ResetPasswordByTokenPayload,
  UserInfoInterface,
  UserInfoTwoFactor,
} from 'interfaces'
import { get2FacHeaders } from 'utils'

export class UserApi {
  constructor(private url: string) {}
  loginUser(data: Omit<LoginPayload, 'remember_me'>, config?: AxiosRequestConfig) {
    return httpApiClient.post(`${this.url}login`, data, config)
  }

  getUserInfo(config?: AxiosRequestConfig) {
    return httpApiClient.get<UserInfoInterface>(`/user`, config)
  }

  putUserInfoTwoFactor(config?: AxiosRequestConfig) {
    return httpApiClient.get<UserInfoTwoFactor>(`/user/twoFactor`, config)
  }

  updateUser(url: string, data: Partial<UserInfoInterface>, config?: AxiosRequestConfig) {
    return httpApiClient.put<UserInfoInterface>(`${this.url}${url}`, data, config)
  }

  updateTwoFactor(url: string, data: Partial<UserInfoInterface>, config?: AxiosRequestConfig) {
    return httpApiClient.put<{ data: UserInfoInterface }>(`${this.url}${url}/twoFactor`, data, config)
  }

  resendCode(token: string | undefined, config?: AxiosRequestConfig) {
    return httpApiClient.post<LoginResponse>(`${this.url}2fa/resend`, get2FacHeaders(token), config)
  }

  send2FaCode(url: string, data: Partial<ISendVerifyCode>, accessToken: string, config?: AxiosRequestConfig) {
    return httpApiClient.post<LoginResponse>(`${this.url}2fa/${url}`, data, {
      ...config,
      ...get2FacHeaders(accessToken),
    })
  }

  resetPassword(data: { email: string }, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ message: string }>(`${this.url}password/email`, data, config)
  }

  resetPasswordByToken(payload: ResetPasswordByTokenPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ message: string }>(`${this.url}password/reset`, payload, config)
  }
}

export const userApi = new UserApi('/')
