import React, { FC, useEffect, useState } from 'react'

import {
  MY_EVALUATIONS_HEADERS,
  NO_EVALUATIONS_MESSAGE_TEXT,
  OWN_EVALUATIONS_OFFLINE_TEXT,
} from 'constants/evaluations'
import { Colors } from 'constants/global'
import { routes } from 'constants/routes'
import usePagination from 'hooks/usePagination'
import { ContinueFuncType, Evaluation } from 'interfaces'
import { selectIsLoading, selectMyEvaluationsPagination } from 'modules/evaluations/selectors'
import { useAppSelector } from 'modules/store'
import { MyEvaluationsFilters, MyEvaluationsItem, MyEvaluationsItemSkeleton } from 'page-components'
import { ContentCard, Pagination, Table } from 'shared-components'
import { DisabledOverlay } from 'shared-components/disabled-overlay/style'

import { EvaluationsTableWrapper } from './style'

interface Props {
  evaluationTitle: string
  setIsLoadingOffline: (value: boolean) => void
  evaluations?: Evaluation[]
  isOffline?: boolean
  skeletonItemsCount?: number
  hideTable?: boolean
  isDisabled?: boolean
  onContinue: ContinueFuncType
  handleUnloadConfirm: (value: number) => void
}

const MyEvaluationsList: FC<Props> = ({
  evaluationTitle,
  skeletonItemsCount,
  isDisabled,
  setIsLoadingOffline,
  evaluations,
  ...props
}) => {
  const isEvaluationsLoading = useAppSelector(selectIsLoading)
  const myEvaluationsPagination = useAppSelector(selectMyEvaluationsPagination)

  const [isLoading, setIsLoading] = useState(true)

  const { handleChangePage } = usePagination({
    baseUrl: routes.myEvaluations,
    paginationProps: myEvaluationsPagination,
    isLength: !!evaluations?.length,
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 2600)

    return () => clearTimeout(timer)
  }, [])

  const filteredEvaluations = evaluations?.filter((evaluation) => !evaluation.completed_at)

  if (props.hideTable) return null

  return (
    <>
      <MyEvaluationsFilters />
      <p>{OWN_EVALUATIONS_OFFLINE_TEXT}</p>
      <EvaluationsTableWrapper>
        <ContentCard
          title={evaluationTitle}
          topBackground={Colors.GRAY}
          spaces='0'
          topCustomComponent={
            myEvaluationsPagination?.total / myEvaluationsPagination?.pageSize > 1 ? (
              <Pagination onChange={handleChangePage} {...myEvaluationsPagination} />
            ) : null
          }
        >
          {isDisabled && <DisabledOverlay>{evaluationTitle} is disabled</DisabledOverlay>}

          {isLoading || isEvaluationsLoading ? (
            <>
              {Array.from({ length: skeletonItemsCount || 3 }, (_, k) => k + 1).map((item) => (
                <MyEvaluationsItemSkeleton key={item} />
              ))}
            </>
          ) : (
            <>
              {!filteredEvaluations?.length && !evaluations?.length ? (
                <p>{NO_EVALUATIONS_MESSAGE_TEXT}</p>
              ) : (
                <Table headers={MY_EVALUATIONS_HEADERS}>
                  <>
                    {filteredEvaluations?.length
                      ? filteredEvaluations.map((item) => (
                          <MyEvaluationsItem
                            {...item}
                            {...props}
                            setIsLoadingOfflineItem={setIsLoadingOffline}
                            key={item?.id}
                          />
                        ))
                      : null}
                  </>
                </Table>
              )}
            </>
          )}
        </ContentCard>
      </EvaluationsTableWrapper>
    </>
  )
}

export default MyEvaluationsList
