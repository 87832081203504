import { createAsyncThunk } from '@reduxjs/toolkit'

import { riskGradingsApi } from 'apis'
import { routes } from 'constants/routes'
import {
  ListResponse,
  RiskGradingsEditPayload,
  RiskGradingsItemResponse,
  RiskGradingsValues,
  TableDefaultItemInterface,
} from 'interfaces'
import history from 'services/history'

import { setErrorNotify, setSuccessNotify } from '../notifications/action'

export const RISK_SLICE_NAME = 'risk'

export const getRiskGradingsList = createAsyncThunk(`${RISK_SLICE_NAME}/getAllRisks`, async (page?: number) => {
  const response = await riskGradingsApi.getList<ListResponse>(page)

  return response.data
})

export const getRiskAction = createAsyncThunk(`${RISK_SLICE_NAME}/getRisk`, async (id: string, { rejectWithValue }) => {
  try {
    const response = await riskGradingsApi.getItem<RiskGradingsItemResponse>(id)
    return response.data
  } catch (e) {
    return rejectWithValue(e)
  }
})

export const createRiskAction = createAsyncThunk(
  `${RISK_SLICE_NAME}/createRisk`,
  async (data: RiskGradingsValues, { dispatch, rejectWithValue }) => {
    try {
      const response = await riskGradingsApi.createItem<RiskGradingsValues, TableDefaultItemInterface>(data)
      dispatch(setSuccessNotify('Risk grading was successfully created'))
      history.push(routes.riskGradings)
      return response.data
    } catch (e) {
      dispatch(setErrorNotify("Risk grading wasn't created"))
      return rejectWithValue(e)
    }
  },
)

export const editRiskAction = createAsyncThunk(
  `${RISK_SLICE_NAME}/editRisk`,
  async (data: RiskGradingsEditPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await riskGradingsApi.editItem<RiskGradingsEditPayload, TableDefaultItemInterface>(data)
      dispatch(setSuccessNotify(`Risk grading ${data.id} was successfully edited`))
      history.push(routes.riskGradings)
      return response.data
    } catch (e) {
      dispatch(setErrorNotify(`Risk grading ${data.id} wasn't edited`))
      return rejectWithValue(e)
    }
  },
)

export const deleteRiskAction = createAsyncThunk(
  `${RISK_SLICE_NAME}/deleteRisk`,
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await riskGradingsApi.deleteItem(id)
      if (response.status !== 204) throw new Error('Something went wrong.')

      dispatch(setSuccessNotify(`Risk grading ${id} was successfully deleted`))
      return id
    } catch (e) {
      dispatch(setErrorNotify(`Risk grading ${id} wasn't deleted`))
      return rejectWithValue(e)
    }
  },
)
