import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'modules/store'

const selectState = (x: RootState) => x.allEvaluationsReducer

export const selectAllEvaluations = createSelector(selectState, (state) => state)
export const selectAllEvaluationsPagination = createSelector(selectState, ({ total, current_page, per_page }) => ({
  total,
  pageSize: per_page,
  current: current_page,
}))

export const selectSettingsData = createSelector(
  selectState,
  ({ areas, categories, questionSets, evaluation, users }) => ({
    evaluation,
    areas,
    categories,
    questionSets,
    users,
  }),
)
