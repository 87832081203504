import { AxiosRequestConfig } from 'axios'

import { httpApiClientV4 } from 'configs/http-client'
import { QuestionSetV4 } from 'interfaces'

import { BaseApi } from './base.apis'

export class QuestionSetsApi extends BaseApi {
  getQuestionSetsList(config?: AxiosRequestConfig) {
    return httpApiClientV4.get<{ data: QuestionSetV4[] }>(this.url, config)
  }
}

export const questionSetsV4Api = new QuestionSetsApi('/questionSets')
