import React, { FC, MouseEventHandler, useMemo } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import { ALL_EVALUATIONS_NAVIGATE_TEXT } from 'constants/all-evaluations'
import {
  MY_EVALUATIONS_NAVIGATE_TEXT,
  MY_EVALUATIONS_COMPLETE_TEXT,
  EVALUATION_SUBMIT_EVALUATION_TEXT,
} from 'constants/evaluations'
import { Colors } from 'constants/global'
import { routes } from 'constants/routes'
import { Button } from 'shared-components'

import { EvaluationBottomWrapper } from './style'

const EvaluationBottom: FC = () => {
  const location = useLocation()

  const isOnEvaluation = new RegExp(routes.fillEvaluation).test(location.pathname)
  const backText = isOnEvaluation ? ALL_EVALUATIONS_NAVIGATE_TEXT : MY_EVALUATIONS_NAVIGATE_TEXT

  const submitText = useMemo(() => {
    if (isOnEvaluation) return EVALUATION_SUBMIT_EVALUATION_TEXT

    return MY_EVALUATIONS_COMPLETE_TEXT
  }, [isOnEvaluation])

  const navigate = useNavigate()

  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    navigate(-1)
  }

  return (
    <EvaluationBottomWrapper>
      {isOnEvaluation ? (
        <Link to={routes.myEvaluations}>
          <FontAwesomeIcon icon={faAngleLeft as IconDefinition} />
          {backText}
        </Link>
      ) : (
        <Button onClick={handleClick}>
          <FontAwesomeIcon icon={faAngleLeft as IconDefinition} />
          {backText}
        </Button>
      )}

      <Button type='submit' backgroundColor={Colors.GREEN}>
        {submitText}
      </Button>
    </EvaluationBottomWrapper>
  )
}

export default EvaluationBottom
