import { createSelector } from '@reduxjs/toolkit'

import { NumberOrNullType } from 'interfaces'
import { RootState } from 'modules/store'
import { convertQuestionSetsV4ListToOptions } from 'utils'

const selectState = (x: RootState) => x.questionSetsReducer

export const selectQuestionSetsState = createSelector(selectState, (state) => state.active)

export const selectQuestionSetsArchiveState = createSelector(selectState, (state) => state.archive)

export const selectQuestionSetsItemById = (id: number) =>
  createSelector(selectState, (state) => state.active.find((i) => i.id === id))

export const selectQuestionSetsForRename = (id: NumberOrNullType) =>
  createSelector(selectState, (state) => {
    if (id) {
      const item = state.active.find((i) => i.id === id)
      return { id: item?.id, name: item?.name }
    }
    return ''
  })

export const selectQuestionSetsListAsOptions = createSelector(selectState, (state) =>
  convertQuestionSetsV4ListToOptions(state.questionSetsList),
)
