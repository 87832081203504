import React from 'react'

import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'

import ThemeContext from 'contexts/ThemeContext'
import { store } from 'modules/store'
import history from 'services/history'

import App from './App'
import './lib.css'
import './globals'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/js/modal'
import 'bootstrap/js/dropdown'
import 'bootstrap/js/tooltip'
import 'bootstrap/dist/css/bootstrap.css'
import 'react-summernote/dist/react-summernote.css' // import styles
import 'react-summernote/lang/summernote-ru-RU' // you can import any other locale
import NetworkContext from './contexts/NetworkContext'

ReactDOM.render(
  <HistoryRouter history={history}>
    <ThemeContext>
      <Provider store={store}>
        <NetworkContext>
          <App />
        </NetworkContext>
      </Provider>
    </ThemeContext>
  </HistoryRouter>,
  document.getElementById('root'),
)
