import React from 'react'

import { SkeletonEvaluationItem, SkeletonEvaluationContainer } from './style'

const SkeletonEvaluation = () => {
  const items = [1, 2, 3, 4, 5]

  return (
    <SkeletonEvaluationContainer>
      {items.map((item) => (
        <SkeletonEvaluationItem key={item} />
      ))}
    </SkeletonEvaluationContainer>
  )
}

export default SkeletonEvaluation
