import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Colors } from '../../constants/global'

export const LinkWithIconWrapper = styled(Link)<{
  background: Colors
  color: Colors
}>`
  background: ${({ background, theme }) => `${theme.colors[background]}`};
  color: ${({ color, theme }) => `${theme.colors[color]}`};

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  line-height: 21px;
  padding: 8px;
  vertical-align: middle;
  border-radius: 4px;

  & > svg {
    margin-right: 4px;
  }

  &:hover {
    text-decoration: none;
    color: #fff;
  }
`
