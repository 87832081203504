import React, { FC } from 'react'

import { ReactComponent as SpinLoader } from 'assets/icons/spin-loader.svg'
import { PageProps } from 'interfaces'
import { Overlay } from 'shared-components/modal/style'

import { ScreenLoaderWrapper } from './style'

interface Props extends Pick<PageProps, 'isSidebarOpen'> {
  isOpen: boolean
}

const ScreenLoader: FC<Props> = ({ isSidebarOpen, isOpen }) => {
  return (
    <Overlay isOpen={isOpen}>
      <ScreenLoaderWrapper isSidebarOpen={isSidebarOpen}>
        <SpinLoader />
      </ScreenLoaderWrapper>
    </Overlay>
  )
}

export default ScreenLoader
