import React, { FC, SelectHTMLAttributes, useMemo } from 'react'

import { format } from 'date-fns'
import { FormikHelpers } from 'formik'
import DatePickerComponent from 'react-datepicker'

import { ISO_STRING_FORMAT, LONDON_TIMEZONE } from 'constants/dates'
import { DEFAULT_DATE_FORMAT } from 'constants/global'
import { Label, Error } from 'shared-components/input/styles'
import { formatInTimeZone } from 'utils/dates'

import { DatePickerWrapper } from './style'

interface Props extends SelectHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: string
  value: string
  name: string
  setFieldValue: FormikHelpers<string>['setFieldValue']
  placeholder?: string
  dateFormatToSet?: string
}

const DatePicker: FC<Props> = ({ name, placeholder, label, value, error, setFieldValue, dateFormatToSet }) => {
  const selectedDate: Date | null = useMemo(() => {
    if (value) return new Date(new Date(value).setHours(0, 0, 0, 0))
    return null
  }, [value])

  return (
    <DatePickerWrapper error={error}>
      {label && <Label>{label}</Label>}

      <DatePickerComponent
        name={name}
        placeholderText={placeholder}
        selected={selectedDate}
        dateFormat={DEFAULT_DATE_FORMAT}
        onChange={(value: Date | null) => {
          if (value) {
            const ukValue = dateFormatToSet
              ? format(value, dateFormatToSet)
              : formatInTimeZone(new Date(value), ISO_STRING_FORMAT, LONDON_TIMEZONE)

            setFieldValue(name, ukValue)
          } else {
            setFieldValue(name, '')
          }
        }}
      />
      {error && <Error>{error}</Error>}
    </DatePickerWrapper>
  )
}

export default DatePicker
