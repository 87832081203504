import React, { FC } from 'react'

import { Link } from 'react-router-dom'

import { AUTH_LOGO_IMAGE_WIDTH, AUTH_LOGO_IMAGE_HEIGHT } from 'constants/auth'
import { routes } from 'constants/routes'

import OfflineLogo from '../assets/icons/provev-offline.svg'

interface Props {
  size?: number
}

const Logo: FC<Props> = ({ size }) => {
  return (
    <Link to={routes.myEvaluations}>
      <img src={OfflineLogo} alt='logo' width={size || AUTH_LOGO_IMAGE_WIDTH} height={size || AUTH_LOGO_IMAGE_HEIGHT} />
    </Link>
  )
}

export default Logo
