import React, { FC, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { Colors } from 'constants/global'
import { routes } from 'constants/routes'
import { asyncGetMyFillEvaluations } from 'modules/fill-evaluation/action'
import { selectEvaluationFinishInfo } from 'modules/fill-evaluation/selectors'
import { useAppDispatch, useAppSelector } from 'modules/store'
import { EvaluationCompleteContentSkeleton, EvaluationFinishItem, HomeContentLayout } from 'page-components'
import { BottomActions, ContentCard, Table } from 'shared-components'

import { EvaluationFinishWrapper } from './style'

const EVALUATION_FINISH_TABLE_HEADERS = ['id', 'question', 'answer']

const EvaluationFinish: FC = () => {
  const { id: paramsId } = useParams()
  const dispatch = useAppDispatch()

  const { id, title, questions, isLoading } = useAppSelector(selectEvaluationFinishInfo)

  const pageTitle = `Finish Evaluation "${title}"`

  useEffect(() => {
    if (paramsId && !id) dispatch(asyncGetMyFillEvaluations(Number(paramsId)))
  }, [dispatch, paramsId, id])

  return (
    <>
      {!isLoading || questions?.length ? (
        <HomeContentLayout title={pageTitle}>
          <EvaluationFinishWrapper>
            <ContentCard topBackground={Colors.GRAY} spaces='0' title='Finish Evaluation Info'>
              <Table headers={EVALUATION_FINISH_TABLE_HEADERS}>
                {questions.map((i) => (
                  <EvaluationFinishItem key={i.id} {...i} />
                ))}
              </Table>
              <BottomActions navigateLink={routes.myEvaluations} customBackground={Colors.GRAY1} />
            </ContentCard>
          </EvaluationFinishWrapper>
        </HomeContentLayout>
      ) : (
        <EvaluationCompleteContentSkeleton />
      )}
    </>
  )
}

export default EvaluationFinish
