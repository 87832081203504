import React, { FC, useMemo } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse } from 'react-collapse'
import { NavLink, useLocation } from 'react-router-dom'

import { Colors } from 'constants/global'
import useToggle from 'hooks/useToggle'
import { SidebarLink } from 'interfaces'
import { Button } from 'shared-components'

import { SidebarItem, SidebarNavHeader } from './style'

interface Props extends SidebarLink {
  isSublistItem?: boolean
  isOpen?: boolean
}

const SidebarNavItem: FC<Props> = ({ name, icon, path, sublist, ...props }) => {
  const [isSublistOpen, toggleSublistOpen] = useToggle()

  const location = useLocation()

  const isSublistItemActive = useMemo(() => {
    return sublist?.find((i) => i.path === location.pathname)
  }, [location.pathname, sublist])

  if (!path && !sublist) return <SidebarNavHeader>{name}</SidebarNavHeader>

  return (
    <SidebarItem {...props} isSublistOpened={isSublistOpen}>
      {sublist?.length ? (
        <>
          <Button
            icon={icon as IconDefinition}
            onClick={toggleSublistOpen}
            backgroundColor={isSublistItemActive ? Colors.BLUE : Colors.TRANSPARENT}
          >
            <span>{name}</span>
            <FontAwesomeIcon icon={faAngleLeft as IconDefinition} />
          </Button>
          <Collapse isOpened={isSublistOpen}>
            <ul>
              {sublist.map((i) => (
                <SidebarNavItem key={i.name} {...i} isSublistItem />
              ))}
            </ul>
          </Collapse>
        </>
      ) : (
        <NavLink to={path || ''}>
          {icon && <FontAwesomeIcon icon={icon} />}
          <span>{name}</span>
        </NavLink>
      )}
    </SidebarItem>
  )
}

export default SidebarNavItem
