import styled, { keyframes } from 'styled-components'

import { Colors } from 'constants/global'

const spin = keyframes`
  from{
    transform: rotate(0deg);
  }to{
    transform: rotate(360deg);
  }
`

export const ButtonWrapper = styled.button<{
  backgroundColor: Colors
  color: Colors
  isChildren: boolean
  disabledEffects?: boolean
  disabled?: boolean
}>`
  position: relative;
  padding: ${({ theme }) => `7px ${theme.spaces.min2}`};
  background: ${({ backgroundColor, disabled, theme }) =>
    disabled ? theme.colors.gray10 : `${theme.colors[backgroundColor]}`} !important;
  color: ${({ color, theme }) => `${theme.colors[color]}`} !important;

  font-size: 16px;
  line-height: 24px;

  border: none;
  border-radius: 4px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    transition: 0.3s all ease;
  }

  &:hover {
    &:before {
      opacity: ${({ disabledEffects }) => (disabledEffects ? 0 : 0.1)};
    }
  }

  & > svg {
    margin-right: ${({ isChildren }) => (isChildren ? '4px' : '')};
  }
`

export const Loading = styled.div`
  width: 24px;
  height: 24px;

  border: 2px dashed #f3f3f3;
  border-radius: 100%;

  margin: auto;

  animation: ${spin} 1s infinite linear;
`
