export const createPriorityTaskObject = ({
  priority,
  recommendation,
}: {
  priority: string | number
  recommendation: string
}) => {
  if (!priority || !recommendation) return undefined

  return { priority, recommendation }
}
