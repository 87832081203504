import React, { FC, InputHTMLAttributes, useEffect, useState } from 'react'

import { FormikHelpers } from 'formik'

import { Option } from 'interfaces'
import { Select } from 'shared-components'
import { StyledInput, Error, Label } from 'shared-components/input/styles'

import { InputWithSelectContainer, InputWithSelectWrapper } from './style'

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  selectCurrentValue: string
  selectName: string
  error?: string
  label?: string
  setFieldValue: FormikHelpers<string>['setFieldValue']
  options: Option[]
}

const InputWithSelect: FC<Props> = ({
  label,
  type,
  options,
  value,
  setFieldValue,
  selectCurrentValue,
  selectName,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value)
  const [selectValue, setSelectValue] = useState(selectCurrentValue || options[0].value)

  useEffect(() => {
    setFieldValue(props.name, inputValue)
    setFieldValue(selectName, selectValue)
  }, [inputValue, props.name, selectName, selectValue, setFieldValue])

  return (
    <InputWithSelectContainer>
      {label && <Label>{label}</Label>}
      <InputWithSelectWrapper error={props.error}>
        <StyledInput
          type={type}
          {...props}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setInputValue(e?.currentTarget?.value)
          }}
        />
        <Select
          options={options}
          onChange={(e: React.FormEvent<HTMLSelectElement>) => setSelectValue(e.currentTarget.value)}
        />
      </InputWithSelectWrapper>
      <Error>{props.error}</Error>
    </InputWithSelectContainer>
  )
}

export default InputWithSelect
