import styled from 'styled-components'

export const DateRangePickerWrapper = styled.div<{ error?: string }>`
  position: relative;
  & span {
    display: none;
  }
`

export const DateRangePickerInputContainer = styled.div`
  display: flex;
  height: 38px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  overflow: hidden;

  & > input {
    width: 100%;
    height: 100%;
    padding: 6px 36px 6px 12px;
    border: none;
  }
`

export const DateRangePickerIcon = styled.div`
  width: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #e9ecef;
  border-right: 1px solid #ced4da;
`
