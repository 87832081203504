import styled from 'styled-components'

const fadeOut = ` 
  opacity:0;
  width:0;
  transform: translateX(-40px);
  transition: width 0.5s, height 0.5s, opacity 0.5s, transform 0.5s;
`

const fadeIn = `
  transform: translateX(0);
  transition: width 0.5s, height 0.5s, opacity 0.5s, transform 0.5s;`

export const SidebarContainer = styled.aside<{
  isOpen: boolean
}>`
  min-height: 100vh;

  background: ${({ theme }) => theme.colors.gray5};
  box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
  overflow: hidden;
  z-index: 1012;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.3s all ease-in-out;
  min-width: ${({ isOpen }) => (isOpen ? '250px' : '75px')};
  max-width: 75px;

  @media (max-width: 996px) {
    left: ${({ isOpen }) => (isOpen ? 0 : '-100%')};
  }

  & > div > a span {
    @media (min-width: 997px) {
      ${({ isOpen }) => {
        if (!isOpen) {
          return `
        & {
          ${fadeOut}
        };
      `
        } else {
          return `
        & {
           ${fadeIn}
        };
      `
        }
      }}
    }
  }

  &:hover {
    min-width: 250px;
    & h4 {
      display: block;
      height: 100%;
    }
    & ul li span,
    & div > a span {
      opacity: 1;
      transform: translateX(0);
    }

    & li > button > span ~ svg {
      display: block;
    }
  }
`

export const SidebarHeader = styled.div`
  padding: 13px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border4};

  & span {
    display: block;
    color: ${({ theme }) => theme.colors.secondWhite};
  }

  & img {
    margin-left: 3px;
  }

  & > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray2};
    font-size: 20px;
    font-weight: 300;

    display: flex;
    align-items: center;
  }

  & b {
    font-weight: 400;
  }
`

export const SidebarNavigation = styled.nav<{
  isOpen: boolean
}>`
  padding: 8px 8px 0;

  @media (min-width: 997px) {
    ${({ isOpen }) => {
      if (!isOpen) {
        return `
        & span {
          ${fadeOut}
          };
        
        & h4 {
          display:none;
          height: 0;
        };
        
      `
      } else {
        return `
        & span {
          ${fadeIn}
        };
      `
      }
    }}
  }
`

export const SidebarItem = styled.li<{
  isSublistItem?: boolean
  isOpen?: boolean
  isSublistOpened?: boolean
}>`
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  & > a,
  & > button {
    position: relative;
    padding: 8px ${({ theme }) => theme.spaces.min3};
    margin-bottom: 4px;
    border-radius: 4px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray6};

    display: flex;
    align-items: center;
    justify-content: flex-start;

    height: 40px;
    width: 100%;

    & svg {
      margin-right: 4px;
      width: 16px;
      height: 16px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;
      & svg {
        fill: #fff;
      }
    }

    &.active {
      background: ${({ theme, isSublistItem }) => (isSublistItem ? theme.colors.white5 : theme.colors.blue1)};
      color: ${({ theme, isSublistItem }) => (isSublistItem ? theme.colors.black1 : '#fff')};
    }
  }

  & > button {
    & > span ~ svg {
      position: absolute;
      top: calc(50% - 6px);
      right: 0;
      transform: ${({ isSublistOpened }) => `rotate(${isSublistOpened ? '-90deg' : 0})`};
      transition: 0.3s all ease;
      display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    }
  }
  & .ReactCollapse--collapse {
    transition: 0.3s all ease;
  }
`

export const SidebarNavHeader = styled.h4`
  padding: 8px ${({ theme }) => theme.spaces.min2};
  font-size: 14.4px;
  color: ${({ theme }) => theme.colors.gray7};
`

export const HeaderWrapper = styled.header<{
  isSidebarOpen: boolean
}>`
  background: #fff;
  width: 100%;
  border-bottom: 1px solid rgb(222, 226, 230);
  position: sticky;
  z-index: 10;
  top: 0;

  @media (max-width: 996px) {
    padding-left: 0;
  }
`

export const HeaderContainer = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    padding: 10px 8px;
  }
`

export const HeaderLeftSideContainer = styled.div`
  display: flex;
  align-items: center;
`

export const HeaderMenuButton = styled.button`
  margin: 8px ${({ theme }) => theme.spaces.min3};
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray9};

  &:hover {
    color: rgba(0, 0, 0, 0.7);
  }
`

export const SidebarShadow = styled.div<{
  isShown: boolean
}>`
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.2;
  z-index: 1010;
  @media (max-width: 996px) {
    display: ${({ isShown }) => (isShown ? 'block' : 'none')};
  }
`

export const HeaderEnvironment = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
`
