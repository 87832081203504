import styled from 'styled-components'

export const HomeLayoutWrapper = styled.div`
  & > main {
    padding: 0 8px;
  }
`

export const HomeLayoutHeader = styled.div`
  padding: ${({ theme }) => theme.spaces.min3} 8px;

  & > h2 {
    font-size: 29px;
    font-weight: 500;
    line-height: ${({ theme }) => theme.lineHeight.title};
    color: ${({ theme }) => theme.colors.black1};
    padding-left: 8px;
  }
`
