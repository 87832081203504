import { format, isBefore, isToday } from 'date-fns'
import { FormikProps } from 'formik'
import { browserName, osName } from 'react-device-detect'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import {
  AuthField,
  FormikProps as FormikPropsInterface,
  FormikPropsItem,
  Option,
  StringOrNullType,
  NotifyOptions,
  TableDataFormItem,
  StringOrNumber,
} from 'interfaces'

import { emptyEvaluationApi } from '../apis'

export const getFormikInitialValues = (
  array: AuthField[],
  defaultValues?: { [key: string]: unknown },
): { [key: string]: string } => {
  return array.reduce(
    (acc, item) => ({
      ...acc,
      [item.name]: (defaultValues && defaultValues[item.name]) || '',
    }),
    {},
  )
}

export const getFormikValidationSchema = (array: AuthField[]) =>
  Yup.object().shape({
    ...array.reduce(
      (obj, { name, validation }) => ({
        ...obj,
        [name]: validation,
      }),
      {},
    ),
  })

export const getFormikProps = (
  formik: FormikProps<{
    [key: string]: string
  }>,
): FormikPropsInterface => ({
  values: formik.values,
  errors: formik.errors,
  handleSubmit: formik.handleSubmit,
  onChange: formik.handleChange,
  setField: formik.setFieldValue,
})

export const notify = ({ message, type }: NotifyOptions): void => {
  toast(message, {
    type,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'colored',
  })
}

export const convertDate = (date: Date) => {
  return format(new Date(date), "dd-MM-yyyy' 'HH:mm:ss", {})
}

//eslint-disable-next-line
export const createFormikPropsByName = (formik: any, name: string): FormikPropsItem => {
  return {
    value: formik.values[name],
    error: formik.errors[name],
    onChange: formik.handleChange,
  }
}

export const formatObjectToSelectData = (data: { [key: string]: string }): Option[] => {
  return Object.entries(data).map(([value, name]) => ({
    name,
    value,
  }))
}

export const getDeviceAndDateDetails = (): string => `${browserName},${osName}`

export const generateRandomId = (): string => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

export const checkIsTodayOrBefore = (date: StringOrNullType): boolean => {
  if (!date) return false
  const formattedDate = new Date(date)

  return isBefore(formattedDate, new Date()) || isToday(formattedDate)
}

export const getPercentageOfTheNumber = (value: number, total: number): string => {
  return ((value * 100) / total).toFixed(2)
}

export const createInitialValues = <T>(fields: TableDataFormItem[]): T =>
  fields.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.name]: curr?.initial === false || curr?.initial === 0 ? curr?.initial : curr.initial || '',
    }),
    {},
  ) as T

export const getFontColorByHex = (hex: string) => {
  const c = hex.substring(1) // strip #
  const rgb = parseInt(c, 16) // convert rrggbb to decimal
  const red = (rgb >> 16) & 0xff // extract red
  const green = (rgb >> 8) & 0xff // extract green
  const blue = (rgb >> 0) & 0xff // extract blue
  const val = red * 0.299 + green * 0.587 + blue * 0.114

  if (val > 127.5) return '#000'
  else return '#fff'
}

export async function getBase64FromUrl(url: string) {
  return emptyEvaluationApi.getQuestionPicture(url).then((response) => {
    const reader = new window.FileReader()
    return new Promise((resolve) => {
      reader.readAsDataURL(response.data)
      reader.onload = function () {
        const imageDataUrl = reader.result
        resolve(imageDataUrl)
      }
    })
  })
}

export const convertObjectToQueryParametersString = (object: Record<string, StringOrNumber | null>) => {
  if (!Object.values(object)?.length) return ''

  return Object.entries(object).reduce((prev, [key, value]) => {
    if (!value) return prev

    return `${prev}${prev ? '&' : ''}${key}=${value}`
  }, '')
}

export const filterObjectByKeys = (inputObject: Record<string, string>, keysToKeep: string[]) => {
  return Object.fromEntries(Object.entries(inputObject).filter(([key]) => keysToKeep.includes(key)))
}
