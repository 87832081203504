import { createSelector } from '@reduxjs/toolkit'

import { NumberOrNullType } from 'interfaces'
import { RootState } from 'modules/store'

const selectState = (x: RootState) => x.evaluationActionsGroupReducer

export const selectTasksState = createSelector(selectState, (state) => state.tasks)

export const selectIsNotesOpen = createSelector(selectState, (state) => state.notes.isOpen)

export const selectIsLoading = createSelector(selectState, (state) => state.isLoading)

export const selectTasksInfo = createSelector(
  selectState,
  ({ tasks: { tasks, precannedTasks, users, priorities, questionId } }) => ({
    tasks,
    precannedTasks,
    users,
    priorities,
    questionId,
  }),
)

export const selectTasksCountById = (id: number) => createSelector(selectState, (state) => state?.tasksCounts?.[id])

export const selectNoteInfoById = (id: NumberOrNullType) =>
  createSelector(selectState, ({ notes: { notesFiles, list } }) => ({
    note: list[String(id)],
    files: id ? notesFiles[id] : [],
  }))

export const selectNote = createSelector(selectState, ({ notes: { list, questionId, notesFiles } }) => ({
  questionId,
  notesFiles: questionId ? notesFiles[questionId] : [],
  note: questionId ? list[questionId] : '',
}))

export const selectTasksUserNameById = (id: number) =>
  createSelector(selectState, (state) => state?.tasks?.users?.find((i) => Number(i.id) === Number(id))?.name)

export const selectEvaluationNotesFilesByQuestionId = createSelector(
  selectState,
  ({ notes: { questionId, notesFiles } }) => {
    return questionId ? notesFiles[questionId] : []
  },
)
