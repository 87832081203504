import styled from 'styled-components'

export const QuestionSetsWrapper = styled.div`
  padding: 0 8px;
`

export const QuestionSetsViewWrapper = styled(QuestionSetsWrapper)`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 49%;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    & > div {
      width: 100%;
      margin-bottom: ${({ theme }) => theme.spaces.min3};
      padding: 0;
    }
  }
`
