import { createSlice } from '@reduxjs/toolkit'

import { BASE_PAGINATION_DATA } from 'constants/shared'
import { EvaluationsState } from 'interfaces/evaluations.interfaces'

import { EVALUATIONS_SLICE_NAME } from './action'
import { getMyEvaluationsReducer, saveDeviceRelatedInfoReducer, unloadEvaluationReducer } from './reducers'

const initialState: EvaluationsState = {
  myEvaluations: [],
  myEvaluationsPagination: BASE_PAGINATION_DATA,
  allEvaluations: [],
  isLoading: false,
}

export const evaluationsSlice = createSlice({
  name: EVALUATIONS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getMyEvaluationsReducer(builder)
    unloadEvaluationReducer(builder)
    saveDeviceRelatedInfoReducer(builder)
  },
})

export default evaluationsSlice.reducer
