import { AxiosRequestConfig } from 'axios'

import { httpApiClientV3 } from 'configs/http-client'
import { AllEvaluations, AllEvaluationsDropdownInfo, Evaluation, EvaluationCreatePayload } from 'interfaces'

export class AllEvaluationsApi {
  constructor(private url: string) {}

  getAllEvaluations(page?: number, config?: AxiosRequestConfig) {
    return httpApiClientV3.get<AllEvaluations>(`${this.url}/?page=${page}`, config)
  }

  deleteFromAllEvaluationsList(id: number, config?: AxiosRequestConfig) {
    return httpApiClientV3.delete(`${this.url}/${id}`, config)
  }

  createAllEvaluationsItem(data: EvaluationCreatePayload, config?: AxiosRequestConfig) {
    return httpApiClientV3.post<Evaluation[]>(this.url, data, config)
  }

  editAllEvaluationsItem(id: string, data: EvaluationCreatePayload, config?: AxiosRequestConfig) {
    return httpApiClientV3.put<Evaluation>(`${this.url}/${id}`, data, config)
  }

  getAllEvaluationsDropdownsList(config?: AxiosRequestConfig) {
    return httpApiClientV3.get<AllEvaluationsDropdownInfo>(`${this.url}/create`, config)
  }

  getAllEvaluationsEditInfo(id: string, config?: AxiosRequestConfig) {
    return httpApiClientV3.get<AllEvaluationsDropdownInfo>(`${this.url}/${id}/edit`, config)
  }
}

export const allEvaluationsApi = new AllEvaluationsApi('/admin/evaluations')
