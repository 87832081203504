import styled from 'styled-components'

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 10%;
  @media (max-width: 768px) {
    flex: 0 1 10%;

    &:nth-child(3),  &:nth-child(4){
      display: none;
    }

    @media (max-width: 576px) {
      flex: 0 1 20%;

      &:nth-child(5),  &:nth-child(6){
        display: none;
      }
`
