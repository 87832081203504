import React, { FC, ReactNode, useContext } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLiveQuery } from 'dexie-react-hooks'

import { NetworkStatusContext } from 'contexts/NetworkContext'
import { db } from 'db/mainDb'
import useToggle from 'hooks/useToggle'
import { useAppSelector } from 'modules/store'
import { selectUserData } from 'modules/user/selectors'
import { DropdownContent } from 'page-components'
import { Dropdown } from 'shared-components'

import { UserDropdownWrapper, HeaderUserButton } from './style'

interface Props {
  children?: ReactNode
  isPinModalOpen?: boolean
}

const UserDropdown: FC<Props> = ({ children, isPinModalOpen }) => {
  const isOnline = useContext(NetworkStatusContext)

  const [isDropdownOpen, toggleDropdownOpen] = useToggle()
  const users = useLiveQuery(() => db.user.toArray())
  const { name } = useAppSelector(selectUserData)

  const offlineUser = users && users[0]

  const userNameToShow = isOnline ? name : offlineUser?.name || ''

  return (
    <UserDropdownWrapper>
      <HeaderUserButton onClick={toggleDropdownOpen}>
        <FontAwesomeIcon icon={faUser as IconDefinition} size='1x' />
        <span>{userNameToShow}</span>
      </HeaderUserButton>
      <Dropdown
        width={280}
        isOpen={isDropdownOpen}
        onClose={toggleDropdownOpen}
        isDisabledOutsideClick={isPinModalOpen}
      >
        <DropdownContent userName={userNameToShow}>{children}</DropdownContent>
      </Dropdown>
    </UserDropdownWrapper>
  )
}

export default UserDropdown
