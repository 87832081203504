import { AxiosRequestConfig } from 'axios'

import { AreaV4 } from 'interfaces'

import { httpApiClientV4 } from '../configs/http-client'
import { BaseApi } from './base.apis'

export class AreasApi extends BaseApi {
  getAreasList(config?: AxiosRequestConfig) {
    return httpApiClientV4.get<{ data: AreaV4[] }>(this.url, config)
  }
}

export const areasApi = new AreasApi('/admin/areas')
export const areasApiV4 = new AreasApi('/areas')
