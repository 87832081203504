import { createAction } from '@reduxjs/toolkit'

import { Notify } from 'interfaces'

export const NOTIFICATIONS_SLICE_NAME = 'notifications'

export const setNotify = createAction<Notify>('SET_NOTIFY')

export const setSuccessNotify = createAction<string>('SET_SUCCESS_NOTIFY')

export const setErrorNotify = createAction<string>('SET_ERROR_NOTIFY')

export const setWarningNotify = createAction<string>('SET_WARNING_NOTIFY')

export const resetNotify = createAction('RESET_NOTIFY')
