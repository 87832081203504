import React, { FC, useContext, useEffect, useMemo, useState } from 'react'

import { useLiveQuery } from 'dexie-react-hooks'
import { useFormik } from 'formik'

import { NetworkStatusContext } from 'contexts/NetworkContext'
import { fetchDownloadedFilesById, fetchNotesFilesById } from 'db/fetch-db'
import { addNotesFileById, addNotesFileToDelete } from 'db/files'
import { db } from 'db/mainDb'
import { saveNoteToDeletedList } from 'db/notes'
import useConfirm, { HandleOpenAndSubmitConfirmType } from 'hooks/useConfirm'
import { KeyWithString, MyNoteIndexed, NotesFile, UploadFile } from 'interfaces'
import {
  asyncCreateNote,
  asyncUploadEvaluationNotesFiles,
  deleteEvaluationNote,
  deleteEvaluationNotesFile,
  setIsNotesOpen,
} from 'modules/evaluation-actions-group/action'
import { selectIsLoading, selectIsNotesOpen, selectNote } from 'modules/evaluation-actions-group/selectors'
import { useAppDispatch, useAppSelector } from 'modules/store'
import { Button, Confirm, InputFile, ModalWindow, TextEditor } from 'shared-components'

import { DeleteNoteContainer, InputFileHeader } from './style'

interface Props {
  evaluationId: number
  isDisabled?: boolean
}

const Notes: FC<Props> = ({ evaluationId, isDisabled }) => {
  const isOnline = useContext(NetworkStatusContext)

  const dispatch = useAppDispatch()

  const [offlineFiles, setOfflineFiles] = useState<NotesFile[] | null>(null)
  const [downloadedOfflineFiles, setDownloadedOfflineFiles] = useState<UploadFile[] | null>(null)

  const { note, notesFiles, questionId } = useAppSelector(selectNote)
  const isOpen = useAppSelector(selectIsNotesOpen)
  const isLoading = useAppSelector(selectIsLoading)

  const offlineNote = useLiveQuery(
    () => (questionId && evaluationId ? db.notes.get({ id: questionId, evaluationId }) : {}),
    [questionId, evaluationId],
  ) as MyNoteIndexed

  const formattedOfflineFiles: UploadFile[] = useMemo(() => {
    if (!offlineFiles) return []

    return offlineFiles?.map(({ evaluationId, base64, file, user_id, id }) => ({
      id,
      user_id,
      base64,
      evaluation_id: evaluationId,
      originalName: file?.name,
      type: file?.type,
      size: file?.size,
      file: '',
      fileURL: '',
    }))
  }, [offlineFiles])

  const formik = useFormik({
    initialValues: {
      content: note || '',
    } as KeyWithString,
    onSubmit: (data) => {
      if (!evaluationId || !questionId) return

      if (!data?.content) {
        handleCloseNotes()
        return
      }

      dispatch(
        asyncCreateNote({
          evaluationId,
          questionId,
          isOnline,
          data,
        }),
      )
        .unwrap()
        .finally(handleCloseNotes)
    },
  })

  const handleDeleteNoteFile: HandleOpenAndSubmitConfirmType = (id, file) => {
    if (!evaluationId || !questionId) return

    if (isOnline) {
      dispatch(deleteEvaluationNotesFile({ evaluationId, questionId, file: file as string }))
      return
    }

    if (!id) return

    if (file && downloadedOfflineFiles?.length) {
      const noteFile = downloadedOfflineFiles?.find(
        ({ id: fileId, file: filePath }) => filePath === file && fileId === id,
      )
      addNotesFileToDelete(noteFile).then(handleFetchFiles)
      return
    }
    addNotesFileById(id).then(handleFetchFiles)
  }

  const handleDeleteNote = () => {
    const payload = { evaluationId, questionId: Number(questionId) }

    if (isOnline) {
      dispatch(deleteEvaluationNote(payload))
      return
    }

    saveNoteToDeletedList(payload).then(() => {
      formik.setFieldValue('content', '')
    })
  }

  const handleCloseNotes = () => dispatch(setIsNotesOpen(false))

  const { isConfirmOpen, handleOpenConfirm, handleCloseConfirm, onConfirm } = useConfirm(handleDeleteNoteFile)
  const {
    isConfirmOpen: isDeleteConfirmOpen,
    handleOpenConfirm: handleOpenDeleteConfirm,
    handleCloseConfirm: handleCloseDeleteConfirm,
    onConfirm: handleConfirmDelete,
  } = useConfirm(handleDeleteNote)

  const handleFetchFiles = () => {
    const payload = { evaluationId, questionId: Number(questionId) }
    fetchNotesFilesById(payload).then((files) => {
      setOfflineFiles(files)
    })
    fetchDownloadedFilesById(payload).then((files) => {
      setDownloadedOfflineFiles(files)
    })
  }

  const uploadFiles = async (files: File[]) => {
    if (!questionId) return

    await dispatch(
      asyncUploadEvaluationNotesFiles({
        evaluationId,
        questionId,
        files,
        isOnline,
      }),
    )

    handleFetchFiles()
  }

  useEffect(() => {
    if (!questionId) return

    handleFetchFiles()
  }, [evaluationId, questionId])

  useEffect(() => {
    if (note || offlineNote?.content) {
      formik.setFieldValue('content', isOnline ? note : offlineNote?.content)
    }
    //eslint-disable-next-line
  }, [note, offlineNote?.content, isOnline])

  return (
    <>
      <Confirm
        isOpen={isConfirmOpen}
        onClose={handleCloseConfirm}
        onConfirm={onConfirm}
        text='Are you sure you want to remove this file?'
        isClose
      />
      <Confirm
        isOpen={isDeleteConfirmOpen}
        onClose={handleCloseDeleteConfirm}
        onConfirm={handleConfirmDelete}
        text='Are you sure you want to delete this note?'
        isClose
      />
      <ModalWindow
        isDisableOutsideClick
        isLoading={isLoading}
        isOpen={isOpen && !isDisabled}
        onClose={handleCloseNotes}
        onSubmit={formik.handleSubmit}
        title='Notes'
      >
        <TextEditor
          label='Note'
          name='content'
          value={formik.values.content}
          error={formik.errors.content}
          setFieldValue={formik.setFieldValue}
        />
        <DeleteNoteContainer>
          <Button disabled={isOnline ? !note : !offlineNote?.content} onClick={() => handleOpenDeleteConfirm(0)}>
            Delete Note
          </Button>
        </DeleteNoteContainer>
        <InputFileHeader>
          <span>Files</span>
        </InputFileHeader>
        <InputFile
          multiple
          name='notes'
          files={isOnline ? notesFiles : [...(downloadedOfflineFiles || []), ...formattedOfflineFiles]}
          onUploadFiles={uploadFiles}
          onDeleteFile={handleOpenConfirm}
        />
      </ModalWindow>
    </>
  )
}

export default Notes
