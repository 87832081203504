import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faPoll, faQuestion, faUsers, faUsersCog } from '@fortawesome/free-solid-svg-icons'

import { IDashboardItem, SidebarLink, Routes } from 'interfaces'
import EvaluationComplete from 'views/home/evaluation-complete/EvaluationComplete'
import EvaluationFinish from 'views/home/evaluation-finish/EvaluationFinish'
import Evaluation from 'views/home/evaluation/Evaluation'
import MyEvaluations from 'views/home/evaluations/MyEvaluations'

import { routes } from './routes'

export const HOME_SIDEBAR_IMAGE_SIZE_WIDTH = 230

export const HOME_SIDEBAR_IMAGE_SIZE_HEIGHT = 50

export const DASHBOARD_PAGE_TITLE = 'Basic info'

export const SIDEBAR_NAV_ITEMS: SidebarLink[] = [
  {
    name: 'My Evaluations',
    path: routes.myEvaluations,
    icon: faPoll as IconDefinition,
  },
]

export const DASHBOARDS_ITEMS: IDashboardItem[] = [
  {
    id: 'users',
    name: 'Users',
    icon: faUsers as IconDefinition,
    background: 'cyan',
  },
  {
    id: 'roles',
    name: 'Roles',
    icon: faUsersCog as IconDefinition,
    background: 'blue',
  },
  {
    id: 'questionSets',
    name: 'Question sets',
    icon: faQuestion as IconDefinition,
    background: 'indigo',
  },
  {
    id: 'evaluations',
    name: 'Evaluations',
    icon: faPoll as IconDefinition,
    background: 'navy',
  },
]

export const HOME_ROUTES: Routes[] = [
  {
    path: routes.myEvaluations,
    element: MyEvaluations,
  },
  {
    path: routes.evaluation,
    element: Evaluation,
  },
  {
    path: routes.evaluationComplete,
    element: EvaluationComplete,
  },
  {
    path: routes.evaluationFinish,
    element: EvaluationFinish,
  },
]
