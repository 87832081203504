import styled from 'styled-components'

export const FiltersContentWrapper = styled.div`
  padding: ${({ theme }) => theme.spaces.min4};

  display: flex;
  flex-wrap: wrap;

  & > div {
    flex: 0 1 33%;
    padding: 0 6px;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      flex: 0 1 49%;
    }

    @media (max-width: 576px) {
      flex: 0 1 100%;
    }
  }
`

export const FiltersFooterWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spaces.min3} ${theme.spaces.min5}`};

  background-color: rgba(0, 0, 0, 0.03);
  display: flex;

  margin-top: 40px;

  & > button:first-child {
    margin-right: 4px;
  }
`
