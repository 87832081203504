import { CustomFileRetrieve, QuestionRecommendations, QuestionSingleOption } from './evaluations.interfaces'
import { StringOrNullType } from './global.interfaces'

export enum QuestionType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  MARKS = 'marks',
  PERCENTAGE = 'percentage',
  CONDITIONAL = 'yesno',
  CONDITIONAL_REVERSE = 'noyes',
  COLOR = 'color',
  PICTURE = 'picture',
}

export type SharedOption = { percentage: string; description: string }

export type SingleQuestionTypeOptions = {
  compliance: string
  options: SharedOption[]
} & QuestionRecommendations

export type ColorQuestionTypeOptions = {
  options: QuestionSingleOption[]
  params: {
    compliant: string
  }
} & QuestionRecommendations

export type MultipleQuestionTypeOptions = {
  options: MultipleOption[]
} & QuestionRecommendations

export type MarksQuestionTypeOptions = {
  compliance: string
  default: StringOrNullType
  maximum: string
  minimum: string
  step: string
} & QuestionRecommendations

export type PercentageQuestionTypeOptions = {
  compliance: string
  step: string
  default: StringOrNullType
} & QuestionRecommendations

export type TextQuestionTypeOptions = {
  inputType: TextQuestionTypeInputType
} & QuestionRecommendations

export type DateQuestionTypeOptions = { default: StringOrNullType } & QuestionRecommendations

export type PictureQuestionTypeOptions = {
  compliance: string
  files: { file: CustomFileRetrieve }[]
  options: SharedOption[]
} & QuestionRecommendations

export type NumberQuestionTypeOptions = {
  compliance: string
  complianceType: string
  maxCompliance: StringOrNullType
  measure: string
  minCompliance: StringOrNullType
} & QuestionRecommendations

export type QuestionOptionsTypes = {
  [QuestionType.SINGLE]: SingleQuestionTypeOptions
  [QuestionType.COLOR]: ColorQuestionTypeOptions
  [QuestionType.MULTIPLE]: MultipleQuestionTypeOptions
  [QuestionType.MARKS]: MarksQuestionTypeOptions
  [QuestionType.CONDITIONAL]: QuestionRecommendations
  [QuestionType.CONDITIONAL_REVERSE]: QuestionRecommendations
  [QuestionType.PERCENTAGE]: PercentageQuestionTypeOptions
  [QuestionType.TEXT]: TextQuestionTypeOptions
  [QuestionType.DATE]: DateQuestionTypeOptions
  [QuestionType.PICTURE]: PictureQuestionTypeOptions
  [QuestionType.NUMBER]: NumberQuestionTypeOptions
}

export type MultipleOption = { description: string }

export type TextQuestionTypeInputType = 'multiple' | 'single'

export type QuestionTypeOptionsType<T extends QuestionType> = T extends keyof QuestionOptionsTypes
  ? QuestionOptionsTypes[T]
  : never
