import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import { AreaField, AreaFieldResponse, AreaFieldsListResponse, AreaFieldsState } from 'interfaces'

import { createAreaField, deleteAreaField, editAreaField, getAreaField, getAreaFields } from './action'

export function getAreaFieldsReducer(builder: ActionReducerMapBuilder<AreaFieldsState>) {
  builder.addCase(getAreaFields.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(getAreaFields.fulfilled, (state, action: PayloadAction<AreaFieldsListResponse>) => {
    const { data, ...pagination } = action.payload
    return {
      ...state,
      ...pagination,
      areaFields: data,
      isLoading: false,
      isCalled: true,
    }
  })

  builder.addCase(getAreaFields.rejected, (state) => {
    state.isLoading = false
  })
}

export function deleteAreaFieldReducer(builder: ActionReducerMapBuilder<AreaFieldsState>) {
  builder.addCase(deleteAreaField.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(deleteAreaField.fulfilled, (state, action: PayloadAction<number>) => {
    state.areaFields = state.areaFields.filter(({ id }) => id !== action.payload)
    state.isLoading = false
  })

  builder.addCase(deleteAreaField.rejected, (state) => {
    state.isLoading = false
  })
}
export function createAreaFieldReducer(builder: ActionReducerMapBuilder<AreaFieldsState>) {
  builder.addCase(createAreaField.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(createAreaField.fulfilled, (state, action: PayloadAction<AreaField>) => {
    state.areaFields.push(action.payload)
    state.isLoading = false
  })

  builder.addCase(createAreaField.rejected, (state) => {
    state.isLoading = false
  })
}

export function editAreaFieldReducer(builder: ActionReducerMapBuilder<AreaFieldsState>) {
  builder.addCase(editAreaField.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(editAreaField.fulfilled, (state, action: PayloadAction<AreaField>) => {
    state.areaFields = state.areaFields.map((i) => (i.id === action.payload.id ? action.payload : i))
    state.isLoading = false
  })

  builder.addCase(editAreaField.rejected, (state) => {
    state.isLoading = false
  })
}

export function getAreaFieldReducer(builder: ActionReducerMapBuilder<AreaFieldsState>) {
  builder.addCase(getAreaField.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(getAreaField.fulfilled, (state, action: PayloadAction<AreaFieldResponse>) => {
    state.current = action.payload.areaField
    state.isLoading = false
  })

  builder.addCase(getAreaField.rejected, (state) => {
    state.isLoading = false
  })
}
