import styled from 'styled-components'

export const EvaluationFillWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spaces.min2} ${theme.spaces.min5}`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border1};
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  margin-bottom: ${({ theme }) => theme.spaces.min3};

  & > div {
    padding: 0 7.5px;
  }
`

export const LeftSide = styled.div`
  padding: ${({ theme }) => `${theme.spaces.min1} ${theme.spaces.min2}`};

  color: ${({ theme }) => theme.colors.black1};
  line-height: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 75%;
`

export const LeftSideContent = styled.div`
  cursor: pointer;
  align-items: center;
  display: block;

  h4 {
    display: inline-flex;
    margin-left: 5px;
    line-height: 1.9;
  }

  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .circleGray {
    color: ${({ theme }) => theme.colors.gray10};
    font-weight: 900;
  }
  .circleOrange {
    color: ${({ theme }) => theme.colors.orange};
    font-weight: 900;
  }
  .circleGreen {
    color: ${({ theme }) => theme.colors.green1};
    font-weight: 900;
  }

  .check {
    color: white;
    font-size: 12px;
  }
`

export const RightSide = styled.div`
  flex: 0 0 25%;

  & > button {
    width: 100%;
  }
`

export const CompletionStyles = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin-left: 15px;
    font-size: 30px;
    font-weight: 600;
    border: none;
    background: none;
    color: grey;
  }
  .greyColor {
    color: grey;
  }
  .blackColor {
    color: black;
  }
`

export const DynamicContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & textarea {
    height: 254px;
  }
`

export const DefaultTag = styled.p`
  font-size: 0.875rem;
  padding-top: 0.25rem;
  color: ${({ theme }) => `${theme.colors.gray10}`};
`

export const AnsweredStatus = styled.span<{
  isAnswered: boolean
}>`
  padding: 3px 4.8px;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  margin-right: 4px;
  border-radius: 4px;
  background: ${({ theme, isAnswered }) => (isAnswered ? `${theme.colors.green1}` : `${theme.colors.error}`)};
`

export const FlexGroup = styled.div<{ isRow: boolean }>`
  align-self: start;
  display: flex;
  column-gap: 10px;
  width: ${({ isRow }) => (isRow ? '100%' : 'auto')};
  flex-direction: ${({ isRow }) => (isRow ? 'row' : 'column')};
  flex-wrap: ${({ isRow }) => (isRow ? 'wrap' : 'nowrap')};
  & > label {
    flex: 0 1 auto;

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`

export const ButtonForSavingStyles = styled.div`
  width: 110px;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  margin: 10px 0;
`

export const DisabledEvaluation = styled.p`
  padding-right: 3px;
`

export const ButtonStyles = styled.div`
  margin-top: 1rem;
  margin-right: auto;
`

export const UndoButtonStyles = styled.button`
  border-radius: 0.2rem;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
  background: ${({ theme }) => `${theme.colors.orange}`};
  color: ${({ theme }) => `${theme.colors.black1}`};
  border: 0;
`
