import styled from 'styled-components'

export const UserCardWrapper = styled.div`
  padding: 10px 10px 30px;
  background: ${({ theme }) => theme.colors.blue1};

  display: flex;
  flex-direction: column;
  align-items: center;

  & > svg {
    color: #fff;
  }

  & > p {
    margin-top: 10px;
    color: #fff;
    line-height: 25.5px;
    text-align: center;
  }
`
