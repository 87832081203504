import styled, { keyframes } from 'styled-components'

const fileuploaderSlideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const InputFileWrapper = styled.div`
  position: relative;
  & > input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`

export const FileWrapper = styled.label`
  background: ${({ theme }) => theme.colors.gray15};
  padding: ${({ theme }) => theme.spaces.min3};
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  cursor: pointer;

  form {
    width: 100%;
  }
`

export const ChooseFiles = styled.div`
  background: ${({ theme }) => theme.colors.purple2};
  padding: 10px ${({ theme }) => theme.spaces.min5};
  border-radius: 4px;
  color: #fff;
  transition: 0.3s all ease;
  position: relative;
  display: inline-block;
  text-align: center;

  &:hover {
    background: ${({ theme }) => theme.colors.purple3};
  }

  & button {
    background: transparent;
    border: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    }
  }
`

export const ChooseFilesToUpload = styled.div`
  background: #fff;
  padding: 10px ${({ theme }) => theme.spaces.min3};
  margin-right: ${({ theme }) => theme.spaces.min3};

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.border6};
  color: ${({ theme }) => theme.colors.purple};
  flex-grow: 1;
  overflow: hidden;

  & > input[type='file']::file-selector-button {
    display: none;
  }
`

export const SelectedFilesList = styled.div`

  color: ${({ theme }) => theme.colors.purple2};
  background: ${({ theme }) => theme.colors.gray15};

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 20px;
      border-bottom: 1px solid #e8e8e8;
      border-radius: 4px;
      transition: transform 0.3s ease-out;

      animation: ${fileuploaderSlideIn} 0.4s ease; 

      &.file-item-exit-active {
        opacity: 0;
        transition: opacity 300ms ease-out, transform 300ms ease-out;
        animation: ${fileuploaderSlideIn} 0.4s ease;
      }
    }

      &:last-child {
        border-bottom: none;
      }

      .file-info {
        display: flex;
        flex-direction: column;
      }

      .file-name-size-separator {
        width: 100%;
      }

      .file-name {
        font-size: 16px;
        font-weight: bold;
        color: #74809D;
      }

      .file-size {
        font-size: 12px;
        color: #979fb8;
      }
      
      .file-container {
        display: flex;
        align-items: center;
      }

      .file-type {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 36px;
        min-height: 36px;
        max-width: 36px;
        max-height: 36px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        background: #ddd;
        border-radius: 4px;
        margin-right: ${({ theme }) => theme.spaces.min3};
      
        & img {
          width: 36px;
          height: 36px;
          padding: 0;
          margin-bottom: 0;
        }
      }

      .buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        vertical-align: text-top;

        .download { 
          background: ${({ theme }) => theme.colors.purple2};
          display: inline-block;
          position: relative;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          cursor: pointer;
          vertical-align: top;
          transition: all 0.2s ease-out;
          border: 0;

          &:hover {
            background: #7b90ef;
          }

          & .arrow {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -1px;
            margin-top: -4px;
            width: 2px;
            height: 5px;
            border-radius: 0;
            background: #fff;

            &:after{
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              bottom: -4px;
              left: -3px;
              border-left: 4px solid rgba(0, 0, 0, 0);
              border-right: 4px solid rgba(0, 0, 0, 0);
              border-top: 5px solid #fff;
              border-radius: 2px;
            }
          }
        }
      }

        .delete { 
          border-radius: 50%;
          color: white;
          display: inline-block;
          position: relative;
          width: 18px;
          height: 18px;
          transition: all 0.2s ease-out;
          margin-left: 10px;
          background: #fae1e1;
          border: 1px solid #ffcfcf;

          &:hover {
            background: #ffefef;
          }

          & .x {
            &:before{
              transform: rotate(45deg);
              content: '';
              position: absolute;
              top: 4px;
              left: 7px;
              height: 8px;
              width: 2px;
              background-color: #f59595;
            }

            &:after{
              transform: rotate(-45deg);
              content: '';
              position: absolute;
              top: 4px;
              left: 7px;
              height: 8px;
              width: 2px;
              background-color: #f59595;
            }
          }
        }
      }
    }
  
`

export const fileTypeStyles = {
  zip: {
    background: '#21c872',
  },
  rar: {
    background: '#230882',
  },
  '7z': {
    background: '#23c872',
  },
  xls: {
    background: '#ffffff',
    color: '#888',
  },
  xlsx: {
    background: '#591605',
  },
  csv: {
    background: '#068120',
  },
  doc: {
    background: '#384e53',
  },
  docx: {
    background: '#409024',
  },
  pdf: {
    background: '#f23c0f',
  },
  txt: {
    background: '#709c27',
  },
  mp3: {
    background: '#f0bc6f',
  },
  ogg: {
    background: '#8f63d8',
  },
  wav: {
    background: '#ecbbae',
    color: '#888',
  },
} as const
