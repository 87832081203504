import styled from 'styled-components'

export const InputFileHeader = styled.div`
  margin-bottom: 1.5rem;

  & span {
    font-weight: 700;
    font-size: 1rem;
  }
`

export const DeleteNoteContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
