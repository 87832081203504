import styled from 'styled-components'

import { InputWrapper } from '../input/styles'

export const InputWithSelectContainer = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spaces.min3};
`

export const InputWithSelectWrapper = styled(InputWrapper)<{
  error?: string
}>`
  display: flex;
  & > input {
    flex: 0 1 70%;
    border-right-width: 0.5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:after {
    display: none;
  }

  & > div {
    flex: 0 1 30%;
    & > select {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  @media (max-width: 576px) {
    flex-direction: column;
    & > div {
      & > select {
        border-left: 1px solid ${({ theme }) => theme.colors.border2};
      }
    }
  }
`
