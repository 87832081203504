import styled from 'styled-components'

export const SectionWrapper = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  margin-bottom: ${({ theme }) => theme.spaces.min3};

  & .ReactCollapse--collapse {
    transition: height 500ms;
  }
`

export const SectionHeaderWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white3};

  & > div {
    margin-bottom: 0;
  }
`
