import { useCallback, useEffect, useState } from 'react'

import { NumberOrNullType, NumberStringBoolean } from 'interfaces'

export type HandleOpenAndSubmitConfirmType = (value: number, additionalValue?: NumberStringBoolean | number[]) => void

const useConfirm = (
  onSubmit: HandleOpenAndSubmitConfirmType,
): {
  currentId: NumberOrNullType
  isConfirmOpen: boolean
  handleOpenConfirm: HandleOpenAndSubmitConfirmType
  onConfirm: VoidFunction
  handleCloseConfirm: VoidFunction
} => {
  const [currentId, setCurrentId] = useState<NumberOrNullType>(null)
  const [additionalValue, setAdditionalValue] = useState<NumberStringBoolean | number[] | null>(null)

  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const isAdditionalValueValid = additionalValue || additionalValue === 0

  const handleOpenConfirm: HandleOpenAndSubmitConfirmType = useCallback((value, additionalValue?) => {
    if (additionalValue || additionalValue === 0) setAdditionalValue(additionalValue)
    setCurrentId(value)
    setIsConfirmOpen(true)
  }, [])

  const onConfirm = useCallback(() => {
    if (currentId !== null) {
      onSubmit(currentId, isAdditionalValueValid ? additionalValue : '')
      setCurrentId(null)
      setAdditionalValue(null)
    }
  }, [additionalValue, currentId, isAdditionalValueValid, onSubmit])

  const handleCloseConfirm = useCallback(() => {
    setIsConfirmOpen(false)
    setCurrentId(null)
    setAdditionalValue(null)
  }, [])

  useEffect(() => {
    if (currentId === null) setIsConfirmOpen(false)
  }, [currentId])

  return {
    currentId,
    isConfirmOpen,
    handleOpenConfirm,
    handleCloseConfirm,
    onConfirm,
  }
}

export default useConfirm
