import React, { FC, ReactNode } from 'react'

import { AuthContainer, AuthContent } from 'page-components/auth/styles'

interface Props {
  children: ReactNode
  isEnlarged?: boolean
}

const AuthLayout: FC<Props> = ({ children, isEnlarged }) => {
  return (
    <AuthContainer>
      <AuthContent isEnlarged={isEnlarged}>{children}</AuthContent>
    </AuthContainer>
  )
}

export default AuthLayout
