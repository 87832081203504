import styled from 'styled-components'

export const UserPinCodeModalContentWrapper = styled.div`
  padding: 20px;
`

export const UserPinCodeWrapper = styled.div`
  position: relative;
  padding: ${({ theme }) => `7px ${theme.spaces.min2}`};
  background: ${({ theme }) => `${theme.colors.white}`};
  color: ${({ theme }) => `${theme.colors.white}`};

  font-size: 16px;
  line-height: 24px;

  border: 1px solid rgb(221, 221, 221);
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  margin-bottom: 5px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    transition: 0.3s all ease;
  }

  & > button {
    display: contents;
  }
`

export const UserPinCodeTitle = styled.p`
  color: ${({ theme }) => theme.colors.gray10};
  user-select: none;
  margin-right: 4px;
  text-wrap: nowrap;
`

export const UserPinCodeValue = styled.p`
  color: #017bff;
  margin-right: 4px;
`

export const ContentTitle = styled.h4`
  font-size: 16px;
  color: #434343;
  margin-bottom: ${({ theme }) => theme.spaces.min2};
`

export const UserPinCodeModalFooter = styled.div<{ buttonPercentage?: number }>`
  margin-top: 30px;
  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #f4f4f4;

  & > button {
    width: ${({ buttonPercentage }) => (buttonPercentage ? `${buttonPercentage}%` : '70%')};
  }
`
