import styled from 'styled-components'

export const Status = styled.span<{
  background?: string
  color?: string
}>`
  display: inline-block;
  padding: 3px 4.8px;
  background: ${({ theme, background }) => background || theme.colors.error};
  border-radius: 4px;
  color: ${({ color }) => color || '#fff'};
  line-height: 12px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
`
