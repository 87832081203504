import { createContext } from 'react'

import { FormikProps } from 'formik'

import { KeyWithString, NumberOrNullType } from 'interfaces'

export const QuestionsContext = createContext<{
  formik: FormikProps<KeyWithString> | null
  evaluationId: NumberOrNullType
  isDisabled?: boolean
}>({
  evaluationId: null,
  formik: null,
  isDisabled: false,
})
