import React, { CSSProperties, FC, ReactNode } from 'react'

import { Colors } from 'constants/global'
import Button from 'shared-components/button/Button'

import { ConfirmBottom, ConfirmWrapper, ConfirmationTop } from './style'

interface Props {
  isOpen: boolean
  title?: string
  text?: string
  onClose: VoidFunction
  onConfirm: VoidFunction
  content?: ReactNode
  style?: CSSProperties
  isClose?: boolean
  customConfirm?: string
}

const Confirm: FC<Props> = ({ isOpen, customConfirm, isClose, title, text, onClose, onConfirm, content, style }) => {
  if (!isOpen) return null

  return (
    <ConfirmWrapper style={style}>
      <ConfirmationTop>{title || 'Confirmation'}</ConfirmationTop>
      {content || <h4>{text || 'Are you sure?'}</h4>}
      <ConfirmBottom>
        {isClose && (
          <Button onClick={onClose} backgroundColor={Colors.GRAY}>
            Cancel
          </Button>
        )}
        <Button onClick={onConfirm}>{customConfirm || 'Confirm'}</Button>
      </ConfirmBottom>
    </ConfirmWrapper>
  )
}

export default Confirm
