import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import {
  DeleteSectionsQuestionsItemPayload,
  QuestionSetsAdditionalState,
  QuestionSetsListItem,
  QuestionSetsSectionValues,
} from 'interfaces'

import {
  createQuestion,
  createSection,
  deleteSectionsQuestionsListItem,
  editQuestion,
  editSection,
  getSectionsQuestionsList,
} from './action'

export function getSectionsQuestionsListReducer(builder: ActionReducerMapBuilder<QuestionSetsAdditionalState>) {
  builder.addCase(getSectionsQuestionsList.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(getSectionsQuestionsList.fulfilled, (state, action: PayloadAction<QuestionSetsListItem[]>) => {
    state.list = action.payload
    state.isLoading = false
  })

  builder.addCase(getSectionsQuestionsList.rejected, (state) => {
    state.isLoading = false
  })
}

export function deleteSectionsQuestionsListItemReducer(builder: ActionReducerMapBuilder<QuestionSetsAdditionalState>) {
  builder.addCase(
    deleteSectionsQuestionsListItem.fulfilled,
    (state, action: PayloadAction<DeleteSectionsQuestionsItemPayload>) => {
      const { id, parent_id } = action.payload
      const sectionIndex = state?.list?.findIndex((i) => String(i.id) === (String(parent_id) || String(id)))
      if ((parent_id || parent_id === 0) && state.list?.length)
        state.list[sectionIndex].questions = state.list[sectionIndex].questions?.filter((i) => i.id !== id)
      else state.list = state.list.filter((i) => i.id !== id)
      state.isLoading = false
    },
  )
}

export function createSectionReducer(builder: ActionReducerMapBuilder<QuestionSetsAdditionalState>) {
  builder.addCase(createSection.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(createSection.fulfilled, (state, action: PayloadAction<QuestionSetsListItem>) => {
    state.list.push(action.payload)
    state.isLoading = false
  })

  builder.addCase(createSection.rejected, (state) => {
    state.isLoading = false
  })
}

export function editSectionReducer(builder: ActionReducerMapBuilder<QuestionSetsAdditionalState>) {
  builder.addCase(editSection.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(editSection.fulfilled, (state, action: PayloadAction<QuestionSetsSectionValues>) => {
    state.list = state.list.map((i) =>
      i.id === action.payload.id ? { ...i, ...action.payload, questions: i.questions } : i,
    )
    state.isLoading = false
  })

  builder.addCase(editSection.rejected, (state) => {
    state.isLoading = false
  })
}

export function createQuestionReducer(builder: ActionReducerMapBuilder<QuestionSetsAdditionalState>) {
  builder.addCase(createQuestion.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(createQuestion.fulfilled, (state, action: PayloadAction<QuestionSetsListItem>) => {
    state.list.push(action.payload)
    state.isLoading = false
  })

  builder.addCase(createQuestion.rejected, (state) => {
    state.isLoading = false
  })
}

export function editQuestionReducer(builder: ActionReducerMapBuilder<QuestionSetsAdditionalState>) {
  builder.addCase(editQuestion.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(editQuestion.fulfilled, (state, action: PayloadAction<QuestionSetsListItem>) => {
    const index = state.list.findIndex((i) => i.id === action.payload.parent_id)
    if (index !== -1)
      state.list[index].questions = state.list[index].questions?.map((i) =>
        i.id === action.payload.id ? action.payload : i,
      )
    else state.list.map((i) => (i.id === action.payload.id ? action.payload : i))
    state.isLoading = false
  })

  builder.addCase(editQuestion.rejected, (state) => {
    state.isLoading = false
  })
}
