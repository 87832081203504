import styled from 'styled-components'

export const RadioV2Wrapper = styled.div<{ value?: string }>`
  input {
    display: none;
  }

  .button-group {
    display: block;
    text-align: center;
  }

  .button {
    margin: 10px 2px 10px 1px;
    display: inline-block;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    font-weight: 700;
    width: 12rem;
  }

  label {
    align-items: start;
  }

  .checked-green {
    background-color: ${({ theme, value }) => (value === '1' ? `${theme.colors.green4}` : `${theme.colors.white}`)};
    color: ${({ theme, value }) => (value === '1' ? `${theme.colors.white}` : `${theme.colors.green4}`)};
    border-color: ${({ theme }) => `${theme.colors.green4}`};
  }

  .checked-red {
    background-color: ${({ theme, value }) => (value === '0' ? `${theme.colors.error}` : `${theme.colors.white}`)};
    color: ${({ theme, value }) => (value === '0' ? `${theme.colors.white}` : `${theme.colors.error}`)};
    border-color: ${({ theme }) => `${theme.colors.error}`};
  }

  .reset-green {
    background-color: ${({ theme, value }) => (value === '1' ? `${theme.colors.green4}` : `${theme.colors.white}`)};
    color: ${({ theme, value }) => (value === '1' ? `${theme.colors.white}` : `${theme.colors.green4}`)};
    border-color: ${({ theme }) => `${theme.colors.green4}`};
  }

  .reset-red {
    background-color: ${({ theme }) => `${theme.colors.white}`};
    color: ${({ theme }) => `${theme.colors.error}`};
    border-color: ${({ theme }) => `${theme.colors.error}`};
  }
`
