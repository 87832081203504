import React from 'react'

import {
  FlexWrapper,
  SkeletonWrapper,
  HeaderItemShimmer,
  TextShimmer,
  FlexCol,
  ShortTextShimmer,
  ProgressShimmer,
  ButtonShimmer,
} from './style'

const Skeleton = () => {
  return (
    <SkeletonWrapper>
      <FlexWrapper>
        <FlexCol>
          <HeaderItemShimmer />
          <TextShimmer />
          <TextShimmer />
          <TextShimmer />
        </FlexCol>
        <FlexCol>
          <HeaderItemShimmer />
          <TextShimmer />
        </FlexCol>
        <FlexCol>
          <HeaderItemShimmer />
          <ShortTextShimmer />
        </FlexCol>
        <FlexCol>
          <HeaderItemShimmer />
          <ProgressShimmer />
          <TextShimmer />
        </FlexCol>
        <FlexCol>
          <HeaderItemShimmer />
          <ButtonShimmer />
        </FlexCol>
      </FlexWrapper>
    </SkeletonWrapper>
  )
}

export default Skeleton
