import { createAsyncThunk } from '@reduxjs/toolkit'

import { myEvaluationsApi } from 'apis'
import { ISO_STRING_FORMAT, LONDON_TIMEZONE } from 'constants/dates'
import { saveNotesFiles } from 'db/files'
import { removeEvaluationByIdFromDB, saveAllEvaluationsToDB, unloadEvaluation } from 'db/helpers'
import { IsOnline, QueryParams } from 'interfaces'
import { DeviceInfoToSend } from 'interfaces/evaluations.interfaces'
import { asyncGetMyTasksListAndNotes, getEvaluationNotesFiles } from 'modules/evaluation-actions-group/action'
import { setErrorNotify, setSuccessNotify } from 'modules/notifications/action'
import {
  getFlatEvaluationQuestions,
  getQuestionsWithFormattedOptionsAndAnswers,
  processEvaluationQuestionsPictures,
} from 'utils'
import { formatInTimeZone } from 'utils/dates'
import { formatNotesFiles } from 'utils/files'
import { generateRandomId, getDeviceAndDateDetails } from 'utils/global'

export const EVALUATIONS_SLICE_NAME = 'evaluations'

export const asyncGetMyEvaluations = createAsyncThunk(
  `${EVALUATIONS_SLICE_NAME}/ownEvaluations`,
  async ({ params, isOnline }: QueryParams & IsOnline, { rejectWithValue }) => {
    try {
      if (!isOnline) return null

      const response = await myEvaluationsApi.getMyEvaluations(params)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const asyncGetOfflineEvaluation = createAsyncThunk(
  `${EVALUATIONS_SLICE_NAME}/offlineEvaluation`,
  async (id: number, { dispatch }) => {
    const deviceResponse = await dispatch(asyncSaveDeviceRelatedInfo(String(id)))

    if (deviceResponse?.payload) {
      const response = await myEvaluationsApi.getOfflineEvaluation(id)

      const questions = response?.data?.questions

      const answers = await myEvaluationsApi.getEvaluationAnswers(id)

      const currentDate = formatInTimeZone(new Date(), ISO_STRING_FORMAT, LONDON_TIMEZONE)

      const flatQuestions = getFlatEvaluationQuestions(questions || [])

      const questionsFiles = flatQuestions
        ?.filter((i) => i?.options?.picture?.files)
        ?.map((item) => ({ files: item?.options?.picture?.files, id: item.id }))

      const downloadedPictures = await processEvaluationQuestionsPictures(questionsFiles)

      // Notes files
      await Promise.all(
        flatQuestions?.map(async ({ id: questionId }) => {
          const response = await dispatch(getEvaluationNotesFiles({ evaluationId: id, questionId })).unwrap()

          if (!Object.keys(response)?.length) return

          const formattedNotesFiles = await formatNotesFiles(response, id)

          await saveNotesFiles(formattedNotesFiles.flat(2))
        }),
      )
      // Tasks and Notes
      await dispatch(
        asyncGetMyTasksListAndNotes({
          evaluationId: id,
          questionId: id,
        }),
      )

      await saveAllEvaluationsToDB([
        {
          ...response.data,
          downloaded_at: currentDate,
          questions: getQuestionsWithFormattedOptionsAndAnswers({
            questions,
            answers: answers?.data,
            downloadedPictures,
          }),
        },
      ])
    }
  },
)

export const asyncSaveDeviceRelatedInfo = createAsyncThunk(
  `${EVALUATIONS_SLICE_NAME}/saveDeviceRelatedInfo`,
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const dataToSend: DeviceInfoToSend = {
        deviceDescription: getDeviceAndDateDetails(),
        deviceToken: generateRandomId(),
      }

      const response = await myEvaluationsApi.saveDeviceRelatedInfo(id, dataToSend)
      return response?.data
    } catch (e) {
      dispatch(setErrorNotify("Evaluation wasn't downloaded. Please, contact the administrator"))
      return rejectWithValue(e)
    }
  },
)

export const asyncUnloadEvaluation = createAsyncThunk(
  `${EVALUATIONS_SLICE_NAME}/unloadOfflineEvaluation`,
  async ({ id: evaluationId, isOnline }: { id: number; isOnline?: boolean }, { rejectWithValue, dispatch }) => {
    try {
      if (isOnline) {
        await myEvaluationsApi.unloadEvaluation(evaluationId)
      }

      await removeEvaluationByIdFromDB(evaluationId)
      await unloadEvaluation(evaluationId)

      dispatch(setSuccessNotify('Offline version of evaluation was successfully deleted'))
      return evaluationId
    } catch (e) {
      dispatch(setErrorNotify('Please contact the administrator.'))
      return rejectWithValue(e)
    }
  },
)

export const asyncCheckExtraEvaluations = createAsyncThunk(
  `${EVALUATIONS_SLICE_NAME}/asyncCheckExtraEvaluations`,
  async (evaluationsIds: number[], { rejectWithValue }) => {
    try {
      const response = await myEvaluationsApi.getExtraEvaluations(evaluationsIds)

      return (
        Object.keys(response.data)
          .filter((key) => response.data[key] === 'notExists')
          ?.map((id) => Number(id)) || []
      )
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)
