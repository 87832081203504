import React from 'react'

import { ThemeProvider } from 'styled-components'

const ThemeContext: React.FC = ({ children }) => {
  const theme = {
    colors: {
      gray1: '#e9ecef',
      gray2: '#495057',
      gray3: '#666666',
      gray4: '#00000008',
      gray5: '#343a40',
      gray6: '#c2c7d0',
      gray7: '#d0d4db',
      gray8: '#f4f6f9',
      gray9: '#00000080',
      gray10: '#6c757d',
      gray11: '#dee2e6',
      gray12: '#d3d3d3',
      gray13: '#5d6974',
      gray14: '#464f58',
      gray15: '#f5f6fA',
      blue1: '#007bff',
      blue2: '#e8f0fe',
      blue3: '#80bdff',
      blue4: '#0056b3',
      black1: '#212529',
      black2: '#1f2d3d',
      border1: 'rgba(0, 0, 0, 0.125)',
      border2: '#ced4da',
      border3: '#d3cfc8',
      border4: '#4b545c',
      border5: '#ecedf1',
      border6: '#dde4f6',
      error: '#dc3545',
      purple: '#94a2e8',
      purple2: '#687ddb',
      purple3: '#7b90ef',
      white: '#fff',
      secondWhite: '#ffffffcc',
      white3: '#f8f9fa',
      white4: '#a0aec0',
      white5: '#ffffffe6',
      green1: '#28a745',
      green2: '#17a2b8',
      green3: '#117a8b',
      green4: '#198754',
      green5: '#1c7430;',
      green6: '#10707f;',
      green7: '#3a7d34',
      orange: '#ffc107',
      red1: '#ec3f41',
      red2: '#ff0000',
      transparent: 'transparent',
    },
    spaces: {
      min1: '6px',
      min2: '12px',
      min3: '16px',
      min4: '14px',
      min5: '20px',
      min6: '24px',
      med1: '36px',
      med2: '40px',
      med3: '50px',
    },
    fonts: {
      main: '"Source Sans Pro", -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    shadows: {
      shadow1: '0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);',
      shadow2: 'rgba(0, 0, 0, 0.125) 0px 0px 1px 0px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px;',
    },
    lineHeight: {
      title: '34px',
    },
    gradients: {
      cyan: '#17a2b8 linear-gradient(180deg, #3ab0c3, #17a2b8) repeat-x;',
      blue: '#007bff linear-gradient(180deg, #268fff, #007bff) repeat-x;',
      indigo: '#6610f2 linear-gradient(180deg, #7d34f4, #6610f2) repeat-x;',
      navy: '#001f3f linear-gradient(180deg, #26415c, #001f3f) repeat-x;',
      progress:
        '45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent',
    },
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default ThemeContext
