import styled from 'styled-components'

export const SettingsContainer = styled.div`
  flex: 0 0 75%;
  padding: 0 8px;
  @media (max-width: 768px) {
    flex: none;
  }
`

export const SettingsWrapper = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border-radius: 4px;
  background: #fff;
`

export const SettingsTop = styled.div`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border1};
`

export const Button = styled.button`
  padding: ${({ theme }) => `8px ${theme.spaces.min3}`};
  background: ${({ theme }) => theme.colors.blue1};
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
`

export const SettingsContent = styled.div`
  padding: ${({ theme }) => theme.spaces.min3};

  & > div {
    padding: 0;
    margin-bottom: ${({ theme }) => theme.spaces.min5};
  }
`

export const FormContent = styled.div`
  padding: ${({ theme }) => `${theme.spaces.min2} ${theme.spaces.min5}`};

  & > div {
    margin-top: ${({ theme }) => theme.spaces.min5};
  }
`

export const SettingsFormBottom = styled.div`
  padding: ${({ theme }) => `${theme.spaces.min2} ${theme.spaces.min5}`};
  background: ${({ theme }) => theme.colors.gray4};

  display: flex;
  justify-content: center;
  align-items: center;
`

export const Text = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black1};
  line-height: 21px;
  margin-bottom: ${({ theme }) => theme.spaces.min5};
`
