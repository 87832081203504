import * as Yup from 'yup'

import { TableDataFormItem } from 'interfaces'
import { Input, RadioV2 } from 'shared-components'

export const CATEGORIES_PAGE_TITLE = 'Categories'

export const CATEGORIES_CONTENT_CARD_TITLE = 'Categories list'

export const CATEGORIES_HEADERS = ['Name', 'Created', 'Updated', 'Active']

export const CATEGORIES_CREATE_TITLE = 'Add Category'
export const CATEGORIES_EDIT_TITLE = 'Edit Category'

export const CATEGORIES_CREATE_CONTENT_TITLE = 'Category Details'

export const CATEGORIES_FORM_FIELDS: TableDataFormItem[] = [
  {
    name: 'name',
    placeholder: 'Enter category name',
    label: 'Name',
    type: 'text',
    component: Input,
    validation: Yup.string().required('Required field'),
  },
  {
    name: 'active',
    placeholder: 'Active',
    label: 'Active',
    component: RadioV2,
    value: 'yes',
  },
]
