import styled from 'styled-components'

export const ContentCardWrapper = styled.div`
  position: relative;
  border-radius: 4px;
  overflow: visible;
  background: #fff;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
`

export const Top = styled.div<{ topBackground: string; isSticky?: boolean }>`
  border-radius: 0.25rem 0.25rem 0 0;
  display: flex;
  justify-content: space-between;
  background: ${({ theme, topBackground }) => theme.colors[topBackground]};
  padding: ${({ theme }) => `${theme.spaces.min2} ${theme.spaces.min5}`};
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'unset')};
  z-index: ${({ isSticky }) => (isSticky ? '1' : 'unset')};
  top: ${({ isSticky }) => (isSticky ? '57px' : 'unset')};
`

export const Title = styled.h3`
  font-size: 17.3px;
  line-height: 21px;
  color: #fff;
`

export const Content = styled.div<{ spaces?: string }>`
  padding: ${({ theme, spaces }) => spaces || theme.spaces.min5};
  & > p {
    padding: 20px;
  }
`
