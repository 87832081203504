import styled from 'styled-components'

export const TopAction = styled.div`
  display: flex;
  flex-direction: column;
  & > button {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`

export const BottomAction = styled.div`
  margin-top: 4px;

  & > button {
    width: 100%;
  }
`
