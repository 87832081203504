import React, { useState } from 'react'

import cn from 'classnames'
import { useLiveQuery } from 'dexie-react-hooks'
import VerificationInput from 'react-verification-input'

import { Colors } from 'constants/global'
import { setCurrentUserToDB } from 'db/helpers'
import { db } from 'db/mainDb'
import { OfflineUser } from 'interfaces'
import { Button } from 'shared-components'

import { AuthOfflineContentWrapper, AuthOfflineWrapper } from './styles'
import { ContentTitle, UserPinCodeModalFooter } from './user-pin-code/style'
import { USER_AUTH_PIN_CODE_LENGTH } from './user-pin-code/UserPinCodeModalContent'

const USER_OFFLINE_AUTH_TITLE = 'Enter User Pin Code'

const AuthOfflineContent = () => {
  const offlineUsers = useLiveQuery(() => db.users.toArray()) as OfflineUser[]

  const [pinCode, setPinCode] = useState('')
  const [shownError, setIsShownError] = useState(false)

  const handleChangePinCode = (pinCode: string) => setPinCode(pinCode)

  const handleSubmit = async () => {
    const userByPinCode = offlineUsers?.find((user) => user?.authPinCode === pinCode)
    if (pinCode?.length !== USER_AUTH_PIN_CODE_LENGTH || !userByPinCode) {
      setIsShownError(true)
      return
    }
    await setCurrentUserToDB(userByPinCode)
  }

  return (
    <AuthOfflineWrapper>
      <AuthOfflineContentWrapper>
        <ContentTitle>{USER_OFFLINE_AUTH_TITLE}</ContentTitle>
        <VerificationInput
          value={pinCode}
          onChange={handleChangePinCode}
          length={USER_AUTH_PIN_CODE_LENGTH}
          classNames={{
            character: cn('verification-input-character', { 'verification-input-character-error': shownError }),
            container: 'verification-input-container',
          }}
          validChars='0-9'
          inputProps={{ inputMode: 'numeric' }}
          passwordMode
          placeholder=''
        />
      </AuthOfflineContentWrapper>
      <UserPinCodeModalFooter buttonPercentage={90}>
        <Button backgroundColor={Colors.BLUE} onClick={handleSubmit}>
          Sign in
        </Button>
      </UserPinCodeModalFooter>
    </AuthOfflineWrapper>
  )
}

export default AuthOfflineContent
