import { AxiosRequestConfig } from 'axios'

import { httpApiClientV3 } from 'configs/http-client'

import { StringOrNumber } from '../interfaces'

export class BaseApi {
  constructor(protected url: string) {}

  getList<R>(page?: number, config?: AxiosRequestConfig) {
    return httpApiClientV3.get<R>(`${this.url}?page=${page || 1}`, config)
  }

  deleteItem(id: number, config?: AxiosRequestConfig) {
    return httpApiClientV3.delete(`${this.url}/${id}`, config)
  }

  createItem<T, R>(data: T, config?: AxiosRequestConfig) {
    return httpApiClientV3.post<R>(this.url, data, config)
  }

  editItem<T extends { id: number }, R>(data: T, config?: AxiosRequestConfig) {
    const { id, ...payload } = data
    return httpApiClientV3.put<R>(`${this.url}/${id}`, payload, config)
  }

  getItem<R>(id: StringOrNumber, config?: AxiosRequestConfig) {
    return httpApiClientV3.get<R>(`${this.url}/${id}/edit`, config)
  }
}
