import React, { FC } from 'react'

import { NotFoundWrapper, ErrorText } from './style'

interface Props {
  isOpen: boolean
}

const NotFound: FC<Props> = ({ isOpen }) => {
  return (
    <NotFoundWrapper isOpen={isOpen}>
      <div>
        <p>404</p>
        <ErrorText>Not found</ErrorText>
      </div>
    </NotFoundWrapper>
  )
}

export default NotFound
