import { routes } from 'constants/routes'
import { StringOrNumber } from 'interfaces'

export const getFillEvaluationLink = (id: number) => `${routes.fillEvaluation}/${id}`

export const getAreaFieldsEditLink = (id: number) => `${routes.areaFields}/${id}/edit`

export const getAllEvaluationsEditLink = (id: number) => `${routes.evaluations}/${id}/edit`

export const getCategoriesEditLink = (id: StringOrNumber) => `${routes.categories}/${id}/edit`

export const getFillEvaluationFinishLink = (id: StringOrNumber) => `${routes.evaluationFinishTo}/${id}`

export const getQuestionSetsViewLink = (id: number) => `${routes.questionSets}/${id}`

export const getQuestionSetsEditLink = (id: number) => `${routes.questionSets}/${id}/edit`

export const getQuestionSetsPreviewLink = (id: StringOrNumber) => `${routes.questionSets}/${id}/preview`

export const getQuestionSetsScoringLink = (id: StringOrNumber, questionId?: string) =>
  `${routes.questionSets}/${id}/setSectionScoring${`/${questionId}` || ''}`

export const getQuestionSetsCreateQuestionLink = (id: StringOrNumber) => `${routes.questionSets}/${id}/createQuestion`

export const getQuestionSetsCreateSectionLink = (id: StringOrNumber) => `${routes.questionSets}/${id}/createSection`

export const getQuestionSetsCopySectionLink = (id: StringOrNumber, sectionId: StringOrNumber | undefined) =>
  `${routes.questionSets}/${id}/copySection/${sectionId}`

export const getQuestionSetsCopyQuestionLink = (id: StringOrNumber, sectionId: StringOrNumber | undefined) =>
  `${routes.questionSets}/${id}/copyQuestion/${sectionId}`

export const getQuestionSetsEditSectionLink = (id: StringOrNumber, sectionId: StringOrNumber | undefined) =>
  `${routes.questionSets}/${id}/editSection/${sectionId}`

export const getQuestionSetsEditQuestionLink = (id: StringOrNumber, questionId: StringOrNumber) =>
  `${routes.questionSets}/${id}/editQuestion/${questionId}`

export const getAreasEditLink = (id: number) => `${routes.areas}/${id}/edit`

export const getRiskGradingsEditLink = (id: StringOrNumber) => `${routes.riskGradings}/${id}${routes.edit}`

export const getEvaluationCompleteLink = (id: number) => `${routes.evaluationCompleteTo}/${id}`
