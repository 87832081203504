import { createAsyncThunk } from '@reduxjs/toolkit'

import { adminCategoriesApi, categoriesApi } from 'apis'
import { routes } from 'constants/routes'
import {
  CategoriesEditPayload,
  CategoriesValues,
  CategoryItemResponse,
  ListResponse,
  TableDefaultItemInterface,
} from 'interfaces'
import history from 'services/history'

import { setErrorNotify, setSuccessNotify } from '../notifications/action'

export const CATEGORIES_SLICE_NAME = 'categories'

export const getAllCategoriesItems = createAsyncThunk(
  `${CATEGORIES_SLICE_NAME}/getAllCategories`,
  async (page?: number) => {
    const response = await adminCategoriesApi.getList<ListResponse>(page)
    return response.data
  },
)

export const getCategoryAction = createAsyncThunk(
  `${CATEGORIES_SLICE_NAME}/getCategory`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await adminCategoriesApi.getItem<CategoryItemResponse>(id)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const createCategoryAction = createAsyncThunk(
  `${CATEGORIES_SLICE_NAME}/createCategory`,
  async (data: CategoriesValues, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminCategoriesApi.createItem<CategoriesValues, TableDefaultItemInterface>(data)
      dispatch(setSuccessNotify('Category was successfully created'))
      history.push(routes.categories)
      return response.data
    } catch (e) {
      dispatch(setErrorNotify("Category wasn't created"))
      return rejectWithValue(e)
    }
  },
)

export const editCategoryAction = createAsyncThunk(
  `${CATEGORIES_SLICE_NAME}/editCategory`,
  async (data: CategoriesEditPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminCategoriesApi.editItem<CategoriesEditPayload, TableDefaultItemInterface>(data)
      history.push(routes.categories)
      dispatch(setSuccessNotify(`Category ${data.id} was successfully edited`))
      return response.data
    } catch (e) {
      dispatch(setErrorNotify(`Category ${data.id} wasn't edited`))
      return rejectWithValue(e)
    }
  },
)

export const deleteCategoryAction = createAsyncThunk(
  `${CATEGORIES_SLICE_NAME}/deleteCategory`,
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminCategoriesApi.deleteItem(id)
      if (response.status !== 204) throw new Error('Something went wrong.')
      dispatch(setSuccessNotify(`Category ${id} was successfully deleted`))
      return id
    } catch (e) {
      dispatch(setErrorNotify(`Category ${id} wasn't deleted`))
      return rejectWithValue(e)
    }
  },
)

export const getCategoriesList = createAsyncThunk(
  `${CATEGORIES_SLICE_NAME}/getCategoriesList`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await categoriesApi.getCategoriesList()

      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)
