import styled from 'styled-components'

export const ScreenLoaderWrapper = styled.div<{ isSidebarOpen?: boolean }>`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-left: ${({ isSidebarOpen }) => (isSidebarOpen ? '250px' : '75px')};

  @media (max-width: 996px) {
    padding: 0;
  }

  & > svg {
    width: 100px;
    height: 100px;
    stroke: #fff;
  }
`
