import styled from 'styled-components'

export const PasswordCheckItemWrapper = styled.div<{ filled?: boolean }>`
  display: flex;
  color: ${({ theme, filled }) => (filled ? theme.colors.green7 : theme.colors.red1)};
  margin-top: 4px;

  & strong {
    font-weight: 700;
  }

  & > svg {
    margin-right: 6px;
  }
`

export const PasswordComplexityWrapper = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  padding: 5px 10px;

  border-radius: 4px;
  transition: 0.7s all ease-in-out;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ theme }) => theme.colors.white};
  line-height: 24px;
`

export const PasswordCheckWidgetWrapper = styled.div`
  padding: 10px 0 5px 0;
`
