import styled from 'styled-components'

import { SkeletonBlock } from 'shared-components/shimmer/style'

export const SkeletonEvaluationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SkeletonEvaluationItem = styled(SkeletonBlock)`
  width: 95%;
  height: 150px;
  margin-top: ${({ theme }) => theme.spaces.min3};
`
