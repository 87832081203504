import * as Yup from 'yup'

import { AuthField, UserInfoInterface } from 'interfaces'

import { VALIDATION_PATTERNS } from './validation-patterns'

export const ACCESS_TOKEN_KEY = 'access_token'
export const REMEMBER_ME_KEY = 'remember_me'
export const VERIFY_ACCESS_TOKEN_KEY = 'verify_access_token'

export const AUTH_LOGO_IMAGE_WIDTH = 250

export const AUTH_LOGO_IMAGE_HEIGHT = 50

export const LOGIN_BUTTON_LABEL = 'Sign in'

export const FORGOT_PAGE_TITLE = 'Reset Password'

export const FORGOT_BUTTON_LABEL = 'Send Password Reset Link'

export const VERIFY_PAGE_TITLE = 'Two factor Verification'

export const VERIFY_BUTTON_LABEL = 'Verify'

export const GOOGLE_2_FA = 'google'
export const EMAIL_2_FA = 'email'

export const EMAIL_AUTH_FIELD = {
  name: 'email',
  placeholder: 'Email',
  type: 'text',
  validation: VALIDATION_PATTERNS.email,
}

export const LOGIN_FIELDS: AuthField[] = [
  EMAIL_AUTH_FIELD,
  {
    name: 'password',
    placeholder: 'Password',
    type: 'password',
    validation: VALIDATION_PATTERNS.password,
  },
]

export const REMEMBER_ME_FIELD: AuthField = {
  name: 'remember_me',
  label: 'Remember me',
  type: 'checkbox',
}

export const VERIFY_FIELDS: AuthField[] = [
  {
    name: 'two_factor_code',
    placeholder: 'Two factor code',
    type: 'text',
    validation: Yup.string()
      .required('Two factor code is required')
      .matches(/^[A-Za-z0-9]*$/, 'Incorrect code'),
  },
]

export const INITIAL_USER_INFO: UserInfoInterface = {
  id: null,
  name: '',
  email: '',
  createdAt: '',
  updatedAt: '',
  phone: '',
  avatar: '',
  roles: [],
  twoFactor: '',
  isChangeable: false,
  isActive: 0,
}

export const RESET_PASSWORD_FORM_DEFAULT_VALUES = {
  email: '',
  password: '',
  password_confirmation: '',
}
