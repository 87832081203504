import styled from 'styled-components'

export const PaginationWrapper = styled.div`
  border-radius: 4px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.black1};

  & .rc-pagination-prev,
  & .rc-pagination-next,
  & .rc-pagination-item {
    margin: 0;
    min-width: 24px;

    &:hover {
      background: ${({ theme }) => theme.colors.gray1};
      border-color: ${({ theme }) => theme.colors.gray1};

      & .rc-pagination-item-link {
        background: ${({ theme }) => theme.colors.gray1};
      }

      & .rc-pagination-item-link,
      & a {
        border-color: ${({ theme }) => theme.colors.gray1};
        color: ${({ theme }) => theme.colors.black1};
      }
    }
  }

  & .rc-pagination-item {
    &-active {
      background: ${({ theme }) => theme.colors.blue1};
      &:hover {
        background: ${({ theme }) => theme.colors.blue1};
        border-color: ${({ theme }) => theme.colors.blue1};

        & a {
          color: #fff;
        }
      }
      & a {
        color: #fff;
      }
    }
    & a {
      padding: 0 4px;
    }
  }
`
