import React, { FC } from 'react'

import { Evaluation } from 'interfaces'

import { UnloadContentWrapper } from './style'

interface Props {
  evaluations: Evaluation[]
}

const UnloadOfflineEvaluationsModalContent: FC<Props> = ({ evaluations }) => {
  return (
    <UnloadContentWrapper>
      <h5>Dear customer</h5>
      <p>
        Please follow that evaluations below will be removed from the offline mode, because they are expired/removed:
      </p>
      <div>
        {evaluations.map((evaluation) => (
          <p key={evaluation.id}>-{evaluation.title}</p>
        ))}
      </div>
    </UnloadContentWrapper>
  )
}

export default UnloadOfflineEvaluationsModalContent
