import { createSlice } from '@reduxjs/toolkit'

import { EvaluationActionsGroupState } from 'interfaces'

import { EVALUATION_ACTIONS_GROUP_SLICE_NAME } from './action'
import {
  addMyNoteReducer,
  createMyTaskReducer,
  deleteEvaluationNotesFilesReducer,
  getEvaluationNotesFilesReducer,
  getMyTasksReducer,
  removeTaskReducer,
  setIsNotesOpenReducer,
  setIsTasksOpenReducer,
  setNotesQuestionIdActionReducer,
  setTasksCountsReducer,
  setTasksQuestionIdActionReducer,
  updateMyTaskReducer,
  uploadEvaluationNotesFilesReducer,
} from './reducers'

const initialState: EvaluationActionsGroupState = {
  tasks: {
    isOpen: false,
    questionId: null,
    tasks: [],
    users: [],
    priorities: [],
    precannedTasks: [],
  },
  notes: {
    questionId: null,
    isOpen: false,
    list: {},
    notesFiles: {},
  },
  tasksCounts: {},
  isLoading: false,
}

export const evaluationActionsGroupSlice = createSlice({
  name: EVALUATION_ACTIONS_GROUP_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    setIsTasksOpenReducer(builder)
    setIsNotesOpenReducer(builder)
    getMyTasksReducer(builder)
    createMyTaskReducer(builder)
    addMyNoteReducer(builder)
    updateMyTaskReducer(builder)
    removeTaskReducer(builder)
    getEvaluationNotesFilesReducer(builder)
    uploadEvaluationNotesFilesReducer(builder)
    deleteEvaluationNotesFilesReducer(builder)
    setNotesQuestionIdActionReducer(builder)
    setTasksQuestionIdActionReducer(builder)
    setTasksCountsReducer(builder)
  },
})

export default evaluationActionsGroupSlice.reducer
