import React, { FC } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  isAnswered: boolean
  answersCount: number
  questionsLength: number
}

const EvaluationStatusIcon: FC<Props> = ({ isAnswered, answersCount, questionsLength }) => {
  if (questionsLength && answersCount && questionsLength - answersCount) {
    return <FontAwesomeIcon className='circleOrange' icon={faMinusCircle as IconDefinition} />
  }
  if (isAnswered || (questionsLength && questionsLength === answersCount)) {
    return <FontAwesomeIcon className='circleGreen' icon={faCheckCircle as IconDefinition} />
  }
  return <FontAwesomeIcon className='circleGray' icon={faCircle as IconDefinition} />
}

export default EvaluationStatusIcon
