import { createSlice } from '@reduxjs/toolkit'

import { ToastifyType } from 'constants/global'
import { Notify } from 'interfaces'

import { NOTIFICATIONS_SLICE_NAME } from './action'
import { resetNotifyReducer, setNotifyReducer } from './reducers'

const initialState: Notify = {
  message: '',
  type: ToastifyType.DEFAULT,
}

export const notificationsSlice = createSlice({
  name: NOTIFICATIONS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    setNotifyReducer(builder)
    resetNotifyReducer(builder)
  },
})

export default notificationsSlice.reducer
