import styled from 'styled-components'

import { ALIGN } from 'constants/shared'

export const Overlay = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: 0.3s all ease-in-out;
  padding: 8px;
  z-index: 1021;
  & > div {
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-150%)')};
    transition: 0.3s all ease;
  }
`

export const ModalWrapper = styled.div`
  margin: 28px auto 0;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  background: #fff;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.black1};

  &.minified-modal {
    max-width: 500px;

    & > div:last-child,
    & > div:nth-child(2) {
      padding: 0;
    }
  }

  @media (max-width: 992px) {
    max-width: 500px;
  }
  @media (max-width: 576px) {
    min-width: 100%;
  }
`

export const Top = styled.div`
  padding: ${({ theme }) => theme.spaces.min3};
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  line-height: 24px;
  & > svg {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray9};
    &:hover {
      color: #000;
    }
  }
`

export const Content = styled.div`
  padding: ${({ theme }) => theme.spaces.min3};
  border-top: 1px solid ${({ theme }) => theme.colors.gray1};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
  font-size: 12.8px;
  line-height: 19.2px;

  &.modal-content-no-border {
    border: none;
  }
`

export const Bottom = styled.div<{ align?: ALIGN }>`
  padding: ${({ theme }) => theme.spaces.min3};
  display: flex;
  justify-content: ${({ align }) => align || 'flex-end'};
  & > button {
    margin-left: 6px;
  }
`
