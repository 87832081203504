import styled from 'styled-components'

export const TitleBlock = styled.div`
  background: ${({ theme }) => theme.colors.gray12};
  padding: ${({ theme }) => `${theme.spaces.min2} ${theme.spaces.min3}`};
  margin-bottom: ${({ theme }) => theme.spaces.min6};

  & > a {
    color: ${({ theme }) => theme.colors.gray13};
    font-weight: 700;
    line-height: 24px;
    text-decoration: underline;
    &:hover {
      color: ${({ theme }) => theme.colors.gray14};
    }
  }
`
