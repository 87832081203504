import { useEffect, useState } from 'react'

export const UseNetworkStatus = () => {
  const [online, setOnline] = useState<boolean>(typeof window !== 'undefined' ? window.navigator.onLine : true)

  function goOnline() {
    setOnline(true)
  }
  function goOffline() {
    setOnline(false)
  }
  useEffect(() => {
    window.addEventListener('online', goOnline)
    window.addEventListener('offline', goOffline)
    return () => {
      window.removeEventListener('online', goOnline)
      window.removeEventListener('offline', goOffline)
    }
  }, [])

  return online
}

export default UseNetworkStatus
