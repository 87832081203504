import { useState, useCallback } from 'react'

const useToggle = (initialState?: boolean): [boolean, VoidFunction] => {
  const [state, setState] = useState<boolean>(initialState || false)

  const toggle = useCallback((): void => setState((state) => !state), [])
  return [state, toggle]
}

export default useToggle
