import styled from 'styled-components'

export const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 17px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray3};
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & > span {
    position: absolute;
    top: 2px;
    left: 0;
    height: 13px;
    width: 13px;
    border: 1px solid ${({ theme }) => theme.colors.gray3};
    border-radius: 2px;
    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  &:hover {
    & > span {
      border: 1px solid ${({ theme }) => theme.colors.blue1};
    }
  }

  & > input:checked ~ span {
    background-color: ${({ theme }) => theme.colors.blue1};
    border: 1px solid ${({ theme }) => theme.colors.blue1};
  }

  & > input:checked ~ span:after {
    display: block;
  }

  & > span:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0px;
    left: 3px;
    width: 5px;
    height: 9px;
    border: solid 2px #fff;
    border-left: none;
    border-top: none;
    transform: rotate(45deg);
  }
`
