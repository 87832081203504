import React, { FC, useEffect, useState } from 'react'

import cn from 'classnames'
import VerificationInput from 'react-verification-input'

import { Colors } from 'constants/global'
import { saveUserAuthPinCodeById } from 'db/helpers'
import { StringOrNullType } from 'interfaces'
import { useAppDispatch } from 'modules/store'
import { setUserPinCode } from 'modules/user/action'
import { Button } from 'shared-components'
import { notify } from 'utils'

import { ContentTitle, UserPinCodeModalContentWrapper, UserPinCodeModalFooter } from './style'

const USER_PIN_CODE_MODAL_TEXT = 'Use this pin when accessing pro-evaluate in offline mode'

export const USER_AUTH_PIN_CODE_LENGTH = 4

interface Props {
  userId: StringOrNullType
  pinCode?: string
  handleCloseModal: VoidFunction
}

const UserPinCodeModalContent: FC<Props> = ({ userId, pinCode, handleCloseModal }) => {
  const [authPinCode, setAuthPinCode] = useState('')

  const dispatch = useAppDispatch()

  const [shownError, setIsShownError] = useState(false)

  const onChangeAuthPinCode = (pinCode: string) => {
    if (shownError) setIsShownError(false)

    setAuthPinCode(pinCode)
  }

  const handleSaveAuthPinCode = async () => {
    if (!userId) return

    if (authPinCode?.length !== USER_AUTH_PIN_CODE_LENGTH) {
      setIsShownError(true)
      return
    }

    await saveUserAuthPinCodeById(userId, authPinCode)
    notify({ message: 'Pin Code was successfully updated', type: 'success' })
    await dispatch(setUserPinCode(authPinCode))
    handleCloseModal()
  }

  useEffect(() => {
    if (pinCode) setAuthPinCode(pinCode)
  }, [pinCode])

  return (
    <div>
      <UserPinCodeModalContentWrapper>
        <ContentTitle>{USER_PIN_CODE_MODAL_TEXT}</ContentTitle>
        <VerificationInput
          value={authPinCode}
          onChange={onChangeAuthPinCode}
          length={USER_AUTH_PIN_CODE_LENGTH}
          classNames={{
            character: cn('verification-input-character', { 'verification-input-character-error': shownError }),
            container: 'verification-input-container',
          }}
          validChars='0-9'
          inputProps={{ inputMode: 'numeric' }}
          placeholder=''
        />
      </UserPinCodeModalContentWrapper>

      <UserPinCodeModalFooter>
        <Button backgroundColor={Colors.BLUE} onClick={handleSaveAuthPinCode}>
          Set Code
        </Button>
      </UserPinCodeModalFooter>
    </div>
  )
}

export default UserPinCodeModalContent
