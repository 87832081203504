import React, { FC } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { UserCardWrapper } from './style'

interface Props {
  userName: string
}

const UserCard: FC<Props> = ({ userName }) => {
  return (
    <UserCardWrapper>
      <FontAwesomeIcon icon={faUser as IconDefinition} size='6x' />
      <p>{userName}</p>
    </UserCardWrapper>
  )
}

export default UserCard
