import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'modules/store'

const selectState = (x: RootState) => x.areaFieldsReducer

export const selectAreaFieldsWithPagination = createSelector(
  selectState,
  ({ areaFields, current_page, total, per_page }) => ({
    areaFields,
    total,
    current: current_page,
    pageSize: per_page,
  }),
)

export const selectAreaFields = createSelector(selectState, ({ areaFields, isCalled }) => ({
  areaFields,
  isCalled,
}))

export const selectAreaFieldToEdit = (id: string) =>
  createSelector(selectState, (state) => {
    const { mandatory, ...item } = state.areaFields.find((i) => i.id === Number(id)) || {}

    if (Object.keys(item)?.length === 0) return null

    return {
      ...item,
      isMandatory: mandatory,
    }
  })

export const selectCurrentAreaField = createSelector(selectState, ({ current }) => current)

export const selectIsLoading = createSelector(selectState, (state) => state.isLoading)
