import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import { AreasItem, AreasState, AreaV4, NumberStringBoolean } from 'interfaces'

import { createArea, deleteArea, editArea, getAreas, getAreasList, getCurrentArea } from './action'

export function getAreasReducer(builder: ActionReducerMapBuilder<AreasState>) {
  builder.addCase(getAreas.fulfilled, (state, action: PayloadAction<AreasItem[]>) => {
    state.areas = action.payload
  })
}
export function createAreaReducer(builder: ActionReducerMapBuilder<AreasState>) {
  builder.addCase(createArea.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(createArea.fulfilled, (state, action: PayloadAction<AreasItem>) => {
    state.areas.push(action.payload)
    state.isLoading = false
  })

  builder.addCase(createArea.rejected, (state) => {
    state.isLoading = false
  })
}

export function deleteAreaReducer(builder: ActionReducerMapBuilder<AreasState>) {
  builder.addCase(deleteArea.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(
    deleteArea.fulfilled,
    (state, action: PayloadAction<{ id: number; parent_id?: NumberStringBoolean | null }>) => {
      const { id, parent_id } = action.payload
      const index = state.areas.findIndex(({ id }) => id === parent_id)

      if (index !== -1)
        state.areas[index] = {
          ...state.areas[index],
          areas: state?.areas[index]?.areas?.filter((i) => i.id !== id) || [],
        }
      else state.areas = state.areas.filter((i) => i.id !== id)
      state.isLoading = false
    },
  )

  builder.addCase(deleteArea.rejected, (state) => {
    state.isLoading = false
  })
}
// TODO - CHANGE TYPE IN PAYLOADACTION<ANY>
export function getCurrentAreaReducer(builder: ActionReducerMapBuilder<AreasState>) {
  //eslint-disable-next-line
  builder.addCase(getCurrentArea.fulfilled, (state, action: PayloadAction<any>) => {
    state.currentArea = action.payload
  })
}

export function editAreaReducer(builder: ActionReducerMapBuilder<AreasState>) {
  builder.addCase(editArea.fulfilled, (state, action: PayloadAction<AreasItem>) => {
    const areaIndex = state.areas.findIndex((i) => i.id === action.payload.id)
    state.areas[areaIndex] = action.payload
  })
}

export function getAreasListReducer(builder: ActionReducerMapBuilder<AreasState>) {
  builder.addCase(getAreasList.fulfilled, (state, action: PayloadAction<AreaV4[]>) => {
    state.areasList = action.payload
  })
}
