import { UploadFile } from 'interfaces'
import { processEvaluationQuestionPicture } from 'utils'

export const getFileSize = (size: number): string => {
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  let index = 0

  while (size >= 1024 && index < units.length - 1) {
    size /= 1024
    index++
  }

  return `${size.toFixed(1)} ${units[index]}`
}

export const downloadFile = async (fileUrl: string, fileName: string) => {
  const a = document.createElement('a')

  const response = await fetch(fileUrl)
  const file = await response.blob()

  a.download = fileName
  a.href = window.URL.createObjectURL(file)
  a.dataset.downloadurl = ['application/octet-stream', a.download, a.href].join(':')

  a.click()
}

export const convertFileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

// return files with base64(fetch by file url)
export const formatNotesFiles = async (response: Record<string, UploadFile[]>, evaluationId: number) => {
  return Promise.all(
    Object.entries(response)?.map(
      async ([key, files]) =>
        await Promise.all(
          files?.map(async (file) => {
            const base64 = await processEvaluationQuestionPicture({
              file: `${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/storage/${file?.file}`,
            })

            return {
              ...file,
              evaluation_id: evaluationId,
              id: Math.random() * 1000000,
              questionId: Number(key),
              base64,
            } as UploadFile
          }),
        ),
    ),
  )
}
