import { useCallback, useState } from 'react'

const useOpen = (initState: boolean): [boolean, VoidFunction, VoidFunction] => {
  const [isOpen, setIsOpen] = useState(initState)

  const onOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  return [isOpen, onOpen, onClose]
}

export default useOpen
