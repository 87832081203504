import React, { FC, HTMLProps } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CheckboxWrapper } from './style'

interface Props extends HTMLProps<HTMLInputElement> {
  currentValue: string
}

const CheckboxV3: FC<Props> = ({ value, currentValue, ...props }) => {
  const isChecked = Array.isArray(currentValue) ? currentValue.includes(value as string) : currentValue === value
  return (
    <CheckboxWrapper checked={isChecked}>
      {value}
      <input type='checkbox' value={value || currentValue} {...props} checked={isChecked} />
      <span>
        <FontAwesomeIcon icon={faCheck as IconDefinition} size='xs' />
      </span>
    </CheckboxWrapper>
  )
}

export default CheckboxV3
