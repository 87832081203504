import { useContext, useMemo } from 'react'

import { useLiveQuery } from 'dexie-react-hooks'

import { NetworkStatusContext } from 'contexts/NetworkContext'
import { db } from 'db/mainDb'
import { NumberOrNullType, Question } from 'interfaces'
import { selectFillEvaluation } from 'modules/fill-evaluation/selectors'
import { useAppSelector } from 'modules/store'

const useFillEvaluation = (
  paramId: number,
): {
  id: NumberOrNullType | undefined
  isCompleted: boolean
  title: string | undefined
  questions: Question[]
  isOnline?: boolean
} => {
  const isOnline = useContext(NetworkStatusContext)

  const evaluation = useAppSelector(selectFillEvaluation)

  const evaluationOffline = useLiveQuery(() => db.fullList.get({ id: paramId }))
  const id = isOnline ? evaluation.id : evaluationOffline?.id

  const title = useMemo(() => {
    return isOnline ? evaluation.title : evaluationOffline?.title
  }, [evaluation.title, evaluationOffline?.title, isOnline])

  const questions = useMemo(() => {
    return isOnline ? evaluation.questions : evaluationOffline?.questions
  }, [evaluation.questions, evaluationOffline?.questions, isOnline])

  return {
    id,
    title,
    isCompleted: !!evaluation.completed_at,
    questions: questions || [],
    isOnline,
  }
}

export default useFillEvaluation
