import { createSlice } from '@reduxjs/toolkit'

import { QuestionSetsAdditionalState } from 'interfaces'

import { QUESTION_SETS_ADDITIONAL_SLICE_NAME } from './action'
import {
  createQuestionReducer,
  createSectionReducer,
  deleteSectionsQuestionsListItemReducer,
  editQuestionReducer,
  editSectionReducer,
  getSectionsQuestionsListReducer,
} from './reducers'

const initialState: QuestionSetsAdditionalState = {
  list: [],
  isLoading: false,
}

export const questionSetsAdditionalSlice = createSlice({
  name: QUESTION_SETS_ADDITIONAL_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getSectionsQuestionsListReducer(builder)
    deleteSectionsQuestionsListItemReducer(builder)
    createSectionReducer(builder)
    editSectionReducer(builder)
    editQuestionReducer(builder)
    createQuestionReducer(builder)
  },
})

export default questionSetsAdditionalSlice.reducer
