import React, { createContext, ReactNode } from 'react'

import useNetworkStatus from '../hooks/useNetworkStatus'

export const NetworkStatusContext = createContext<boolean | undefined>(undefined)

const NetworkContext = ({ children }: { children: ReactNode }) => {
  const isOnline = useNetworkStatus()

  return <NetworkStatusContext.Provider value={isOnline}>{children}</NetworkStatusContext.Provider>
}

export default NetworkContext
