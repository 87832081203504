import styled from 'styled-components'

export const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  z-index: 1000;
  padding-top: 10px;
  user-select: none;
  cursor: not-allowed;
  align-items: center;

  display: flex;
  justify-content: center;
  &:first-child {
    padding-top: 8px;
  }
`
