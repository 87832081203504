import styled from 'styled-components'

import { SkeletonBlock } from 'shared-components/shimmer/style'

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const SkeletonItem = styled(SkeletonBlock)`
  width: 95%;
  height: 200px;
  margin: ${({ theme }) => theme.spaces.min3} auto 0;
`

export const SkeletonTitle = styled(SkeletonBlock)`
  width: 70%;
  height: 40px;
  margin-top: ${({ theme }) => theme.spaces.min3};
  margin-left: 40px;
`
