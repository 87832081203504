import React, { FC, useMemo } from 'react'

import { QuestionType } from 'interfaces'
import { Question } from 'interfaces/evaluations.interfaces'
import { Status } from 'shared-components/status-label/style'
import { getFinishEvaluationFormattedAnswer } from 'utils/evaluations'

const EvaluationFinishItem: FC<Pick<Question, 'id' | 'answer' | 'question' | 'type'>> = ({
  id,
  answer,
  question,
  type,
}) => {
  const formattedAnswer = useMemo(() => {
    return getFinishEvaluationFormattedAnswer(answer, type)
  }, [answer, type])

  return (
    <tr>
      <td>{id}</td>
      <td>{question}</td>
      <td>
        {type === QuestionType.COLOR && answer ? (
          <Status background={answer} color='#000'>
            {answer}
          </Status>
        ) : (
          formattedAnswer
        )}
      </td>
      <td />
    </tr>
  )
}

export default EvaluationFinishItem
