import styled from 'styled-components'

export const ResendWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.min5};
`

export const Message = styled.p`
  padding: ${({ theme }) => `${theme.spaces.min3} ${theme.spaces.min5}`};
  margin-bottom: ${({ theme }) => theme.spaces.min3};
  background: ${({ theme }) => theme.colors.green2};
  color: #fff;
  border-radius: 4px;
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.gray10};
  line-height: 24px;
`

export const TextButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.blue1};
  margin-left: 4px;
  font-size: 16px;
`
