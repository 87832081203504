import React, { FC } from 'react'

import { TextButton, Message, ResendWrapper, Text } from './style'

interface Props {
  isShownMessage: boolean
  isShown: boolean
  onResend: VoidFunction
}

const Resend: FC<Props> = ({ isShownMessage, isShown, onResend }) => {
  return (
    <ResendWrapper>
      {isShownMessage && <Message>The two factor code has been sent again</Message>}
      {isShown ? (
        <Text>
          You will receive an email which contains a two factor login code. If you have not received it, please click
          <TextButton type='button' onClick={onResend}>
            here
          </TextButton>
          .
        </Text>
      ) : null}
    </ResendWrapper>
  )
}

export default Resend
