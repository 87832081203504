import Dexie, { Table } from 'dexie'

import {
  Evaluation,
  MyDeletedTaskIndexed,
  MyEditedTaskIndexed,
  MyNoteIndexed,
  MyTaskIndexed,
  MyTaskIndexedMain,
  DashboardValues,
  UploadFile,
  OfflineUser,
  NotesFile,
  EvaluationIds,
} from 'interfaces'

const EVALUATION_FIELDS =
  'id, title, start_date, scheduled_at, category, questionSet, currentScore, downloaded_at, deviceToken'

const UPLOAD_FILE = 'id, user_id, evaluation_id, originalName, type, size, file, fileURL'

export class MainDb extends Dexie {
  evaluations!: Table<Evaluation>
  fullList!: Table<Evaluation>
  dashboard!: Table<DashboardValues & { id: number }>
  user!: Table<OfflineUser>
  users!: Table<OfflineUser>
  answers!: Table<{ answerList: string; id: number }>
  tasksList!: Table<MyTaskIndexed>
  newTasks!: Table<MyTaskIndexedMain>
  editedTasks!: Table<MyEditedTaskIndexed>
  deletedTasks!: Table<MyDeletedTaskIndexed>
  notes!: Table<MyNoteIndexed>
  evaluationsUsers!: Table<{ id: number; evaluationId: number[]; name: string }>
  priorities!: Table<{ id: number; name: string; days: number }>
  dbVersion!: Table<{ id: number; version: number }>
  deletedNotes!: Table<EvaluationIds>
  files!: Table<UploadFile>
  notesFiles!: Table<NotesFile>
  filesToDelete!: Table<UploadFile>

  constructor() {
    super('database')
    this.version(5).stores({
      evaluations: EVALUATION_FIELDS,
      fullList: EVALUATION_FIELDS,
      dashboard: 'id, roles, users, questionSets, evaluations',
      user: 'id, name, authPinCode', // current user info
      users: 'id, name, authPinCode', // all users info
      answers: 'id, answerList',
      tasksList: 'id, evaluationId, users, tasks',
      newTasks: 'id, evaluationId, questionId, task',
      editedTasks: 'id, evaluationId, questionId, taskId, task',
      deletedTasks: 'id, questionId, evaluationId',
      notes: 'id, evaluationId, content',
      deletedNotes: 'questionId, evaluationId',
      evaluationsUsers: 'id, evaluationId, name',
      priorities: 'id, name, days',
      dbVersion: 'id, version',
      files: UPLOAD_FILE, // downloaded notes files
      notesFiles: 'id, userId, evaluationId, questionId, file, base64', // offline notes files
      filesToDelete: UPLOAD_FILE, // downloaded files to delete
    })
  }
}

export const db = new MainDb()
