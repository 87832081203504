import styled from 'styled-components'

export const SelectWrapper = styled.select<{
  error?: string
}>`
  width: 100%;
  height: 38px;
  padding: ${({ theme }) => `${theme.spaces.min1} ${theme.spaces.min2}`};
  padding-right: ${({ theme }) => `${theme.spaces.med1}`};
  border-radius: 4px;
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.error : theme.colors.border2)};
  outline: none;

  font-size: 16px;
  transition: border-color 0.15s ease-in-out;

  color: ${({ theme }) => theme.colors.gray2};
  margin-bottom: 0 !important;
  &:focus {
    border-color: ${({ theme }) => theme.colors.blue3};
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.gray1};
  }
`

export const SelectContainer = styled.div`
  margin-bottom: 4px;
  position: relative;
`
