import styled from 'styled-components'

export const Title = styled.h5`
  font-size: 18px;
`

export const Paragraph = styled.p`
  margin-top: ${({ theme }) => theme.spaces.min3};
  font-size: 14px;
`

export const Span = styled.span`
  display: block;
`

export const Strong = styled.strong`
  font-weight: 700;
`

export const Block = styled.div`
  margin-top: ${({ theme }) => theme.spaces.min3};
`

export const Center = styled(Block)`
  width: 100%;
  text-align: center;
  font-size: 16px;
`
