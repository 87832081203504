import React, { FC, ReactNode } from 'react'

import { Colors } from 'constants/global'

import { Button, ContentCard } from '../index'
import { FiltersContentWrapper, FiltersFooterWrapper } from './style'

interface Props {
  children: ReactNode
  handleSubmit: VoidFunction
  handleReset: VoidFunction
  disabledActions?: boolean
  isLoading?: boolean
}

const Filters: FC<Props> = ({ children, handleSubmit, handleReset, disabledActions, isLoading }) => {
  return (
    <ContentCard topBackground={Colors.GRAY} spaces='0' title='Apply Filters'>
      <form onSubmit={handleSubmit}>
        <FiltersContentWrapper>{children}</FiltersContentWrapper>
        <FiltersFooterWrapper>
          <Button backgroundColor={Colors.GREEN} onClick={handleReset} disabled={disabledActions || isLoading}>
            Reset Filters
          </Button>
          <Button type='submit' onClick={handleSubmit} disabled={disabledActions} isLoading={isLoading}>
            Filter
          </Button>
        </FiltersFooterWrapper>
      </form>
    </ContentCard>
  )
}

export default Filters
