import { ACCESS_TOKEN_KEY, REMEMBER_ME_KEY } from 'constants/auth'

export const resetUserAuthStorage = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
  localStorage.removeItem(REMEMBER_ME_KEY)
  sessionStorage.removeItem(ACCESS_TOKEN_KEY)
}

export const checkIsAccessTokenExist = () => {
  return (localStorage.getItem(REMEMBER_ME_KEY) ? localStorage : sessionStorage).getItem(ACCESS_TOKEN_KEY)
}

export const setUserAccess = (remember_me: string | boolean, access_token: string) => {
  if (remember_me) {
    localStorage.setItem(ACCESS_TOKEN_KEY, access_token)
    localStorage.setItem(REMEMBER_ME_KEY, '1')
  } else {
    sessionStorage.setItem(ACCESS_TOKEN_KEY, access_token)
  }
}

export const get2FacHeaders = (token?: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token || sessionStorage.getItem('two_auth_access')}`,
    },
  }
}
