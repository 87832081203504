import styled from 'styled-components'

export const SettingsWrapper = styled.div`
  padding: ${({ theme }) => theme.spaces.min5} ${({ theme }) => theme.spaces.min5} 0 ${({ theme }) => theme.spaces.min5};
  & > div {
    margin-bottom: ${({ theme }) => theme.spaces.min3};
  }
`

export const SettingsActionsWrapper = styled.div`
  & button {
    width: 100%;
  }
`
