import { EvaluationIds } from 'interfaces'

import { db } from './mainDb'

export const saveNoteToDeletedList = async (payload: EvaluationIds) => {
  await db.deletedNotes.bulkPut([payload])
  await db.notes.where('id').equals(payload?.questionId).delete()
}

export const removeByIdFromDeletedList = async (questionId: number) => {
  await db.deletedNotes.where('questionId').equals(questionId).delete()
}
