import React, { FC, InputHTMLAttributes } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { RadioV1Wrapper } from './style'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  background?: string
  currentValue?: string
  label?: string
  isAdaptiveColor?: boolean
}

const RadioV1: FC<Props> = ({ background, currentValue, value, label, isAdaptiveColor, ...props }) => {
  const isChecked = currentValue === value
  if (!value) return null

  return (
    <RadioV1Wrapper background={background} checked={isChecked} isAdaptiveColor={isAdaptiveColor}>
      {label || value}
      <input type='radio' {...props} value={value || ''} checked={isChecked} />
      <span>
        <FontAwesomeIcon icon={faCheck as IconDefinition} size='xs' />
      </span>
    </RadioV1Wrapper>
  )
}

export default RadioV1
