import React, { FC, useState } from 'react'

import { Route, Routes } from 'react-router-dom'

import { HOME_ROUTES } from 'constants/home'
import { routes } from 'constants/routes'
import { Sidebar, Header } from 'page-components'
import NotFound from 'views/not-found/NotFound'

import { ContentWrapper, HomeContainer, HomeContent } from './style'

import 'rc-pagination/assets/index.css'

const Home: FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 996)

  const handleIsOpenSidebar = () => setIsSidebarOpen((prev) => !prev)

  return (
    <HomeContainer>
      <Sidebar isOpen={isSidebarOpen} handleOpenSidebar={handleIsOpenSidebar} />
      <HomeContent isOpen={isSidebarOpen}>
        <Header isSidebarOpen={isSidebarOpen} handleOpenSidebar={handleIsOpenSidebar} />
        <ContentWrapper isOpen={isSidebarOpen}>
          <Routes>
            <Route path={routes.notFound} element={<NotFound isOpen={isSidebarOpen} />} />
            {HOME_ROUTES.map(({ element, path }) => {
              const Component = element
              return <Route key={path} path={path} element={<Component isSidebarOpen={isSidebarOpen} />} />
            })}
          </Routes>
        </ContentWrapper>
      </HomeContent>
    </HomeContainer>
  )
}

export default Home
