import styled from 'styled-components'

export const AuthContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.colors.gray1};

  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
`

export const AuthContent = styled.div<{ isEnlarged?: boolean }>`
  @media (max-width: 576px) {
    width: 90%;
  }
  width: ${({ isEnlarged }) => (isEnlarged ? '600px' : '360px')};
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const AuthContentHeader = styled.div`
  padding: 12px 20px;

  & > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray2};
    font-size: 33.6px;
    font-weight: 300;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: ${({ theme }) => theme.spaces.min4};
  }

  & b {
    font-weight: 400;
  }
`

export const Title = styled.h3`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black1};
  text-align: center;
`

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.colors.border1};
  margin: 0 -20px;
`

export const AuthContentForm = styled.form`
  width: 100%;
  padding: 20px;

  & > input {
    margin-bottom: ${({ theme }) => theme.spaces.min3};
  }
`

export const AuthFormBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ErrorMessageStyle = styled.div`
  font-size: 12px;
  color: #dc3545;
  font-weight: 700;
  margin: -10px 0 5px 0;
`

export const Button = styled.button<{
  isShownCheckbox: boolean
}>`
  padding: ${({ theme }) => `9.5px ${theme.spaces.min3}`};
  background: ${({ theme }) => theme.colors.blue1};
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 ${(props) => (props?.isShownCheckbox ? '39%' : '100%')};

  &.button-alignLeft {
    max-width: 87px;
  }

  cursor: pointer;
  & > svg {
    margin-right: 3px;
  }
`

export const AuthFooter = styled.div`
  width: 100%;
  padding: ${({ theme }) => `${theme.spaces.min2} ${theme.spaces.min5}`};
  background: ${({ theme }) => theme.colors.gray4};

  & > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.blue1};
  }
`

export const AuthOfflineWrapper = styled.div`
  width: 100%;
`

export const AuthOfflineContentWrapper = styled.div`
  padding: 20px 80px;
`
