import { createSlice } from '@reduxjs/toolkit'

import { INITIAL_FILL_EVALUATION_STATE } from 'constants/evaluations'
import { FillEvaluation } from 'interfaces'

import { EVALUATIONS_FILL_SLICE_NAME } from './action'
import {
  addNotApplicableReducer,
  asyncSaveQuestionsAnswerReducer,
  finishFillEvaluationReducer,
  getMyFillEvaluationsReducer,
  getQuestionsAnswerReducer,
  offlineUploadReducer,
  saveAnswersReducer,
  setFillEvaluationReducer,
} from './reducers'

const initialState: FillEvaluation = INITIAL_FILL_EVALUATION_STATE

export const evaluationsFillSlice = createSlice({
  name: EVALUATIONS_FILL_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getMyFillEvaluationsReducer(builder)
    saveAnswersReducer(builder)
    addNotApplicableReducer(builder)
    offlineUploadReducer(builder)
    finishFillEvaluationReducer(builder)
    getQuestionsAnswerReducer(builder)
    asyncSaveQuestionsAnswerReducer(builder)
    setFillEvaluationReducer(builder)
  },
})

export default evaluationsFillSlice.reducer
