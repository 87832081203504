import React, { FC, memo, useContext, useEffect, useMemo, useState } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faDownload, faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { format } from 'date-fns'
import { useLiveQuery } from 'dexie-react-hooks'

import { fillEvaluationApi } from 'apis'
import {
  MY_EVALUATIONS_ITEM_STATUS_COMPLETE,
  MY_EVALUATIONS_ITEM_STATUS_IN_PROGRESS,
  MY_EVALUATIONS_ITEM_STATUS_OVERDUE,
  MY_EVALUATIONS_ITEM_STATUS_UPCOMING,
} from 'constants/evaluations'
import { Colors, DEFAULT_DATE_FORMAT } from 'constants/global'
import { NetworkStatusContext } from 'contexts/NetworkContext'
import { fetchFilesToDeleteCount, fetchNewFilesCount } from 'db/fetch-db'
import { removeEvaluationByIdFromDB } from 'db/helpers'
import { db } from 'db/mainDb'
import { ContinueFuncType, Evaluation } from 'interfaces'
import { asyncGetOfflineEvaluation } from 'modules/evaluations/action'
import { useAppDispatch } from 'modules/store'
import { Button, ProgressBar, SelectV3 } from 'shared-components'
import {
  checkIsTodayOrBefore,
  getPercentageOfTheNumber,
  getEvaluationTextByScore,
  getFlatAndFilteredEvaluationCompletion,
} from 'utils'

import { MyEvaluationsItemWrapper, StatusBlock, StatusBlockWrapper } from './style'

interface Props extends Evaluation {
  setIsLoadingOfflineItem: (value: boolean) => void
  onContinue: ContinueFuncType
  isOffline?: boolean
  handleUnloadConfirm: (value: number) => void
}

const MyEvaluationsItem: FC<Props> = memo(
  ({
    id,
    title,
    questionSet,
    category,
    area,
    currentScore,
    isOffline,
    setIsLoadingOfflineItem,
    onContinue,
    completed_at,
    deviceToken,
    deadline_at,
    handleUnloadConfirm,
  }) => {
    const [isLoadingOffline, setIsLoadingOffline] = useState(false)
    const [isLoadingNotes, setIsLoadingNotes] = useState(true)
    const [notesNotMatchedContent, setNotesNotMatchedContent] = useState(false)

    const dispatch = useAppDispatch()

    const isOnline = useContext(NetworkStatusContext)

    const offlineItem = useLiveQuery(() => db.fullList.get({ id }))

    const answers = useLiveQuery(() => db.answers.get({ id }))

    const deletedTasks = useLiveQuery(() => db.deletedTasks.get({ evaluationId: id }))

    const editedTasks = useLiveQuery(() => db.editedTasks.get({ evaluationId: id }))

    const newTasks = useLiveQuery(() => db.newTasks.get({ evaluationId: id }))

    const filesToDeleteCount = useLiveQuery(() => fetchFilesToDeleteCount(id))

    const newFilesCount = useLiveQuery(() => fetchNewFilesCount(id))

    const offlineNotes = useLiveQuery(() => db.notes.get({ evaluationId: id }))

    const isDownloaded = offlineItem

    const isOverdue = checkIsTodayOrBefore(deadline_at)

    useEffect(() => {
      if (!isOnline) return

      const fetchData = async () => {
        if (offlineItem?.id) {
          const onlineNotesData = await fillEvaluationApi.getNotesList(offlineItem.id)
          const onlineContentArray = Object.values(onlineNotesData)

          const firstKey = Object.keys(onlineContentArray[0])[0]
          const onlineContent = onlineContentArray[0][firstKey]?.[0]?.content
          const offlineContent = offlineNotes?.content

          setNotesNotMatchedContent(onlineContent !== offlineContent)
        }
        setIsLoadingNotes(false)
      }
      const timeout = setTimeout(() => {
        fetchData()
      }, 2000)
      return () => clearTimeout(timeout)
    }, [offlineItem?.id, offlineItem?.title, offlineNotes?.content])

    const shouldRenderSyncOfflineDataLabel =
      isDownloaded &&
      isOnline &&
      (answers ||
        deletedTasks ||
        editedTasks ||
        newTasks ||
        filesToDeleteCount ||
        newFilesCount ||
        (notesNotMatchedContent && !isLoadingNotes))

    const completedScore = useMemo(() => {
      if (!isOnline && offlineItem?.questions?.length) {
        const { answersCount, questionsLength } = getFlatAndFilteredEvaluationCompletion(offlineItem?.questions)

        return getPercentageOfTheNumber(answersCount, questionsLength)
      }
      return currentScore || 0
    }, [currentScore, isOnline, offlineItem?.questions])

    const isShownDownloadedItemDropdown = isDownloaded && !Number(completedScore)

    const onLoadOfflineEvaluation = () => {
      if (id && !isDownloaded) {
        setIsLoadingOffline(true)
        setIsLoadingOfflineItem(true)
        dispatch(asyncGetOfflineEvaluation(id))
      }
    }

    const onUnloadOfflineEvaluation = () => {
      handleUnloadConfirm(Number(id))
    }

    const handleContinue = () => {
      if (id) onContinue({ id, shouldSync: !!shouldRenderSyncOfflineDataLabel })
    }

    useEffect(() => {
      if (isOffline && offlineItem && offlineItem?.deviceToken !== deviceToken && id)
        removeEvaluationByIdFromDB(id).then(() => '')
    }, [deviceToken, id, isOffline, offlineItem, offlineItem?.deviceToken])

    useEffect(() => {
      if (offlineItem && !isOffline) {
        if (isLoadingOffline) {
          setIsLoadingOffline(false)
          setIsLoadingOfflineItem(false)
        }
      }
      const handleOnlineStatusChange = () => {
        if (navigator.onLine) {
          window.location.reload()
        }
      }

      window.addEventListener('online', handleOnlineStatusChange)

      return () => {
        window.removeEventListener('online', handleOnlineStatusChange)
      }

      // eslint-disable-next-line
    }, [dispatch, id, isLoadingOffline, offlineItem])

    if (!offlineItem && isOffline) return null

    return (
      <MyEvaluationsItemWrapper isOfflineItem={!!offlineItem} isDisabled={!isDownloaded && !isOnline}>
        <td>
          <p>{title}</p>
        </td>
        <td>{questionSet}</td>
        <td>{category}</td>
        <td>{area}</td>

        <td>{deadline_at ? format(new Date(deadline_at), DEFAULT_DATE_FORMAT) : null}</td>
        <td>
          <StatusBlockWrapper>
            {completed_at || (isOverdue && Number(completedScore) === 0) ? (
              <StatusBlock backgroundColor={isOverdue ? Colors.RED : Colors.GREEN}>
                {isOverdue ? MY_EVALUATIONS_ITEM_STATUS_OVERDUE : MY_EVALUATIONS_ITEM_STATUS_COMPLETE}
              </StatusBlock>
            ) : isOverdue ? (
              <>
                <StatusBlock backgroundColor={Colors.RED}>{MY_EVALUATIONS_ITEM_STATUS_OVERDUE}</StatusBlock>
                <ProgressBar valuePercentage={Number(completedScore)} />
              </>
            ) : Number(completedScore) > 0 ? (
              <>
                <StatusBlock backgroundColor={Colors.GREEN_SECONDARY}>
                  {MY_EVALUATIONS_ITEM_STATUS_IN_PROGRESS}
                </StatusBlock>
                <ProgressBar valuePercentage={Number(completedScore)} />
              </>
            ) : (
              <StatusBlock backgroundColor={Colors.GREEN_SECONDARY}>{MY_EVALUATIONS_ITEM_STATUS_UPCOMING}</StatusBlock>
            )}
          </StatusBlockWrapper>
        </td>
        <td>
          {!completed_at && !(isOnline && isOffline) && (
            <Button
              style={{ flex: '1 1 auto', width: '100%' }}
              onClick={handleContinue}
              icon={faSignInAlt as IconDefinition}
            >
              {getEvaluationTextByScore(Number(completedScore), isOnline)}
            </Button>
          )}
          {shouldRenderSyncOfflineDataLabel && <label>Please sync offline data to see status</label>}
        </td>
        {!isOffline && (
          <td>
            {!completed_at && (
              <>
                <Button
                  style={{ flex: '1 1 auto', width: '80%', cursor: isDownloaded ? 'not-allowed' : 'pointer' }}
                  onClick={onLoadOfflineEvaluation}
                  icon={faDownload as IconDefinition}
                  backgroundColor={isDownloaded ? Colors.GREEN : undefined}
                  isLoading={isLoadingOffline}
                  disabledEffects={!!isDownloaded}
                />
                {isShownDownloadedItemDropdown ? (
                  <SelectV3
                    style={{ flex: '1 1 auto', width: '20%' }}
                    onClick={onUnloadOfflineEvaluation}
                    name={''}
                    options={[{ name: 'Remove Download', value: 'delete' }]}
                    setFieldValue={() => onUnloadOfflineEvaluation()}
                  >
                    Delete
                  </SelectV3>
                ) : null}
              </>
            )}
          </td>
        )}
      </MyEvaluationsItemWrapper>
    )
  },
)

export default MyEvaluationsItem

MyEvaluationsItem.displayName = 'MyEvaluationsItem'
