import React, { FC } from 'react'

import { FormikProps, useFormik } from 'formik'

import { TableDataFormItem, Area, AreasValues } from 'interfaces'
import { createArea, editArea } from 'modules/areas/action'
import { useAppDispatch } from 'modules/store'
import { Settings } from 'shared-components'
import { createInitialValues } from 'utils'
import { getTableDataFormItemValidation } from 'utils/shared'

interface Props {
  initialValues: AreasValues | null
  fields: TableDataFormItem[]
}

const AreasForm: FC<Props> = React.memo(({ initialValues, fields }) => {
  const dispatch = useAppDispatch()

  const onSubmit = (values: AreasValues) => {
    if (initialValues) dispatch(editArea(values as Area))
    else dispatch(createArea(values))

    formik.resetForm()
  }

  const formik: FormikProps<AreasValues> = useFormik<AreasValues>({
    initialValues: initialValues || createInitialValues<AreasValues>(fields),
    onSubmit,
    validationSchema: getTableDataFormItemValidation(fields),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
  })
  return <Settings formik={formik} fields={fields} hideNavigate submitText='Save Area' />
})

export default AreasForm

AreasForm.displayName = 'AreasForm'
