import React, { useEffect } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faShareSquare } from '@fortawesome/free-solid-svg-icons'
import { useFormik } from 'formik'

import { FORGOT_PAGE_TITLE, FORGOT_BUTTON_LABEL, EMAIL_AUTH_FIELD } from 'constants/auth'
import { KeyWithString } from 'interfaces'
import { useAppDispatch } from 'modules/store'
import { asyncResetPassword, resetError } from 'modules/user/action'
import { AuthContent } from 'page-components'
import { getFormikProps } from 'utils'

const ForgotPassword = () => {
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      email: '',
    } as KeyWithString,
    onSubmit: (values) => {
      dispatch(asyncResetPassword({ email: values.email }))
    },
  })

  const formikProps = getFormikProps(formik)

  useEffect(() => {
    dispatch(resetError())
  }, [dispatch])

  return (
    <AuthContent
      formikProps={formikProps}
      fields={[EMAIL_AUTH_FIELD]}
      title={FORGOT_PAGE_TITLE}
      submitButtonProps={{
        label: FORGOT_BUTTON_LABEL,
        customButtonIcon: faShareSquare as IconDefinition,
      }}
      isShownFooter={false}
    />
  )
}

export default ForgotPassword
