import { IndexableType } from 'dexie'

import {
  Evaluation,
  KeyWithQuestionAnswer,
  MyNoteIndexed,
  MyTask,
  MyTaskIndexed,
  DashboardValues,
  KeyWithString,
  MyTaskUser,
  OfflineUser,
  OfflineCompletionType,
} from 'interfaces'
import { changeEvaluationQuestionAnswerById, changeEvaluationQuestionsAnswer } from 'utils'

import { db } from './mainDb'

export const saveEvaluationsToDB = async (evaluations: Evaluation[]) => {
  if (!evaluations?.length) return

  await db.evaluations.bulkPut(evaluations)
}

export const saveDashboardToDB = async (dashboard: DashboardValues) => {
  await db.dashboard.bulkPut([{ id: 1, ...dashboard }])
}

export const saveAllEvaluationsToDB = async (evaluations: Evaluation[]) => {
  await db.fullList.bulkPut(evaluations)
}

export const removeEvaluationByIdFromDB = async (id: number) => {
  await db.fullList.where('id').equals(id).delete()

  await syncOnlineToOffline(id)
}

export const removeEvaluationByIdFromDBLists = async (id: number) => {
  await db.fullList.where('id').equals(id).delete()
  await db.evaluations.where('id').equals(id).delete()
  await syncOnlineToOffline(id)
}

export const removeEvaluationsUsersByIdFromDB = async (id: number) => {
  await db.evaluationsUsers
    .filter((user) => user.evaluationId?.includes(id))
    .modify(async (user: { evaluationId: number[]; id: IndexableType }) => {
      const index = user.evaluationId.indexOf(id)
      if (index !== -1) {
        user.evaluationId.splice(index, 1)
      }
      if (!user?.evaluationId?.length) {
        await db.evaluationsUsers.where('id').equals(user.id).delete()
      }
    })
}

export const setCurrentUserToDB = async (user: OfflineUser) => {
  const isAlreadyCurrentUser = await db.user.get({ id: user?.id })

  if (isAlreadyCurrentUser) return

  await db.user.clear()
  await db.user.bulkPut([user])
}

export const deleteCurrentUserFromDB = async () => {
  await db.user.clear()
}

export const saveUserToDB = async (user: OfflineUser) => {
  if (!user?.id) return

  const currentUserData = await db.users.get({ id: user?.id })

  await db.users.bulkPut([{ ...user, authPinCode: currentUserData?.authPinCode }])
}

export const saveUserAuthPinCodeById = async (userId: string, authPinCode: string) => {
  await db.users
    .where('id')
    .equals(userId)
    .modify((user) => {
      user.authPinCode = authPinCode
    })
}

export const savePriorities = async (
  data: {
    id: number
    name: string
    days: number
  }[],
) => {
  await db.priorities.bulkPut(data)
}

export const saveAnswers = async (id: number, answers: KeyWithString) => {
  await db.answers.bulkPut([
    {
      id,
      answerList: JSON.stringify(answers),
    },
  ])
}

export const saveNotesById = async (questionId: number, evaluationId: number, content: string) => {
  await db.notes.put({
    id: questionId,
    evaluationId,
    content,
  })
  await db.deletedNotes.where('questionId').equals(questionId).delete()
}

export const saveArrNotesById = async (notes: MyNoteIndexed[]) => {
  await db.notes.bulkPut(notes)
}

export const saveTasksById = async (data: MyTaskIndexed[]) => {
  await db.tasksList.bulkPut(data)
}

export const saveEvaluationsUsers = async (users: Omit<MyTaskUser, 'evaluationId'>[], evaluationId: number) => {
  const oldUsers = (await db.evaluationsUsers.toArray()) as MyTaskUser[]

  const formattedUsers = users.map((user) => {
    const oldUser = oldUsers.find((oldUser) => user.id === oldUser.id)

    return { ...user, evaluationId: [evaluationId, ...(oldUser ? oldUser.evaluationId : [])] }
  })

  await db.evaluationsUsers.bulkPut(formattedUsers)
}

export const editTaskById = async (questionId: number, data: MyTask) => {
  await db.tasksList
    .where('id')
    .equals(questionId)
    .modify((x) => {
      x.tasks = x.tasks.map((i) => (i.id === data.id ? data : i))
    })
}

export const addNewTaskToListById = async (questionId: number, evaluationId: number, data: MyTask) => {
  const isExist = await db.tasksList.get({
    id: questionId,
  })
  if (isExist)
    await db.tasksList
      .where('id')
      .equals(questionId)
      .modify((x) => {
        x.tasks.push(data)
      })
  else {
    await db.tasksList.bulkPut([
      {
        evaluationId,
        id: questionId,
        tasks: [data],
        users: [],
        priorities: [],
        precannedTasks: [],
      },
    ])
  }
}

export const addNewTaskByQuestionId = async (questionId: number, evaluationId: number, data: MyTask) => {
  const randomId = Number(Math.random() * 100000000000)

  await db.newTasks.put({
    id: randomId,
    task: { ...data, id: randomId },
    questionId,
    evaluationId,
  })
}

export const editTaskByTaskAndQuestionId = async (
  questionId: number,
  evaluationId: number,
  task_id: number,
  data: MyTask,
) => {
  db.newTasks
    .where('id')
    .equals(task_id)
    .modify((i) => {
      i.task = { ...data, id: task_id }
    })
    .then(async (r) => {
      if (!r) {
        await db.editedTasks.put({
          id: task_id,
          task: data,
          questionId,
          evaluationId,
        })
        await db.tasksList
          .where('id')
          .equals(questionId)
          .modify((x) => {
            x.tasks = x.tasks.map((i) =>
              i.id === task_id
                ? {
                    ...data,
                    id: task_id,
                  }
                : i,
            )
          })
      }
    })
}

export const removeTaskByQuestionIdAndTask = async (questionId: number, taskId: number, evaluationId: number) => {
  db.tasksList
    .where('id')
    .equals(questionId)
    .modify((x) => {
      x.tasks = x.tasks.filter((i) => i.id !== taskId)
    })
    .then(async (res) => {
      if (res) {
        await db.deletedTasks.put({
          id: taskId,
          questionId,
          evaluationId,
        })

        await db.editedTasks.where('id').equals(taskId).delete()
      }
      await db.newTasks.where('id').equals(taskId).delete()
    })
}

export const updateFullListAnswers = async (id: number, answers: KeyWithString) => {
  db.fullList
    .where('id')
    .equals(id)
    .modify((x) => {
      x.questions = x.questions?.length ? changeEvaluationQuestionsAnswer(x.questions, answers) : []
    })
}

export const updateFullListAnswerById = async ({
  evaluationId,
  id,
  answer,
}: {
  evaluationId: string
  id: number
  answer: string
}) => {
  const numberEvaluationId = Number(evaluationId)

  const isAnswersListExist = await db.answers.get({
    id: numberEvaluationId,
  })
  // Update answer in question by id
  await db.fullList
    .where('id')
    .equals(numberEvaluationId)
    .modify((x) => {
      x.questions = x.questions?.length ? changeEvaluationQuestionAnswerById(x.questions, id, answer) : []
    })

  if (isAnswersListExist) {
    // if answersList exist - update
    await db.answers
      .where('id')
      .equals(numberEvaluationId)
      .modify((x) => {
        const parsedAnswers = JSON.parse(x?.answerList)
        x.answerList = JSON.stringify({ ...parsedAnswers, [id]: answer })
      })
    return
  }
  // create new answerList if not exist
  await db.answers.bulkPut([
    {
      id: numberEvaluationId,
      answerList: JSON.stringify({ [id]: answer }),
    },
  ])
}

export const clearNewTasks = async () => {
  await db.newTasks.clear()
}

export const clearEditedTasks = async () => {
  await db.editedTasks.clear()
}

export const clearNotes = async () => {
  await db.notes.clear()
}

export const clearDeleted = async () => {
  await db.deletedTasks.clear()
}

export const clearAnswers = async () => {
  await db.answers.clear()
}

export const clearDeletedByEvaluationId = async (evaluationId: number) => {
  await db.deletedTasks.where({ evaluationId }).delete()
}

export const clearEditedByEvaluationId = async (evaluationId: number) => {
  await db.editedTasks.where({ evaluationId }).delete()
}

export const clearNewTasksByEvaluationId = async (evaluationId: number) => {
  await db.newTasks.where({ evaluationId }).delete()
}

export const clearNotesByEvaluationId = async (evaluationId: number) => {
  await db.notes.where({ evaluationId }).delete()
}

export const clearAnswersByEvaluationId = async (evaluationId: number) => {
  await db.answers.where({ id: evaluationId }).delete()
}

export const deleteTaskFromTasksList = async (questionId: number, taskId: number) => {
  await db.tasksList
    .where('id')
    .equals(questionId)
    .modify((x) => {
      x.tasks = x.tasks.filter((i) => i.id !== taskId)
    })
}

export const syncOnlineToOffline = async (id: number, answers?: KeyWithQuestionAnswer) => {
  if (answers && Object.keys(answers)?.length > 0) {
    await syncOnlineToOfflineFullList(id, answers)
  }

  await db.answers.where('id').equals(id).delete()
  await db.tasksList.where('evaluationId').equals(id).delete()
  await db.editedTasks.where('evaluationId').equals(id).delete()
  await db.deletedTasks.where('evaluationId').equals(id).delete()
  await db.newTasks.where('evaluationId').equals(id).delete()
  await db.notes.where('evaluationId').equals(id).delete()
  await db.deletedNotes.where('evaluationId').equals(id).delete()
  await removeEvaluationsUsersByIdFromDB(id)
}

export const unloadEvaluation = async (id: number) => {
  await syncOnlineToOffline(id)
  await db.answers.where('id').equals(id).delete()
}

export const syncOnlineToOfflineFullList = async (id: number, answers: KeyWithQuestionAnswer) => {
  await db.fullList
    .where('id')
    .equals(id)
    .modify((x) => {
      x.questions = x.questions?.length
        ? x.questions.map((i) => ({
            ...i,
            answer: answers[i.id]?.answer || null,
          }))
        : []
    })
}

export const proceedEvaluationFeedback = async ({
  id,
  feedback,
}: {
  id: number
} & OfflineCompletionType) => {
  if (!feedback) return

  await db.fullList
    .where('id')
    .equals(id)
    .modify((evaluation) => {
      evaluation.feedback = feedback
    })
}
