import styled from 'styled-components'

export const TextEditorWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.min3};

  & .note-editable {
    height: 400px;
  }

  & .note-editor {
    margin-bottom: 0;
  }

  & .error {
    display: contents !important;
  }

  & p {
    font-size: 16px;
  }

  & b {
    font-weight: 700;
  }

  & .note-editable li {
    margin: 10px;
  }

  & .note-editable ol {
    list-style: decimal;
  }

  & .note-editable ul {
    list-style: disc;
  }
`
