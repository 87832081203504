import React, { FC, memo, useContext, useMemo, useState } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faClipboard, faInfo, faTasks } from '@fortawesome/free-solid-svg-icons'
import { useLiveQuery } from 'dexie-react-hooks'

import { EntityTypes } from 'constants/evaluations'
import { Colors } from 'constants/global'
import { NetworkStatusContext } from 'contexts/NetworkContext'
import { QuestionsContext } from 'contexts/QuestionsContext'
import { db } from 'db/mainDb'
import { MyNoteIndexed, Question } from 'interfaces/evaluations.interfaces'
import {
  asyncGetMyNote,
  asyncGetMyTasks,
  getEvaluationNotesFiles,
  setIsNotesOpen,
  setIsTasksOpen,
  setNotesQuestionIdAction,
  setTasksQuestionIdAction,
} from 'modules/evaluation-actions-group/action'
import { selectNoteInfoById, selectTasksCountById } from 'modules/evaluation-actions-group/selectors'
import { useAppDispatch, useAppSelector } from 'modules/store'
import { Button, ModalWindow } from 'shared-components'

import { fetchTasksCountById } from '../../../../db/fetch-db'
import { BottomAction, TopAction } from './style'

interface Props extends Pick<Question, 'notApplicable' | 'guidance' | 'entityType'> {
  tasksCount: number
  hasNote: boolean
  questionId: number
}

const ActionsGroup: FC<Props> = memo(({ notApplicable, guidance, entityType, tasksCount, questionId }) => {
  const dispatch = useAppDispatch()

  const isOnline = useContext(NetworkStatusContext)
  const { evaluationId, isDisabled } = useContext(QuestionsContext)

  const [isGuidanceOpen, onGuidanceToggle] = useState(false)

  const onlineTasksCount = useAppSelector(selectTasksCountById(questionId))

  const { note, files } = useAppSelector(selectNoteInfoById(questionId))

  const offlineNote = useLiveQuery(() =>
    questionId && evaluationId ? db.notes.get({ id: questionId, evaluationId }) : {},
  ) as MyNoteIndexed

  const offlineTasksCount = useLiveQuery(() => fetchTasksCountById(questionId, Number(evaluationId)))

  const mainContent = note || offlineNote?.content

  const hasMainContent = mainContent && mainContent !== '<p><br></p>'

  const calculatedAnswersCount = useMemo(() => {
    if (!isOnline) return offlineTasksCount || 0

    if (onlineTasksCount !== undefined) return onlineTasksCount

    return tasksCount || 0
  }, [isOnline, offlineTasksCount, onlineTasksCount, tasksCount])

  const fileNumber = useMemo(() => {
    if (files?.length === 1) return `${files.length} file`

    if (files?.length > 1) return `${files.length} files`

    return ''
  }, [files])

  const noteDisplayText = useMemo(() => {
    let displayText = 'Notes'

    if (files?.length && !hasMainContent) {
      displayText = `${fileNumber}`
    }

    if (hasMainContent) displayText = '1 note'

    if (files?.length) displayText += `, ${fileNumber}`

    return displayText
  }, [fileNumber, files?.length, hasMainContent])

  const handleFetchAndOpenTasksModal = () => {
    if (!evaluationId) return

    if (!isOnline) {
      dispatch(setTasksQuestionIdAction(questionId))
      dispatch(setIsTasksOpen(true))
      return
    }
    dispatch(asyncGetMyTasks({ evaluationId, questionId }))
  }

  const handleOpenNotes = () => {
    if (!evaluationId) return

    dispatch(setIsNotesOpen(true))
    dispatch(setNotesQuestionIdAction(questionId))

    if (isOnline) {
      dispatch(asyncGetMyNote({ evaluationId, questionId }))
      dispatch(getEvaluationNotesFiles({ evaluationId, questionId }))
    }
  }

  if (!evaluationId) return null

  return (
    <div>
      <TopAction>
        {entityType === EntityTypes.QUESTION && (
          <>
            <Button icon={faTasks as IconDefinition} onClick={handleFetchAndOpenTasksModal}>
              Tasks
              {`(${calculatedAnswersCount})`}
            </Button>
            <Button icon={faClipboard as IconDefinition} onClick={handleOpenNotes}>
              {noteDisplayText}
            </Button>
          </>
        )}
      </TopAction>
      <BottomAction>
        {guidance && (
          <Button
            onClick={() => onGuidanceToggle(true)}
            backgroundColor={Colors.GREEN_SECONDARY}
            icon={faInfo as IconDefinition}
          >
            Guidance
          </Button>
        )}
      </BottomAction>
      <ModalWindow isOpen={isGuidanceOpen} onClose={() => onGuidanceToggle(false)} title='Guidance'>
        <div dangerouslySetInnerHTML={{ __html: guidance }} />
      </ModalWindow>
    </div>
  )
})

export default ActionsGroup

ActionsGroup.displayName = 'ActionsGroup'
