import { useLiveQuery } from 'dexie-react-hooks'

import {
  fetchOfflineDeletedTasks,
  fetchOfflineFullList,
  fetchOfflineNewTasks,
  fetchOfflineNotes,
  fetchOfflineTasks,
} from '../db/fetch-db'
import {
  MyDeletedTaskIndexed,
  MyNoteIndexed,
  MyTaskIndexed,
  MyTaskIndexedMain,
  NumberOrNullType,
  Question,
  StringOrNullType,
} from '../interfaces'
import { FilteredQuestion } from '../page-components/home/evaluation/questions-container/QuestionsContainer'

const useDownloadEvaluationJson = ({ id }: { id?: number }) => {
  const offlineNotes = useLiveQuery(fetchOfflineNotes) as MyNoteIndexed[]

  const offlineTasks = useLiveQuery(fetchOfflineTasks) as MyTaskIndexed[]

  const offlineNewTasks = useLiveQuery(fetchOfflineNewTasks) as MyTaskIndexedMain[]

  const offlineDeletedTasks = useLiveQuery(fetchOfflineDeletedTasks) as MyDeletedTaskIndexed[]

  function filterQuestions(questions: Question[] | undefined): FilteredQuestion[] {
    if (!questions?.length) return []

    return questions.map(({ id, name, entityType, guidance, question, answer, questions }) => {
      const nestedQuestions = filterQuestions(questions)
      const notesContent = getNotesContent(id)
      const tasksContent = getTasksContent(id)
      const newTasksContent = getNewTasksContent(id)
      const deletedTasksContent = getDeletedTasksContent(id)

      return {
        id,
        name,
        entityType,
        guidance,
        question,
        answer,
        notes: notesContent,
        tasks: tasksContent,
        newTasks: newTasksContent,
        deletedTasks: deletedTasksContent,
        questions: nestedQuestions.length > 0 ? nestedQuestions : null,
      }
    })
  }

  function getNotesContent(questionId: number): { content: string }[] {
    const matchingNotes = offlineNotes?.filter((note) => note.id === questionId)
    return matchingNotes ? matchingNotes.map((note) => ({ content: note.content })) : []
  }

  function getTasksContent(questionId: number): {
    assign_id: NumberOrNullType
    complete_before: StringOrNullType
    content: StringOrNullType
    priority: NumberOrNullType
  }[] {
    const matchingTasks = offlineTasks?.filter((tasksList) => tasksList.id === questionId)
    return matchingTasks
      ? matchingTasks.flatMap((tasksList) =>
          tasksList.tasks.map((item) => ({
            assign_id: item.assign_id,
            complete_before: item.complete_before,
            content: item.content !== undefined ? item.content : null,
            priority: item.priority,
          })),
        )
      : []
  }

  // TODO - The function below needs to be refactored

  function getNewTasksContent(questionId: number): {
    assign_id: NumberOrNullType
    complete_before: StringOrNullType
    content: StringOrNullType
    priority: NumberOrNullType
  }[] {
    const matchingNewTasks = offlineNewTasks?.filter((newTasks) => newTasks.questionId === questionId)
    return matchingNewTasks
      ? matchingNewTasks.flatMap((newTasks) => {
          if (Array.isArray(newTasks.task)) {
            return newTasks.task.map((item) => ({
              assign_id: item.assign_id,
              complete_before: item.complete_before,
              content: item?.content || null,
              priority: item.priority,
            }))
          } else {
            const item = newTasks.task
            return [
              {
                assign_id: item.assign_id,
                complete_before: item.complete_before,
                content: item?.content || null,
                priority: item.priority,
              },
            ]
          }
        })
      : []
  }

  function getDeletedTasksContent(questionId: number): { id: number }[] {
    const matchingDeletedTasks = offlineDeletedTasks?.filter((deletedTasks) => deletedTasks.questionId === questionId)
    return matchingDeletedTasks ? matchingDeletedTasks.map((deletedTasks) => ({ id: deletedTasks.id })) : []
  }

  const handleDownloadJson = async (feedback?: string) => {
    if (!id) return

    const updatedFullList = await fetchOfflineFullList(id)

    const filterFillEvaluationAndNotesList = {
      id: updatedFullList?.id,
      title: updatedFullList?.title,
      questions: filterQuestions(updatedFullList?.questions),
      feedback,
    }

    const jsonData = JSON.stringify(filterFillEvaluationAndNotesList)
    const a = document.createElement('a')
    a.href = 'data:text/json;charset=utf-8,' + encodeURIComponent(jsonData)
    a.download = 'data.json'
    a.click()
  }

  return { handleDownloadJson }
}

export default useDownloadEvaluationJson
