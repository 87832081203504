import React, { useEffect, useState } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { useFormik } from 'formik'
import qs from 'query-string'
import { useLocation, useParams } from 'react-router-dom'
import { object, string, ref } from 'yup'

import { RESET_PASSWORD_FORM_DEFAULT_VALUES } from 'constants/auth'
import { VALIDATION_PATTERNS } from 'constants/validation-patterns'
import useOpen from 'hooks/useOpen'
import { ResetPasswordValues } from 'interfaces'
import { useAppDispatch } from 'modules/store'
import { asyncSendResetPassword } from 'modules/user/action'
import { AuthHeader, AuthLayout } from 'page-components'
import { AuthContentForm, Separator } from 'page-components/auth/styles'
import { Button, Input, LoadingLine, PasswordCheckWidget } from 'shared-components'

import { ResetPasswordButtonWrapper } from './styled'

enum ResetPasswordFieldNames {
  EMAIL = 'email',
  PASSWORD = 'password',
  RETYPE_PASSWORD = 'password_confirmation',
}

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()

  const params = useParams<{ token?: string }>()
  const location = useLocation()

  const parsedLocation = qs.parse(location?.search)

  const [isWidgetOpen, handleOpenWidget] = useOpen(false)

  const formik = useFormik<ResetPasswordValues>({
    initialValues: RESET_PASSWORD_FORM_DEFAULT_VALUES,
    onSubmit: (values) => {
      if (!params?.token) return

      setIsLoading(true)
      dispatch(asyncSendResetPassword({ ...values, token: params?.token }))
        .unwrap()
        .catch(() => '')
        .finally(() => setIsLoading(false))
    },
    validationSchema: object().shape({
      [ResetPasswordFieldNames.EMAIL]: VALIDATION_PATTERNS.email,
      [ResetPasswordFieldNames.PASSWORD]: VALIDATION_PATTERNS.password,
      [ResetPasswordFieldNames.RETYPE_PASSWORD]: string()
        .required('Required field')
        .oneOf([ref('password'), null], 'The password does not match.'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
  })

  useEffect(() => {
    if (parsedLocation?.email) {
      formik.setFieldValue(ResetPasswordFieldNames.EMAIL, parsedLocation?.email)
    }
  }, [parsedLocation?.email])

  return (
    <AuthLayout isEnlarged={false}>
      <LoadingLine isLoading={isLoading} />
      <AuthHeader />
      <Separator />

      <AuthContentForm onSubmit={formik.handleSubmit}>
        <Input
          {...formik.getFieldProps(ResetPasswordFieldNames.EMAIL)}
          placeholder='Email'
          error={formik?.errors[ResetPasswordFieldNames.EMAIL]}
        />
        <Input
          {...formik.getFieldProps(ResetPasswordFieldNames.PASSWORD)}
          error={formik?.errors[ResetPasswordFieldNames.PASSWORD]}
          onFocus={handleOpenWidget}
          placeholder='Password'
          type='password'
        />
        <PasswordCheckWidget isOpen={isWidgetOpen} password={formik.values.password} />

        <Input
          {...formik.getFieldProps(ResetPasswordFieldNames.RETYPE_PASSWORD)}
          error={formik?.errors[ResetPasswordFieldNames.RETYPE_PASSWORD]}
          placeholder='Retype password'
          type='password'
        />

        <ResetPasswordButtonWrapper>
          <Button disabled={isLoading} type='submit' icon={faSyncAlt as IconDefinition}>
            Reset Password
          </Button>
        </ResetPasswordButtonWrapper>
      </AuthContentForm>
    </AuthLayout>
  )
}

export default ResetPassword
