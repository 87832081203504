import styled from 'styled-components'

import { DashboardItemColorsType } from 'interfaces'
import { SkeletonBlock } from 'shared-components/shimmer/style'

export const DashboardItemContainer = styled.div`
  background: #fff;

  width: 100%;
  height: 80px;
  padding: 8px;

  box-shadow: ${({ theme }) => theme.shadows.shadow2};
  border-radius: 4px;

  display: flex;
`

export const ImageSection = styled.div<{
  background: DashboardItemColorsType
}>`
  width: 70px;
  height: 64px;

  background: ${({ theme, background }) => theme.gradients[background]};
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
`

export const DashboardContent = styled.div`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.black1};
  line-height: 28px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;

  & > span {
    margin-top: 4px;
    font-weight: 700;
  }
`

export const ShimmerWrapper = styled.div`
  display: flex;
  width: 100%;
`

export const Shimmer = styled(SkeletonBlock)`
  width: 70px;
  height: 64px;
  margin-right: 10px;
`

export const ShimmerName = styled(SkeletonBlock)`
  height: 28px;
  width: 100px;
`

export const ShimmerCount = styled(SkeletonBlock)`
  height: 28px;
  width: 50%;
`
