import { toast, ToastContent, ToastOptions } from 'react-toastify'

const NOTIFICATIONS_CONFIG: ToastOptions = {
  theme: 'colored',
  draggable: true,
}

export const successNotify = (message: ToastContent) => toast(message, { type: 'success', ...NOTIFICATIONS_CONFIG })

export const errorNotify = (message: ToastContent) => toast(message, { type: 'error', ...NOTIFICATIONS_CONFIG })
