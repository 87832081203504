import { createSelector } from '@reduxjs/toolkit'

import { EntityTypes } from 'constants/evaluations'
import { KeyWithAnyValue, Question } from 'interfaces'
import { RootState } from 'modules/store'
import { getAnswersCountInfo, getFlatEvaluationQuestions } from 'utils'

const selectState = (x: RootState) => x.fillEvaluationReducer

export const selectFillEvaluation = createSelector(selectState, (state) => state)

export const selectLoadingStatus = createSelector(selectState, (state) => state.isLoading)

export const selectIsModalLoading = createSelector(selectState, (state) => state.isModalLoading)

export const selectIsQuestionLoading = createSelector(selectState, (state) => state.isQuestionLoading)

export const selectOfflineSyncData = createSelector(selectState, ({ id, questions, feedback }) => {
  const flatQuestions: Question[] = []
  const onlineAnswers: KeyWithAnyValue = {}

  questions?.forEach((question) => {
    if (question.entityType === EntityTypes.QUESTION) {
      flatQuestions.push(question)
      onlineAnswers[question.id] = question.answer
      return
    }

    const nestedQuestions = getFlatEvaluationQuestions(question.questions || [])
    flatQuestions.push(...nestedQuestions)

    nestedQuestions.forEach((nestedQuestion) => {
      onlineAnswers[nestedQuestion.id] = nestedQuestion.answer
    })
  })

  return {
    fillEvaluationId: id,
    onlineAnswers,
    feedback,
  }
})

export const selectOfflineUploadProgress = createSelector(selectState, (state) => state.offlineUploadProgress)

export const selectQuestionsLength = createSelector(
  selectState,
  (state) => getFlatEvaluationQuestions(state?.questions)?.length,
)

export const selectQuestionsAllLength = createSelector(selectState, (state) =>
  getFlatEvaluationQuestions(state?.questions),
)

export const selectQuestionsText = createSelector(selectState, ({ questions }) => {
  return getFlatEvaluationQuestions(questions).map(({ question, id }) => ({
    id,
    question,
  }))
})

export const selectUpdatedAt = createSelector(selectState, (state) => state.updated_at)

export const selectAnswersData = createSelector(selectState, ({ questions }) => getAnswersCountInfo(questions))

export const selectQuestions = createSelector(selectState, ({ questions }) => questions)

export const selectEvaluationForComplete = createSelector(
  selectState,
  ({ id, title, completed_at, feedback, isLoading }) => {
    return {
      id,
      title,
      completed_at,
      feedback,
      isLoading,
    }
  },
)

export const selectEvaluationFinishInfo = createSelector(selectState, ({ id, title, questions, isLoading }) => {
  const answeredQuestions = questions?.length
    ? getFlatEvaluationQuestions(questions)
        .filter((i) => i.question && i.answer)
        .map(({ id, question, answer, type }) => ({
          id,
          question,
          answer,
          type,
        }))
    : []

  return {
    id,
    title,
    questions: answeredQuestions,
    isLoading,
  }
})

export const selectEvaluationSectionCompleteInfo = (sectionId: number) =>
  createSelector(selectState, ({ sectionCompletions }) => {
    return sectionCompletions && sectionCompletions[sectionId]
  })

export const selectEvaluationCompletionsInfo = createSelector(selectState, ({ evaluationCompletions }) => {
  return evaluationCompletions
})
