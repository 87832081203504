import { createAsyncThunk } from '@reduxjs/toolkit'

import { questionSetsV4Api } from 'apis/question-sets.apis'
import { routes } from 'constants/routes'
import { QuestionSetRenamePayload, QuestionSetsItem } from 'interfaces'
import history from 'services/history'

import { setErrorNotify, setSuccessNotify } from '../notifications/action'

export const QUESTION_SETS_SLICE_NAME = 'question-sets'

export const getActiveQuestionSets = createAsyncThunk(
  `${QUESTION_SETS_SLICE_NAME}/getActiveQuestionSets`,
  async (_, { rejectWithValue }) => {
    try {
      // const response = await httpApiClient.get<
      //   QuestionSetsItem[]
      // >('/questionSets');
      //
      //

      return [
        {
          id: 1,
          name: 'Test',
          version: 'V1.0',
          category: 'Inspection',
          created: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          updated: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          author: 'John Harris',
          active: true,
        },
        {
          id: 2,
          name: 'Test1',
          version: 'V1.0',
          category: 'Inspection',
          created: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          updated: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          author: 'John Harris',
          active: true,
        },
        {
          id: 3,
          name: 'Test2',
          version: 'V1.0',
          category: 'Inspection',
          created: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          updated: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          author: 'John Harris',
          active: true,
        },
        {
          id: 4,
          name: 'Test3',
          version: 'V1.0',
          category: 'Inspection',
          created: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          updated: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          author: 'John Harris',
          active: true,
        },
      ]
      // eslint-disable-next-line no-unreachable
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getArchiveQuestionSets = createAsyncThunk(
  `${QUESTION_SETS_SLICE_NAME}/getArchiveQuestionSets`,
  async (_, { rejectWithValue }) => {
    try {
      // const response = await httpApiClient.get<
      //   QuestionSetsItem[]
      // >('/questionSets/archive');
      //
      //

      return [
        {
          id: 6564,
          name: 'Josh TestArch',
          version: 'V1.0',
          category: 'Inspection',
          created: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          updated: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          author: 'John Harris',
          active: true,
        },
        {
          id: 456235,
          name: 'Test1',
          version: 'V1.0',
          category: 'Inspection',
          created: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          updated: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          author: 'John Harris',
          active: true,
        },
        {
          id: 745,
          name: 'Test665',
          version: 'V1.0',
          category: 'Inspection',
          created: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          updated: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          author: 'John Harris',
          active: true,
        },
        {
          id: 47567,
          name: 'Test4',
          version: 'V1.0',
          category: 'Inspection',
          created: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          updated: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          author: 'John Harris',
          active: false,
        },
        {
          id: 54554,
          name: 'Test10',
          version: 'V1.0',
          category: 'Inspection',
          created: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          updated: 'Mon Apr 18 2022 21:41:25 GMT+0300 (Восточная Европа, летнее время)',
          author: 'John Harris',
          active: false,
        },
      ]
      // eslint-disable-next-line no-unreachable
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const addQuestionSetsItem = createAsyncThunk(
  `${QUESTION_SETS_SLICE_NAME}/addQuestionSets`,
  async ({ data }: { data: QuestionSetsItem }, { rejectWithValue, dispatch }) => {
    try {
      // const response = await httpApiClient.get<
      //   QuestionSetsItem[]
      // >('/questionSets/createQuestion');
      //
      //
      dispatch(setSuccessNotify('Question set was successfully created'))
      return data
    } catch (e) {
      dispatch(setErrorNotify("Question set wasn't created"))
      return rejectWithValue(e)
    }
  },
)

export const moveQuestionSetToArchive = createAsyncThunk(
  `${QUESTION_SETS_SLICE_NAME}/moveQuestionSetToArchive`,
  async ({ id }: { id: number }, { rejectWithValue, dispatch }) => {
    try {
      // const response = await httpApiClient.get<
      //   QuestionSetsItem[]
      // >('/questionSets/createQuestion');
      //
      //

      const response = await new Promise((res) => setTimeout(() => res(id), 1000))

      dispatch(setSuccessNotify('Question set was successfully archived'))

      return response as number
    } catch (e) {
      dispatch(setErrorNotify("Question set wasn't archived"))
      return rejectWithValue(e)
    }
  },
)

export const deleteQuestionSetsItem = createAsyncThunk(
  `${QUESTION_SETS_SLICE_NAME}/deleteQuestionSetsItem`,
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      // const response = await httpApiClient.get<
      //   QuestionSetsItem[]
      // >('/questionSets/createQuestion');
      //
      //

      const response = await new Promise((res) => setTimeout(() => res(id), 1000))

      dispatch(setSuccessNotify('Question set was successfully deleted'))

      return response as number
    } catch (e) {
      dispatch(setErrorNotify("Question set wasn't deleted"))
      return rejectWithValue(e)
    }
  },
)

export const unArchiveQuestionSetsItem = createAsyncThunk(
  `${QUESTION_SETS_SLICE_NAME}/unArchiveQuestionSetsItem`,
  async ({ id }: { id: number }, { rejectWithValue, dispatch }) => {
    try {
      // const response = await httpApiClient.get<
      //   QuestionSetsItem[]
      // >('/questionSets/createQuestion');
      //
      //

      const response = await new Promise((res) => setTimeout(() => res(id), 1000))

      dispatch(setSuccessNotify('Question set was successfully unArchived'))
      return response as number
    } catch (e) {
      dispatch(setErrorNotify("Question set wasn't unArchived"))
      return rejectWithValue(e)
    }
  },
)

export const editQuestionSetsItem = createAsyncThunk(
  `${QUESTION_SETS_SLICE_NAME}/editQuestionSetsItem`,
  async (data: QuestionSetsItem, { rejectWithValue, dispatch }) => {
    try {
      // const response = await httpApiClient.get<
      //   QuestionSetsItem[]
      // >('/questionSets/createQuestion');
      //
      //
      dispatch(setSuccessNotify('Question set was successfully edited'))
      history.push(routes.questionSets)
      return data
    } catch (e) {
      dispatch(setErrorNotify("Question set wasn't edited"))
      return rejectWithValue(e)
    }
  },
)

export const renameQuestionSetItem = createAsyncThunk(
  `${QUESTION_SETS_SLICE_NAME}/renameQuestionSetItem`,
  async (data: QuestionSetRenamePayload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setSuccessNotify('Question set was successfully renamed'))
      return data
    } catch (e) {
      dispatch(setErrorNotify("Question set wasn't renamed"))
      return rejectWithValue(e)
    }
  },
)

export const getQuestionSetsList = createAsyncThunk(
  `${QUESTION_SETS_SLICE_NAME}/getQuestionSetsList`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await questionSetsV4Api.getQuestionSetsList()

      return response.data.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)
