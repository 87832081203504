import React, { FC, useEffect, useMemo, useRef } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { endOfMonth, startOfMonth, subMonths, subYears } from 'date-fns'
import subDays from 'date-fns/subDays'
import { FormikHelpers } from 'formik'
import RangePicker, { EventHandler } from 'react-bootstrap-daterangepicker'

import { DATE_FORMAT_WITH_SLASHES } from 'constants/dates'
import { Label } from 'shared-components/input/styles'

import { DateRangePickerIcon, DateRangePickerInputContainer, DateRangePickerWrapper } from './style'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-daterangepicker/daterangepicker.css'

interface Props {
  name: string
  value: string
  setFieldValue: FormikHelpers<string>['setFieldValue']
  label?: string
}

const DateRangePicker: FC<Props> = ({ name, value = ' - ', label, setFieldValue }) => {
  const ref = useRef<RangePicker>(null)

  const ranges = useMemo(() => {
    const currentDate = new Date()

    const currentDay = currentDate.getDay()

    const currentMonth = startOfMonth(currentDate)

    const yesterdayDate = subDays(currentDate, 1)

    const previousMonth = subMonths(currentDate, 1)

    return {
      Today: [currentDate, currentDate],
      Yesterday: [subDays(currentDate, 1), yesterdayDate],
      'Current week': [subDays(currentDate, currentDay - 1), yesterdayDate],
      'Last 7 days': [subDays(currentDate, 7), yesterdayDate],
      'Previous week': [subDays(currentDate, currentDay + 6), subDays(currentDate, currentDay)],
      'Current month': [currentMonth, yesterdayDate],
      'Last 30 days': [subDays(currentDate, 30), yesterdayDate],
      'Previous month': [startOfMonth(previousMonth), endOfMonth(previousMonth)],
      'Last 6 months': [subMonths(yesterdayDate, 6), yesterdayDate],
      'Last year': [subYears(yesterdayDate, 1), yesterdayDate],
    }
  }, [])

  const handleApply: EventHandler = (event, picker) => {
    const newDate = `${picker.startDate.format(DATE_FORMAT_WITH_SLASHES)} | ${picker.endDate.format(
      DATE_FORMAT_WITH_SLASHES,
    )}`

    setFieldValue(name, newDate)
    picker.element.val(newDate)
  }

  useEffect(() => {
    if (!value && ref?.current?.ref?.value) {
      ref.current.ref.value = ''
    }
  }, [value])

  return (
    <DateRangePickerWrapper>
      {label && <Label>{label}</Label>}
      <DateRangePickerInputContainer>
        <DateRangePickerIcon>
          <FontAwesomeIcon icon={faCalendar as IconDefinition} />
        </DateRangePickerIcon>

        <RangePicker
          ref={ref}
          onApply={handleApply}
          initialSettings={{
            ranges,
            opens: 'center',
            alwaysShowCalendars: true,
            autoApply: true,
            autoUpdateInput: false,
            startDate: value?.split('|')[0] || new Date(),
            endDate: value?.split('|')[1] || new Date(),
            locale: { format: 'DD/MM/YYYY' },
          }}
        >
          <input name={name} readOnly type='text' placeholder='Select' />
        </RangePicker>
      </DateRangePickerInputContainer>
    </DateRangePickerWrapper>
  )
}

export default DateRangePicker
