import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { StylesConfig } from 'react-select'

import { ActionButtonsItem, MultiSelectOption } from 'interfaces'

import { Colors } from './global'

export const DEFAULT_ACTION_BUTTONS: ActionButtonsItem[] = [
  {
    name: 'edit',
    icon: faEdit as IconDefinition,
    backgroundColor: Colors.BLUE,
  },
  {
    name: 'delete',
    icon: faTrash as IconDefinition,
    backgroundColor: Colors.RED,
  },
]

export const multiSelectStyles: StylesConfig<MultiSelectOption, true> = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    outline: 'none',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? undefined : isSelected ? '#dee2e6' : isFocused ? '#0074f0' : undefined,
      color: isFocused ? '#fff' : '#212529',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled ? (isSelected ? data.color : '#00b7ff') : undefined,
      },
    }
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#007bff',
    }
  },
  multiValueLabel: (styles) => ({
    ...styles,
    fontSize: '16px',
    color: '#fff',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#ffffffb3',
    ':hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
}

export const DRAG_LIST_DEFAULT_ACTION_BUTTONS: ActionButtonsItem[] = [
  {
    name: 'edit',
    icon: faEdit as IconDefinition,
    backgroundColor: Colors.GREEN,
  },
  {
    name: 'delete',
    icon: faTrash as IconDefinition,
    backgroundColor: Colors.RED,
  },
]

export const BASE_PAGINATION_DATA = {
  current_page: 0,
  per_page: 1,
  total: 0,
}

export const DEFAULT_UPLOAD_ALLOWED_EXTENSIONS = [
  '.png',
  '.jpg',
  '.jpeg',
  '.gif',
  '.zip',
  '.rar',
  '.7z',
  '.xls',
  '.xlsx',
  '.csv',
  '.doc',
  '.docx',
  '.pdf',
  '.txt',
  '.mp3',
  '.ogg',
  '.wav',
]

export enum ALIGN {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  FLEX_START = 'flex-start',
  FLEX_END = 'flex-end',
  SPACE_BETWEEN = 'space-between',
  SPACE_AROUND = 'space-around',
}
