import styled from 'styled-components'

export const SelectV3Wrapper = styled.div<{
  error?: string
  disabledEffects?: boolean
}>`
  .menuListStyles {
    width: 100%;
    overflow-y: visible !important;
    display: inline-grid;
    white-space: nowrap;
    position: relative;
    right: 110px;
    padding: 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 110px);
      height: 100%;
      background: #000;
      opacity: 0;
      transition: 0.3s all ease;
      pointer-events: none;
    }

    &:hover {
      &:before {
        opacity: ${({ disabledEffects }) => (disabledEffects ? 0 : 0.1)};
      }
    }
  }

  .menuStyles {
    box-shadow: none;
    background-color: transparent;
  }

  .optionStyles {
    background-color: ${({ theme }) => theme.colors.error};
    border-radius: 4px;
    &:active {
      background-color: ${({ theme }) => theme.colors.error};
    }
  }

  position: relative;

  & span {
    display: none;
  }

  & > div > div {
    border: 1px solid ${({ theme, error }) => (error ? theme.colors.error : 'auto')};
    width: 100%;
  }
`
