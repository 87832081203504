import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { format } from 'date-fns'
import { isEqual, reduce } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { DEFAULT_DATE_FORMAT } from 'constants/global'
import { NetworkStatusContext } from 'contexts/NetworkContext'
import {
  fetchDeletedNotes,
  fetchFilesToDeleteById,
  fetchFilesToDeleteCount,
  fetchNewFilesCount,
  fetchNewNotesFilesById,
} from 'db/fetch-db'
import { syncOnlineToOffline } from 'db/helpers'
import { db } from 'db/mainDb'
import {
  AnsweredCountPropsInterface,
  ContinueFuncType,
  MyDeletedTaskPayload,
  MyTaskPayload,
  AdditionalButton,
  KeyWithString,
  NumberOrNullType,
} from 'interfaces'
import { asyncGetOfflineEvaluation } from 'modules/evaluations/action'
import {
  asyncGetMyFillEvaluations,
  offlineUploadEvaluationData,
  setOfflineUploadProgress,
} from 'modules/fill-evaluation/action'
import {
  selectIsModalLoading,
  selectOfflineSyncData,
  selectOfflineUploadProgress,
} from 'modules/fill-evaluation/selectors'
import { useAppDispatch, useAppSelector } from 'modules/store'
import { getOfflineSyncData, getFillEvaluationLink } from 'utils'

interface OfflineState {
  isOpen: boolean
  called: boolean
  id: NumberOrNullType
}

const useOfflineSync = (): {
  additionalButtons: AdditionalButton[]
  onContinue: ContinueFuncType
  handleCloseSyncModal: VoidFunction
  isSyncModalOpen: boolean
  answers: {
    offlineAnswers: KeyWithString
    onlineAnswers: KeyWithString
    differentAnswers: string[]
  }
  answeredCountProps: AnsweredCountPropsInterface
} => {
  const isOnline = useContext(NetworkStatusContext)

  const [offlineState, setOfflineState] = useState<OfflineState>({
    isOpen: false,
    called: false,
    id: null,
  })

  const [differentAnswers, setDifferentAnswers] = useState<string[]>([])

  const [offlineAnswers, setOfflineAnswers] = useState<
    | {
        answerList: string
        id: number
      }
    | null
    | undefined
  >(null)

  const [isTasksOrNotes, setIsTasksOrNotes] = useState<boolean | null>(null)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { fillEvaluationId, onlineAnswers, feedback: onlineFeedback } = useAppSelector(selectOfflineSyncData)

  const isModalLoading = useAppSelector(selectIsModalLoading)

  const progressValue = useAppSelector(selectOfflineUploadProgress)

  const handleOnlineSync = useCallback(async () => {
    if (offlineState?.id) {
      const { deletedNotes, newFiles, filesToDelete, feedback, ...evaluationData } = await getOfflineSyncData(
        offlineState.id,
      )

      const allTasks = evaluationData?.tasks?.reduce(
        (acc, { id, task: { complete_before, assign_id, ...fullTask }, ...curr }) => {
          return {
            ...acc,
            [curr.questionId]: [
              ...(acc[curr.questionId] || []),
              {
                ...fullTask,
                ...(id ? { id } : {}),
                completionDue: complete_before ? format(new Date(complete_before), DEFAULT_DATE_FORMAT) : null,
                assignedTo: assign_id,
              },
            ],
          }
        },
        {} as {
          [key: string]: MyTaskPayload[]
        },
      )

      const deletedTasks = evaluationData?.deletedTasks?.reduce(
        (acc, { id, questionId }) => {
          if (Number(id) === id && id % 1 !== 0) return acc
          return {
            ...acc,
            [questionId]: [...(acc[questionId] || []), { id }],
          }
        },
        {} as {
          [key: string]: MyDeletedTaskPayload[]
        },
      )

      const notes = evaluationData?.notes?.reduce((acc, curr) => {
        if (!curr.content) return { ...acc }
        return {
          ...acc,
          [curr.id]: {
            content: curr.content,
          },
        }
      }, {})

      dispatch(
        offlineUploadEvaluationData({
          tasks: allTasks,
          notes,
          deletedTasks,
          answers: offlineAnswers?.answerList,
          deletedNotes: deletedNotes,
          newFiles,
          filesToDelete,
          evaluationId: offlineState.id,
          feedback: feedback,
          shouldBeCompleted: evaluationData?.shouldBeCompleted,
        }),
      )
    }
  }, [dispatch, offlineAnswers?.answerList, offlineState.id])

  const actionButtons = useMemo(() => {
    return [
      {
        text: 'Online',
        action: async () => {
          if (offlineState?.id) {
            dispatch(setOfflineUploadProgress(0))
            dispatch(asyncGetOfflineEvaluation(offlineState.id))
            await syncOnlineToOffline(offlineState.id)
            dispatch(setOfflineUploadProgress(100))
          }
        },
        disabled: isModalLoading,
      },
      {
        text: 'Offline',
        action: handleOnlineSync,
        disabled: isModalLoading,
      },
    ]
  }, [dispatch, handleOnlineSync, isModalLoading, offlineState.id])

  const parsedOfflineAnswers = useMemo(() => {
    return offlineAnswers?.answerList && JSON.parse(offlineAnswers.answerList)
  }, [offlineAnswers?.answerList])

  const onlineAnsweredCount = useMemo(() => {
    return Object.values(onlineAnswers).filter((i) => i !== null)?.length
  }, [onlineAnswers])

  const offlineAnsweredCount = useMemo(() => {
    return parsedOfflineAnswers ? Object.values(parsedOfflineAnswers).filter((i) => i !== 'null')?.length : null
  }, [parsedOfflineAnswers])

  const handleCloseSyncModal = useCallback(() => {
    setOfflineState((prev) => ({
      ...prev,
      isOpen: false,
      called: false,
    }))
  }, [])

  const handleOpenSyncModal = useCallback(() => {
    setOfflineState((prev) => ({
      ...prev,
      isOpen: true,
      called: true,
    }))
  }, [])

  const handleContinue: ContinueFuncType = useCallback(
    async ({ id, shouldSync }) => {
      if (id !== fillEvaluationId && isOnline) {
        dispatch(asyncGetMyFillEvaluations(id))
          .unwrap()
          .then(() => {
            if (!shouldSync) navigate(getFillEvaluationLink(id))
          })
      }

      if (!isOnline) navigate(getFillEvaluationLink(id))

      const answers = await db.answers.get({ id })

      const { tasks, deletedTasks } = await getOfflineSyncData(id)

      setOfflineAnswers(answers)
      setIsTasksOrNotes(Boolean(tasks?.length || deletedTasks?.length))
      setOfflineState((prev) => ({
        ...prev,
        called: true,
        id,
      }))
    },
    // eslint-disable-next-line
    [dispatch, fillEvaluationId, isOnline],
  )

  useEffect(() => {
    if (Object.keys(onlineAnswers)?.length > 0 && offlineState.id && offlineAnswers !== null) {
      const differentAnswersList = parsedOfflineAnswers
        ? reduce(
            onlineAnswers,
            function (result, value, key) {
              return isEqual(value, parsedOfflineAnswers[key]) || !(value || parsedOfflineAnswers[key])
                ? result
                : result.concat(key as any)
            },
            [],
          )
        : null

      db.fullList.get({ id: Number(fillEvaluationId) }).then(async (offlineEvaluation) => {
        const numberedEvaluationId = Number(fillEvaluationId)

        const { feedback } = offlineEvaluation || {}

        const deletedEvaluationNotes = await fetchDeletedNotes(numberedEvaluationId)

        const filesToDeleteCount = await fetchFilesToDeleteCount(numberedEvaluationId)
        const newFilesCount = await fetchNewFilesCount(numberedEvaluationId)

        if (
          !differentAnswersList?.length &&
          !isTasksOrNotes &&
          (!feedback || feedback === onlineFeedback) &&
          !deletedEvaluationNotes?.length &&
          !filesToDeleteCount &&
          !newFilesCount
        ) {
          navigate(getFillEvaluationLink(offlineState?.id as number))
        } else if (
          (differentAnswersList?.length ||
            isTasksOrNotes ||
            feedback !== onlineFeedback ||
            deletedEvaluationNotes?.length ||
            filesToDeleteCount ||
            newFilesCount) &&
          offlineState.called
        ) {
          setDifferentAnswers(differentAnswersList || [])
          handleOpenSyncModal()
        }
      })
    }
    // eslint-disable-next-line
  }, [fillEvaluationId, offlineState?.called])

  useEffect(() => {
    if (progressValue !== 100) return
    const timer = setTimeout(() => {
      navigate(getFillEvaluationLink(offlineState.id as number))
      dispatch(setOfflineUploadProgress(null))
    }, 2000)

    return () => clearTimeout(timer)
    // eslint-disable-next-line
  }, [progressValue])

  const answersProps: any = {
    offlineAnswers: parsedOfflineAnswers,
    onlineAnswers,
    differentAnswers,
  }

  return {
    isSyncModalOpen: offlineState.isOpen,
    additionalButtons: actionButtons,
    handleCloseSyncModal,
    onContinue: handleContinue,
    answers: answersProps,
    answeredCountProps: {
      onlineAnsweredCount,
      offlineAnsweredCount,
    },
  }
}

export default useOfflineSync
