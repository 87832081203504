import React, { FC, ReactNode } from 'react'

import { HomeLayoutHeader, HomeLayoutWrapper } from './style'

interface Props {
  title?: string
  children: ReactNode
}

const HomeContentLayout: FC<Props> = ({ title, children }) => {
  return (
    <HomeLayoutWrapper>
      <HomeLayoutHeader>{title && <h2>{title}</h2>}</HomeLayoutHeader>
      <main>{children}</main>
    </HomeLayoutWrapper>
  )
}

export default HomeContentLayout
