import styled from 'styled-components'

import { SkeletonBlock } from 'shared-components/shimmer/style'

export const Top = styled.div`
  padding-bottom: 16px;
`

export const TasksFormWrapper = styled.div`
  & select,
  & input {
    margin-bottom: 16px;
  }

  .button {
    background-color: ${({ theme }) => theme.colors.green2} !important;
    border-color: #17a2b8;
    box-shadow: none;
    color: #fff;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .buttonDiv {
    display: flex;
    place-content: center;
  }

  .label {
    padding: 0;
    text-align: left;
    margin-bottom: 1.5rem;
  }
`

export const TasksSkeletonFlex = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TasksSkeletonItem = styled(SkeletonBlock)`
  width: 100%;
  height: 30px;
`

export const FormFieldItem = styled.div`
  & > div {
    margin-bottom: 16px;
  }

  & input {
    margin-bottom: 0;
  }

  & > div span {
    bottom: -17px;
  }
`
