/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { FC, SelectHTMLAttributes, useMemo } from 'react'

import { FormikHelpers } from 'formik'
import Select, { components } from 'react-select'

import { multiSelectStyles } from 'constants/shared'
import { Option, MultiSelectOption } from 'interfaces'
import { Error } from 'shared-components/input/styles'

import { SelectV3Wrapper } from './style'

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string
  options: Option[]
  label?: string
  error?: string
  isMulti?: boolean
  setFieldValue: FormikHelpers<string | string[]>['setFieldValue']
}

const SelectV3: FC<Props> = ({ options = [], label, setFieldValue, isMulti = false, error, name, value }) => {
  const correctOptions = useMemo(() => {
    return options.map(({ name, ...option }) => ({
      ...option,
      label: name,
    }))
  }, [options])

  const formattedValue = Array.isArray(value) ? value : correctOptions.find((i) => i.value === value)

  const handleChange = (value: any) => {
    if (Array.isArray(value)) setFieldValue(name, value)
    else setFieldValue(name, value?.value || '')
  }

  return (
    <SelectV3Wrapper error={error}>
      <Select
        value={formattedValue}
        isMulti={isMulti || undefined}
        options={correctOptions as MultiSelectOption[]}
        styles={multiSelectStyles}
        onChange={handleChange}
        placeholder=''
        isSearchable={false}
        components={{
          SingleValue: () => null,
          ValueContainer: () => null,
          MenuList: (props) => <components.MenuList {...props} className='menuListStyles' />,
          Menu: (props) => <components.Menu {...props} className='menuStyles' />,
          Option: (props) => <components.Option {...props} className='optionStyles' />,
        }}
      />
      {error && <Error>{error}</Error>}
    </SelectV3Wrapper>
  )
}

export default SelectV3
