import React, { FC, useEffect } from 'react'

import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkmark-1.svg'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'

import { PasswordCheck } from './PasswordCheckWidget'
import { PasswordCheckItemWrapper } from './style'

interface Props extends Omit<PasswordCheck, 'pattern'> {
  filled: boolean
}

const PasswordCheckItem: FC<Props> = ({ id, text, boldText, filled }) => {
  const certainIcon = filled ? <CheckMarkIcon /> : <CrossIcon />

  return (
    <PasswordCheckItemWrapper filled={filled}>
      <div>{certainIcon}</div>
      <p>
        {text} <strong>{boldText}</strong>
      </p>
    </PasswordCheckItemWrapper>
  )
}

export default PasswordCheckItem
