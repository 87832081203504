import styled from 'styled-components'

export const PictureRadioWrapper = styled.label<{
  checked?: boolean
}>`
  display: block;
  position: relative;
  padding: 15px;
  cursor: pointer;
  user-select: none;
  color: #000;
  line-height: 24px;
  text-align: center;
  text-indent: 16px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  background: ${({ theme }) => theme.colors.gray1};
  border-radius: 15px !important;
  flex: 0 0 32.333% !important;
  max-width: 32.333%;
  margin: 10px 0;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  & > div {
    display: flex;
  }

  & span {
    position: relative;
    display: block;
    min-width: 20px;
    height: 20px;
    background-color: ${({ theme }) => `${theme.colors.gray2}`};
    border-radius: 50%;
    margin-bottom: 10px;

    & svg {
      display: ${({ checked }) => (checked ? 'block' : 'none')};
      position: absolute;
      top: calc(50% - 6px);
      left: calc(50% - 6px);
      fill: #fff;
    }
  }
`
