import styled from 'styled-components'

export const AreasPageWrapper = styled.div`
  padding: ${({ theme }) => theme.spaces.min3} 8px;
  display: flex;
  justify-content: space-between;

  & > div {
    flex: 0 1 49%;
    &:first-child {
      align-self: baseline;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;

    & > div {
      flex: 0 1 100%;
      &:first-child {
        align-self: auto;
      }

      margin-bottom: ${({ theme }) => theme.spaces.min3};
    }
  }
`

export const AreasContentWrapper = styled.div`
  align-self: baseline;
`
