import { createSlice } from '@reduxjs/toolkit'

import { INITIAL_USER_INFO } from 'constants/auth'
import { LoginResponse, UserInfoInterface } from 'interfaces'

import { USER_SLICE_NAME } from './action'
import {
  userInfoReducer,
  loginReducer,
  logoutReducer,
  isAuthReducer,
  updateUserReducer,
  resendVerifyCodeReducer,
  postVerifyCodeReducer,
  resetPasswordReducer,
  setUserPinCodeReducer,
  resetErrorReducer,
} from './reducers'

export interface UserState extends Partial<LoginResponse> {
  errors?: {
    [key: string]: string[]
  } | null
  error?: string
  isAuth: boolean | null
  isLoaded: boolean
  isLoading: boolean
  isUserLoading: boolean
  isResended: boolean
  user: UserInfoInterface
  userPinCode?: string
}

export const initialState: UserState = {
  error: '',
  errors: null,
  isAuth: null,
  isLoaded: false,
  isLoading: false,
  isUserLoading: false,
  isResended: false,
  user: INITIAL_USER_INFO,
  userPinCode: '',
}

export const userSlice = createSlice({
  name: USER_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    loginReducer(builder)
    userInfoReducer(builder)
    logoutReducer(builder)
    isAuthReducer(builder)
    updateUserReducer(builder)
    resendVerifyCodeReducer(builder)
    postVerifyCodeReducer(builder)
    resetPasswordReducer(builder)
    setUserPinCodeReducer(builder)
    resetErrorReducer(builder)
  },
})

export default userSlice.reducer
