import styled from 'styled-components'

export const UserDropdownWrapper = styled.div`
  position: relative;
`

export const HeaderUserButton = styled.button`
  display: flex;
  align-items: center;

  padding: 8px ${({ theme }) => theme.spaces.min3};
  line-height: 24px;
  cursor: pointer;

  border: none;
  background: none;

  font-size: 16px;

  & svg,
  & span {
    color: ${({ theme }) => theme.colors.gray9};
  }

  & span {
    margin-left: 4px;
  }

  &:hover {
    & span,
    & svg {
      color: rgba(0, 0, 0, 0.7);
    }
  }

  @media (max-width: 767px) {
    & span {
      display: none;
    }
  }
`

export const PersonalAction = styled.div`
  padding: 10px;

  display: block;
  justify-content: space-between;
  align-items: center;

  & > a {
    text-decoration: none;
  }

  & > a,
  & > button {
    padding: ${({ theme }) => `${theme.spaces.min1} ${theme.spaces.min2}`};
    background: ${({ theme }) => theme.colors.white3};
    border: 1px solid rgb(221, 221, 221);
    border-radius: 0;

    font-size: 16px;
    line-height: 24px;
    color: #6c757d;
    cursor: pointer;

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
      margin-right: 4px;
    }
  }
`
