import styled from 'styled-components'

export const SelectV2Wrapper = styled.div<{
  error?: string
}>`
  position: relative;
  & span {
    display: none;
  }

  & > div > div {
    border: 1px solid ${({ theme, error }) => (error ? theme.colors.error : 'auto')};
  }
`
