import React, { FC, InputHTMLAttributes, useMemo } from 'react'

import { ReactComponent as CheckMark } from 'assets/icons/checkmark.svg'
import { CustomFileRetrieve } from 'interfaces'

import { PictureRadioWrapper } from './style'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  currentValue?: string
  file: CustomFileRetrieve
  label?: string
  isOnline?: boolean
}

const PictureRadio: FC<Props> = ({ currentValue, value, file, label, isOnline, ...props }) => {
  const isChecked = currentValue === value

  const fileUrl = useMemo(() => {
    if (isOnline) return `${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/${file.file}`

    return file.file
  }, [file, isOnline])

  if (!value) return null

  return (
    <PictureRadioWrapper checked={isChecked}>
      <input type='radio' {...props} value={value || ''} checked={isChecked} />
      <div>
        <span>
          <CheckMark />
        </span>
        <p>{label}</p>
      </div>
      <img style={{ maxHeight: '150px', maxWidth: '100%', width: 'auto' }} src={fileUrl} alt={label} />
    </PictureRadioWrapper>
  )
}

export default PictureRadio
