import React, { FC, HTMLProps, useEffect, useState } from 'react'

import { FormikHelpers } from 'formik'

import { QuestionType } from 'interfaces'

import { RadioV2Wrapper } from './style'

interface Props extends HTMLProps<HTMLInputElement> {
  isDefault: boolean
  label?: string
  isNumber?: boolean
  setField?: FormikHelpers<string>['setFieldValue']
  onSaveAnswer?: (e: any, value: string | number) => void
  type?: QuestionType
}

const RadioV2: FC<Props> = ({ isDefault, label, isNumber, setField, onSaveAnswer, type, ...props }) => {
  const { name, value: initialValue } = props

  const [value, setValue] = useState(initialValue || '')

  const isConditionalReverse = type === QuestionType.CONDITIONAL_REVERSE

  const handleChangeNumberValue = (e: any) => {
    const newValue = e.target.value
    setValue(newValue)

    if (setField && onSaveAnswer && name) {
      const numberValue = isConditionalReverse ? Number(e.target.value === '1') : Number(e.target.value === '0')

      setField(name, numberValue)
      onSaveAnswer(e, String(numberValue))
    }
  }

  const [defaultRedButtonStyle, setDefaultRedButtonStyle] = useState({ backgroundColor: '', color: '' })
  const [defaultGreenButtonStyle, setDefaultGreenButtonStyle] = useState({ backgroundColor: '', color: '' })

  const changeGreenStyle = () => {
    setDefaultGreenButtonStyle({ backgroundColor: '#198754', color: 'white' })
    setDefaultRedButtonStyle({ backgroundColor: 'white', color: '#dc3545' })
  }

  const changeRedStyle = () => {
    setDefaultRedButtonStyle({ backgroundColor: '#dc3545', color: 'white' })
    setDefaultGreenButtonStyle({ backgroundColor: 'white', color: '#198754' })
  }

  useEffect(() => {
    const initializeValue = () => {
      setValue(props.value?.toString() || '')
    }

    initializeValue()
  })

  return (
    <RadioV2Wrapper value={value?.toString() || ''}>
      <div className='button-group'>
        <label className={`button checked-green`} style={defaultGreenButtonStyle} onClick={changeGreenStyle}>
          <input
            type='radio'
            name='answer'
            onChange={isNumber ? handleChangeNumberValue : props.onChange}
            value={isConditionalReverse ? '1' : '0'}
          />
          {isConditionalReverse ? 'NO' : 'YES'}
        </label>

        <label className={`button checked-red`} style={defaultRedButtonStyle} onClick={changeRedStyle}>
          <input
            type='radio'
            name='answer'
            onChange={isNumber ? handleChangeNumberValue : props.onChange}
            value={isConditionalReverse ? '0' : '1'}
          />
          {isConditionalReverse ? 'YES' : 'NO'}
        </label>
      </div>
    </RadioV2Wrapper>
  )
}

export default RadioV2
