import React, { FC } from 'react'

import { BarContainer, Filler, FillerBackground, Text, Wrapper } from './style'

interface Props {
  valuePercentage: number
  hideCompletedText?: boolean
}

const ProgressBar: FC<Props> = ({ valuePercentage, hideCompletedText }) => {
  return (
    <Wrapper role='progressbar' aria-valuemin={0} aria-valuemax={100} aria-valuenow={valuePercentage}>
      <BarContainer className='barContainer'>
        <Filler className='filler' style={{ width: `${valuePercentage}%` }}>
          <FillerBackground className='fillerBackground'>{valuePercentage}%</FillerBackground>
        </Filler>
      </BarContainer>
      {!hideCompletedText && <Text className='textValue'>Completed: {`${valuePercentage}%`}</Text>}
    </Wrapper>
  )
}

export default ProgressBar
