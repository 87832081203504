import { NotesFile, UploadFile } from 'interfaces'

import { db } from './mainDb'

export const addNotesFiles = async (files: NotesFile[]) => {
  await db.notesFiles.bulkPut(files)
}

export const addNotesFileById = async (id: number) => {
  await db.notesFiles.where('id').equals(id).delete()
}

export const saveNotesFiles = async (files: UploadFile[]) => {
  if (!files?.length) return

  await db.files.bulkPut(files)
}

export const addNotesFileToDelete = async (file?: UploadFile) => {
  if (!file?.id) return
  await db.filesToDelete.bulkPut([file])
  await db.files.where('id').equals(file?.id).delete()
}

export const clearNotesFilesToDelete = async (evaluationId: number) => {
  return db.filesToDelete.where('evaluation_id').equals(evaluationId).delete()
}

export const clearNewFiles = async (evaluationId: number) => {
  return db.notesFiles.where('evaluationId').equals(evaluationId).delete()
}
