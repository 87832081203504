import React from 'react'

import { SkeletonContainer, SkeletonItem, SkeletonTitle } from './style'

const Skeleton = () => {
  return (
    <SkeletonContainer>
      <SkeletonTitle />
      <SkeletonItem />
    </SkeletonContainer>
  )
}

export default Skeleton
