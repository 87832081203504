import React, { FC } from 'react'

import { DEFAULT_ACTION_BUTTONS } from 'constants/shared'
import { KeyWithAnyValue, ActionButtonsItem, NumberStringBoolean } from 'interfaces'
import { Button } from 'shared-components'

import { Actions } from './style'

interface Props {
  id: number
  customActionButtons?: ActionButtonsItem[]
  actionFunctions: KeyWithAnyValue
  additionalValue?: NumberStringBoolean | null
}

const ActionButtons: FC<Props> = ({ id, additionalValue, customActionButtons, actionFunctions }) => {
  return (
    <Actions>
      {(customActionButtons?.length ? customActionButtons : DEFAULT_ACTION_BUTTONS).map((item) => (
        //eslint-disable-next-line
        <Button key={item.name} {...item} onClick={() => (actionFunctions as any)[item.name](id, additionalValue)} />
      ))}
    </Actions>
  )
}

export default ActionButtons
