import React, { FC, useMemo } from 'react'

import addDays from 'date-fns/addDays'
import { FormikHelpers } from 'formik'

import { MyTasksFieldNames, TASKS_FORM_HEADERS } from 'constants/evaluations'
import { FormikProps, FormField, KeyWithAnyValue, MyTask, FillEvaluationMyTasks } from 'interfaces'
import { selectTasksInfo } from 'modules/evaluation-actions-group/selectors'
import { useAppSelector } from 'modules/store'
import { Table } from 'shared-components'
import { createFormikPropsByName, createPriorityTaskObject } from 'utils'

import 'react-datepicker/dist/react-datepicker.css'
import TaskTableItem from '../task-table-item/TaskTableItem'
import TasksFormSkeleton from './Skeleton'
import { FormFieldItem, TasksFormWrapper, Top } from './style'

interface Props extends FormikProps {
  fieldsToRender: FormField[]
  setFieldValue: FormikHelpers<string>['setFieldValue']
  tasks: MyTask[]
  actionFunctions: KeyWithAnyValue
  isLoading: boolean
  offlineTaskInfo: FillEvaluationMyTasks
}

const TasksForm: FC<Props> = ({
  fieldsToRender,
  handleSubmit,
  setFieldValue,
  values,
  errors,
  tasks,
  onChange,
  actionFunctions,
  isLoading,
  offlineTaskInfo,
}) => {
  const precannedTasksField = fieldsToRender?.find(
    (field) => field.name === MyTasksFieldNames.PRECANNED_RECOMMENDATIONS,
  )
  const hasPrecannedTasks = precannedTasksField && precannedTasksField.options && precannedTasksField.options.length > 0

  const onlineTasksInfo = useAppSelector(selectTasksInfo)

  const tasksInfo = useMemo(
    () => (window.navigator.onLine ? onlineTasksInfo : offlineTaskInfo),
    [offlineTaskInfo, onlineTasksInfo],
  )

  const getTaskByPriority = (priority: string | number) => {
    const tasks = Object.values(tasksInfo.precannedTasks)

    if (!priority) {
      const firstTask = tasks[0]
      return createPriorityTaskObject(firstTask)
    }

    const foundTask = tasks.find((task) => task.priority === priority)

    return foundTask ? createPriorityTaskObject(foundTask) : undefined
  }

  const handlePrefillRecommendation = () => {
    const priority = values[MyTasksFieldNames.PRECANNED_RECOMMENDATIONS]
    const recommendation = getTaskByPriority(priority)
    const priorityDays = tasksInfo?.priorities?.find(({ id }) => id === Number(priority))?.days

    if (recommendation) {
      setFieldValue(MyTasksFieldNames.RECOMMENDATION, recommendation.recommendation)
      setFieldValue(MyTasksFieldNames.PRIORITY, recommendation.priority)
      setFieldValue(MyTasksFieldNames.COMPLETION_DUE, addDays(new Date(), priorityDays || 1))
    }
  }

  return (
    <TasksFormWrapper>
      <form onSubmit={handleSubmit}>
        {!isLoading ? (
          <Top>
            <Table headers={TASKS_FORM_HEADERS}>
              {tasks.map((item) => (
                <TaskTableItem {...item} key={item.id} actionFunctions={actionFunctions} />
              ))}
            </Table>
          </Top>
        ) : (
          <TasksFormSkeleton />
        )}
        {fieldsToRender.map(({ component, ...props }) => {
          const Component = component

          if (!Component || (props.name === MyTasksFieldNames.PRECANNED_RECOMMENDATIONS && !props.options?.length))
            return null

          if (props.name === MyTasksFieldNames.PREFILL_RECOMMENDATIONS) {
            if (!hasPrecannedTasks) return null

            return (
              <FormFieldItem className='buttonDiv' key={props.name}>
                <Component className='button' onClick={handlePrefillRecommendation}>
                  {props.label}
                </Component>
              </FormFieldItem>
            )
          }
          return (
            <FormFieldItem key={props.name}>
              <Component
                setFieldValue={setFieldValue}
                {...props}
                {...createFormikPropsByName(
                  {
                    values,
                    errors,
                    handleChange: onChange,
                  },
                  props.name,
                )}
              />
            </FormFieldItem>
          )
        })}
      </form>
    </TasksFormWrapper>
  )
}

export default TasksForm
