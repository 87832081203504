import styled from 'styled-components'

export const FlexCol = styled.div<{ count: number }>`
  display: flex;
  flex-direction: column;
  flex: 0 1 ${({ count }) => 100 / count - 2}%;
  @media (max-width: 768px) {
    flex: 0 1 ${({ count }) => 100 / (count - 1)}%;

    &:nth-child(3),  &:nth-child(4){
      display: none;
    }

    @media (max-width: 576px) {
      flex: 0 1 40%;

      &:nth-child(5),  &:nth-child(6){
        display: none;
      }
`
