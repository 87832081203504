import styled from 'styled-components'

import { getFontColorByHex } from 'utils'

export const RadioV1Wrapper = styled.label<{
  background?: string
  checked?: boolean
  isAdaptiveColor?: boolean
}>`
  display: block;
  position: relative;
  padding: ${({ theme }) => `${theme.spaces.min1} ${theme.spaces.min2}`};
  cursor: pointer;
  font-weight: 700;
  user-select: none;
  background-color: ${({ background, theme }) => background || theme.colors.green2};
  color: ${({ background, isAdaptiveColor }) => (isAdaptiveColor ? getFontColorByHex(background || '#000') : '#fff')};

  line-height: 24px;
  text-indent: 16px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  text-align: left;

  &:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  &:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.gray3};
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  & span {
    position: absolute;
    top: 10px;
    left: 9px;
    height: 16px;
    width: 16px;
    background-color: #ffffff;

    border-radius: 50%;
  }

  & svg {
    position: absolute;
    top: calc(50% - 6px);
    left: calc(50% - 5px);
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    color: ${({ checked, theme, isAdaptiveColor }) =>
      checked && !isAdaptiveColor
        ? theme.colors.green6
        : theme.colors.green2 || (checked && isAdaptiveColor)
        ? theme.colors.black1
        : theme.colors.white};
  }

  & input:checked ~ span:after {
    display: block;
  }

  & span:after {
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
`
