import React, { FC, SelectHTMLAttributes, useMemo } from 'react'

import { FormikHelpers } from 'formik'
import Select from 'react-select'

import { multiSelectStyles } from 'constants/shared'
import { Option, MultiSelectOption } from 'interfaces'
import { Label, Error, InputPreloader } from 'shared-components/input/styles'

import { SelectV2Wrapper } from './style'

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string
  options: Option[]
  label?: string
  error?: string
  isMulti?: boolean
  setFieldValue: FormikHelpers<string | string[]>['setFieldValue']
  shownOnlyWithOptions?: boolean
}

const DEFAULT_PLACEHOLDER_TEXT = 'Select an option'

const SelectV2: FC<Props> = ({
  options = [],
  label,
  setFieldValue,
  isMulti = false,
  error,
  name,
  value,
  shownOnlyWithOptions,
  placeholder,
}) => {
  const correctOptions = useMemo(() => {
    return options.map(({ name, ...option }) => ({
      ...option,
      label: name,
    }))
  }, [options])

  const formattedValue = Array.isArray(value) ? value : correctOptions.find((i) => String(i.value) === String(value))

  //eslint-disable-next-line
  const handleChange = (value: any) => {
    if (Array.isArray(value)) setFieldValue(name, value)
    else setFieldValue(name, value?.value || '')
  }

  return (
    <SelectV2Wrapper error={error}>
      {label && <Label>{label}</Label>}
      {shownOnlyWithOptions && !correctOptions?.length ? (
        <InputPreloader />
      ) : (
        <Select
          value={formattedValue || null}
          isMulti={isMulti || undefined}
          options={correctOptions as MultiSelectOption[]}
          styles={multiSelectStyles}
          onChange={handleChange}
          placeholder={placeholder || DEFAULT_PLACEHOLDER_TEXT}
        />
      )}

      {error && <Error>{error}</Error>}
    </SelectV2Wrapper>
  )
}

export default SelectV2
