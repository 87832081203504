import { AxiosRequestConfig } from 'axios'

import { Category } from 'interfaces'

import { httpApiClientV3 } from '../configs/http-client'
import { BaseApi } from './base.apis'

export class CategoriesApi extends BaseApi {
  getCategoriesList(config?: AxiosRequestConfig) {
    return httpApiClientV3.get<Category[]>(`${this.url}`, config)
  }
}

export const adminCategoriesApi = new CategoriesApi('/admin/categories')
export const categoriesApi = new CategoriesApi('/categories')
