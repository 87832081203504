import styled from 'styled-components'

export const SliderWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 70%;

  & .rc-slider-rail {
    height: 10px;
    top: calc(50% - 5px);
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  }

  & .rc-slider-track {
    background: ${({ theme }) => theme.colors.green1};
    height: 10px;
    top: calc(50% - 5px);
  }

  & .rc-slider-dot,
  & .rc-slider-mark {
    display: none;
  }

  .rc-slider-tooltip-inner {
    padding: 8px;
    color: #fff;
    background: ${({ theme }) => theme.colors.black1};
    font-size: 12.8px;
    height: auto;
  }

  .rc-slider-tooltip-arrow {
    border-top-color: ${({ theme }) => theme.colors.black1};
  }

  & .rc-slider-handle {
    background: ${({ theme }) => theme.colors.green1};
    width: 20px;
    height: 20px;
    border: none;
    bottom: calc(50% - 10px);
    opacity: 1;

    &:before {
      content: '';
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      background: red;
    }

    &:active,
    &:focus,
    &-dragging {
      border: none !important;
      box-shadow: none !important;
    }
  }
`
