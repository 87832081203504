import { AxiosError } from 'axios'

import { DEFAULT_SERVER_ERROR_MESSAGE, INCORRECT_SERVER_ERROR_MESSAGE_PATTERN } from 'constants/messages'

export const getServerErrorMessage = (error: unknown): string => {
  const err = error as AxiosError<{ message: string }>

  const { message = DEFAULT_SERVER_ERROR_MESSAGE } = err?.response?.data || {}

  if (message?.includes(INCORRECT_SERVER_ERROR_MESSAGE_PATTERN)) return DEFAULT_SERVER_ERROR_MESSAGE

  return message
}

export const getParsedServerErrors = (
  errors: Record<string, Record<string, string[]>>,
): { [p: string]: string[] } | null => {
  console.log(errors)
  if (!Object.keys(errors)?.length) return null

  return Object.fromEntries(Object.entries(errors).map(([key, value]) => [key, value[0]]))
}
