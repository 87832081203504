import React, { FC } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

import { Colors } from 'constants/global'
import { Button, ContentWrapper } from 'shared-components'

interface Props {
  submitButtonText?: string
  navigateButtonText?: string
  customBackground?: Colors
  onSubmit?: VoidFunction
  isStraight?: boolean
  submitBackground?: Colors
  hideNavigate?: boolean
  navigateLink?: string
  handleAdditionalButton?: () => void
}

const DEFAULT_NAVIGATE_BUTTON_TEXT = 'Back to list'

const BottomActions: FC<Props> = ({
  submitButtonText,
  navigateButtonText,
  onSubmit,
  submitBackground,
  hideNavigate,
  navigateLink,
  handleAdditionalButton,
  ...props
}) => {
  const navigate = useNavigate()

  const navigateTo = (navigateLink || -1) as string

  return (
    <ContentWrapper {...props}>
      {!hideNavigate && (
        <Button
          onClick={handleAdditionalButton ? handleAdditionalButton : () => navigate(navigateTo)}
          icon={faAngleLeft as IconDefinition}
        >
          {navigateButtonText || DEFAULT_NAVIGATE_BUTTON_TEXT}
        </Button>
      )}
      {submitButtonText && (
        <Button backgroundColor={submitBackground} type={onSubmit ? 'button' : 'submit'} onClick={onSubmit}>
          {submitButtonText}
        </Button>
      )}
    </ContentWrapper>
  )
}

export default BottomActions
