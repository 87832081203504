import * as Yup from 'yup'

import { TableDataFormItem, FormField, KeyWithAnyValue, Pagination } from 'interfaces'

export const getFormikInitialValues = (
  array: TableDataFormItem[],
  initialValues?: KeyWithAnyValue,
): { [key: string]: string } => {
  return array.reduce(
    (acc, item) => ({
      ...acc,
      [item.name]: (initialValues && initialValues[item.name]) || item?.initial || '',
    }),
    {},
  )
}

export const getFormikValidationShared = (array: FormField[]) => {
  return Yup.object().shape({
    ...array.reduce(
      (obj, { name, validation }) => ({
        ...obj,
        [name]: validation,
      }),
      {},
    ),
  })
}

export const getTableDataFormItemValidation = (array: TableDataFormItem[]) => {
  return Yup.object().shape({
    ...array.reduce(
      (obj, { name, validation }) => ({
        ...obj,
        [name]: validation,
      }),
      {},
    ),
  })
}

export const getPaginationDataFromMeta = ({ current_page, per_page, total }: Pagination) => ({
  current_page,
  per_page,
  total,
})
