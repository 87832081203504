import React, { FC } from 'react'

import { UploadFile } from 'interfaces'
import { downloadFile, getFileSize } from 'utils/files'

import { fileTypeStyles } from './style'

const IMG_EXTENSIONS = ['png', 'jpg', 'jpeg', 'gif']

interface Props {
  file: UploadFile
  handleDeleteFile: VoidFunction
}

const InputFileListItem: FC<Props> = ({ file, handleDeleteFile }) => {
  const fileAddress = `${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/storage/${file.file}`

  return (
    <li>
      <div className='file-info'>
        <div className='file-container'>
          <i
            className='file-type'
            style={fileTypeStyles[file.originalName.split('.').pop()?.toLowerCase() as keyof typeof fileTypeStyles]}
          >
            {IMG_EXTENSIONS.includes(
              file.originalName.split('.').pop()?.toLowerCase() as keyof typeof fileTypeStyles,
            ) ? (
              <img
                src={file?.base64 || `${process.env.REACT_APP_NEXT_PUBLIC_API_URL}/storage/${file.file}`}
                alt='Thumbnail'
                className='thumbnail'
              />
            ) : (
              file.originalName.split('.').pop()
            )}
          </i>
          <div className='file-name-size-separator'>
            <span className='file-name'>{file.originalName}</span>
            <div className='file-size'>{getFileSize(file.size)}</div>
          </div>
          <div className='buttons'>
            <button className='download' title='Download' onClick={() => downloadFile(fileAddress, file.originalName)}>
              <span className='arrow' />
            </button>
            <button className='delete' onClick={handleDeleteFile} title='Remove'>
              <span className='x' />
            </button>
          </div>
        </div>
      </div>
    </li>
  )
}

export default InputFileListItem
