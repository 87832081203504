import { isAfter, isBefore, isSameDay } from 'date-fns'
import * as Yup from 'yup'

import { TableDataFormItem } from 'interfaces'
import { RadioV2, DatePicker, Input, InputWithSelect, Select, SelectV2 } from 'shared-components'

export const ALL_EVALUATIONS_TITLE = 'Evaluations'

export const ALL_EVALUATIONS_CONTENT_CARD_TITLE = 'Evaluations list'

export const ALL_EVALUATIONS_TABLE_HEADERS = [
  'Title',
  'Question set',
  'Category',
  'Scheduled',
  'Created',
  'Updated',
  'Active',
]

export const ALL_EVALUATION_ADD_PAGE_TITLE = 'Add evaluation'

export const ALL_EVALUATION_DETAILS_TITLE = 'Evaluation details'

export const ALL_EVALUATIONS_SCHEDULED_TITLE = 'Scheduled Evaluation'

export const ALL_EVALUATIONS_SUBMIT_TEXT = 'Add evaluation'

export const ALL_EVALUATIONS_EDIT_TEXT = 'Save evaluation'

export const ALL_EVALUATIONS_NAVIGATE_TEXT = 'Save and Exit'

export const ALL_EVALUATIONS_CATEGORIES = [
  { name: 'Choose category', value: '' },
  { name: 'Audit', value: 'audit' },
  { name: 'Inspection', value: 'inspection' },
  { name: 'Investigation', value: 'investigation' },
  { name: 'PSA assessment', value: 'psa-assessment' },
  { name: 'Self-assessment', value: 'self-assessment' },
]

export const AREAS_DEFAULT_OPTION = {
  name: 'Choose Evaluation Area',
  value: '',
}
export const CATEGORIES_DEFAULT_OPTION = {
  name: 'Choose category',
  value: '',
}
export const QUESTION_SETS_DEFAULT_OPTION = {
  name: 'Choose question set',
  value: '',
}

export const ALL_EVALUATIONS_FORM_FIELDS: TableDataFormItem[] = [
  {
    name: 'title',
    placeholder: 'Enter evaluation title',
    label: 'Title',
    type: 'text',
    component: Input,
    validation: Yup.string().required('Required field'),
  },
  {
    name: 'scheduled_at',
    label: 'Evaluation date',
    component: DatePicker,
    validation: Yup.string()
      .required('Required field')
      .test('dob', 'Date should be between today and deadline data', (value, ctx) => {
        const date = new Date(value as string)
        const deadlineDate = new Date(ctx.parent.deadline_at)
        return (
          (!isBefore(date, new Date()) || isSameDay(date, new Date())) &&
          (isAfter(deadlineDate, date) || isSameDay(date, deadlineDate))
        )
      }),
  },
  {
    name: 'area_id',
    label: 'Evaluation Area',
    component: Select,
    options: [AREAS_DEFAULT_OPTION],
    validation: Yup.string().required('Required field'),
  },
  {
    name: 'category_id',
    label: 'Category',
    component: Select,
    options: [CATEGORIES_DEFAULT_OPTION],
    validation: Yup.string().required('Required field'),
  },
  {
    name: 'deadline_at',
    label: 'Due date',
    component: DatePicker,
    validation: Yup.string().required('Required field'),
  },
  {
    name: 'users',
    label: 'Users',
    component: SelectV2,
    isMulti: true,
    options: [],
    renderRelation: 'area_id',
  },
  {
    name: 'question_set_id',
    label: 'Question set',
    options: [QUESTION_SETS_DEFAULT_OPTION],
    component: SelectV2,
    validation: Yup.string().required('Required field'),
  },
]

export const ALL_EVALUATIONS_FOR_EVERY_OPTIONS = [
  { name: 'years(s)', value: 'year' },
  { name: 'quarter(s)', value: 'quarter' },
  { name: 'month(s)', value: 'month' },
  { name: 'week(s)', value: 'week' },
  { name: 'day(s)', value: 'day' },
]

export const ALL_EVALUATIONS_SCHEDULED_FIELDS: TableDataFormItem[] = [
  {
    name: 'schedule_every',
    selectName: 'schedule_every_period',
    label: 'For Every',
    type: 'number',
    options: ALL_EVALUATIONS_FOR_EVERY_OPTIONS,
    component: InputWithSelect,
    validation: Yup.number().when('scheduled', {
      is: true,
      then: Yup.number().required('Required field'),
    }),
  },
  {
    name: 'schedule_recurrence',
    label: 'For (Recurrence)',
    type: 'number',
    component: Input,
    validation: Yup.number().when('scheduled', {
      is: true,
      then: Yup.number().required('Required field'),
    }),
  },
  {
    name: 'appendDate',
    label: 'Append Date/Period to Evaluation title',
    component: RadioV2,
    type: 'radio',
  },
]

export const ALL_EVALUATIONS_BOTTOM_FIELDS: TableDataFormItem[] = [
  {
    name: 'active',
    label: 'Active',
    component: RadioV2,
    type: 'radio',
  },
  {
    name: 'enabled_verification',
    label: 'Needs verification',
    component: RadioV2,
    type: 'radio',
  },
]

export enum Times {
  YEAR = 'year',
  QUARTER = 'quarter',
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
}
