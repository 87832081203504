import React, { FC, InputHTMLAttributes, useEffect, useState } from 'react'

import { FormikHandlers, FormikHelpers } from 'formik'
import { default as InputRange } from 'rc-slider'

import { QuestionPercentageOption } from 'interfaces'

import { SliderWrapper } from './style'

import 'rc-slider/assets/index.css'

const SliderWithTooltip = InputRange.createSliderWithTooltip(InputRange)

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  onChange: FormikHandlers['handleChange']
  setField: FormikHelpers<string>['setFieldValue']
  initial?: string
}

const Slider: FC<Props & Partial<QuestionPercentageOption>> = ({
  name,
  setField,
  step,
  minimum,
  maximum = 100,
  default: defaultValue,
  initial,
  disabled,
}) => {
  const [value, setValue] = useState(0)
  const onChangeValue = (value: number | number[]) => {
    setValue(Number(value))
  }

  const numberDefault = Number(defaultValue)

  useEffect(() => {
    setValue(Number(initial || numberDefault))
  }, [numberDefault, initial])

  return (
    <SliderWrapper>
      <SliderWithTooltip
        disabled={disabled}
        value={value}
        onChange={onChangeValue}
        onAfterChange={(val) => setField(name as string, val)}
        defaultValue={numberDefault || 0}
        min={Number(minimum) || 0}
        max={Number(maximum) || 100}
        step={Number(step || 1)}
      />
    </SliderWrapper>
  )
}

export default Slider
