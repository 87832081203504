import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0 8px;
`

export const TableDataFormContainer = styled.div<{
  isFullWidth?: boolean
}>`
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '50%')};
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const FormColumn = styled.div<{
  isFullWidthColumn?: boolean
}>`
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: ${({ isFullWidthColumn }) => (isFullWidthColumn ? '100%' : '48%')}; ;
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
