import { useCallback, useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { SelectPagination } from 'interfaces'

const usePagination = ({
  baseUrl,
  paginationProps,
  onChangePage,
  isLength,
}: {
  baseUrl: string
  paginationProps: SelectPagination
  onChangePage?: (page?: number) => void
  isLength: boolean
}): {
  handleChangePage: (page: number) => void
} => {
  const { search } = useLocation()
  const navigate = useNavigate()

  const handleChangePage = useCallback(
    (page: number) => {
      navigate({
        pathname: baseUrl,
        search: `?page=${page}`,
      })
    },
    [baseUrl, navigate],
  )

  useEffect(() => {
    const page = Number(search?.split('=')[1])
    if (page && page !== paginationProps.current) {
      onChangePage && onChangePage(page)
    }

    //eslint-disable-next-line
  }, [onChangePage, search])

  useEffect(() => {
    if (!isLength && !search && onChangePage) onChangePage()
  }, [search, isLength, onChangePage])

  return {
    handleChangePage,
  }
}

export default usePagination
