import { AxiosRequestConfig } from 'axios'

import { httpApiClient, httpApiClientV3, httpEmptyClient } from 'configs/http-client'
import {
  CompleteEvaluationPayload,
  CreateMyTask,
  DeletedInterface,
  Evaluation,
  FillEvaluation,
  FillEvaluationMyTasks,
  KeyWithQuestionAnswer,
  KeyWithString,
  MyTask,
  NoteResponse,
  OfflineDeleteTasksPayload,
  OfflineNotesResponse,
  OfflineTasks,
  OfflineUploadNotesPayload,
  OfflineUploadTasksPayload,
  SaveAnswerResponse,
  SaveEvaluationAnswerPayload,
  EvaluationIds,
  UploadFile,
  DeleteEvaluationNotesFilePayload,
  ApplicablePayload,
} from 'interfaces'

export class FillEvaluationApi {
  constructor(private url: string) {}

  getFillEvaluation(id: number, config?: AxiosRequestConfig) {
    return httpApiClientV3.get<FillEvaluation>(`${this.url}/${id}`, config)
  }

  saveQuestionsAnswers(id: number, data: { answer: KeyWithString }, config?: AxiosRequestConfig) {
    return httpApiClient.post<Evaluation>(`${this.url}/${id}/save`, data, config)
  }

  saveQuestionsAnswer({ id, questionId, answer }: SaveEvaluationAnswerPayload, config?: AxiosRequestConfig) {
    return httpApiClientV3.post<SaveAnswerResponse>(`${this.url}/${id}/answer/${questionId}/save`, { answer }, config)
  }

  getMyTasks(id: number, questionId: number, config?: AxiosRequestConfig) {
    return httpApiClient.get<FillEvaluationMyTasks>(`${this.url}/${id}/${questionId}`, config)
  }

  createMyTask(id: number, questionId: number, { task_id, data }: CreateMyTask, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ task: MyTask }>(`${this.url}/${id}/${questionId}`, { task_id, ...data }, config)
  }

  deleteMyTask(id: number, questionId: number, taskId: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete<DeletedInterface>(`${this.url}/${id}/${questionId}/${taskId}`, config)
  }

  getMyNote(id: number, questionId: number, config?: AxiosRequestConfig) {
    return httpApiClient.get<NoteResponse>(`${this.url}/${id}/${questionId}`, config)
  }

  createMyNote(id: number, questionId: number, data: KeyWithString, config?: AxiosRequestConfig) {
    return httpApiClient.post<NoteResponse>(`${this.url}/${id}/${questionId}`, data, config)
  }

  deleteMyNote({ evaluationId, questionId }: EvaluationIds, config?: AxiosRequestConfig) {
    return httpApiClientV3.delete(`${this.url}/${evaluationId}/${questionId}/notes`, config)
  }

  createEvaluationFiles(id: number, questionId: number, data: FormData, config?: AxiosRequestConfig) {
    return httpApiClientV3.post<{ data: UploadFile[] }>(`${this.url}/${id}/${questionId}/files`, data, config)
  }

  getEvaluationFiles({ evaluationId, questionId }: EvaluationIds, config?: AxiosRequestConfig) {
    return httpApiClientV3.get<{ data: UploadFile[] }>(`${this.url}/${evaluationId}/${questionId}/files`, config)
  }

  deleteEvaluationFile(
    { evaluationId, questionId, file }: DeleteEvaluationNotesFilePayload,
    config?: AxiosRequestConfig,
  ) {
    return httpApiClientV3.delete<{ data: UploadFile[] }>(`${this.url}/${evaluationId}/${questionId}/files`, {
      ...config,
      data: { file },
    })
  }

  uploadTasksFromOffline(id: number, tasks?: OfflineUploadTasksPayload, config?: AxiosRequestConfig) {
    return httpApiClientV3.post(`${this.url}/${id}/tasks`, { tasks }, config)
  }

  deleteTasksFromOffline(id: number, tasks?: OfflineDeleteTasksPayload, config?: AxiosRequestConfig) {
    return httpApiClientV3.delete(`${this.url}/${id}/tasks`, {
      ...config,
      data: {
        tasks,
      },
    })
  }

  uploadNotesFromOffline(id: number, notes?: OfflineUploadNotesPayload, config?: AxiosRequestConfig) {
    return httpApiClientV3.post(`${this.url}/${id}/notes`, { notes }, config)
  }

  getUsersAndPrioritiesList(id: number, questionId: number, config?: AxiosRequestConfig) {
    return httpApiClientV3.get<OfflineTasks>(`${this.url}/${id}/${questionId}`, config)
  }

  getTasksList(id: number, config?: AxiosRequestConfig) {
    return httpApiClientV3.get<OfflineTasks>(`${this.url}/${id}/tasks`, config)
  }

  getNotesList(id: number, config?: AxiosRequestConfig) {
    return httpApiClientV3.get<OfflineNotesResponse>(`${this.url}/${id}/notes`, config)
  }

  setNotApplicable({ id, questionId, applicable }: ApplicablePayload, config?: AxiosRequestConfig) {
    return httpApiClientV3.post(`${this.url}/notApplicable/${id}/${questionId}`, { applicable }, config)
  }

  finishEvaluation({ id, feedback, action }: CompleteEvaluationPayload, config?: AxiosRequestConfig) {
    return httpApiClientV3.put<FillEvaluation>(`${this.url}/${id}/complete`, { feedback, action }, config)
  }

  getQuestionAnswers(id: number, config?: AxiosRequestConfig) {
    return httpApiClientV3.get<KeyWithQuestionAnswer>(`${this.url}/${id}/answers`, config)
  }

  getQuestionPicture(imageUrl: string) {
    return httpEmptyClient.get(`${this.url}${imageUrl}`, { responseType: 'blob' })
  }
}

export const emptyEvaluationApi = new FillEvaluationApi('')
export const fillEvaluationApi = new FillEvaluationApi('/evaluations')
export const fillEvaluationTasksApi = new FillEvaluationApi('/evaluations/tasks')
export const fillEvaluationNotesApi = new FillEvaluationApi('/evaluations/notes')
