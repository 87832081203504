import React, { FC, InputHTMLAttributes } from 'react'

import { Label } from './styles'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string
}

const Checkbox: FC<Props> = ({ label, ...props }) => {
  return (
    <Label>
      {label}
      <input type='checkbox' {...props} />
      <span />
    </Label>
  )
}

export default Checkbox
