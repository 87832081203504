import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import { QuestionSetRenamePayload, QuestionSetsItem, QuestionSetsState, QuestionSetV4 } from 'interfaces'

import {
  addQuestionSetsItem,
  deleteQuestionSetsItem,
  editQuestionSetsItem,
  getActiveQuestionSets,
  getArchiveQuestionSets,
  getQuestionSetsList,
  moveQuestionSetToArchive,
  renameQuestionSetItem,
  unArchiveQuestionSetsItem,
} from './action'

export function getActiveQuestionSetsReducer(builder: ActionReducerMapBuilder<QuestionSetsState>) {
  builder.addCase(getActiveQuestionSets.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(getActiveQuestionSets.fulfilled, (state, action: PayloadAction<QuestionSetsItem[]>) => {
    state.active = action.payload
    state.isLoading = false
  })

  builder.addCase(getActiveQuestionSets.rejected, (state) => {
    state.isLoading = false
  })
}

export function getArchiveQuestionSetsReducer(builder: ActionReducerMapBuilder<QuestionSetsState>) {
  builder.addCase(getArchiveQuestionSets.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(getArchiveQuestionSets.fulfilled, (state, action: PayloadAction<QuestionSetsItem[]>) => {
    state.archive = action.payload
    state.isLoading = false
  })

  builder.addCase(getArchiveQuestionSets.rejected, (state) => {
    state.isLoading = false
  })
}

export function addQuestionSetsItemReducer(builder: ActionReducerMapBuilder<QuestionSetsState>) {
  builder.addCase(addQuestionSetsItem.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(addQuestionSetsItem.fulfilled, (state, action: PayloadAction<QuestionSetsItem>) => {
    state.active.unshift(action.payload)
    state.isLoading = false
  })

  builder.addCase(addQuestionSetsItem.rejected, (state) => {
    state.isLoading = false
  })
}

export function moveQuestionSetToArchiveReducer(builder: ActionReducerMapBuilder<QuestionSetsState>) {
  builder.addCase(moveQuestionSetToArchive.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(moveQuestionSetToArchive.fulfilled, (state, action: PayloadAction<number>) => {
    const index = state.active.findIndex((i) => i.id === action.payload)
    state.archive.unshift(state.active[index])
    state.active = state.active.filter((i) => i.id !== action.payload)
    state.isLoading = false
  })

  builder.addCase(moveQuestionSetToArchive.rejected, (state) => {
    state.isLoading = false
  })
}

export function unArchiveQuestionSetsItemReducer(builder: ActionReducerMapBuilder<QuestionSetsState>) {
  builder.addCase(unArchiveQuestionSetsItem.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(unArchiveQuestionSetsItem.fulfilled, (state, action: PayloadAction<number>) => {
    const index = state.archive.findIndex((i) => i.id === action.payload)
    state.active.unshift(state.archive[index])
    state.archive = state.archive.filter((i) => i.id !== action.payload)
    state.isLoading = false
  })

  builder.addCase(unArchiveQuestionSetsItem.rejected, (state) => {
    state.isLoading = false
  })
}

export function editQuestionSetsItemReducer(builder: ActionReducerMapBuilder<QuestionSetsState>) {
  builder.addCase(editQuestionSetsItem.fulfilled, (state, action: PayloadAction<QuestionSetsItem>) => {
    const index = state.active.findIndex((i) => i.id === action.payload.id)

    state.active[index] = action.payload
  })
}

export function deleteQuestionSetsItemReducer(builder: ActionReducerMapBuilder<QuestionSetsState>) {
  builder.addCase(deleteQuestionSetsItem.fulfilled, (state, action: PayloadAction<number>) => {
    state.active = state?.active?.filter((i) => i.id !== action.payload)
  })
}

export function renameQuestionSetItemReducer(builder: ActionReducerMapBuilder<QuestionSetsState>) {
  builder.addCase(renameQuestionSetItem.fulfilled, (state, action: PayloadAction<QuestionSetRenamePayload>) => {
    const { id, name } = action.payload
    state.active = state?.active?.map((i) => (i.id === id ? { ...i, name } : i))
  })
}

export function getQuestionSetsListReducer(builder: ActionReducerMapBuilder<QuestionSetsState>) {
  builder.addCase(getQuestionSetsList.fulfilled, (state, action: PayloadAction<QuestionSetV4[]>) => {
    state.questionSetsList = action.payload
  })
}
