import { EvaluationIds, NumberOrNullType } from '../interfaces'
import { db } from './mainDb'

export const fetchOfflineFullList = async (id: number) => {
  return db.fullList.get({ id })
}

export const fetchOfflineNotes = async () => {
  return db.notes.toArray()
}

export const fetchOfflineTasks = async () => {
  return db.tasksList.toArray()
}

export const fetchOfflineNewTasks = async () => {
  return db.newTasks.toArray()
}

export const fetchOfflineDeletedTasks = async () => {
  return db.deletedTasks.toArray()
}

export const fetchTasksCountById = async (id?: NumberOrNullType, evaluationId?: number) => {
  if (!id) return 0

  const tasksList = await db.tasksList.get({ id, evaluationId })
  const newTasks = await db.newTasks.where('questionId').equals(id)?.toArray()

  const tasksCount = (tasksList?.tasks?.length || 0) + newTasks?.length

  return tasksCount || 0
}

export const fetchCurrentOfflineUser = async () => {
  const users = await db.user.toArray()

  return users[0]
}

export const fetchNotesFilesById = async ({ evaluationId, questionId }: EvaluationIds) => {
  const currentUser = await fetchCurrentOfflineUser()

  return db.notesFiles.where({ evaluationId, questionId, user_id: currentUser?.id }).toArray()
}

export const fetchDeletedNotes = (evaluationId: number) => {
  return db.deletedNotes.where('evaluationId').equals(evaluationId).toArray()
}

export const fetchDownloadedFilesById = async ({ evaluationId, questionId }: EvaluationIds) => {
  const currentUser = await fetchCurrentOfflineUser()

  return db.files.where({ evaluation_id: evaluationId, questionId, user_id: currentUser?.id }).toArray()
}

export const fetchFilesToDeleteById = async (evaluationId: number) => {
  const currentUser = await fetchCurrentOfflineUser()

  return db.filesToDelete.where({ evaluation_id: evaluationId, user_id: currentUser?.id }).toArray()
}

export const fetchNewNotesFilesById = async (evaluationId: number) => {
  const currentUser = await fetchCurrentOfflineUser()

  return db.notesFiles.where({ evaluationId, user_id: currentUser?.id }).toArray()
}

export const fetchFilesToDeleteCount = async (evaluationId: NumberOrNullType) => {
  if (!evaluationId) return 0

  const currentUser = await fetchCurrentOfflineUser()
  return db.filesToDelete.where({ evaluation_id: evaluationId, user_id: currentUser?.id }).count()
}

export const fetchNewFilesCount = async (evaluationId?: NumberOrNullType) => {
  if (!evaluationId) return 0

  const currentUser = await fetchCurrentOfflineUser()

  return db.notesFiles.where({ evaluationId, user_id: currentUser?.id }).count()
}
