import { createAsyncThunk } from '@reduxjs/toolkit'

import { areasApi, areasApiV4 } from 'apis'
import { AreaPayload, NumberStringBoolean, Area, AreasItem, AreasValues, AreaEditPayload } from 'interfaces'
import { getAreaPayload } from 'utils'

import { setErrorNotify, setSuccessNotify } from '../notifications/action'

export const AREAS_SLICE_NAME = 'areas'

export const getAreas = createAsyncThunk(`${AREAS_SLICE_NAME}/getAreas`, async (_, { rejectWithValue }) => {
  try {
    const response = await areasApi.getList<AreasItem[]>()
    return response.data
  } catch (e) {
    return rejectWithValue(e)
  }
})

export const createArea = createAsyncThunk(
  `${AREAS_SLICE_NAME}/createArea`,
  async (data: AreasValues, { dispatch, rejectWithValue }) => {
    try {
      const response = await areasApi.createItem<AreaPayload, AreasItem>(getAreaPayload(data))

      dispatch(setSuccessNotify(`Area was successfully created`))
      return response.data
    } catch (e) {
      dispatch(setErrorNotify("Area wasn't created"))
      return rejectWithValue(e)
    }
  },
)

export const editArea = createAsyncThunk(
  `${AREAS_SLICE_NAME}/editArea`,
  async (data: Area, { dispatch, rejectWithValue }) => {
    try {
      const { id, ...payload } = data

      const response = await areasApi.editItem<AreaEditPayload, AreasItem>({ ...getAreaPayload(payload), id })
      dispatch(setSuccessNotify(`Area ${id} was successfully edited`))

      return response.data
    } catch (e) {
      dispatch(setErrorNotify(`Area ${data.id} wasn't edited`))
      return rejectWithValue(e)
    }
  },
)

export const deleteArea = createAsyncThunk(
  `${AREAS_SLICE_NAME}/deleteArea`,
  async (data: { id: number; parent_id?: NumberStringBoolean | null }, { dispatch, rejectWithValue }) => {
    try {
      const { id } = data

      const response = await areasApi.deleteItem(id)
      if (response.status !== 204) throw new Error('Something went wrong.')
      dispatch(setSuccessNotify(`Area ${id} was successfully deleted`))
      return data
    } catch (e) {
      dispatch(setErrorNotify(`Area ${data.id} was successfully deleted`))
      return rejectWithValue(e)
    }
  },
)

export const getCurrentArea = createAsyncThunk(
  `${AREAS_SLICE_NAME}/getCurrentArea`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await areasApi.getItem<Area>(id)

      return response.data?.area
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getAreasList = createAsyncThunk(`${AREAS_SLICE_NAME}/getAreasList`, async (_, { rejectWithValue }) => {
  try {
    const response = await areasApiV4.getAreasList()

    return response.data.data
  } catch (e) {
    return rejectWithValue(e)
  }
})
