import React, { FC, useCallback, useContext, useState } from 'react'

import { NetworkStatusContext } from 'contexts/NetworkContext'
import useConfirm from 'hooks/useConfirm'
import useTasks from 'hooks/useTasks'
import { setIsTasksOpen, asyncDeleteTask } from 'modules/evaluation-actions-group/action'
import { selectIsLoading, selectTasksState } from 'modules/evaluation-actions-group/selectors'
import { useAppDispatch, useAppSelector } from 'modules/store'
import { ModalWindow, Confirm } from 'shared-components'
import { getFormikProps } from 'utils'

import TasksForm from './tasks-form/TasksForm'

interface Props {
  evaluationId: number
  isDisabled?: boolean
}

const Tasks: FC<Props> = ({ evaluationId, isDisabled }) => {
  const dispatch = useAppDispatch()

  const { isOpen, questionId } = useAppSelector(selectTasksState)
  const isLoading = useAppSelector(selectIsLoading)
  const [editId, setEditId] = useState<number | null>(null)
  const isOnline = useContext(NetworkStatusContext)

  const handleCloseTasksModal = () => dispatch(setIsTasksOpen(false))

  const { onCloseModalWindow, formik, tasks, onlineTasks, fieldsToRender, offlineTasks } = useTasks(
    evaluationId,
    editId,
    setEditId,
    handleCloseTasksModal,
  )

  const onEdit = useCallback((id: number) => {
    setEditId(id)
  }, [])

  const onDelete = useCallback(
    (taskId: number) => {
      if (!questionId) return

      dispatch(
        asyncDeleteTask({
          isOnline,
          questionId,
          evaluationId,
          taskId,
          tasksCount: onlineTasks?.length,
        }),
      )
    },
    [dispatch, evaluationId, isOnline, onlineTasks?.length, questionId],
  )

  const { isConfirmOpen, handleOpenConfirm, handleCloseConfirm, onConfirm } = useConfirm(onDelete)

  return (
    <>
      <Confirm isOpen={isConfirmOpen} onConfirm={onConfirm} onClose={handleCloseConfirm} />
      <ModalWindow
        isDisableOutsideClick
        isLoading={isLoading}
        isOpen={isOpen && !isDisabled}
        onClose={onCloseModalWindow}
        onSubmit={formik.handleSubmit}
        title='Tasks'
      >
        <TasksForm
          tasks={tasks}
          setFieldValue={formik.setFieldValue}
          fieldsToRender={fieldsToRender}
          actionFunctions={{
            edit: onEdit,
            delete: handleOpenConfirm,
          }}
          isLoading={isLoading}
          offlineTaskInfo={offlineTasks}
          {...getFormikProps(formik)}
        />
      </ModalWindow>
    </>
  )
}

export default Tasks
