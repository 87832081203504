import React, { FC } from 'react'

import { LoadingLineBlock } from './style'

interface Props {
  isAbsolute?: boolean
  isLoading: boolean
}

const LoadingLine: FC<Props> = ({ isAbsolute, isLoading }) => {
  return isLoading ? <LoadingLineBlock isAbsolute={isAbsolute} /> : null
}

export default LoadingLine
