import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import { Evaluation, FillEvaluation, KeyWithString, SaveAnswerResponse } from 'interfaces'
import { changeEvaluationQuestionAnswerById, changeEvaluationQuestionsAnswer } from 'utils'

import {
  asyncGetMyFillEvaluations,
  asyncSaveQuestionsAnswer,
  asyncSaveQuestionsAnswers,
  asyncSetNotApplicable,
  finishFillEvaluation,
  getQuestionsAnswer,
  offlineUploadEvaluationData,
  setFillEvaluation,
  setOfflineUploadProgress,
} from './action'

export function getMyFillEvaluationsReducer(builder: ActionReducerMapBuilder<FillEvaluation>) {
  builder.addCase(asyncGetMyFillEvaluations.pending, (state) => ({
    ...state,
    isLoading: true,
  }))

  builder.addCase(asyncGetMyFillEvaluations.fulfilled, (state, action: PayloadAction<FillEvaluation>) => ({
    ...state,
    ...action.payload,
    evaluationCompletions: null,
    sectionCompletions: null,
    isLoading: false,
  }))

  builder.addCase(asyncGetMyFillEvaluations.rejected, (state) => ({
    ...state,
    isLoading: false,
  }))
}

export function saveAnswersReducer(builder: ActionReducerMapBuilder<FillEvaluation>) {
  builder.addCase(asyncSaveQuestionsAnswers.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(asyncSaveQuestionsAnswers.fulfilled, (state, action: PayloadAction<Evaluation>) => {
    state.questions = action.payload.questions || []
    state.isLoading = false
  })

  builder.addCase(asyncSaveQuestionsAnswers.rejected, (state) => {
    state.isLoading = false
  })
}

export function asyncSaveQuestionsAnswerReducer(builder: ActionReducerMapBuilder<FillEvaluation>) {
  builder.addCase(asyncSaveQuestionsAnswer.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(
    asyncSaveQuestionsAnswer.fulfilled,
    (state, action: PayloadAction<SaveAnswerResponse & { questionId: number }>) => {
      const { answer, evaluationCompletions, sectionCompletions, questionId } = action.payload
      state.questions = changeEvaluationQuestionAnswerById(
        JSON.parse(JSON.stringify(state.questions)),
        questionId,
        answer?.content || '',
      )
      state.evaluationCompletions = evaluationCompletions
      state.sectionCompletions = { ...state.sectionCompletions, ...sectionCompletions }
      state.isLoading = false
    },
  )

  builder.addCase(asyncSaveQuestionsAnswer.rejected, (state) => {
    state.isLoading = false
  })
}

export function addNotApplicableReducer(builder: ActionReducerMapBuilder<FillEvaluation>) {
  builder.addCase(asyncSetNotApplicable.pending, (state) => {
    state.isQuestionLoading = true
  })

  builder.addCase(asyncSetNotApplicable.fulfilled, (state, action) => {
    state.questions = changeEvaluationQuestionAnswerById(state.questions, Number(action.payload), '-1000')
    state.isQuestionLoading = false
  })

  builder.addCase(asyncSetNotApplicable.rejected, (state) => {
    state.isQuestionLoading = false
  })
}

export function offlineUploadReducer(builder: ActionReducerMapBuilder<FillEvaluation>) {
  builder.addCase(offlineUploadEvaluationData.pending, (state) => {
    state.isModalLoading = true
    state.offlineUploadProgress = 0
  })

  builder.addCase(offlineUploadEvaluationData.fulfilled, (state) => {
    state.offlineUploadProgress = 100
  })

  builder.addCase(offlineUploadEvaluationData.rejected, (state) => {
    state.offlineUploadProgress = null
    state.isModalLoading = false
  })

  builder.addCase(setOfflineUploadProgress, (state, action: PayloadAction<number | null>) => {
    state.offlineUploadProgress = action.payload
  })
}

export function finishFillEvaluationReducer(builder: ActionReducerMapBuilder<FillEvaluation>) {
  builder.addCase(finishFillEvaluation.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(
    finishFillEvaluation.fulfilled,
    (state, action: PayloadAction<Pick<FillEvaluation, 'completed_at' | 'feedback'>>) => {
      const { completed_at, feedback } = action.payload
      state.completed_at = completed_at
      state.feedback = feedback
      state.isLoading = false
    },
  )

  builder.addCase(finishFillEvaluation.rejected, (state) => {
    state.isLoading = false
  })
}

export function getQuestionsAnswerReducer(builder: ActionReducerMapBuilder<FillEvaluation>) {
  builder.addCase(getQuestionsAnswer.pending, (state) => {
    state.isLoading = true
  })
  builder.addCase(getQuestionsAnswer.fulfilled, (state, action: PayloadAction<KeyWithString>) => {
    state.questions = changeEvaluationQuestionsAnswer(state.questions, action.payload)
    state.isLoading = false
  })
  builder.addCase(getQuestionsAnswer.rejected, (state) => {
    state.isLoading = false
  })
}

export function setFillEvaluationReducer(builder: ActionReducerMapBuilder<FillEvaluation>) {
  builder.addCase(setFillEvaluation, (state, action: PayloadAction<FillEvaluation>) => ({
    ...state,
    ...action.payload,
  }))
}
