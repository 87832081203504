import React, { FC, ReactNode } from 'react'

import { Container, TableWrapper, Tbody, Thead } from './style'

interface Props {
  children: ReactNode
  headers: string[]
}

const Table: FC<Props> = ({ children, headers }) => {
  return (
    <Container>
      <TableWrapper>
        <Thead>
          <tr>{headers?.length && headers.map((header) => <th key={header}>{header}</th>)}</tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </TableWrapper>
    </Container>
  )
}

export default Table
