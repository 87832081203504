/* eslint-disable  @typescript-eslint/no-explicit-any */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { userApi } from 'apis'
import { REMEMBER_ME_KEY, VERIFY_ACCESS_TOKEN_KEY } from 'constants/auth'
import { saveUserToDB, setCurrentUserToDB } from 'db/helpers'
import { errorNotify, successNotify } from 'helpers/notifications'
import { ISendVerifyCode, IsOnline, LoginPayload, ResetPasswordByTokenPayload, UserInfoInterface } from 'interfaces'
import { setErrorNotify, setSuccessNotify } from 'modules/notifications/action'
import { setUserAccess } from 'utils'
import { getServerErrorMessage } from 'utils/errors'

export const USER_SLICE_NAME = 'user'

export const asyncLogin = createAsyncThunk(
  `${USER_SLICE_NAME}/login`,
  async ({ isOnline, ...data }: LoginPayload & IsOnline, { rejectWithValue, dispatch }) => {
    try {
      const { remember_me, ...rest } = data
      const response = await userApi.loginUser(rest)
      if (response?.data?.access_token) {
        const { access_token, required2fa } = response.data
        if (required2fa) {
          localStorage.setItem(VERIFY_ACCESS_TOKEN_KEY, access_token)
          if (remember_me) localStorage.setItem(REMEMBER_ME_KEY, '1')
        } else {
          setUserAccess(remember_me, access_token)
        }
      }
      dispatch(getUserInfo({ isOnline }))
      return response.data
    } catch (e) {
      return rejectWithValue(getServerErrorMessage(e))
    }
  },
)

export const getUserInfo = createAsyncThunk(
  `${USER_SLICE_NAME}/getUserInfo`,
  async ({ isOnline = true }: IsOnline, { rejectWithValue }) => {
    try {
      if (!isOnline) return rejectWithValue({ isOnline })

      const response = await userApi.getUserInfo()

      const { id, name } = response?.data

      const userData = { id: Number(id), name }

      await setCurrentUserToDB(userData)
      await saveUserToDB(userData)

      return response.data
    } catch (e) {
      return rejectWithValue({})
    }
  },
)

export const putUserInfoTwoFactor = createAsyncThunk(`${USER_SLICE_NAME}/twoFactor`, async (_, { rejectWithValue }) => {
  try {
    const response = await userApi.putUserInfoTwoFactor()

    if (!window.navigator.onLine) return rejectWithValue('Offline user.')

    return response.data
  } catch (e) {
    return rejectWithValue(e)
  }
})

export const asyncUpdateUser = createAsyncThunk(
  `${USER_SLICE_NAME}/updateUser`,
  async (
    {
      data,
      path,
    }: {
      data: Partial<UserInfoInterface>
      path?: string
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const url = `user${path || ''}`

      const response = data?.twoFactor
        ? ((await userApi.updateTwoFactor(url, data)) as any)
        : await userApi.updateUser(url, data)

      dispatch(setSuccessNotify('Your profile information successfully updated'))

      if (response?.data?.data) {
        return response.data?.data
      }

      return response.data
    } catch (err: any) {
      if (err?.response?.data) {
        dispatch(setErrorNotify(err.response.data.message))
        return rejectWithValue(err.response.data.errors)
      }

      return rejectWithValue('Something went wrong')
    }
  },
)

export const asyncResendCode = createAsyncThunk(`${USER_SLICE_NAME}/resendCode`, async (token: string | undefined) => {
  const response = await userApi.resendCode(token)
  return response.data
})

export const send2FaCode = createAsyncThunk(
  `${USER_SLICE_NAME}`,
  async (data: ISendVerifyCode, { rejectWithValue }) => {
    try {
      const { url, access_token, ...rest } = data

      const storageVerifyToken = localStorage.getItem(VERIFY_ACCESS_TOKEN_KEY)

      const response = await userApi.send2FaCode(url, rest, access_token || storageVerifyToken || '')

      setUserAccess(localStorage.getItem(REMEMBER_ME_KEY) || false, response.data.access_token)

      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const asyncResetPassword = createAsyncThunk(
  `${USER_SLICE_NAME}`,
  async (data: { email: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await userApi.resetPassword(data)

      if (response?.data?.message) dispatch(setSuccessNotify(response.data.message))

      if (response.status !== 200) throw new Error('Something went wrong.')

      return null
    } catch (e) {
      dispatch(setErrorNotify("Email wasn't sent, contact admin"))
      return rejectWithValue(e)
    }
  },
)

export const asyncSendResetPassword = createAsyncThunk(
  `${USER_SLICE_NAME}`,
  async (payload: ResetPasswordByTokenPayload, { rejectWithValue }) => {
    try {
      const response = await userApi.resetPasswordByToken(payload)

      successNotify(response?.data?.message)
      return response.data
    } catch (e) {
      errorNotify(getServerErrorMessage(e))
      return rejectWithValue(e)
    }
  },
)

export const setUserPinCode = createAction<string>('SET_USER_PIN_CODE')

export const userLogout = createAction('LOGOUT')

export const setIsAuth = createAction<boolean>('SET_IS_AUTH')

export const resetError = createAction('RESET_USER_ERROR')
