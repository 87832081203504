import React, { FC, ReactNode } from 'react'

import { NumberOrNullType } from 'interfaces'

import { TextWrapper, EvaluationText, EvaluationCompleteWrapper } from './style'

interface Props {
  id: NumberOrNullType
  totalQuestionsCount: number
  positiveAnswersCount: number
  children?: ReactNode
  className?: string
}

const EvaluationCompleteContent: FC<Props> = ({ totalQuestionsCount, positiveAnswersCount, children, className }) => {
  return (
    <EvaluationCompleteWrapper>
      <TextWrapper>
        <EvaluationText>
          You answered {positiveAnswersCount} out of {totalQuestionsCount} questions
        </EvaluationText>
      </TextWrapper>
      {children}
    </EvaluationCompleteWrapper>
  )
}

export default EvaluationCompleteContent
