import styled from 'styled-components'

import { MyEvaluationsItemWrapper } from 'page-components/home/evaluations/my-evaluations-item/style'

export const TaskTableItemWrapper = styled(MyEvaluationsItemWrapper)`
  background: #fff !important;
  border-top: 1px solid #dee2e6;

  & td {
    text-align: left !important;
  }
`
