import styled from 'styled-components'

export const EvaluationBottomWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) => `${theme.spaces.min2} ${theme.spaces.min3}`};
  background: ${({ theme }) => theme.colors.gray4};

  & > a {
    position: relative;
    padding: ${({ theme }) => `7px ${theme.spaces.min2}`};
    background: ${({ theme }) => `${theme.colors.blue1}`};
    color: #fff;
    line-height: 24px;
    text-decoration: none;

    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 4px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0;
      transition: 0.3s all ease;
    }

    &:hover {
      &:before {
        opacity: 0.1;
      }
    }

    & > svg {
      margin-right: 4px;
    }
  }
`
