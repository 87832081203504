import styled from 'styled-components'

export const ConfirmWrapper = styled.div`
  position: fixed;
  top: 130px;
  left: 50%;
  min-width: 300px;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border-radius: 4px;
  text-align: left;
  color: ${({ theme }) => theme.colors.black1};
  line-height: 24px;
  font-size: 16px;
  overflow: hidden;
  z-index: 1050;

  & > h4 {
    padding: ${({ theme }) => theme.spaces.min2};
  }
`

export const ConfirmationTop = styled.div`
  padding: ${({ theme }) => theme.spaces.min2};
  background: ${({ theme }) => theme.colors.blue1};
  user-select: none;

  color: #fff;
`

export const ConfirmBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spaces.min2};
  border-top: 1px solid ${({ theme }) => theme.colors.gray1};

  & > button:nth-child(2) {
    margin-left: 16px;
  }
`
