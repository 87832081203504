import * as Yup from 'yup'

import { AreaFieldsTypes } from 'constants/area-fields'
import { AreasFormTypes } from 'constants/areas'
import { DragItem, AreasItem, AreaField, AreasValues, AreaPayload, AreaV4 } from 'interfaces'

import { getAreasEditLink } from './url'

export const convertAreasToDragList = (list: AreasItem[], parent_id?: number): DragItem[] =>
  list.map(({ id, name, areas }) => ({
    id,
    parent_id: parent_id || null,
    text: name,
    link: getAreasEditLink(id),
    children: areas?.length ? convertAreasToDragList(areas, id) : [],
  }))

export const transformAreaFieldsToFields = (areaFields: AreaField[]) =>
  areaFields?.map(({ id, name, type, mandatory }) => ({
    name: String(id),
    label: `${name} ${mandatory ? '*' : ''}`,
    ...getAreaFieldsFormSettings(type),
    ...(Boolean(mandatory) && { validation: Yup.string().required('Required field') }),
  }))

export const getAreaFieldsFormSettings = (type: AreaFieldsTypes) => AreasFormTypes[type]

export const getAreaPayload = (data: AreasValues): AreaPayload => {
  const { id, name, language, files, ...values } = data

  return {
    name,
    language,
    //eslint-disable-next-line
    ...((id && { id }) as any),
    ...(files && { files }),
    additionalFields: Object.keys(values)
      .map((key) => {
        if (!values[key]) return null
        return {
          [key]: {
            value: values[key],
          },
        }
      })
      .filter((i) => i)
      .reduce((obj, item) => Object.assign(obj, item), {}),
  }
}

export const convertAreasV4ListToOptions = (areas: AreaV4[]) => {
  if (!areas?.length) return []

  return areas?.map(({ areaId, areaName }) => ({ name: areaName, value: areaId }))
}
