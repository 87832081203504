import React, { useContext, useEffect, useMemo, useState } from 'react'

import { useFormik } from 'formik'
import qs from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'

import { NetworkStatusContext } from 'contexts/NetworkContext'
import { getAreasList } from 'modules/areas/action'
import { selectAreasListAsOptions } from 'modules/areas/selectors'
import { getCategoriesList } from 'modules/categories/action'
import { selectCategoriesListAsOptions } from 'modules/categories/selectors'
import { asyncGetMyEvaluations } from 'modules/evaluations/action'
import { getQuestionSetsList } from 'modules/question-sets/action'
import { selectQuestionSetsListAsOptions } from 'modules/question-sets/selectors'
import { useAppDispatch, useAppSelector } from 'modules/store'
import { Input, SelectV2, DateRangePicker } from 'shared-components'
import Filters from 'shared-components/filters/Filters'
import { convertObjectToQueryParametersString, filterObjectByKeys } from 'utils'

enum MyEvaluationsFiltersFieldNames {
  SEARCH = 'search',
  CATEGORY = 'category',
  QUESTION_SET = 'questionSet',
  AREA = 'area',
  DATES = 'dates',
}

const MY_EVALUATIONS_FILTERS_INITIAL_VALUES = {
  search: '',
  dates: '',
  area: '',
  category: '',
  questionSet: '',
}

interface MyEvaluationsFiltersValues {
  search: string
  dates: string
  area: string
  category: string
  questionSet: string
}

const MyEvaluationsFilters = () => {
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isOnline = useContext(NetworkStatusContext)

  const areasOptions = useAppSelector(selectAreasListAsOptions)
  const questionSetsOptions = useAppSelector(selectQuestionSetsListAsOptions)
  const categoriesOptions = useAppSelector(selectCategoriesListAsOptions)

  const parsedLocationSearch = qs.parse(location?.search)

  const formik = useFormik({
    initialValues: MY_EVALUATIONS_FILTERS_INITIAL_VALUES,
    onSubmit: (values) => {
      const params = convertObjectToQueryParametersString(values)
      navigate(`${location.pathname}?${params}`, { replace: true })
    },
  })

  const handleResetFilters = () => {
    const { page } = parsedLocationSearch

    formik.resetForm()
    navigate(`${location.pathname}${page ? `?page=${page}` : ''}`, { replace: true })
  }

  const isValuesExist = useMemo(() => {
    return Boolean(Object.values(formik?.values || {}).filter((value) => value)?.length)
  }, [formik?.values])

  useEffect(() => {
    setIsLoading(true)
    dispatch(asyncGetMyEvaluations({ params: location?.search?.replace('?', ''), isOnline }))
      .unwrap()
      .finally(() => setIsLoading(false))
  }, [dispatch, location?.search])

  useEffect(() => {
    const searchParams = qs.parse(location?.search)

    const filteredFormParams = filterObjectByKeys(searchParams as Record<string, string>, [
      ...Object.keys(MY_EVALUATIONS_FILTERS_INITIAL_VALUES),
    ])

    if (!isValuesExist && Object.keys(filteredFormParams)?.length) {
      formik.setValues(filteredFormParams as { [key: string]: string } & MyEvaluationsFiltersValues)
    }
  }, [location.pathname, location?.search, navigate])

  useEffect(() => {
    if (!isOnline) return

    dispatch(getAreasList())
    dispatch(getQuestionSetsList())
    dispatch(getCategoriesList())
  }, [dispatch, isOnline])

  if (!isOnline) return null

  return (
    <Filters
      handleSubmit={formik.handleSubmit}
      handleReset={handleResetFilters as VoidFunction}
      disabledActions={!isValuesExist}
      isLoading={isLoading}
    >
      <Input
        {...formik.getFieldProps(MyEvaluationsFiltersFieldNames.SEARCH)}
        label='Search by title'
        placeholder='Enter text for search'
      />

      <SelectV2
        {...formik.getFieldProps(MyEvaluationsFiltersFieldNames.CATEGORY)}
        setFieldValue={formik.setFieldValue}
        label='All Categories'
        options={categoriesOptions}
        shownOnlyWithOptions
      />

      <SelectV2
        {...formik.getFieldProps(MyEvaluationsFiltersFieldNames.QUESTION_SET)}
        setFieldValue={formik.setFieldValue}
        label='Question Set'
        options={questionSetsOptions}
        shownOnlyWithOptions
      />

      <SelectV2
        {...formik.getFieldProps(MyEvaluationsFiltersFieldNames.AREA)}
        setFieldValue={formik.setFieldValue}
        label='Area'
        options={areasOptions}
        shownOnlyWithOptions
      />

      <DateRangePicker
        label='Dates'
        {...formik.getFieldProps(MyEvaluationsFiltersFieldNames.DATES)}
        setFieldValue={formik.setFieldValue}
      />
    </Filters>
  )
}

export default MyEvaluationsFilters
