import React, { FC, useMemo } from 'react'

import { Collapse } from 'react-collapse'

import useToggle from 'hooks/useToggle'
import { Question, QuestionType } from 'interfaces'
import { EvaluationFillCard } from 'page-components'
import { SectionHeader } from 'shared-components'

import { SectionWrapper } from './style'

export interface Props extends Question {
  position: number | string
  children: React.ReactNode
}

const Section: FC<Props> = ({ children, ...props }) => {
  const [isOpen, handleToggle] = useToggle()

  const { type, answer, conditional } = props

  const isSectionOpen = useMemo(() => {
    if (type === QuestionType.CONDITIONAL && isOpen && conditional && (!answer || answer === '0')) return false
    return isOpen
  }, [answer, conditional, isOpen, type])

  return (
    <SectionWrapper>
      <SectionHeader>
        <EvaluationFillCard toggleSection={handleToggle} isSectionOpen={isSectionOpen} {...props} />
      </SectionHeader>
      <Collapse isOpened={isSectionOpen}>{children}</Collapse>
    </SectionWrapper>
  )
}

export default Section
