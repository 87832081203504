import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'modules/store'

const selectState = (x: RootState) => x.evaluationsReducer

export const selectMyEvaluations = createSelector(selectState, (state) => state.myEvaluations || [])

export const selectMyEvaluationsPagination = createSelector(
  selectState,
  ({ myEvaluationsPagination: { total, per_page, current_page } }) => ({
    total,
    pageSize: per_page,
    current: current_page,
  }),
)

export const selectAllEvaluations = createSelector(selectState, (state) => state.allEvaluations)

export const selectIsLoading = createSelector(selectState, (state) => state.isLoading)

export const selectInfoAboutOffline = (evaluationId: number) =>
  createSelector(selectState, (state) => {
    const item = state?.myEvaluations?.find((evaluation) => evaluation.id === evaluationId)

    return {
      title: item?.title || '',
      device: item?.deviceDescription || '',
      downloaded_at: item?.downloaded_at,
    }
  })
