import jquery from 'jquery'
;(function ($) {
  $.extend($.summernote.lang, {
    'en-GB': {
      color: {
        recent: 'Recent Colour',
        more: 'More Colour',
        background: 'Background Colour',
        foreground: 'Text Colour',
      },
    },
  })
})(jquery)
