import styled from 'styled-components'

export const Wrapper = styled.div<{ width?: number }>`
  position: absolute;
  top: 100%;
  right: 8px;
  width: ${({ width }) => `${width}px` || 'auto'};
  z-index: 1010;
  background: ${({ theme }) => theme.colors.white3};
  border: 1px solid rgba(0, 0, 0, 0.15);
`
