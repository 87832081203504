import styled from 'styled-components'

export const ProfileInfoWrapper = styled.div`
  flex: 0 0 25%;
  padding: 0 8px;
  margin-bottom: ${({ theme }) => theme.spaces.min3};

  @media (max-width: 768px) {
    flex: none;
  }
`

export const ProfileTop = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.min3};

  & > div {
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border-radius: 4px;
    border-top: 3px solid ${({ theme }) => theme.colors.blue1};
    background: #fff;
    padding: ${({ theme }) => theme.spaces.min5};

    & svg,
    & p {
      color: ${({ theme }) => theme.colors.black1};
    }

    & p {
      font-size: 21px;
    }
  }
`

export const RolesWrapper = styled.div`
  line-height: 24px;
  margin-bottom: ${({ theme }) => theme.spaces.min3};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border1};
`

export const RolesTitle = styled.h3`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black1};

  & > svg {
    margin-right: ${({ theme }) => theme.spaces.min1};
  }
`

export const Role = styled.p`
  color: ${({ theme }) => theme.colors.gray10};
  margin-bottom: ${({ theme }) => theme.spaces.min3};
`
