import { createAsyncThunk } from '@reduxjs/toolkit'

import { areaFieldsApi } from 'apis'
import { routes } from 'constants/routes'
import { AreaField, AreaFieldEditPayload, AreaFieldResponse, AreaFieldsListResponse, AreaFieldValues } from 'interfaces'
import history from 'services/history'

import { setErrorNotify, setSuccessNotify } from '../notifications/action'

export const AREA_FIELDS_SLICE_NAME = 'area-fields'

export const getAreaFields = createAsyncThunk(
  `${AREA_FIELDS_SLICE_NAME}/getAreaFields`,
  async (page: number, { rejectWithValue }) => {
    try {
      const response = await areaFieldsApi.getList<AreaFieldsListResponse>(page)

      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getAreaField = createAsyncThunk(
  `${AREA_FIELDS_SLICE_NAME}/getAreaField`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await areaFieldsApi.getItem<AreaFieldResponse>(id)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const deleteAreaField = createAsyncThunk(
  `${AREA_FIELDS_SLICE_NAME}/deleteAreaField`,
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await areaFieldsApi.deleteItem(id)
      if (response.status !== 204) throw new Error('Something went wrong.')
      dispatch(setSuccessNotify(`Area field ${id} was successfully deleted`))
      return id
    } catch (e) {
      dispatch(setErrorNotify(`Area field ${id} wasn't deleted`))
      return rejectWithValue(e)
    }
  },
)

export const createAreaField = createAsyncThunk(
  `${AREA_FIELDS_SLICE_NAME}/createAreaField`,
  async (data: AreaFieldValues, { rejectWithValue, dispatch }) => {
    try {
      const response = await areaFieldsApi.createItem<AreaFieldValues, AreaField>(data)
      dispatch(setSuccessNotify('Area field was successfully created'))
      history.push(routes.areaFields)

      return response.data
    } catch (e) {
      dispatch(setErrorNotify("Area field wasn't created"))
      return rejectWithValue(e)
    }
  },
)

export const editAreaField = createAsyncThunk(
  `${AREA_FIELDS_SLICE_NAME}/editAreaField`,
  async (data: AreaFieldEditPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await areaFieldsApi.editItem<AreaFieldEditPayload, AreaField>(data)
      dispatch(setSuccessNotify(`Area field ${data.id} was successfully edited.`))
      history.push(routes.areaFields)
      return response.data
    } catch (e) {
      dispatch(setErrorNotify(`Area field ${data.id} wasn't edited`))
      return rejectWithValue(e)
    }
  },
)
