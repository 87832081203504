import styled from 'styled-components'

import error from 'assets/icons/error.svg'
import lock from 'assets/icons/lock.svg'
import { SkeletonBlock } from 'shared-components/shimmer/style'

import { Props } from './Input'

export const InputContainer = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spaces.min3};
`

export const InputWrapper = styled.div<{
  error?: string
  isColor?: boolean
}>`
  display: flex;
  position: relative;

  &:after {
    display: ${({ error }) => (error ? 'block' : 'none')};
    content: '';
    position: absolute;
    top: calc(50% - 10px);
    right: 10px;
    width: 20px;
    height: 20px;
    background: url(${error});
  }

  &:before {
    display: ${({ isColor }) => (isColor ? 'block' : 'none')};
    content: attr(data-val);
    position: absolute;
    top: calc(50% - 10px);
    left: 14px;
    width: 30px;
    height: 25px;
    text-transform: uppercase;
  }

  &.lock {
    &:after {
      display: block;
      background: url(${lock});
    }
  }
`

export const StyledInput = styled.input<Omit<Props, 'value'> & { isAdditionalValueExist?: boolean }>`
  width: 100%;
  height: 38px;
  padding: ${({ theme }) => `${theme.spaces.min1} ${theme.spaces.min2}`};

  border-radius: ${({ isAdditionalValueExist }) => (isAdditionalValueExist ? '4px 0 0 4px' : '4px')};
  border: 1px solid
    ${({ theme, type, error }) => (error ? theme.colors.error : type === 'file' ? 'none' : theme.colors.border2)};
  outline: none;

  font-size: 16px;
  transition: border-color 0.15s ease-in-out;

  color: ${({ theme }) => theme.colors.gray2};

  &:focus {
    border-color: ${({ theme }) => theme.colors.blue3};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.gray1};
  }
`

export const Error = styled.span`
  display: block !important;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 4px;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  bottom: -13px;
  left: 0;
`

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.black1};
  font-weight: 700;
  margin-bottom: 8px;
  display: block;
  font-size: 16px;
`

export const InputPreloader = styled(SkeletonBlock)`
  width: 100%;
  height: 38px;
`

export const AdditionalEndingBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 38px;
  width: fit-content;
  min-width: 38px;

  background-color: ${({ theme }) => theme.colors.gray1};
  color: ${({ theme }) => theme.colors.gray2};

  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.border2};

  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`
