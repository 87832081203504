import styled from 'styled-components'

export const NotFoundWrapper = styled.div<{
  isOpen: boolean
}>`
  display: flex;
  justify-content: center;

  min-width: ${({ isOpen }) => (isOpen ? '250px' : '75px')};

  @media (max-width: 996px) {
    left: ${({ isOpen }) => (isOpen ? 0 : '-100%')};
  }

  & > div {
    margin-top: 20%;
    display: flex;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;

    & > p {
      padding: 0 ${({ theme }) => theme.spaces.min3};
      color: ${({ theme }) => theme.colors.black1};
    }
  }
`

export const ErrorText = styled.p`
  border-left: 1px solid;
`
