/* eslint-disable  @typescript-eslint/no-explicit-any */

import React, { FC, InputHTMLAttributes } from 'react'

import { StringOrNullType } from 'interfaces'

import { InputContainer, StyledInput, InputWrapper, Error, Label, AdditionalEndingBlock } from './styles'

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: string
  label?: string
  value: any
  additionalValue?: StringOrNullType
}

const Input: FC<Props> = ({ type = 'text', label, className, value, additionalValue, ...props }) => {
  return (
    <InputContainer>
      {label && <Label>{label}</Label>}
      <InputWrapper data-val={value} isColor={type === 'color'} className={className} error={props.error}>
        <StyledInput isAdditionalValueExist={Boolean(additionalValue)} type={type} value={value || ''} {...props} />
        {additionalValue && <AdditionalEndingBlock>{additionalValue}</AdditionalEndingBlock>}
      </InputWrapper>
      <Error>{props.error}</Error>
    </InputContainer>
  )
}

export default Input
