import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faArchive, faCopy, faDownload, faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'

import { ActionButtonsItem, TableDataFormItem } from 'interfaces'
import { RadioV2, DatePicker, Input, Select, Textarea } from 'shared-components'

import { ALL_EVALUATIONS_CATEGORIES } from './all-evaluations'
import { Colors } from './global'

export enum SubmitPositions {
  ACTIONS = 'actions',
  FORM = 'form',
  NONE = 'none',
}

export const QUESTION_SETS_ACTIVE_PAGE_TITLE = 'Question sets'
export const QUESTION_SETS_ACTIVE_LIST_TITLE = 'Question sets list'

export const QUESTION_SETS_ARCHIVE_PAGE_TITLE = 'Question sets(archive)'
export const QUESTION_SETS_ARCHIVE_LIST_TITLE = 'Question sets list(archive)'

export const QUESTION_SETS_VIEW_PAGE_TITLE = 'Show question sets'
export const QUESTION_SETS_ADD_PAGE_TITLE = 'Add question set'

export const QUESTION_SETS_ADD_PAGE_CONTENT_TITLE = 'Question set details'

export const QUESTION_SETS_VIEW_LIST_TITLE = 'Sections and questions'

export const QUESTION_SETS_VIEW_FORM_TITLE = 'Question set details'

export const QUESTION_SETS_EDIT_SUBMIT_TEXT = 'Update question set'

export const QUESTION_SETS_HEADERS = ['Name', 'Version', 'Category', 'Created', 'Updated', 'Author', 'Active']

export const QUESTION_SETS_ARCHIVE_HEADERS = ['Name', 'Version', 'Category', 'Created', 'Updated', 'Author']

export const QUESTION_SETS_ACTIVE_ACTION_BUTTONS: ActionButtonsItem[] = [
  {
    name: 'view',
    icon: faEye as IconDefinition,
    backgroundColor: Colors.GREEN,
  },
  {
    name: 'rename',
    icon: faCopy as IconDefinition,
    backgroundColor: Colors.BLUE,
  },
  {
    name: 'export',
    icon: faDownload as IconDefinition,
    backgroundColor: Colors.BLUE,
  },
  {
    name: 'archive',
    icon: faArchive as IconDefinition,
    backgroundColor: Colors.ORANGE,
  },
  {
    name: 'edit',
    icon: faEdit as IconDefinition,
    backgroundColor: Colors.BLUE,
  },
  {
    name: 'delete',
    icon: faTrash as IconDefinition,
    backgroundColor: Colors.RED,
  },
]

export const QUESTION_SETS_ARCHIVE_ACTION_BUTTONS: ActionButtonsItem[] = [
  {
    name: 'unArchive',
    icon: faArchive as IconDefinition,
    backgroundColor: Colors.ORANGE,
  },
]

export const QUESTION_SETS_CHECKBOXES_FIELDS: TableDataFormItem[] = [
  {
    name: 'active',
    label: 'Is this question set active?',
    type: 'radio',
    component: RadioV2,
  },
  {
    name: 'numbering',
    label: 'Can this question set use numbering?',
    component: RadioV2,
  },
]

export const QUESTION_SETS_SETTINGS_TOP_FIELDS: TableDataFormItem[] = [
  {
    name: 'id',
    label: 'ID',
    type: 'text',
    component: Input,
  },
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    component: Input,
  },
  {
    name: 'version',
    label: 'Version',
    type: 'text',
    component: Input,
  },
]

export const QUESTION_SETS_SETTINGS_BOTTOM_FIELDS: TableDataFormItem[] = [
  {
    name: 'author',
    label: 'Author',
    type: 'text',
    component: Input,
  },
  {
    name: 'category',
    label: 'Category',
    component: Select,
    options: ALL_EVALUATIONS_CATEGORIES,
  },
  {
    name: 'created',
    label: 'Created',
    type: 'text',
    component: DatePicker,
  },
  {
    name: 'updated',
    label: 'Updated',
    type: 'text',
    component: DatePicker,
  },
  {
    name: 'description',
    label: 'Description',
    type: 'text',
    component: Textarea,
    height: 250,
  },
]

export const QUESTION_SETS_ADD_FIELDS: TableDataFormItem[] = [
  {
    name: 'name',
    placeholder: 'Enter evaluation set name',
    label: 'Name',
    type: 'text',
    component: Input,
  },
  {
    name: 'version',
    label: 'Version',
    type: 'text',
    component: Input,
    disabled: true,
    initial: '1',
  },
  {
    name: 'category',
    label: 'Category',
    component: Select,
    options: [
      { name: 'Choose category', value: '' },
      { name: 'Category', value: 'category' },
      { name: 'Audit', value: 'audit' },
      { name: 'Inspection', value: 'inspection' },
      { name: 'Investigation', value: 'investigation' },
      { name: 'PSA Assessment', value: 'psa-assessment' },
      {
        name: 'Self-assessment',
        value: 'self-assessment',
      },
    ],
  },
  ...QUESTION_SETS_CHECKBOXES_FIELDS,
  {
    name: 'description',
    placeholder: 'Enter Description',
    label: 'Description',
    component: Textarea,
    height: 250,
  },
]

export const QUESTION_SETS_VIEW_FORM_FIELDS: TableDataFormItem[] = [
  ...QUESTION_SETS_SETTINGS_TOP_FIELDS,
  ...QUESTION_SETS_SETTINGS_BOTTOM_FIELDS,
]

export const QUESTION_SETS_EDIT_FIELDS: TableDataFormItem[] = [
  ...QUESTION_SETS_SETTINGS_TOP_FIELDS,
  ...QUESTION_SETS_CHECKBOXES_FIELDS,
  ...QUESTION_SETS_SETTINGS_BOTTOM_FIELDS,
]

export const QUESTION_SETS_EDIT_DISABLED_FIELDS = ['id', 'version', 'author', 'category', 'created_at', 'updated_at']
