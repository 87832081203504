import { AxiosRequestConfig } from 'axios'

import { httpApiClientV3 } from 'configs/http-client'
import {
  DeviceInfoToSend,
  Evaluation,
  EvaluationsWithMeta,
  ExtraEvaluationsType,
  KeyWithQuestionAnswer,
} from 'interfaces'

export class MyEvaluationsApi {
  constructor(private url: string) {}

  getMyEvaluations(params?: string, config?: AxiosRequestConfig) {
    return httpApiClientV3.get<EvaluationsWithMeta>(`${this.url}?${params || ''}`, config)
  }

  getOfflineEvaluation(id: number, config?: AxiosRequestConfig) {
    return httpApiClientV3.get<Evaluation>(`${this.url}/${id}`, config)
  }

  getEvaluationAnswers(id: number, config?: AxiosRequestConfig) {
    return httpApiClientV3.get<KeyWithQuestionAnswer>(`${this.url}/${id}/answers`, config)
  }
  saveDeviceRelatedInfo(id: string, data: DeviceInfoToSend, config?: AxiosRequestConfig) {
    return httpApiClientV3.put<Evaluation>(`${this.url}/${id}/download`, data, config)
  }

  unloadEvaluation(id: number, config?: AxiosRequestConfig) {
    return httpApiClientV3.delete(`${this.url}/${id}/deleteDevice`, config)
  }

  getExtraEvaluations(evaluationsIds: number[], config?: AxiosRequestConfig) {
    return httpApiClientV3.post<ExtraEvaluationsType>(
      `${this.url}/check`,
      { evaluationAttempts: evaluationsIds },
      config,
    )
  }
}

export const myEvaluationsApi = new MyEvaluationsApi('/evaluations')
