import React, { FC, ReactNode } from 'react'

import { Colors } from 'constants/global'

import { Content, ContentCardWrapper, Title, Top } from './style'

interface Props {
  children: ReactNode
  title: string
  spaces?: string
  topBackground?: Colors
  topCustomComponent?: ReactNode
  isHideTopCustomComponent?: boolean
  className?: string
  isSticky?: boolean
}

const ContentCard: FC<Props> = ({
  children,
  title,
  spaces,
  topBackground = Colors.BLUE,
  topCustomComponent,
  isHideTopCustomComponent,
  className,
  isSticky,
}) => {
  return (
    <ContentCardWrapper>
      <Top isSticky={isSticky} topBackground={topBackground}>
        <Title>{title}</Title>
        {!isHideTopCustomComponent && topCustomComponent}
      </Top>
      <Content className={className} spaces={spaces}>
        {children}
      </Content>
    </ContentCardWrapper>
  )
}

export default ContentCard
