import styled from 'styled-components'

export const DragListWrapper = styled.ol<{
  isFirst?: boolean
}>`
  & a {
    color: ${({ theme }) => theme.colors.gray13};
    text-decoration: underline;
    &:hover {
      color: ${({ theme }) => theme.colors.gray14};
    }
  }

  & .nestable-item-name ~ .nestable-list {
    padding-left: 20px !important;
    margin-top: 0;
  }

  & .nestable-item {
    margin-top: 0;
    &.is-dragging {
      &:before {
        background-color: #e1eef7;
      }
    }
  }
`

export const ListItemTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ecedf1;
  padding: ${({ theme }) =>
    `${theme.spaces.min2} ${theme.spaces.min3} ${theme.spaces.min2} ${theme.spaces.min6}`} !important;
  min-height: 50px;
`

export const CollapseIconWrapper = styled.div<{
  isCollapsed: boolean
}>`
  margin-right: 12px;
  transition: 0.3s all ease;
  margin-top: -10px;
  transform-origin: 50% 50%;
  transform: rotateZ(${({ isCollapsed }) => (!isCollapsed ? '0deg' : '-90deg')})
    translateX(${({ isCollapsed }) => (!isCollapsed ? '0' : '-5px')});
`

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0;

  & span > svg {
    margin-right: 16px;
    cursor: grab;
  }
`

export const ListItemWrapper = styled.div<{
  isLength?: boolean
}>`
  & > div {
    padding-left: ${({ isLength, theme }) => (!isLength ? theme.spaces.med1 : theme.spaces.min6)};
  }

  & li {
    border: none !important;
  }
`
