import React, { FC, ReactNode } from 'react'

import { Colors } from 'constants/global'

import { ContentContainer } from './style'

interface Props {
  children: ReactNode
  customBackground?: Colors
  isStraight?: boolean
}

const ContentWrapper: FC<Props> = ({ children, ...props }) => {
  return <ContentContainer {...props}>{children}</ContentContainer>
}

export default ContentWrapper
