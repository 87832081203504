import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { format } from 'date-fns'

import { emptyEvaluationApi, fillEvaluationApi, fillEvaluationNotesApi, fillEvaluationTasksApi } from 'apis'
import { DEFAULT_DATE_FORMAT } from 'constants/global'
import { fetchCurrentOfflineUser } from 'db/fetch-db'
import { addNotesFiles } from 'db/files'
import {
  addNewTaskByQuestionId,
  addNewTaskToListById,
  deleteTaskFromTasksList,
  editTaskById,
  editTaskByTaskAndQuestionId,
  removeTaskByQuestionIdAndTask,
  saveArrNotesById,
  saveEvaluationsUsers,
  saveNotesById,
  savePriorities,
  saveTasksById,
} from 'db/helpers'
import { EvaluationIds, IsOnline, KeyWithString, MyNoteIndexed, MyTask, SetTasksCountsPayload } from 'interfaces'
import { convertFileToBase64 } from 'utils/files'

import { setErrorNotify, setSuccessNotify } from '../notifications/action'

export const EVALUATION_ACTIONS_GROUP_SLICE_NAME = 'evaluation-actions-group'

export const asyncGetMyTasks = createAsyncThunk(
  `${EVALUATION_ACTIONS_GROUP_SLICE_NAME}/myTasks`,
  async ({ evaluationId, questionId }: { evaluationId: number; questionId: number }) => {
    const response = await fillEvaluationTasksApi.getMyTasks(evaluationId, questionId)
    return { ...response.data, questionId }
  },
)

export const asyncCreateMyTask = createAsyncThunk(
  `${EVALUATION_ACTIONS_GROUP_SLICE_NAME}/myTask/create`,
  async (
    {
      evaluationId,
      questionId,
      task_id,
      data,
      isOnline,
      tasksCount = 0,
    }: {
      evaluationId: number
      questionId: number
      task_id?: number | null
      data: KeyWithString
      tasksCount?: number
    } & IsOnline,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const formattedCompleteBefore = format(new Date(data?.completionDue), DEFAULT_DATE_FORMAT)

      const offlineTask = {
        complete_before: new Date(data?.completionDue).toISOString() || null,
        assign_id: Number(data?.assignedTo),
        content: data?.recommendation || '',
        priority: Number(data?.priority),
      } as MyTask
      if (isOnline) {
        const response = await fillEvaluationTasksApi.createMyTask(evaluationId, questionId, {
          data: { ...data, completionDue: formattedCompleteBefore },
          task_id,
        })

        if (task_id) {
          dispatch(updateTaskAction(response.data.task))
          await editTaskById(questionId, response.data.task)
        } else {
          await addNewTaskToListById(questionId, evaluationId, response.data.task)
          dispatch(setTaskAction(response.data.task))
          dispatch(setTasksCounts({ count: tasksCount + 1, questionId }))
        }
      } else {
        if (task_id) {
          await editTaskByTaskAndQuestionId(questionId, evaluationId, Number(task_id), offlineTask)
          dispatch(updateTaskAction({} as MyTask))
        } else {
          await addNewTaskByQuestionId(questionId, evaluationId, offlineTask)
          dispatch(setTaskAction(offlineTask))
        }
      }
      dispatch(setSuccessNotify(task_id ? 'Task was successfully updated' : 'Task was successfully created'))

      return null
    } catch (err: unknown) {
      if (window.navigator.onLine) dispatch(setErrorNotify(task_id ? "Task wasn't updated" : "Task wasn't created"))
      return rejectWithValue(err)
    }
  },
)

export const asyncGetMyNote = createAsyncThunk(
  `${EVALUATION_ACTIONS_GROUP_SLICE_NAME}/note`,
  async (
    {
      evaluationId,
      questionId,
    }: {
      evaluationId: number
      questionId: number
    },
    { dispatch },
  ) => {
    const response = await fillEvaluationNotesApi.getMyNote(evaluationId, questionId)

    dispatch(
      setNoteAction({
        content: response.data.note?.content || '',
        id: questionId,
      }),
    )
  },
)

export const asyncCreateNote = createAsyncThunk(
  `${EVALUATION_ACTIONS_GROUP_SLICE_NAME}/note/create`,
  async (
    {
      evaluationId,
      questionId,
      data,
      isOnline,
    }: {
      evaluationId: number
      questionId: number
      data: KeyWithString
    } & IsOnline,
    { dispatch, rejectWithValue },
  ) => {
    try {
      if (isOnline) {
        const response = await fillEvaluationNotesApi.createMyNote(evaluationId, questionId, data)
        dispatch(
          setNoteAction({
            content: response.data.note.content,
            id: questionId,
          }),
        )
      }
      await saveNotesById(questionId, evaluationId, data?.content as string)
      dispatch(setSuccessNotify('Note successfully created'))
    } catch (err: unknown) {
      dispatch(setErrorNotify("Note wasn't created"))
      return rejectWithValue(err)
    }
  },
)

export const asyncSyncEvaluationNotesFiles = createAsyncThunk(
  `${EVALUATION_ACTIONS_GROUP_SLICE_NAME}/asyncSyncEvaluationNotesFiles`,
  async (
    {
      evaluationId,
      questionId,
      files,
    }: {
      files: File[]
    } & EvaluationIds,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const formData = new FormData()

      files.map((file) => formData.append('file[]', file))

      await fillEvaluationApi.createEvaluationFiles(evaluationId, questionId, formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })

      return undefined
    } catch (err: any) {
      dispatch(setErrorNotify("File wasn't uploaded"))
      return rejectWithValue(err.response.data)
    }
  },
)

export const asyncUploadEvaluationNotesFiles = createAsyncThunk(
  `${EVALUATION_ACTIONS_GROUP_SLICE_NAME}/asyncUploadEvaluationNotesFiles`,
  async (
    {
      evaluationId,
      questionId,
      files,
      isOnline,
    }: {
      files: File[]
    } & EvaluationIds &
      IsOnline,
    { dispatch, rejectWithValue },
  ) => {
    try {
      if (isOnline) {
        const formData = new FormData()

        files.map((file) => formData.append('file[]', file))

        const response = await fillEvaluationApi.createEvaluationFiles(evaluationId, questionId, formData, {
          headers: { 'Content-type': 'multipart/form-data' },
        })

        dispatch(setSuccessNotify('File successfully uploaded'))

        return { questionId, files: response.data.data }
      }

      const offlineUser = await fetchCurrentOfflineUser()

      const preparedFilesToSave = await Promise.all(
        files?.map(async (file) => ({
          id: Math.random() * 10000000,
          evaluationId,
          questionId,
          file,
          user_id: offlineUser?.id,
          base64: (await convertFileToBase64(file)) as string,
        })),
      )

      await addNotesFiles(preparedFilesToSave)

      return null
    } catch (err: any) {
      dispatch(setErrorNotify("File wasn't uploaded"))
      return rejectWithValue(err.response.data)
    }
  },
)

export const deleteEvaluationNote = createAsyncThunk(
  `${EVALUATION_ACTIONS_GROUP_SLICE_NAME}/deleteEvaluationNote`,
  async (params: EvaluationIds, { dispatch }) => {
    await fillEvaluationApi.deleteMyNote(params)

    dispatch(
      setNoteAction({
        content: '',
        id: params?.questionId,
      }),
    )
  },
)

export const getEvaluationNotesFiles = createAsyncThunk(
  `${EVALUATION_ACTIONS_GROUP_SLICE_NAME}/getEvaluationNotesFiles`,
  async (params: EvaluationIds, { rejectWithValue }) => {
    try {
      const response = await fillEvaluationApi.getEvaluationFiles(params)

      return { [params.questionId]: response.data.data }
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const deleteEvaluationNotesFile = createAsyncThunk(
  `${EVALUATION_ACTIONS_GROUP_SLICE_NAME}/deleteEvaluationNotesFile`,
  async ({ file, ...params }: EvaluationIds & { file: string }, { dispatch, rejectWithValue }) => {
    try {
      await fillEvaluationApi.deleteEvaluationFile({ ...params, file })

      dispatch(setSuccessNotify('File was successfully deleted'))

      return { questionId: params.questionId, file }
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const asyncDeleteTask = createAsyncThunk(
  `${EVALUATION_ACTIONS_GROUP_SLICE_NAME}/task/delete`,
  async (
    {
      evaluationId,
      questionId,
      taskId,
      isOnline,
      tasksCount,
    }: {
      evaluationId: number
      questionId: number
      taskId: number
      tasksCount?: number
    } & IsOnline,
    { dispatch, rejectWithValue },
  ) => {
    try {
      if (isOnline) {
        const response = await fillEvaluationTasksApi.deleteMyTask(evaluationId, questionId, taskId)

        if (response.data.deleted) {
          dispatch(setTasksCounts({ count: tasksCount ? tasksCount - 1 : 0, questionId }))
          dispatch(setSuccessNotify('Task successfully deleted.'))
          await deleteTaskFromTasksList(questionId, taskId)
          return taskId
        }
        return -1
      } else {
        await removeTaskByQuestionIdAndTask(questionId, taskId, evaluationId)
        dispatch(setSuccessNotify('Task successfully deleted.'))
        return Number(taskId)
      }
    } catch (err) {
      if (window.navigator.onLine) dispatch(setErrorNotify('Please contact the administrator.'))
      return rejectWithValue('Something went wrong.')
    }
  },
)

export const asyncGetMyTasksListAndNotes = createAsyncThunk(
  `${EVALUATION_ACTIONS_GROUP_SLICE_NAME}/tasksList`,
  async ({ evaluationId, questionId }: { evaluationId: number; questionId: number }) => {
    const {
      data: { tasks, users, priorities, precannedTasks },
    } = await fillEvaluationApi.getTasksList(evaluationId)

    const notes = await fillEvaluationApi.getNotesList(evaluationId)

    const tasksToSave = Object.keys(tasks).map((key) => {
      const taskId = Number(key)

      return {
        evaluationId,
        questionId,
        id: taskId,
        tasks: tasks[taskId],
        users,
        priorities,
        precannedTasks,
      }
    })

    const usersToSave = users.map(({ id, name }) => ({ id, name }))

    await saveTasksById(tasksToSave)
    await saveEvaluationsUsers(usersToSave, evaluationId)
    await savePriorities(priorities)

    const notesToSave: MyNoteIndexed[] = Object.keys(notes.data).map((key) => ({
      ...notes.data[key][0],
      id: Number(key),
      evaluationId,
    }))
    await saveArrNotesById(notesToSave)
  },
)

export const setNoteAction = createAction<{
  content: string
  id: number
}>('SET_NOTE')

export const setFileAction = createAction<{
  user_id?: number
  evaluation_id?: number
  originalName?: string
  type?: string
  size?: number
  file?: string
  fileURL?: string
}>('SET_FILE_NOTE')

export const setIsTasksOpen = createAction<boolean>('SET_IS_TASKS_OPEN')
export const setIsNotesOpen = createAction<boolean>('SET_IS_NOTES_OPEN')
export const setTaskAction = createAction<MyTask>('SET_TASK')
export const updateTaskAction = createAction<MyTask>('UPDATE_TASK')
export const removeTaskAction = createAction<number>('REMOVE_TASK')
export const setTasksQuestionIdAction = createAction<number>('SET_NOTES_QUESTION_ID_ACTION')
export const setNotesQuestionIdAction = createAction<number>('SET_TASKS_QUESTION_ID_ACTION')
export const setTasksCounts = createAction<SetTasksCountsPayload>('SET_TASKS_COUNTS')
