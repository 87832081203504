import * as Yup from 'yup'

export const VALIDATION_PATTERNS = {
  email: Yup.string().required('The email field is required').email('Invalid email address'),
  password: Yup.string().required('The password field is required'),
}

export const REGEX_PATTERNS = {
  betweenEightAndTwenty: /^.{8,20}$/i,
  capitalLetter: /[A-Z]/,
  smallLetter: /[a-z]/,
  oneNumber: /\d/,
  specialCharacter: /^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/,
}
