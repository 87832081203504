import { ActionReducerMapBuilder, createEntityAdapter } from '@reduxjs/toolkit'

import { DashboardValues } from 'interfaces'

import { getDashboardInfo } from './action'

export const dashboardAdapter = createEntityAdapter<DashboardValues>()

export function dashboardInfoReducer(builder: ActionReducerMapBuilder<DashboardValues>) {
  builder.addCase(getDashboardInfo.fulfilled, (_, action) => action.payload)
}
