import React, { FC } from 'react'

import { Outlet, Navigate } from 'react-router-dom'

const ProtectedRoute: FC<{
  isAuth: boolean | null | string
  to: string
}> = ({ isAuth, to }) => {
  return isAuth ? <Outlet /> : <Navigate to={to} replace />
}

export default ProtectedRoute
