import styled from 'styled-components'

import { Colors } from 'constants/global'

export const Additional = styled.div<{ bg: Colors }>`
  padding: ${({ theme }) => `${theme.spaces.min3} ${theme.spaces.min5}`};
  background: ${({ theme, bg }) => (bg ? theme.colors[bg] : '#fff')};
  display: flex;

  & > a,
  & > button {
    margin-right: ${({ theme }) => theme.spaces.min1};
  }
`
