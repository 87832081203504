import { format } from 'date-fns'

import { EntityTypes } from 'constants/evaluations'
import { DEFAULT_DATE_FORMAT } from 'constants/global'
import { fetchDeletedNotes, fetchFilesToDeleteById, fetchNewNotesFilesById } from 'db/fetch-db'
import { db } from 'db/mainDb'
import {
  CustomFileRetrieve,
  DownloadedQuestionPictureFiles,
  EvaluationCompletionInfo,
  EvaluationCreate,
  EvaluationCreatePayload,
  EvaluationIds,
  KeyWithQuestionAnswer,
  KeyWithString,
  MyDeletedTaskIndexed,
  MyNoteIndexed,
  MyTaskToRequest,
  NotesFile,
  OfflineCompletionType,
  Question,
  QuestionOptions,
  QuestionType,
  StringOrNullType,
  UploadFile,
} from 'interfaces'
import { CheckboxV3, PictureRadio } from 'shared-components'
import RadioV1 from 'shared-components/radio/radio-v1/RadioV1'

import { getBase64FromUrl } from './global'

export const getFillEvaluationMapComponents = (type: QuestionType): any => {
  switch (type) {
    case QuestionType.COLOR:
    case QuestionType.SINGLE:
      return RadioV1

    case QuestionType.PICTURE:
      return PictureRadio

    case QuestionType.MULTIPLE:
      return CheckboxV3
    default:
      break
  }
}

export const getOfflineSyncData = async (
  id: number,
): Promise<
  {
    tasks: MyTaskToRequest[]
    deletedTasks: MyDeletedTaskIndexed[]
    notes: MyNoteIndexed[]
    deletedNotes: EvaluationIds[]
    newFiles: NotesFile[]
    filesToDelete: UploadFile[]
  } & OfflineCompletionType
> => {
  const newTasks = (await db.newTasks.where({ evaluationId: id }).toArray()) || []
  const editedTasks = (await db.editedTasks.where({ evaluationId: id }).toArray()) || []
  const deletedTasks = (await db.deletedTasks.where({ evaluationId: id }).toArray()) || []
  const notes = (await db.notes.where({ evaluationId: id }).toArray()) || []
  const evaluation = await db.fullList.get({ id })
  const deletedNotes = await fetchDeletedNotes(id)
  const newFiles = await fetchNewNotesFilesById(id)
  const filesToDelete = await fetchFilesToDeleteById(id)

  const tasks = [
    ...editedTasks,
    ...(newTasks || []).map(({ id, task: { id: taskId, ...taskRest }, ...rest }) => ({
      ...rest,
      task: {
        ...taskRest,
      },
    })),
  ]

  return {
    tasks,
    deletedTasks,
    notes,
    deletedNotes,
    newFiles,
    filesToDelete,
    feedback: evaluation?.feedback,
    shouldBeCompleted: evaluation?.shouldBeCompleted,
  }
}

export const getFlatEvaluationQuestions = (questions: Question[], onlyConditionalSections?: boolean) => {
  const flatQuestions: Question[] = []

  questions?.forEach((question) => {
    if (question.entityType === EntityTypes.QUESTION) {
      flatQuestions.push(question)
    } else if (question.entityType === EntityTypes.SECTION) {
      const { questions, ...section } = question

      if (section.conditional && onlyConditionalSections) flatQuestions.push(section)

      const nestedQuestions = getFlatEvaluationQuestions(questions || [])
      flatQuestions.push(...nestedQuestions)
    }
  })

  return flatQuestions
}

export const getFlatEvaluationQuestionsWithSections = (questions: Question[]): Question[] => {
  const processQuestions = (nestedQuestions: Question[]): Question[] => {
    return nestedQuestions.flatMap(({ questions, ...rest }) => {
      const subQuestions = questions ? processQuestions(questions) : []
      return [{ questions: subQuestions, ...rest }, ...subQuestions]
    })
  }

  return questions ? processQuestions(questions) : []
}

export const getDisplayQuestions = (questions: Question[]) =>
  questions.reduce(
    (acc, item) => ({
      ...acc,
      ...(item.entityType === EntityTypes.QUESTION || (item.entityType === EntityTypes.SECTION && item.conditional)
        ? { [String(item.id)]: item.answer || '' }
        : {}),
      // TODO - remove 2 lines below, when array inside string will be correct
      ...(item.type === QuestionType.MULTIPLE &&
        item?.answer?.includes('[') && { [String(item.id)]: Array.from(JSON.parse(item.answer)) }),
    }),
    {},
  )

export const getSectionCompletionContent = (
  completion?: string | number,
  answersCount?: number,
  questionsLength?: number,
) => (questionsLength ? `Completion: ${completion}% - ${answersCount}/${questionsLength}` : null)

export const getSectionsAndQuestionsAnswers = (allQuestions?: Question[], withSections?: boolean): number => {
  if (!allQuestions?.length) return 0

  return allQuestions?.reduce((acc, { entityType, answer, questions }) => {
    // if section is conditional and has "No" answer
    if (entityType === EntityTypes.SECTION && answer === '0') {
      return acc + (questions?.length || 0) + (withSections ? 1 : 0)
    }

    if (entityType == EntityTypes.SECTION && answer === '1' && withSections) {
      return acc + getSectionsAndQuestionsAnswers(questions) + 1
    }

    // if section has "Yes" answer
    if (entityType == EntityTypes.SECTION) {
      return acc + getSectionsAndQuestionsAnswers(questions)
    }

    // if question
    return Boolean(answer) || answer === '0' ? acc + 1 : acc
  }, 0)
}

export const getFlatEvaluationQuestionsInfo = (
  questions: Question[],
  sectionAnswer?: StringOrNullType,
): EvaluationCompletionInfo => {
  const flatQuestionsWithSections = getFlatEvaluationQuestions(questions, false)
  const displayQuestions = getDisplayQuestions(flatQuestionsWithSections)
  const questionsLength = Object.entries(displayQuestions)?.length || 0

  const answersCount = String(sectionAnswer) === '0' ? questionsLength : getSectionsAndQuestionsAnswers(questions)

  const completionPercent = Number(((answersCount / questionsLength) * 100).toFixed(2) || 0)

  return {
    questionsLength,
    answersCount,
    completionPercent,
    completionContent: getSectionCompletionContent(completionPercent, answersCount, questionsLength),
  }
}

// Completion info for full Evaluation!
export function getFlatAndFilteredEvaluationCompletion(data: Question[]) {
  let questions: Question[] = []

  function traverse(arr: Question[]) {
    arr.forEach((item: Question) => {
      if (item.entityType === EntityTypes.SECTION && !item.conditional) {
        traverse(item.questions || [])
      } else {
        questions.push(item)
        if (item.questions && Array.isArray(item.questions)) {
          traverse(item.questions)
        }
      }
    })
  }

  traverse(data)

  questions = questions.filter((i: Question) => (i.entityType === EntityTypes.SECTION ? i.conditional : true))

  const answersCount = getSectionsAndQuestionsAnswers(data, true)
  const completionPercent = Number(((answersCount / questions?.length) * 100).toFixed(2) || 0)

  return {
    completionContent: getSectionCompletionContent(completionPercent, answersCount, questions?.length),
    answersCount,
    questionsLength: questions?.length,
  }
}

export const changeEvaluationQuestionsAnswer = (questions: Question[], answers: KeyWithString): Question[] => {
  return questions.map((i) => {
    return {
      ...i,
      answer: answers[i.id] || null,
      ...(i?.questions?.length && { questions: changeEvaluationQuestionsAnswer(i.questions, answers) }),
    }
  })
}

export const changeEvaluationQuestionAnswerById = (questions: Question[], id: number, answer: string): Question[] => {
  return questions.map((i) => {
    return {
      ...i,
      answer: i.id === id ? answer : i.answer,
      ...(i?.questions?.length && { questions: changeEvaluationQuestionAnswerById(i.questions, id, answer) }),
      ...(i?.entityType === EntityTypes.SECTION && i?.conditional && i.id === id && answer === '0'
        ? { questions: i?.questions?.map((i) => ({ ...i, answer: null })) }
        : {}),
    }
  })
}

export const getCorrectAnswerValue = (answer: string | number | string[]): string | number => {
  const isString = typeof answer === 'string'

  if (Array.isArray(answer)) return answer.join(', ')
  else if (isString && answer?.includes('[')) return Array.from(JSON.parse(answer)).join(', ')
  else if (isString) return answer?.replace('["', '').replace('"]', '')

  return answer
}

export const findQuestions = (questions: Question[]): number => {
  if (!questions || questions.length === 0) {
    return 0
  }

  let totalQuestions = 0

  questions.forEach((question) => {
    if (question.entityType === EntityTypes.QUESTION) {
      totalQuestions++
    } else if (question.entityType === EntityTypes.SECTION) {
      const nestedQuestions = findQuestions(question.questions || [])
      totalQuestions += nestedQuestions
    }
  })

  return totalQuestions
}

export const getAnswersCountInfo = (
  questions: Question[],
): {
  questions: Question[]
  totalAnswersCount: number
  positiveAnswersCount: number
} => {
  const answers = getFlatEvaluationQuestions(questions)
  return {
    questions: answers.filter((i) => !i.answer),
    totalAnswersCount: findQuestions(questions),
    positiveAnswersCount: answers.filter(
      (i) => i.answer && (i.entityType === EntityTypes.SECTION ? i.conditional : true),
    )?.length,
  }
}

export const getFinishEvaluationFormattedAnswer = (answer: StringOrNullType, type: QuestionType) => {
  if (type === QuestionType.CONDITIONAL || type === QuestionType.CONDITIONAL_REVERSE)
    return Boolean(Number(answer)).toString()
  else if (answer?.includes('[')) return answer?.replace('["', '').replace('"]', '')
  return answer
}

export const getCorrectToSendEvaluationData = (data: EvaluationCreate): EvaluationCreatePayload => {
  const { title, active, scheduled_at, deadline_at, area_id, category_id, question_set_id, enabledVerification } = data

  return {
    active: !!active,
    area_id: Number(area_id),
    category_id: Number(category_id),
    question_set_id: Number(question_set_id),
    enabledVerification,
    title,
    scheduled_at: format(new Date(scheduled_at || ''), DEFAULT_DATE_FORMAT),
    deadline_at: format(new Date(deadline_at || ''), DEFAULT_DATE_FORMAT),
  }
}

export const getFormattedOfflineOptions = ({
  options,
  downloadedPictures,
  id,
}: {
  options?: QuestionOptions
  downloadedPictures: DownloadedQuestionPictureFiles[]
  id: number
}) => {
  if (!options?.picture || !downloadedPictures?.length) return options

  const downloadedFilesById = downloadedPictures?.find((file) => file.id === id)

  const formattedPictures = options?.picture?.files?.map(({ file, ...restFile }, index) => ({
    file: { file: downloadedFilesById?.files[index], ...restFile },
  }))
  return {
    ...options,
    picture: {
      ...(options?.picture as any),
      files: formattedPictures,
    },
  }
}

export async function processEvaluationQuestionPicture(file: CustomFileRetrieve | { file: string }) {
  return new Promise((resolve, reject) => {
    getBase64FromUrl(file.file)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function processEvaluationQuestionPicturesInArray(obj: DownloadedQuestionPictureFiles) {
  const filePromises = obj.files.map((fileItem) => processEvaluationQuestionPicture(fileItem?.file))
  const responses = await Promise.all(filePromises)
  return { id: obj.id, files: responses }
}

export async function processEvaluationQuestionsPictures(data: any) {
  return Promise.all(data.map((obj: any) => processEvaluationQuestionPicturesInArray(obj)))
}

export const getQuestionsWithFormattedOptionsAndAnswers = ({
  questions,
  answers,
  downloadedPictures,
}: {
  questions?: Question[]
  answers: KeyWithQuestionAnswer
  downloadedPictures: DownloadedQuestionPictureFiles[]
}): any => {
  if (!questions?.length) return []

  return questions.map((i) => ({
    ...i,
    answer: answers[i.id]?.answer || null,
    options: getFormattedOfflineOptions({ id: i.id, options: i?.options, downloadedPictures }),
    questions: i?.questions?.length
      ? getQuestionsWithFormattedOptionsAndAnswers({ questions: i.questions, answers, downloadedPictures })
      : [],
  }))
}

export const getEvaluationTextByScore = (currentScore: number, isOnline?: boolean) => {
  if (currentScore === 100 && isOnline) return 'Upload'

  if (currentScore > 0 || (currentScore === 100 && !isOnline)) return 'Continue'

  return 'Start'
}
