import React, { useContext, useEffect } from 'react'

import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'

import { LOGIN_BUTTON_LABEL, LOGIN_FIELDS, REMEMBER_ME_FIELD } from 'constants/auth'
import { routes } from 'constants/routes'
import { NetworkStatusContext } from 'contexts/NetworkContext'
import { IsOnline, LoginPayload } from 'interfaces'
import { useAppDispatch, useAppSelector } from 'modules/store'
import { asyncLogin, getUserInfo } from 'modules/user/action'
import { selectIsAuthOptions } from 'modules/user/selectors'
import { AuthContent } from 'page-components'
import { getFormikProps, getFormikInitialValues, getFormikValidationSchema } from 'utils'

const DEVICE_NAME = 'Postman'

const Login = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isOnline = useContext(NetworkStatusContext)

  const formikLoginFields = [...LOGIN_FIELDS, REMEMBER_ME_FIELD]

  const { isAuth, isTwoFactor, isLoaded } = useAppSelector(selectIsAuthOptions)

  const formik = useFormik({
    initialValues: getFormikInitialValues(formikLoginFields),
    onSubmit: (values) => {
      dispatch(
        asyncLogin({
          ...values,
          device_name: DEVICE_NAME,
          isOnline,
        } as LoginPayload & IsOnline),
      )
    },
    validationSchema: getFormikValidationSchema(formikLoginFields),
    validateOnBlur: false,
    validateOnChange: false,
  })
  const formikProps = getFormikProps(formik)

  useEffect(() => {
    if (!isAuth && isTwoFactor) {
      navigate(routes.verify)
    }
    if (isAuth) dispatch(getUserInfo({ isOnline }))
    /* eslint-disable-next-line */
  }, [isTwoFactor, isAuth, dispatch, isLoaded])

  return (
    <AuthContent
      formikProps={formikProps}
      fields={LOGIN_FIELDS}
      title={''}
      isShownCheckbox={true}
      submitButtonProps={{ label: LOGIN_BUTTON_LABEL }}
    />
  )
}

export default Login
