import React, { FC, useContext, useMemo } from 'react'

import { useLiveQuery } from 'dexie-react-hooks'

import { ReactComponent as EditIcon } from 'assets/icons/pencil.svg'
import { Colors } from 'constants/global'
import { NetworkStatusContext } from 'contexts/NetworkContext'
import { db } from 'db/mainDb'
import { useAppSelector } from 'modules/store'
import { selectUserId, selectUserPinCode } from 'modules/user/selectors'
import { Button } from 'shared-components'

import { UserPinCodeTitle, UserPinCodeValue, UserPinCodeWrapper } from './style'

interface Props {
  handleOpenModal: VoidFunction
}

const UserPinCode: FC<Props> = ({ handleOpenModal }) => {
  const userId = useAppSelector(selectUserId)
  const userPinCode = useAppSelector(selectUserPinCode)

  const isOnline = useContext(NetworkStatusContext)

  const { authPinCode = '' } = useLiveQuery(() => db.users.get({ id: userId || '' }), [userId, userPinCode]) || {}

  const pinCodeShownValue = useMemo(() => {
    if (!authPinCode) return '_ _ _ _'

    return authPinCode
  }, [authPinCode])

  if (!isOnline) return null

  return (
    <>
      <UserPinCodeWrapper onClick={handleOpenModal}>
        <UserPinCodeTitle>Unique User Code:</UserPinCodeTitle>
        <UserPinCodeValue>{pinCodeShownValue}</UserPinCodeValue>
        <Button backgroundColor={Colors.TRANSPARENT}>
          <EditIcon />
        </Button>
      </UserPinCodeWrapper>
    </>
  )
}

export default UserPinCode
