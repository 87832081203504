import React, { FC } from 'react'

import { Link } from 'react-router-dom'

import ProEvWhite from 'assets/icons/proevwhite.svg'
import { SIDEBAR_NAV_ITEMS, HOME_SIDEBAR_IMAGE_SIZE_HEIGHT, HOME_SIDEBAR_IMAGE_SIZE_WIDTH } from 'constants/home'
import { routes } from 'constants/routes'
import { SidebarNavItem } from 'page-components'

import { SidebarContainer, SidebarHeader, SidebarNavigation, SidebarShadow } from './style'

export interface Props {
  isOpen: boolean
  handleOpenSidebar: VoidFunction
}

const Sidebar: FC<Props> = ({ isOpen, handleOpenSidebar }) => {
  return (
    <>
      <SidebarContainer isOpen={isOpen}>
        <SidebarHeader>
          <div style={{ width: HOME_SIDEBAR_IMAGE_SIZE_WIDTH, height: HOME_SIDEBAR_IMAGE_SIZE_HEIGHT }}>
            <Link to={routes.myEvaluations}>
              <img src={ProEvWhite} alt='logo' />
            </Link>
          </div>
        </SidebarHeader>
        <SidebarNavigation isOpen={isOpen}>
          <ul>
            {SIDEBAR_NAV_ITEMS.map((item) => (
              <SidebarNavItem {...item} key={item.name} isOpen={isOpen} />
            ))}
          </ul>
        </SidebarNavigation>
      </SidebarContainer>
      <SidebarShadow isShown={isOpen} onClick={handleOpenSidebar} />
    </>
  )
}

export default Sidebar
