import React, { FC, useMemo } from 'react'

import { REGEX_PATTERNS } from 'constants/validation-patterns'

import PasswordCheckItem from './PasswordCheckItem'
import PasswordComplexityBlock from './PasswordComplexityBlock'
import { PasswordCheckWidgetWrapper } from './style'

export interface PasswordCheck {
  id: string
  text: string
  boldText?: string
  pattern: string | RegExp
}

interface Props {
  isOpen?: boolean
  password: string
  points?: PasswordCheck[]
}

const PASSWORD_CHECK_POINTS: PasswordCheck[] = [
  {
    id: 'length',
    text: '8-20 ',
    boldText: 'Characters',
    pattern: REGEX_PATTERNS.betweenEightAndTwenty,
  },
  {
    id: 'capitalLetter',
    text: 'At least ',
    boldText: 'one Capital letter',
    pattern: REGEX_PATTERNS.capitalLetter,
  },
  {
    id: 'smallLetter',
    text: 'At least ',
    boldText: 'one Small letter',
    pattern: REGEX_PATTERNS.smallLetter,
  },
  {
    id: 'atLeastOneNumber',
    text: 'At least ',
    boldText: 'one Number',
    pattern: REGEX_PATTERNS.oneNumber,
  },
  {
    id: 'specialCharacter',
    text: 'At least ',
    boldText: 'one Special Character',
    pattern: REGEX_PATTERNS.specialCharacter,
  },
]

const PasswordCheckWidget: FC<Props> = ({ isOpen, password, points = PASSWORD_CHECK_POINTS }) => {
  const numberPasswordComplexity = useMemo(() => {
    return points?.reduce((acc, { pattern }) => {
      const isFilled = new RegExp(pattern).test(password)

      return isFilled ? acc + 1 : acc
    }, 0)
  }, [password, points])

  if (!isOpen) return null

  return (
    <PasswordCheckWidgetWrapper>
      <PasswordComplexityBlock numberedPasswordComplexity={numberPasswordComplexity} />
      {points?.length
        ? points.map(({ pattern, ...pointProps }) => (
            <PasswordCheckItem key={pointProps.id} filled={new RegExp(pattern).test(password)} {...pointProps} />
          ))
        : null}
    </PasswordCheckWidgetWrapper>
  )
}

export default PasswordCheckWidget
