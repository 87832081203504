import { useContext, useEffect, useState } from 'react'

import { useLiveQuery } from 'dexie-react-hooks'

import { NetworkStatusContext } from 'contexts/NetworkContext'
import { saveEvaluationsToDB } from 'db/helpers'
import { db } from 'db/mainDb'
import { HandleOpenAndSubmitConfirmType } from 'hooks/useConfirm'
import { Evaluation } from 'interfaces'
import { asyncCheckExtraEvaluations } from 'modules/evaluations/action'
import { selectMyEvaluations } from 'modules/evaluations/selectors'
import { useAppDispatch, useAppSelector } from 'modules/store'

const useMyEvaluations = ({
  handleOpenRemoveEvaluationsConfirm,
}: {
  handleOpenRemoveEvaluationsConfirm: HandleOpenAndSubmitConfirmType
}): {
  isEvaluationsLength: boolean
  isDownloadedItemsLength: boolean
  evaluations: Evaluation[]
  extraOfflineEvaluations: Evaluation[]
} => {
  const dispatch = useAppDispatch()

  const [extraOfflineEvaluations, setExtraOfflineEvaluations] = useState<Evaluation[]>([])

  const isOnline = useContext(NetworkStatusContext)

  const downloadedOfflineEvaluations = useLiveQuery(() => db.fullList.toArray())

  const filteredOfflineEvaluations = downloadedOfflineEvaluations?.filter((i) => !i?.shouldBeCompleted)

  const isDownloadedItemsLength = useLiveQuery(() => db.fullList.toArray())?.length

  const myOnlineEvaluations = useAppSelector(selectMyEvaluations)

  const isEvaluationsLength = isOnline ? myOnlineEvaluations?.length : downloadedOfflineEvaluations?.length

  const evaluations = (isOnline ? myOnlineEvaluations : filteredOfflineEvaluations) || []

  useEffect(() => {
    saveEvaluationsToDB(myOnlineEvaluations).then(() => '')
  }, [myOnlineEvaluations])

  useEffect(() => {
    if (isOnline && downloadedOfflineEvaluations?.length) {
      const offlineEvaluationsIds = downloadedOfflineEvaluations?.map((evaluation) => evaluation?.id)

      dispatch(asyncCheckExtraEvaluations(offlineEvaluationsIds as number[]))
        .unwrap()
        .then((evaluations) => {
          if (!evaluations?.length) return

          const newExtraEvaluations = downloadedOfflineEvaluations.filter(({ id }) => id && evaluations.includes(id))

          setExtraOfflineEvaluations(newExtraEvaluations)
          handleOpenRemoveEvaluationsConfirm(0, evaluations)
        })
    }
  }, [dispatch, downloadedOfflineEvaluations, handleOpenRemoveEvaluationsConfirm, isOnline])

  return {
    isEvaluationsLength: Boolean(isEvaluationsLength),
    isDownloadedItemsLength: Boolean(isDownloadedItemsLength),
    evaluations,
    extraOfflineEvaluations,
  }
}

export default useMyEvaluations
