import styled from 'styled-components'

export const DatePickerWrapper = styled.div<{
  error?: string
}>`
  position: relative;
  margin-bottom: 4px;
  width: 100%;
  & input {
    width: 100%;
    height: 38px;
    padding: ${({ theme }) => `${theme.spaces.min1} ${theme.spaces.min2}`};
    padding-right: ${({ theme }) => `${theme.spaces.med1}`};

    background-color: ${({ theme }) => theme.colors.gray1};
    border-radius: 4px;
    border: 1px solid ${({ theme, error }) => (error ? theme.colors.error : theme.colors.border2)};
    outline: none;

    font-size: 16px;
    transition: border-color 0.15s ease-in-out;

    color: ${({ theme }) => theme.colors.gray2};

    &:focus {
      border-color: ${({ theme }) => theme.colors.blue3};
    }

    &:disabled {
      background: ${({ theme }) => theme.colors.gray1};
    }

    &::-webkit-color-swatch {
      background-color: transparent !important;
      border: none;
    }
  }

  & .react-datepicker__triangle {
    transform: translate(20px, 0px) !important;
  }
`
