import styled, { keyframes } from 'styled-components'

const lineAnim = keyframes`
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
`

export const LoadingLineBlock = styled.div<{
  isAbsolute?: boolean
}>`
  width: 100vw;
  height: 3px;
  position: ${({ isAbsolute }) => (isAbsolute ? 'absolute' : 'fixed')};
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: #ddd;
  border-radius: 20px;
  z-index: 1005;
  &:before {
    content: '';
    position: absolute;
    left: -50%;
    height: 3px;
    width: 40%;
    background-color: ${({ theme }) => theme.colors.blue1};
    -webkit-animation: ${lineAnim} 2s linear infinite;
    -moz-animation: ${lineAnim} 2s linear infinite;
    animation: ${lineAnim} 2s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }
`
