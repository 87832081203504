import { QuestionType, TableDataFormItem } from 'interfaces'
import { RadioV2, DatePicker, Input, Select, Textarea, TextEditor } from 'shared-components'

export const QUESTION_SETS_PREVIEW_TITLE = 'Preview question set'

export const QUESTION_SETS_BOTTOM_NAVIGATE_TEXT = 'Back to question set'

export const QUESTION_SETS_EDIT_TITLE = 'Edit question set'
export const QUESTION_SETS_QUESTION_EDIT_SUBTITLE = 'Edit question'
export const QUESTION_SETS_SECTION_EDIT_SUBTITLE = 'Edit section'

export const QUESTION_SETS_QUESTION_CREATE_SUBTITLE = 'Create question'
export const QUESTION_SETS_SECTION_CREATE_SUBTITLE = 'Create section'

export const QUESTION_SETS_ADD_SECTION_TITLE = 'Add new section'
export const QUESTION_SETS_ADD_QUESTION_TITLE = 'New question'
export const QUESTION_SETS_ADD_SCORE_TITLE = 'Update section scoring'
export const QUESTION_SETS_COPY_SECTION_TITLE = 'Copy section'
export const QUESTION_SETS_COPY_QUESTION_TITLE = 'Copy question'

export const QUESTION_SETS_UPDATE_SECTION_TITLE = 'Update section'
export const QUESTION_SETS_UPDATE_QUESTION_TITLE = 'Question details'

export const QUESTION_SETS_EDIT_QUESTION_TEXT = 'Update question'

export const QUESTION_SETS_ADD_SECTION_TEXT = 'Add section'
export const QUESTION_SETS_ADD_QUESTION_TEXT = 'Add question'

export const QUESTION_SETS_RISK_GRADING_OPTIONS = [
  { name: 'Choose risk grading', value: '' },
  { name: 'Serious', value: 'serious' },
  { name: 'Minor', value: 'minor' },
  { name: 'Low', value: 'low' },
]
export const QUESTION_SETS_TYPE_OPTIONS = [
  { name: 'Choose type', value: '' },
  { name: 'Yes/No (Yes = 1)', value: QuestionType.CONDITIONAL },
  { name: 'No/Yes (Yes = 0)', value: QuestionType.CONDITIONAL_REVERSE },
  { name: 'Text answer', value: QuestionType.TEXT },
  { name: 'Date answer', value: QuestionType.DATE },
  { name: 'Color answer', value: QuestionType.COLOR },
  { name: 'Percentage', value: QuestionType.PERCENTAGE },
  { name: 'Single select', value: QuestionType.SINGLE },
  { name: 'Multiple select', value: QuestionType.MULTIPLE },
  { name: 'Picture select', value: QuestionType.PICTURE },
  { name: 'Marks', value: QuestionType.MARKS },
]
export const QUESTION_SETS_CONDITIONAL_POLICY_OPTIONS = [
  { name: 'Choose conditional policy', value: '' },
  { name: 'N/A for whole section', value: 'n/a' },
  { name: 'Zero for whole section', value: '0%' },
  { name: '100% for whole section', value: '100%' },
]

export const QUESTION_SETS_SECTION_CONDITIONAL_FIELDS: TableDataFormItem[] = [
  {
    name: 'conditional_policy',
    label: 'Conditional policy',
    placeholder: 'Enter question set name',
    type: 'select',
    component: Select,
    options: QUESTION_SETS_CONDITIONAL_POLICY_OPTIONS,
    renderRelation: 'conditional',
  },
  {
    name: 'question',
    label: 'Question',
    placeholder: 'Enter question',
    component: Textarea,
    height: 250,
    renderRelation: 'conditional',
  },
]
export const QUESTION_SETS_QUESTION_FIELDS: TableDataFormItem[] = [
  {
    name: 'name',
    label: 'Name',
    placeholder: 'Enter question set name',
    type: 'text',
    component: Input,
  },
  {
    name: 'notApplicable',
    label: 'Not applicable',
    type: 'checkbox',
    initial: false,
    component: RadioV2,
  },
  {
    name: 'noScore',
    label: 'No Score',
    type: 'checkbox',
    initial: false,
    component: RadioV2,
  },
  {
    name: 'weighting',
    label: 'Weighting',
    type: 'number',
    component: Input,
    initial: 1,
  },
  {
    name: 'risk_grading',
    label: 'Risk grading',
    type: 'select',
    component: Select,
    options: QUESTION_SETS_RISK_GRADING_OPTIONS,
  },
  {
    name: 'type',
    label: 'Type',
    type: 'select',
    component: Select,
    options: QUESTION_SETS_TYPE_OPTIONS,
  },
  {
    name: 'question',
    label: 'Question',
    placeholder: 'Enter question',
    type: 'textarea',
    component: Textarea,
    height: 250,
  },
  {
    name: 'guidance',
    label: 'Guidance',
    type: 'custom',
    component: TextEditor,
  },
]
export const QUESTION_SETS_SECTIONS_FIELDS: TableDataFormItem[] = [
  {
    name: 'name',
    label: 'Name',
    placeholder: 'Enter section title',
    type: 'text',
    component: Input,
  },
  {
    name: 'conditional',
    label: 'Is this section conditional?',
    type: 'radio',
    initial: false,
    component: RadioV2,
  },
  ...QUESTION_SETS_SECTION_CONDITIONAL_FIELDS,
  {
    name: 'guidance',
    label: 'Guidance',
    type: 'custom',
    component: TextEditor,
  },
]

// export const QUESTION_SETS_SCORING_FIELDS: TableDataFormItem[] = [
//   {
//     name: 'scoring',
//     label: 'Possible Values',
//     type: 'custom',
//     component: QuestionSetsSetScoring,
//   },
// ];

export const QUESTION_SETS_SCORING_POSSIBLE_VALUES_FIELDS: TableDataFormItem[] = [
  {
    name: 'from',
    label: 'Enter from',
    type: 'number',
    component: Input,
  },
  {
    name: 'to',
    label: 'Enter to',
    type: 'number',
    component: Input,
  },
  {
    name: 'color',
    label: 'Choose color',
    type: 'date',
    component: DatePicker,
  },
]

export enum QuestionSetsLinks {
  PREVIEW = 'preview',
  SCORING_GLOBAL = 'scoring-global',
  SCORING = 'scoring',
  QUESTION = 'question',
  SECTION = 'section',
  COPY_QUESTION = 'copy-question',
  COPY_SECTION = 'copy-section',
}

export const QUESTION_SETS_EDIT_QUESTION_LINKS = [
  QuestionSetsLinks.PREVIEW,
  QuestionSetsLinks.QUESTION,
  QuestionSetsLinks.SECTION,
  QuestionSetsLinks.COPY_QUESTION,
]

export const QUESTION_SETS_EDIT_SECTION_LINKS = [
  QuestionSetsLinks.PREVIEW,
  QuestionSetsLinks.SCORING,
  QuestionSetsLinks.QUESTION,
  QuestionSetsLinks.SECTION,
  QuestionSetsLinks.COPY_SECTION,
]

export const QUESTION_SETS_CREATE_QUESTION_AND_SECTION_LINKS = [
  QuestionSetsLinks.PREVIEW,
  QuestionSetsLinks.QUESTION,
  QuestionSetsLinks.SECTION,
]

export const QUESTION_SETS_EDIT_LINKS = [
  QuestionSetsLinks.PREVIEW,
  QuestionSetsLinks.SCORING_GLOBAL,
  QuestionSetsLinks.QUESTION,
  QuestionSetsLinks.SECTION,
]
