import React, { FC, ReactNode } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'

import { Colors } from 'constants/global'
import { deleteCurrentUserFromDB } from 'db/helpers'
import { useAppDispatch } from 'modules/store'
import { userLogout } from 'modules/user/action'
import { Button, UserCard } from 'shared-components'
import { resetUserAuthStorage } from 'utils'

import { PersonalAction } from './style'

const buttonProps = {
  backgroundColor: Colors.WHITE,
  color: Colors.GRAY,
}

interface Props {
  userName: string
  children?: ReactNode
}

const DropdownContent: FC<Props> = ({ userName, children }) => {
  const dispatch = useAppDispatch()

  const handleLogout = async () => {
    resetUserAuthStorage()
    await dispatch(userLogout())
    await deleteCurrentUserFromDB()
  }

  return (
    <div>
      <UserCard userName={userName} />
      <PersonalAction>
        {children}
        <Button onClick={handleLogout} icon={faPowerOff as IconDefinition} {...buttonProps}>
          Log Out
        </Button>
      </PersonalAction>
    </div>
  )
}

export default DropdownContent
