import React, { FC, memo, useContext, useState } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'

import { DISABLED_EVALUATION_TEXT, EntityTypes, EVALUATION_MANUAL_SAVE_QUESTION_TYPES } from 'constants/evaluations'
import { Colors } from 'constants/global'
import { NetworkStatusContext } from 'contexts/NetworkContext'
import { QuestionsContext } from 'contexts/QuestionsContext'
import { updateFullListAnswerById } from 'db/helpers'
import { Question, QuestionType } from 'interfaces'
import { asyncSetNotApplicable, debouncedAsyncSaveQuestionsAnswer } from 'modules/fill-evaluation/action'
import { selectEvaluationSectionCompleteInfo, selectFillEvaluation } from 'modules/fill-evaluation/selectors'
import { setErrorNotify } from 'modules/notifications/action'
import { useAppDispatch, useAppSelector } from 'modules/store'
import { ActionsGroup, FillCardContent } from 'page-components'
import { Button } from 'shared-components'
import { DisabledOverlay } from 'shared-components/disabled-overlay/style'
import { getFlatEvaluationQuestionsInfo, getSectionCompletionContent } from 'utils'

import EvaluationStatusIcon from './EvaluationStatusIcon'
import {
  ButtonForSavingStyles,
  ButtonStyles,
  CompletionStyles,
  DefaultTag,
  DisabledEvaluation,
  EvaluationFillWrapper,
  LeftSide,
  LeftSideContent,
  RightSide,
  UndoButtonStyles,
} from './style'

interface Props extends Question {
  toggleSection?: VoidFunction
  isSectionOpen?: boolean
  position: number | string
  isComplete?: boolean
  sectionPosition?: number | string
}

const EvaluationFillCard: FC<Props> = memo(
  ({
    isSectionOpen,
    toggleSection,
    position,
    entityType,
    hasDefaultAnswer,
    answer = null,
    question,
    notApplicable,
    guidance,
    conditional,
    options,
    type,
    tasksCount,
    hasNote,
    id,
    name,
    isComplete,
    questions,
  }) => {
    const isOnline = useContext(NetworkStatusContext)

    const isDisabled = Number(answer) === -1000

    const [isDisabledQuestion, setIsDisabledQuestion] = useState(notApplicable && isDisabled)

    const [localValue, setLocalValue] = useState<string>('')

    const { id: evaluationId } = useParams()

    const { formik } = useContext(QuestionsContext)

    const { answeredQuestions, totalQuestions, completed } =
      useAppSelector(selectEvaluationSectionCompleteInfo(id)) || {}

    const evaluation = useAppSelector(selectFillEvaluation)

    const dispatch = useAppDispatch()

    const positionText = entityType === EntityTypes.QUESTION ? `Q ${position}` : position

    const defaultTag = 'Change or confirmed the default answer highlighted'

    const onClickClosedSection = (): void => {
      if (conditional) dispatch(setErrorNotify('You should answer "YES" to open this section'))
    }

    const isCheckbox = type === QuestionType.CONDITIONAL_REVERSE || type === QuestionType.CONDITIONAL

    const isHide = isComplete && isCheckbox && (answer === '1' || answer === '0')

    const isShownSectionCompletionInfo = entityType === EntityTypes.SECTION && questions?.length

    const isAnswered = answer !== null

    const { questionsLength, answersCount, completionContent } = getFlatEvaluationQuestionsInfo(questions || [], answer)

    const handleUpdateLocalValue = (value: string) => {
      setLocalValue(value)
    }

    const saveAnswer = async (event: any, value?: string | number) => {
      event.preventDefault()
      event.stopPropagation()
      const answer = localValue || (value as any) || formik?.values[id] || ''

      if (isOnline) {
        dispatch(
          debouncedAsyncSaveQuestionsAnswer({
            id: evaluation.id as number,
            questionId: id,
            answer,
          }),
        )

        return
      }

      if (evaluationId) {
        await updateFullListAnswerById({ evaluationId, id, answer })
      }
    }

    const handleNotApplicable = () => {
      if (isOnline) {
        dispatch((dispatch) => {
          dispatch(
            asyncSetNotApplicable({
              applicable: isDisabledQuestion,
              questionId: id,
              evaluationId: evaluation.id as number,
            }),
          )
        })
      } else {
        formik?.setFieldValue(name, !isDisabledQuestion ? '-1000' : null)
      }
      setIsDisabledQuestion(!isDisabledQuestion)
    }

    if (!formik || isHide) return null

    return (
      <EvaluationFillWrapper>
        <LeftSide>
          <LeftSideContent onClick={!!Number(formik.values[id]) || !conditional ? toggleSection : onClickClosedSection}>
            <span>
              <EvaluationStatusIcon
                isAnswered={isAnswered}
                answersCount={answeredQuestions || answersCount}
                questionsLength={totalQuestions || questionsLength}
              />
            </span>
            <h4>
              {positionText} {name}
            </h4>
            <p>{question}</p>
          </LeftSideContent>
          <FillCardContent
            id={id}
            conditional={conditional}
            options={options}
            type={type}
            answer={answer}
            entityType={entityType}
            onSaveAnswer={saveAnswer}
            hasDefaultAnswer={hasDefaultAnswer}
            evaluationId={evaluation.id || -1}
            handleUpdateLocalValue={handleUpdateLocalValue}
            localValue={localValue}
          />
          {notApplicable && (
            <ButtonStyles>
              <Button
                onClick={handleNotApplicable}
                color={Colors.BLACK}
                backgroundColor={Colors.ORANGE}
                icon={faMinusCircle as IconDefinition}
              >
                Not Applicable
              </Button>
            </ButtonStyles>
          )}
          {isDisabledQuestion && (
            <>
              <DisabledOverlay>
                <DisabledEvaluation>{DISABLED_EVALUATION_TEXT}</DisabledEvaluation>
                <UndoButtonStyles type='button' onClick={handleNotApplicable}>
                  Undo
                </UndoButtonStyles>
              </DisabledOverlay>
            </>
          )}
          {EVALUATION_MANUAL_SAVE_QUESTION_TYPES.includes(type) ? (
            <ButtonForSavingStyles>
              <Button type='button' onClick={saveAnswer} backgroundColor={Colors.GREEN}>
                Save answer
              </Button>
            </ButtonForSavingStyles>
          ) : null}
          {hasDefaultAnswer && entityType === EntityTypes.QUESTION && <DefaultTag>{defaultTag}</DefaultTag>}
        </LeftSide>
        <RightSide>
          <ActionsGroup
            notApplicable={notApplicable}
            guidance={guidance}
            entityType={entityType}
            tasksCount={tasksCount}
            questionId={id}
            hasNote={hasNote}
          />
          {isShownSectionCompletionInfo && (
            <CompletionStyles>
              {completed
                ? getSectionCompletionContent(completed, answeredQuestions, totalQuestions)
                : completionContent}
              <button
                type='button'
                onClick={() => {
                  !!Number(formik.values[id]) || !conditional
                    ? toggleSection && toggleSection()
                    : onClickClosedSection()
                }}
              >
                {!isSectionOpen || (EntityTypes.SECTION && answer === '0') ? '+' : '-'}
              </button>
            </CompletionStyles>
          )}
        </RightSide>
      </EvaluationFillWrapper>
    )
  },
)

export default EvaluationFillCard

EvaluationFillCard.displayName = 'EvaluationFillCard'
