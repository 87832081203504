import React, { FC, ReactNode, useRef } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactDOM from 'react-dom'

import { Colors } from 'constants/global'
import { ALIGN } from 'constants/shared'
import useOutsideClick from 'hooks/useOutsideClick'
import { AdditionalButton } from 'interfaces'
import { Button, LoadingLine } from 'shared-components'

import { Bottom, Content, ModalWrapper, Overlay, Top } from './style'

interface Props {
  isOpen: boolean
  onClose: VoidFunction
  children: ReactNode
  title: string
  submitBtnText?: string
  onSubmit?: VoidFunction
  isCloseOnSubmit?: boolean
  isLoading?: boolean
  isDisableOutsideClick?: boolean
  additionalButtons?: AdditionalButton[]
  isCloseHidden?: boolean
  isCrossHidden?: boolean
  bottomAlign?: ALIGN
  className?: string
  contentClassName?: string
}

const ModalWindow: FC<Props> = ({
  isOpen,
  onClose,
  title,
  children,
  submitBtnText,
  onSubmit,
  isCloseOnSubmit,
  additionalButtons,
  isCloseHidden,
  isCrossHidden,
  bottomAlign,
  className,
  contentClassName,
  isDisableOutsideClick = false,
  isLoading = false,
}) => {
  const modalRef = useRef(null)

  const handleSubmit = (): void => {
    if (onSubmit) onSubmit()
    if (isCloseOnSubmit) onClose()
  }

  useOutsideClick(isDisableOutsideClick ? null : modalRef, onClose)

  return ReactDOM.createPortal(
    <Overlay isOpen={isOpen}>
      <LoadingLine isLoading={isLoading} />
      <ModalWrapper ref={modalRef} className={className}>
        <Top>
          <h4>{title}</h4>
          {!isCrossHidden && <FontAwesomeIcon icon={faClose as IconDefinition} onClick={onClose} />}
        </Top>
        <Content className={contentClassName}>{children}</Content>
        <Bottom align={bottomAlign}>
          {!isCloseHidden && (
            <Button onClick={onClose} backgroundColor={Colors.GRAY}>
              Close
            </Button>
          )}
          {additionalButtons?.length &&
            additionalButtons.map(({ text, action, disabled }) => (
              <Button key={text} onClick={action} disabled={disabled}>
                {text}
              </Button>
            ))}
          {onSubmit && (
            <Button onClick={handleSubmit} backgroundColor={Colors.BLUE}>
              {submitBtnText || 'Save changes'}
            </Button>
          )}
        </Bottom>
      </ModalWrapper>
    </Overlay>,
    document.getElementById('modal') as HTMLElement,
  )
}

export default ModalWindow
