import React, { FC, useContext } from 'react'

import { useFormik } from 'formik'
import pickBy from 'lodash/pickBy'
import { useNavigate } from 'react-router-dom'

import { NetworkStatusContext } from 'contexts/NetworkContext'
import { QuestionsContext } from 'contexts/QuestionsContext'
import { saveAnswers, updateFullListAnswers } from 'db/helpers'
import { FilteredQuestionTask, KeyWithString, Question, StringOrNullType } from 'interfaces'
import { asyncSaveQuestionsAnswers } from 'modules/fill-evaluation/action'
import { setSuccessNotify } from 'modules/notifications/action'
import { useAppDispatch } from 'modules/store'
import { EvaluationBottom, QuestionsList } from 'page-components'
import { getDisplayQuestions, getEvaluationCompleteLink, getFlatEvaluationQuestionsWithSections } from 'utils'

import { QuestionsContainerWrapper } from './style'

interface Props {
  questions: Question[]
  id: number
  hideBottomActions?: boolean
  customSubmitFunc?: (values: KeyWithString) => void
  isComplete?: boolean
  isDisabled?: boolean
}

export interface FilteredQuestion {
  id: number
  name: string
  question: StringOrNullType
  answer: StringOrNullType
  notes: { content: string }[]
  guidance: string
  questions: FilteredQuestion[] | null
  tasks: FilteredQuestionTask[]
  newTasks: FilteredQuestionTask[]
  entityType: StringOrNullType
}

const QuestionsContainer: FC<Props> = ({
  questions,
  id,
  hideBottomActions,
  customSubmitFunc,
  isComplete,
  isDisabled,
}) => {
  const isOnline = useContext(NetworkStatusContext)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const flatQuestions = getFlatEvaluationQuestionsWithSections(questions) || []

  const formik = useFormik({
    initialValues: getDisplayQuestions(flatQuestions) as KeyWithString,

    onSubmit: async (values) => {
      if (isDisabled) return null
      const answers = pickBy(values, function (value) {
        return value !== ''
      })

      if (customSubmitFunc) {
        return customSubmitFunc(answers)
      }
      if (isOnline) {
        dispatch(
          asyncSaveQuestionsAnswers({
            id,
            answer: answers,
            completePage: true,
          }),
        )
      } else {
        await saveAnswers(id, answers)
        await updateFullListAnswers(id, answers)

        dispatch(setSuccessNotify('Offline answers was successfully saved'))
        navigate(getEvaluationCompleteLink(id))
      }
    },
    enableReinitialize: true,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <QuestionsContainerWrapper>
        <QuestionsContext.Provider value={{ formik, isDisabled, evaluationId: id }}>
          <QuestionsList questions={questions} isComplete={isComplete} />
        </QuestionsContext.Provider>
      </QuestionsContainerWrapper>
      {!hideBottomActions && <EvaluationBottom />}
    </form>
  )
}

export default QuestionsContainer
