import styled from 'styled-components'

import { Colors } from 'constants/global'
import { SkeletonBlock } from 'shared-components/shimmer/style'

export const MyEvaluationsItemWrapper = styled.tr<{
  isOfflineItem?: boolean
  isDisabled?: boolean
}>`
  position: relative;
  &:after {
    display: ${({ isDisabled }) => (isDisabled ? 'block' : 'none')};
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    user-select: none;
    cursor: not-allowed;
    z-index: 1000;
  }

  & p {

    & span {
    margin-top: 0px;
    line-height: unset;
    display: unset;
    }
  }

  & span {
    display: block;
    margin-top: 4px;
    font-size: 12.8px;
    line-height: 24px;
  }

  & a {
    color: ${({ theme }) => theme.colors.blue1};
  }

  & a:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.blue4};

  }
    & > svg {
      margin-right: 4px;
    }
  }

  & button {
    font-size: 14px;
    min-height: 38px;
  }

  & td {
    vertical-align: top;
  }

  & td:nth-child(7) {
    text-align: center;
  }
  & td:nth-child(8) {
    text-align: center;
  }
  & td:last-child {
    display: flex;
  }

  & td:nth-child(6) {
    //width: 150px;
    display: flex;
    flex-direction: row;
    flex: 0 1 auto;
    & button {
      &:first-child {
        border-top-right-radius: ${({ isOfflineItem }) => (isOfflineItem ? 0 : 'auto')};
        border-bottom-right-radius: ${({ isOfflineItem }) => (isOfflineItem ? 0 : 'auto')};
      }
      &:last-child {
        border-top-left-radius: ${({ isOfflineItem }) => (isOfflineItem ? 0 : 'auto')};
        border-bottom-left-radius: ${({ isOfflineItem }) => (isOfflineItem ? 0 : 'auto')};
      }
    }
  }
  
  & label {
    padding-top: 10px;
  }
`

export const StatusBlock = styled.div<{
  backgroundColor?: Colors
  className?: string
}>`
  padding: 5px ${({ theme }) => theme.spaces.min2};
  text-align: center;
  white-space: nowrap;
  line-height: 1;
  color: #fff;
  border-radius: 10rem;
  font-size: 1rem !important;
  font-weight: 700;
  background: ${(props) => (props.backgroundColor === Colors.RED ? '#dc3545' : '#17a2b8')};
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

export const SkeletonWrapper = styled.div`
  padding: ${({ theme }) => theme.spaces.min3} 32px;
`

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 15%;
  @media (max-width: 576px) {
    flex: 0 1 20%;

    &:nth-child(3) {
      display: none;
    }
  }

  @media (max-width: 400px) {
    flex: 0 1 45%;

    &:nth-child(4),
    &:nth-child(5) {
      display: none;
    }
  }
`

export const StatusBlockWrapper = styled.div`
  display: block;
  width: 100%;
`

export const HeaderItemShimmer = styled(SkeletonBlock)`
  height: 30px;
  width: 100%;
`

export const TextShimmer = styled(SkeletonBlock)`
  margin-top: 8px;
  width: 70%;
  height: 15px;
`

export const ShortTextShimmer = styled(SkeletonBlock)`
  width: 30%;
  height: 15px;
`

export const ProgressShimmer = styled(SkeletonBlock)`
  width: 80%;
  height: 25px;
`

export const ButtonShimmer = styled(SkeletonBlock)`
  width: 50%;
  height: 25px;
`
