import React, { FC } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLiveQuery } from 'dexie-react-hooks'

import { db } from 'db/mainDb'
import useOpen from 'hooks/useOpen'
import { useAppSelector } from 'modules/store'
import { selectUserId, selectUserPinCode } from 'modules/user/selectors'
import { UserDropdown, UserPinCode } from 'page-components'
import UserPinCodeModalContent from 'page-components/auth/user-pin-code/UserPinCodeModalContent'
import { ModalWindow } from 'shared-components'

import { HeaderContainer, HeaderEnvironment, HeaderLeftSideContainer, HeaderMenuButton, HeaderWrapper } from './style'

interface Props {
  isSidebarOpen: boolean
  handleOpenSidebar: VoidFunction
}

const Header: FC<Props> = ({ handleOpenSidebar, isSidebarOpen }) => {
  const userId = useAppSelector(selectUserId)
  const userPinCode = useAppSelector(selectUserPinCode)

  const { authPinCode = '', id } = useLiveQuery(() => db.users.get({ id: userId || '' }), [userId, userPinCode]) || {}

  const [isPinModalOpen, handleOpenPinModal, handleClosePinModal] = useOpen(false)

  const updateOrCreate = authPinCode ? 'Update' : 'Create'

  return (
    <HeaderWrapper isSidebarOpen={isSidebarOpen}>
      <HeaderContainer>
        <HeaderLeftSideContainer>
          <HeaderMenuButton onClick={handleOpenSidebar}>
            <FontAwesomeIcon icon={faBars as IconDefinition} size='lg' />
          </HeaderMenuButton>
          <ModalWindow
            className='minified-modal'
            contentClassName='modal-content-no-border'
            isOpen={!authPinCode && id ? true : isPinModalOpen}
            onClose={handleClosePinModal}
            title={`${updateOrCreate} offline pin access code`}
            isCrossHidden={!authPinCode}
            isCloseHidden
          >
            <UserPinCodeModalContent userId={userId} pinCode={authPinCode} handleCloseModal={handleClosePinModal} />
          </ModalWindow>
        </HeaderLeftSideContainer>
        <HeaderEnvironment>{process.env.REACT_APP_NAME}</HeaderEnvironment>
        <UserDropdown isPinModalOpen={isPinModalOpen}>
          <UserPinCode handleOpenModal={handleOpenPinModal} />
        </UserDropdown>
      </HeaderContainer>
    </HeaderWrapper>
  )
}

export default Header
