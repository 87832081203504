export const routes = {
  home: '/*',
  notFound: '*',
  // dashboard: '/',
  login: '/login',
  reset: '/password/reset',
  resetPassword: '/password/reset/:token',
  profile: '/profile',
  myEvaluations: '/',
  verify: '/verify',
  evaluation: '/own/evaluations/fill/:id',
  fillEvaluation: '/own/evaluations/fill',
  categories: '/categories',
  categoriesCreate: '/categories/create',
  categoriesEdit: '/categories/:id/edit',
  riskGradings: '/risk-gradigns',
  riskGradingsCreate: '/risk-gradigns/create',
  riskGradingsEdit: '/risk-gradigns/:id/edit',
  questionSets: '/questionSets',
  evaluations: '/evaluations',
  evaluationsCreate: '/evaluations/create',
  evaluationsEdit: '/evaluations/:id/edit',
  edit: '/edit',
  create: '/create',
  questionSetsActive: '/questionSets',
  questionSetsArchive: '/questionSetsArchive',
  questionSetsView: '/questionSets/:viewId',
  questionSetsEdit: '/questionSets/:id/edit',
  questionSetsCreate: '/questionSets/create',
  questionSetsPreview: '/questionSets/:id/preview',
  questionSetsScoring: '/questionSets/:id/setSectionScoring/:questionId',
  questionSetsCreateQuestion: '/questionSets/:id/createQuestion',
  questionSetsCreateSection: '/questionSets/:id/createSection',
  questionSetsEditQuestion: '/questionSets/:id/editQuestion/:questionId',
  questionSetsEditSection: '/questionSets/:id/editSection/:sectionId',
  questionSetsCopySection: '/questionSets/:id/copySection/:copyId',
  questionSetsCopyQuestion: '/questionSets/:id/copyQuestion/:copyId',
  evaluationComplete: '/own/evaluations/complete/:id',
  evaluationFinish: '/own/evaluations/finish/:id',
  evaluationFinishTo: '/own/evaluations/finish',
  evaluationCompleteTo: '/own/evaluations/complete',
  areas: '/areas',
  areasEdit: '/areas/:id/edit',
  areaFields: '/areaFields',
  areaFieldsCreate: '/areaFields/create',
  areaFieldsEdit: '/areaFields/:id/edit',
}
