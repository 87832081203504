import { Dispatch, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import addDays from 'date-fns/addDays'
import { useLiveQuery } from 'dexie-react-hooks'
import { useFormik, getIn } from 'formik'

import { MyTasksFieldNames, MyTasksFields } from 'constants/evaluations'
import { NetworkStatusContext } from 'contexts/NetworkContext'
import { db } from 'db/mainDb'
import { FillEvaluationMyTasks, KeyWithString, MyTaskUser, NumberOrNullType } from 'interfaces'
import { asyncCreateMyTask } from 'modules/evaluation-actions-group/action'
import { useAppDispatch, useAppSelector } from 'modules/store'
import { getFormikValidationShared } from 'utils/shared'

import { selectTasksInfo } from '../modules/evaluation-actions-group/selectors'

const useTasks = (
  evaluationId: number,
  editId: number | null,
  setEditId: Dispatch<NumberOrNullType>,
  onClose: VoidFunction,
) => {
  const dispatch = useAppDispatch()
  const isOnline = useContext(NetworkStatusContext)

  const { tasks = [], users = [], priorities = [], precannedTasks = [], questionId } = useAppSelector(selectTasksInfo)
  const offlineTasks = useLiveQuery(
    () => db.tasksList.get({ id: questionId || '', evaluationId: evaluationId || '' }),
    [questionId, evaluationId],
  )

  const liveQueryNewTasks = useLiveQuery(
    () => (questionId ? db.newTasks.where('questionId').equals(questionId).toArray() : []),
    [questionId],
  )

  const [offlineUsersList, setOfflineUsersList] = useState<MyTaskUser[]>([])

  useEffect(() => {
    const fetchOfflineUsersList = async () => {
      const users = await db.evaluationsUsers.toArray()

      const filteredUsers = users?.filter((user) => user.evaluationId?.includes(evaluationId))
      setOfflineUsersList(filteredUsers)
    }

    fetchOfflineUsersList()
  }, [evaluationId])

  const offlinePrioritiesList = useLiveQuery(() => db.priorities.toArray())

  const offlineTasksRecommendations = useLiveQuery(async () => {
    const evaluation = await db.fullList.get({ id: evaluationId })

    const evaluationQuestion = evaluation?.questions?.flat(10)?.find(({ id }) => id === questionId)
    return evaluationQuestion?.options?.[evaluationQuestion.type]?.recommendations || []
  })

  const newTasks = useMemo(() => {
    return liveQueryNewTasks?.map((i) => i.task) || []
  }, [liveQueryNewTasks])

  const allTasks = useMemo(() => {
    return isOnline ? tasks : [...(offlineTasks?.tasks || []), ...newTasks] || []
  }, [isOnline, newTasks, offlineTasks?.tasks, tasks])

  const allUsers = useMemo(() => {
    return isOnline ? users : offlineUsersList || []
  }, [isOnline, offlineUsersList, users])

  const allPriorities = useMemo(() => {
    if (isOnline) return priorities

    if (offlineTasks?.priorities?.length) return offlineTasks?.priorities

    return offlinePrioritiesList
  }, [isOnline, priorities, offlineTasks?.priorities, offlinePrioritiesList])

  const allTasksRecommendations = useMemo(() => {
    if (isOnline) return precannedTasks

    if (offlineTasks?.precannedTasks?.length) return offlineTasks?.precannedTasks

    return offlineTasksRecommendations
  }, [isOnline, offlineTasks?.precannedTasks, offlineTasksRecommendations, precannedTasks])

  const fieldsToRender = useMemo(() => {
    const fields = MyTasksFields

    fields[0].options = allTasksRecommendations?.length
      ? allTasksRecommendations.map(({ priority, recommendation, ...item }) => ({
          value: priority,
          name: recommendation,
          ...item,
        }))
      : []

    fields[2].options = allPriorities?.length
      ? allPriorities.map(({ id, ...item }) => ({
          value: id,
          placeholder: '',
          ...item,
        }))
      : []

    fields[3].options = allPriorities?.length
      ? allPriorities.map(({ id, ...item }) => ({
          value: id,
          ...item,
        }))
      : []

    fields[4].options = allUsers?.length
      ? allUsers.map(({ id, ...item }) => ({
          value: id,
          ...item,
        }))
      : []

    return fields
  }, [allTasksRecommendations, allUsers, allPriorities])

  const editingTask = useMemo(() => {
    if (!editId || !allTasks?.length) return null

    return allTasks?.find((i) => i.id === editId)
  }, [allTasks, editId])

  const formik = useFormik({
    initialValues: {
      completionDue: '',
      priority: '',
      assignedTo: '',
      recommendation: '',
    } as KeyWithString,
    onSubmit: (data) => {
      if (!evaluationId || !questionId) return

      dispatch(
        asyncCreateMyTask({
          evaluationId,
          questionId,
          task_id: editId,
          data,
          isOnline,
          tasksCount: tasks?.length,
        }),
      )
      formik.resetForm()
      if (editId) setEditId(null)
    },
    validationSchema: getFormikValidationShared(fieldsToRender),
    validateOnBlur: false,
    validateOnChange: false,
  })

  const watchPriorityId = getIn(formik.values, MyTasksFieldNames.PRIORITY)

  const onCloseModalWindow = useCallback(() => {
    onClose()
    setEditId(null)
    formik.resetForm()
  }, [formik, onClose, setEditId])

  useEffect(() => {
    const priorityDays = allPriorities?.find(({ id }) => id === Number(watchPriorityId))?.days

    formik.setFieldValue(
      MyTasksFieldNames.COMPLETION_DUE,
      priorityDays !== undefined ? addDays(new Date(), priorityDays) : undefined,
    )
  }, [watchPriorityId, allPriorities])

  useEffect(() => {
    if (editingTask) {
      formik.setValues({
        completionDue: editingTask?.complete_before || '',
        priority: editingTask?.priority || '',
        assignedTo: editingTask?.assign_id || '',
        recommendation: editingTask?.content || '',
      } as KeyWithString)
    }
    //eslint-disable-next-line
  }, [editId])

  return useMemo(() => {
    return {
      onCloseModalWindow,
      formik,
      tasks: allTasks,
      onlineTasks: tasks,
      fieldsToRender,
      offlineTasks: { ...offlineTasks, precannedTasks: offlineTasksRecommendations } as FillEvaluationMyTasks,
    }
  }, [allTasks, fieldsToRender, formik, offlineTasks, offlineTasksRecommendations, onCloseModalWindow])
}

export default useTasks
