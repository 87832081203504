import { createSlice } from '@reduxjs/toolkit'

import { BASE_PAGINATION_DATA } from 'constants/shared'
import { AreaFieldsState } from 'interfaces'

import { AREA_FIELDS_SLICE_NAME } from './action'
import {
  createAreaFieldReducer,
  deleteAreaFieldReducer,
  editAreaFieldReducer,
  getAreaFieldReducer,
  getAreaFieldsReducer,
} from './reducer'

const initialState: AreaFieldsState = {
  areaFields: [],
  current: null,
  isLoading: false,
  isCalled: false,
  ...BASE_PAGINATION_DATA,
}

export const areaFieldsSlice = createSlice({
  name: AREA_FIELDS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getAreaFieldsReducer(builder)
    deleteAreaFieldReducer(builder)
    createAreaFieldReducer(builder)
    editAreaFieldReducer(builder)
    getAreaFieldReducer(builder)
  },
})
export default areaFieldsSlice.reducer
