import * as Yup from 'yup'

import { TableDataFormItem } from 'interfaces'
import { Input, Select, Textarea, InputFile, DatePicker } from 'shared-components'

import { AreaFieldsTypes } from './area-fields'

export const AREAS_PAGE_TITLE = 'Manage areas'

export const AREAS_LIST_TITLE = 'Current Areas'

export const AREAS_FORM_TITLE = 'Add new area'

export const AREAS_FORM_EDIT_TITLE = 'Edit area'

export const AREAS_FORM_LANGUAGES = [
  { name: 'Select language', value: '' },
  { name: 'English', value: 'en' },
  { name: 'French', value: 'fr' },
]

export const AREAS_FORM_FIELDS: TableDataFormItem[] = [
  {
    name: 'name',
    label: 'Name',
    placeholder: 'Enter name',
    type: 'text',
    component: Input,
    validation: Yup.string().required('Required field'),
  },
  {
    name: 'language',
    label: 'Language',
    component: Select,
    options: AREAS_FORM_LANGUAGES,
    validation: Yup.string().required('Required field'),
  },
  {
    name: 'files',
    label: 'Files',
    type: 'custom',
    component: InputFile,
  },
]

export const AreasFormTypes = {
  [AreaFieldsTypes.SINGLE_LINE]: {
    type: 'text',
    component: Input,
    height: 38,
    style: { resize: 'none' },
  },
  [AreaFieldsTypes.MULTI_LINE]: {
    type: 'textarea',
    component: Textarea,
    height: 250,
  },
  [AreaFieldsTypes.DATE]: {
    type: 'custom',
    component: DatePicker,
  },
  [AreaFieldsTypes.EMAIL]: {
    type: 'text',
    component: Input,
  },
}
