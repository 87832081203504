import styled from 'styled-components'

export const TextareaWrapper = styled.div<{
  height?: number
}>`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spaces.min3};

  & > textarea {
    width: 100%;
    height: ${({ height }) => height}px !important;
    outline: none;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.border2};
    padding: ${({ theme }) => `${theme.spaces.min1} ${theme.spaces.min2}`};
    &:focus {
      border-color: ${({ theme }) => theme.colors.blue3};
    }
  }

  .singleLine {
    resize: none;
    overflow: hidden;
    white-space: nowrap;
  }
`
