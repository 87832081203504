import { useEffect } from 'react'

import { db } from 'db/mainDb'

const APP_INDEXED_DB_VERSION = 1

const useAppVersion = () => {
  const manageDbVersion = async () => {
    try {
      const dbVersionObject = await db.dbVersion.get({ id: 1 })

      const currentDbVersion = dbVersionObject?.version

      // If currentVersion not exist - create
      if (!currentDbVersion) {
        await db.dbVersion.put({ id: 1, version: APP_INDEXED_DB_VERSION })
      }

      if (currentDbVersion && currentDbVersion < APP_INDEXED_DB_VERSION) {
        // Open the database before deleting
        await db.open()

        // Delete the database and update the version
        await db.delete()
        await db.open()

        await db.dbVersion.put({ id: 1, version: APP_INDEXED_DB_VERSION })
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    manageDbVersion().catch((e) => console.log(e))
  }, [])
}

export default useAppVersion
