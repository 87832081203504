import React, { FC } from 'react'

import { format } from 'date-fns'
import { useLiveQuery } from 'dexie-react-hooks'

import { DEFAULT_DATE_FORMAT } from 'constants/global'
import { db } from 'db/mainDb'
import { MyTask, KeyWithAnyValue } from 'interfaces'
import { selectTasksUserNameById } from 'modules/evaluation-actions-group/selectors'
import { useAppSelector } from 'modules/store'
import { ActionButtons } from 'shared-components'

import { TaskTableItemWrapper } from './style'

interface Props extends MyTask {
  actionFunctions: KeyWithAnyValue
}

const TaskTableItem: FC<Props> = ({ id, content, complete_before, assign_id, priority, actionFunctions }) => {
  const userName = useAppSelector(selectTasksUserNameById(assign_id))

  const userNameOffline = useLiveQuery(() => db.evaluationsUsers.get({ id: Number(assign_id) }))?.name

  const priorityOffline = useLiveQuery(() => db.priorities.get({ id: Number(priority) }))?.name

  const formattedDueDate = complete_before ? format(new Date(complete_before), DEFAULT_DATE_FORMAT, {}) : null

  return (
    <TaskTableItemWrapper>
      {content && <td dangerouslySetInnerHTML={{ __html: content.replace(/<(img|iframe|table|br)[^>]*>/g, '') }} />}
      <td>{userName || userNameOffline}</td>
      <td>{priority || priorityOffline}</td>
      <td>{formattedDueDate}</td>
      <td></td>
      <td>
        <ActionButtons id={id} actionFunctions={actionFunctions} />
      </td>
    </TaskTableItemWrapper>
  )
}

export default TaskTableItem
