import axios, { AxiosInstance } from 'axios'

import { ACCESS_TOKEN_KEY, REMEMBER_ME_KEY } from 'constants/auth'
import { resetUserAuthStorage } from 'utils'

const { REACT_APP_NEXT_PUBLIC_API_URL = 'https://pro-evaluate.dev.evolved-innovations.co.uk' } = process.env

function createHttpClient(endPoint = ''): AxiosInstance {
  const instance = axios.create({
    baseURL: `${REACT_APP_NEXT_PUBLIC_API_URL}${endPoint}`,
    timeout: 0,
  })

  instance.interceptors.request.use((config) => {
    const isRemember = !!localStorage.getItem(REMEMBER_ME_KEY)
    const token = (isRemember ? localStorage : sessionStorage).getItem(ACCESS_TOKEN_KEY)
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      }
    }
    return config
  })

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error?.response?.status === 401 && window.navigator.onLine) {
        resetUserAuthStorage()
      }
      return Promise.reject(error)
    },
  )

  return instance
}

export const httpEmptyClient = createHttpClient('/')
export const httpApiClient = createHttpClient('/api/v1')
export const httpApiClientV2 = createHttpClient('/api/v2')
export const httpApiClientV3 = createHttpClient('/api/v3')
export const httpApiClientV4 = createHttpClient('/api/v4')
