import React, { FC } from 'react'

import PaginationPanel, { PaginationProps } from 'rc-pagination'

import { PaginationWrapper } from './style'

const Pagination: FC<PaginationProps> = (props) => {
  const { total, pageSize } = props

  if (total && pageSize && total <= pageSize) return null

  return (
    <PaginationWrapper>
      <PaginationPanel {...props} />
    </PaginationWrapper>
  )
}

export default Pagination
