import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import { AllEvaluationsDropdownInfo, AllEvaluationsState, AllEvaluations, Evaluation } from 'interfaces'

import {
  createEvaluation,
  deleteFromAllEvaluationsList,
  editEvaluation,
  getAllEvaluationsDropdownsList,
  getAllEvaluationsEditInfo,
  getAllEvaluationsList,
  resetEvaluation,
} from './action'

export function getAllEvaluationsReducer(builder: ActionReducerMapBuilder<AllEvaluationsState>) {
  builder.addCase(getAllEvaluationsList.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(getAllEvaluationsList.fulfilled, (state, action: PayloadAction<AllEvaluations>) => {
    const { data, ...rest } = action.payload
    return {
      ...state,
      allEvaluations: data,
      isLoading: false,
      ...rest,
    }
  })

  builder.addCase(getAllEvaluationsList.rejected, (state) => {
    state.isLoading = false
  })
}

export function deleteFromAllEvaluationsListReducer(builder: ActionReducerMapBuilder<AllEvaluationsState>) {
  builder.addCase(deleteFromAllEvaluationsList.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(deleteFromAllEvaluationsList.fulfilled, (state, action: PayloadAction<number>) => {
    state.allEvaluations = state.allEvaluations.filter((i) => i.id !== action.payload)
    state.isLoading = false
  })
  builder.addCase(deleteFromAllEvaluationsList.rejected, (state) => {
    state.isLoading = false
  })
}

export function createEvaluationReducer(builder: ActionReducerMapBuilder<AllEvaluationsState>) {
  builder.addCase(createEvaluation.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(createEvaluation.fulfilled, (state, action: PayloadAction<Evaluation[]>) => {
    state.isLoading = false
    state.allEvaluations = [...state.allEvaluations, ...action.payload]
  })
  builder.addCase(createEvaluation.rejected, (state) => {
    state.isLoading = false
  })
}

export function editEvaluationReducer(builder: ActionReducerMapBuilder<AllEvaluationsState>) {
  builder.addCase(editEvaluation.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(editEvaluation.fulfilled, (state, action: PayloadAction<Evaluation>) => {
    state.isLoading = false
    state.allEvaluations = state.allEvaluations.map((i) => (i.id === action.payload.id ? action.payload : i))
  })
  builder.addCase(editEvaluation.rejected, (state) => {
    state.isLoading = false
  })
}

export function getAllEvaluationsDropdownsListReducer(builder: ActionReducerMapBuilder<AllEvaluationsState>) {
  builder.addCase(
    getAllEvaluationsDropdownsList.fulfilled,
    (state, action: PayloadAction<AllEvaluationsDropdownInfo>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  )
}

export function getAllEvaluationsEditInfoReducer(builder: ActionReducerMapBuilder<AllEvaluationsState>) {
  builder.addCase(getAllEvaluationsEditInfo.fulfilled, (state, action: PayloadAction<AllEvaluationsDropdownInfo>) => {
    return {
      ...state,
      ...action.payload,
    }
  })
}

export function resetReducer(builder: ActionReducerMapBuilder<AllEvaluationsState>) {
  builder.addCase(resetEvaluation, (state) => {
    state.evaluation = null
  })
}
