import styled from 'styled-components'

export const QuestionSetsSettingTopWrapper = styled.div`
  padding: 0 8px;
  margin-bottom: ${({ theme }) => theme.spaces.min3};
  & > div {
    display: flex;
    justify-content: flex-end;
    & a {
      padding: 2px 4px;
      font-size: 12px;
      &:not(:first-child) {
        margin-left: 4px;
      }
    }
  }
`
