import React, { FC, ReactNode, useRef } from 'react'

import useOutsideClick from 'hooks/useOutsideClick'

import { Wrapper } from './style'

interface Props {
  width?: number
  children: ReactNode
  isOpen: boolean
  onClose: VoidFunction
  isDisabledOutsideClick?: boolean
}

const Dropdown: FC<Props> = ({ children, isOpen, onClose, isDisabledOutsideClick, ...props }) => {
  const dropdownRef = useRef(null)

  useOutsideClick(isDisabledOutsideClick ? null : dropdownRef, onClose)

  if (!isOpen) return null

  return (
    <Wrapper ref={dropdownRef} {...props}>
      {children}
    </Wrapper>
  )
}

export default Dropdown
