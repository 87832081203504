import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'modules/store'
import { convertAreasToDragList, convertAreasV4ListToOptions } from 'utils'

const selectState = (x: RootState) => x.areasReducer

export const selectAreas = createSelector(selectState, (state) => convertAreasToDragList(state.areas))

export const selectCurrentArea = createSelector(selectState, (state) => state.currentArea)

export const selectCurrentAreaId = createSelector(selectState, (state) => state?.currentArea?.id)

export const selectAreasListAsOptions = createSelector(selectState, (state) =>
  convertAreasV4ListToOptions(state.areasList),
)
