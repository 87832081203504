import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import { TableDefaultItemInterface, RiskGradingsInitialState, ListResponse, RiskGradingsItemResponse } from 'interfaces'

import { createRiskAction, deleteRiskAction, getRiskGradingsList, getRiskAction, editRiskAction } from './action'

export function getRiskGradingsReducer(builder: ActionReducerMapBuilder<RiskGradingsInitialState>) {
  builder.addCase(getRiskGradingsList.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(getRiskGradingsList.fulfilled, (state, action: PayloadAction<ListResponse>) => {
    const { data, ...pagination } = action.payload
    return {
      ...state,
      ...pagination,
      list: data,
      isLoading: false,
    }
  })

  builder.addCase(getRiskGradingsList.rejected, (state) => {
    state.isLoading = false
  })
}

export function getRiskReducer(builder: ActionReducerMapBuilder<RiskGradingsInitialState>) {
  builder.addCase(getRiskAction.fulfilled, (state, action: PayloadAction<RiskGradingsItemResponse>) => {
    state.currentRisk = action.payload.riskGrading
  })
}

export function createRiskReducer(builder: ActionReducerMapBuilder<RiskGradingsInitialState>) {
  builder.addCase(createRiskAction.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(createRiskAction.fulfilled, (state, action: PayloadAction<TableDefaultItemInterface>) => {
    state.list.push(action.payload)
    state.isLoading = false
  })

  builder.addCase(createRiskAction.rejected, (state) => {
    state.isLoading = false
  })
}

export function editRiskReducer(builder: ActionReducerMapBuilder<RiskGradingsInitialState>) {
  builder.addCase(editRiskAction.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(editRiskAction.fulfilled, (state, action: PayloadAction<TableDefaultItemInterface>) => {
    state.list = state.list.map((i) => (i.id === action.payload.id ? action.payload : i))
    state.isLoading = false
  })

  builder.addCase(editRiskAction.rejected, (state) => {
    state.isLoading = false
  })
}

export function deleteRiskReducer(builder: ActionReducerMapBuilder<RiskGradingsInitialState>) {
  builder.addCase(deleteRiskAction.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(deleteRiskAction.fulfilled, (state, action: PayloadAction<number>) => {
    state.list = state.list.filter((i) => i.id !== action.payload)
    state.isLoading = false
  })

  builder.addCase(deleteRiskAction.rejected, (state) => {
    state.isLoading = false
  })
}
